import React from "react";
import "./index.scss";

import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

import * as basicStylesModule from "../../../../modules/basicStylesModule";
import * as backendModule from "../../../../modules/backendModule";
import * as siteFunctionsActions from "../../../../actions/siteFunctionsActions";

import { FilteredCustomTable } from "../../../../components/customComponents/Table";
import Spinner from "../../../../components/customComponents/Spinner";
import FilterByDate from "../../../../components/filters/FilterByDate";

const DesignersReport = () => {
    const [data, setData] = React.useState();
    const [dateFilters, setDateFilters] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const timestampRef = React.useRef();
    const curDispatch = useDispatch();

    const getData = (ts) => {
        if (!dateFilters) return;

        let filters = [];
        if (dateFilters?.start && dateFilters?.end) {
            filters.push({name: "takenAt", op: "pdgeq", value: dateFilters.start.toDate().getTime()});
            filters.push({name: "takenAt", op: "pdleq", value: dateFilters.end.toDate().getTime()});
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/requests/getReportStats`,
            data: {
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            setData(res.data);
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        });
    };

    const getKPISum = (col) => {
        if (!data) return "-";
        if (data?.status !== "ok") return "-";

        let out = 0;
        let count = 0;

        for (let item of data.data) {
            count += 1;
            out += item[col];
        };

        if (col === "AverageTime") out /= count;
        if (isNaN(out)) out = 0;
        return out;
    };

    const convertSecondsToString = seconds => {
        if (!seconds) return "-";
        let s = Number(Number(seconds).toFixed(0));
        let m = 0;
        let h = 0;

        while (s >= 60) {
            s -= 60;
            m += 1;

            if (m >= 60) {
                h += 1;
                m -= 60;
            };
        };

        let out = [];
        if (h) out.push(`${h}h`);
        if (h || m) out.push(`${m}m`);
        out.push(`${s}s`);

        return out.join(":");
    };

    React.useEffect(() => {
        let handler = () => {
            let ts = Date.now();
            timestampRef.current = ts;
            getData(ts);
        };

        handler();
        curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));

        return () => curDispatch(siteFunctionsActions.removeHeaderRefreshAction(handler));
    }, [dateFilters]);

    return <div className="route__reports__designersReport">
        <FilterByDate
            style={{width: "300px", marginBottom: "30px"}}
            disableAll={true}
            disable24h={true}
            defaultValue="today"
            onChange={e => setDateFilters(e)}
        />

        <div className="route__reports__designersReport__kpi">
            <div className="route__reports__designersReport__kpi__item">
                <p className="route__reports__designersReport__kpi__item__top">Total requests</p>
                <p className="route__reports__designersReport__kpi__item__bottom">{data ? (data?.status === "ok" ? getKPISum("Total") : "-") : <Spinner style={{width: "34px", height: "34px"}} color={themeSelector === "dark" ? "white" : "black"} />}</p>
            </div>
            <div className="route__reports__designersReport__kpi__item">
                <p className="route__reports__designersReport__kpi__item__top">Total accepted requests</p>
                <p className="route__reports__designersReport__kpi__item__bottom">{data ? (data?.status === "ok" ? getKPISum("Completed") : "-") : <Spinner style={{width: "34px", height: "34px"}} color={themeSelector === "dark" ? "white" : "black"} />}</p>
            </div>
            <div className="route__reports__designersReport__kpi__item">
                <p className="route__reports__designersReport__kpi__item__top">Total rejected requests</p>
                <p className="route__reports__designersReport__kpi__item__bottom">{data ? (data?.status === "ok" ? getKPISum("Rejected") : "-") : <Spinner style={{width: "34px", height: "34px"}} color={themeSelector === "dark" ? "white" : "black"} />}</p>
            </div>
            <div className="route__reports__designersReport__kpi__item">
                <p className="route__reports__designersReport__kpi__item__top">Total Average completion time</p>
                <p className="route__reports__designersReport__kpi__item__bottom">{data ? (data?.status === "ok" ? convertSecondsToString(getKPISum("AverageTime")) : "-") : <Spinner style={{width: "34px", height: "34px"}} color={themeSelector === "dark" ? "white" : "black"} />}</p>
            </div>
        </div>

        <FilteredCustomTable
            theme={themeSelector}
            accent="#6C5DD3"
            headers={["User", "Total", "Accepted", "Rejected", "Average complete time"]}
            customColumns={(new Array(5)).fill("max-content")}
            style={{columnGap: "40px"}}
            data={(()=>{
                if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                if (data.status === "error") return [[{keyID: "noData-error", type: "custom", data: "There was an error while fetching data", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]];

                let out = [];
                for (let item of data.data) {
                    out.push([
                        {keyID: item.TakenBy, type: "text", text: item._TakenBy?.Username},
                        {keyID: item.TakenBy, type: "text", text: item.Total},
                        {keyID: item.TakenBy, type: "text", text: item.Completed},
                        {keyID: item.TakenBy, type: "text", text: item.Rejected},
                        {keyID: item.TakenBy, type: "text", text: convertSecondsToString(item.AverageTime)},
                    ]);
                };

                if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);
                return out;
            })()}
        />
    </div>
};

export default DesignersReport;