import React from "react";
import "./index.scss";

import moment from "moment";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";

import { animateBox } from "../../modules/componentAnimation";
import { FilteredCustomTable } from "../../components/customComponents/Table";
import FilterByDate from "../../components/filters/FilterByDate";
import StyledButton from "../../components/styledComponents/Button";
import StyledInput from "../../components/styledComponents/Input";
import Spinner from "../../components/customComponents/Spinner";

import YesNoModal from "../../components/modals/YesNoModal";

const LiveShare = () => {
    const [active, setActive] = React.useState();
    const [password, setPassword] = React.useState(false);
    const [spinner, setSpinner] = React.useState();

    const passwordRef = React.useRef();

    const checkActive = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/serverGlobalFunctions/liveShare/status`,
            ...backendModule.axiosConfig
        }).then(res => setActive(res.data.status === "ok")).catch(() => setActive(false));
    };

    const checkPassword = (e) => {
        if (spinner) return;
        if (!passwordRef.current.value) return;
        let pw = passwordRef.current.value;

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/serverGlobalFunctions/liveShare/checkPassword`,
            data: {
                Password: pw
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "error") {
                animateBox(<YesNoModal
                    heading="Password invalid"
                    text="Current password is invalid, please try again."
                    buttonLeftHidden={true}
                    isRightButtonNormal={true}
                    buttonRightText={"Ok"}
                />);
            } else {
                setPassword(pw);
            };
        }).catch(() => {
            animateBox(<YesNoModal
                heading="Error"
                text="Server timed out, please try again later..."
                buttonLeftHidden={true}
                isRightButtonNormal={true}
                buttonRightText={"Ok"}
            />);
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        checkActive();
    }, []);

    if (password) return <LiveShare__open password={password} />;
    return <div className="route__liveShare">
        {(active !== undefined) ? <>
            {active ? <div className="route__liveShare__box">
            <p>Enter the password to continue</p>
            <StyledInput ref={passwordRef} type="password" placeholder="Password" />
            <StyledButton onClick={checkPassword}>
                {spinner ? <Spinner style={{width: "17px", height: "17px"}} color="white" /> : "Continue"}
            </StyledButton>
        </div> : <>
            <div className="route__liveShare__box" style={{alignItems: "center"}}>
                <p>Live Share is not enabled!</p>
            </div>
        </>}
        </> : <Spinner color="white" />}
    </div>
};

let curTimeout = null;
const LiveShare__open = (props) => {
    const [data, setData] = React.useState();
    const [isFetching, setIsFetching] = React.useState(false);
    const [progress, setProgress] = React.useState(null);
    const [websiteWidth, setWebsiteWidth] = React.useState(1920);

    const timestampRef = React.useRef();
    const currencySignSelector = "€";

    const getData = async (ts) => {
        if (timestampRef.current !== ts) return;

        setProgress(null);
        setIsFetching(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/serverGlobalFunctions/liveShare/getData`,
            data: {
                Password: props.password,
                utcOffset: (new Date()).getTimezoneOffset(),
                filters: [
                    {name: "createdAt", op: "deq", value: Date.now()}
                ]
            }
        }).then(res => setData(res.data)).catch(() => setData(backendModule.genericError)).finally(() => {
            setProgress();
            setIsFetching(false);
        });
    };

    const prepareTableData = (data, column) => {
        let tmpCol = column.replace(/ /g, "_");
        if (tmpCol.endsWith("_IN")) tmpCol = tmpCol.substring(0, tmpCol.length - 3);

        if (tmpCol?.endsWith?.(".lastDate") && tmpCol?.startsWith?.("@")) {
            if (data === 0) return "Never";
            if (moment(data).isValid()) return moment(data).toDate().toLocaleString();
        };
        switch (tmpCol) {
            case "Revenue":
            case "Spent":
            case "Profit":
            case "EPV":
            case "CPA":
            case "CPAO":
            case "CPR":
            case "CPR1000":
            case "CPV":
            case "ADP":
                let tmpRevenue = Number(data);
                if (isNaN(tmpRevenue)) return "-";
                return `${tmpRevenue.toFixed(2)} ${currencySignSelector}`;
            case "CR":
            case "AR":
            case "CUR":
            case "CRR":
            case "ROI":
            case "DR":
                let tmpCR = Number(data);
                if (isNaN(tmpCR)) return "-";
                return `${tmpCR.toFixed(2)} %`;
            case "ROAS":
                let tmpROAS = Number(data);
                if (isNaN(tmpROAS)) return "-";
                return `${tmpROAS.toFixed(2)}x`;
            default:
                let tmp = Number(data);
                if (isNaN(tmp)) return data;
                return tmp.toLocaleString();
        };
    };

    const wrapTableItem = item => <span style={{padding: "0 50px 0 0px"}}>{item}</span>;

    React.useEffect(() => {
        if (isFetching) return;
        if (!data) return;
        clearTimeout(curTimeout);
        curTimeout = setTimeout(() => {
            if (!progress) return setProgress({value: 0, maximum: 120});
            if (progress?.value === 120) {
                setProgress(null);
                let ts = Date.now();
                timestampRef.current = ts
                getData(ts);
                return;
            };
            return setProgress(p => {return {...p, value: p.value += 1}});
        }, 1000);

        return () => clearTimeout(curTimeout);
    }, [data, progress, isFetching]);

    React.useEffect(() => {
        let ts = Date.now();
        timestampRef.current = ts;
        getData(ts);

        setWebsiteWidth(window.innerWidth);
        let handler = () => {
            if (websiteWidth !== window.innerWidth) setWebsiteWidth(window.innerWidth);
        };
        window.addEventListener("resize", handler);
        return () => window.removeEventListener("resize", handler);
    }, []);

    return <div className="route__liveShare" style={{justifyContent: "flex-start", flexDirection: "column"}}>
        <div className={`route__shareCampaign__progress ${!progress ? "route__shareCampaign__progress--animate" : ""}`} style={{pointerEvents: "none", opacity: progress !== undefined ? 1 : 0}}>
            {progress !== undefined && <div className="route__shareCampaign__progress__item" style={{
                width: !progress ? "30%" : `${100 / progress.maximum * progress.value}%`
            }}></div>}
        </div>

        {(data?.status === "ok") && <>
            <FilteredCustomTable
                theme="dark"
                accent="#6C5DD3"
                headers={["Name", "CR", "AR", "CPA", "CPAO", "ADP", "DR", "TrafficSource"]}
                customColumns={["max-content", "auto", "auto", "auto", "auto", "auto", "auto", "auto"]}
                style={{width: "100%"}}
                data={(()=>{
                    let out = [];
                    let keys = ["CampaignName", "CR", "AR", "CPA", "CPAO", "ADP", "DR", "TrafficSource"];

                    for (let item of data?.data?.table ?? []) {
                        let tmp = [];
                        for (let key of keys) {
                            if (key === "CampaignName" || key === "TrafficSource") {
                                tmp.push({keyID: item.ID, type: "text", text: wrapTableItem(item[key])});
                            } else {
                                tmp.push({keyID: item.ID, type: "text", text: wrapTableItem(prepareTableData(item?.TableData[key], key))});
                            };
                        };
                        out.push(tmp);
                    };

                    if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);
                    return out;
                })()}
            />
        </>}
    </div>
};

export default LiveShare;