import React from "react";
import "./index.scss";

import axios from "axios";
import { useSelector } from "react-redux";
import * as backendModule from "../../../../modules/backendModule";
import { countries } from "../../../../modules/countryModule";
import { siteRiskColors, siteRiskColorsLight } from "../../../../modules/miscModule";
import { animateBox } from "../../../../modules/componentAnimation";

import GlobalChanges from "../../../../components/modals/GlobalChangesModal";
import ButtonWithDropdown from "../../../../components/customComponents/ButtonWithDropdown";
import Dropdown from "../../../../components/customComponents/Dropdown";
import Spinner from "../../../../components/customComponents/Spinner";
import YesNoModal from "../../../../components/modals/YesNoModal";

const UserSitesCard = (props) => {
  const [data, setData] = React.useState();
  const [scriptSpinner, setScriptSpinner] = React.useState(false);
  const [dropdownVisible, setdropdownVisible] = React.useState(false);

  const siteRiskSelector = useSelector(state => state?.types?.siteRisks ?? {});
  const curUserSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});
  const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

  const dropdownRef = React.useRef();
  const infoBtn = React.useRef();
  const textToCopy = React.useRef();
  const textToCopy2 = React.useRef();

  const curCountry = countries.find(c => c.code === props.data.Country);

  const copyText = (cRef = textToCopy) => {
    navigator.clipboard.writeText(cRef.current.href)
  };

  const healthCheck = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/sites/healthCheck`,
      data: {
        ID: props.data.ID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      setData(res.data);
    }).catch(() => {
      setData(backendModule.genericError);
    });
  };

  const getTrackingScript = (cls) => {
    if (scriptSpinner) return;
    let script = "";

    setScriptSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/sites/getTrackingScript`,
      data: {
        ID: props.data.ID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        script = res.data.data;
      } else {
        script = "There was an error while fetching the script...";
      };
    }).catch(() => {
      script = "Server timed out";
    }).finally(() => {
      setScriptSpinner(false);

      let item = document.querySelector(cls);
      if (!item) return;
      animateBox({ currentTarget: item }, <TrackingScript data={script} highlight={true} />)
    });
  };

  const openItemHistory = (e) => {
    animateBox(e, <GlobalChanges ID={props.data.ID} Table={"Sites"} />);
  };

  React.useEffect(() => {
    healthCheck();
  }, []);

  return <div className="route__user__sites__card" style={{
    ...(props.style ?? {}),
    boxShadow: `0 0 ${themeSelector === "dark" ? "2" : "4"}px ${(themeSelector === "dark" ? siteRiskColors : siteRiskColorsLight)(props.data.SiteRisk)}`
  }}>
    <div className="route__user__sites__card__head">
    <div className="route__user__sites__card__head__name">
        <p>{props.data.SiteName}</p>
      </div>
      <ButtonWithDropdown
        value="Actions"
        image="/images/icon_edit.svg"
        data={[
          {name: "Get URL", onClick: e => animateBox(e, <GetSiteURL ID={props.data.ID} />)},
          {name: scriptSpinner ? <Spinner style={{ width: "12px", height: "12px" }} color="white" /> : "Get script", onClick: (e) => !scriptSpinner && getTrackingScript(".route__user__sites__buttons")},
          {name: "Edit", onClick: e => {
            // if (!curUserSelector?.Flags?.isAdmin) {
            //   if (props.data.CreatedBy !== curUserSelector?.ID) return animateBox(e, <YesNoModal
            //     heading="Error"
            //     text="You can only edit sites that you have created!"
            //     buttonLeftHidden={true}
            //     buttonRightText={"Ok"}
            //     isRightButtonNormal={true}
            //   />)
            // };
            props?.onEdit?.(e);
          }},
          {name: "Remove", onClick: e => {
            if (!curUserSelector?.Flags?.isAdmin) {
              if (props.data.CreatedBy !== curUserSelector?.ID) return animateBox(e, <YesNoModal
                heading="Error"
                text="You can only remove sites that you have created!"
                buttonLeftHidden={true}
                buttonRightText={"Ok"}
                isRightButtonNormal={true}
              />)
            };
            props?.onRemove?.(e);
          }},
          // (props.data?.IntegrationData?.Facebook?.PixelID ? {name: "Test Facebook Pixel", onClick: e => {
          //   animateBox(e, <TestFBPixel ID={props.data.ID} />)
          // }} : null),
          {name: "View changes history", onClick: e => openItemHistory(e)}
        ].filter(t => t)}
      />
    </div>

    {curCountry && <div className="route__user__sites__card__country">
      <img src={`/images/countryFlags/${curCountry.code.toLowerCase()}.png`} />
      {curCountry.name}

      <div className="route__user__sites__card__head__status" style={{marginLeft: "auto"}}>
        {data ? <>
          {(data.status === "ok" && props.data.isTested) ? <>
            <div className="route__user__sites__card__head__status__notactive">
              <img src="./images/sites/active.svg"></img>
              <p>Active</p>
            </div>
          </> : <>
            <div className="route__user__sites__card__head__status__notactive"
              style={{
                overflow: dropdownVisible ? `visible` : `hidden`,
              }}
            >
              <img src="./images/sites/notactive.svg"></img>
              <p>{props.data.isTested ? (data.data === "SCRIPT_MISSING" ? "Missing script" : "Not active") : "Not tested"}</p>
              <img src="./images/sites/moreinfo.svg" className="route__user__sites__card__head__status__notactive--moreinfo" ref={infoBtn}
                onMouseOver={e => {
                  e.stopPropagation();
                  if (!dropdownVisible) {
                    setdropdownVisible(true)
                  }
                }}
                onMouseLeave={e => {
                  e.stopPropagation();
                  if (dropdownVisible) {
                    setdropdownVisible(false)
                  }
                }}>
              </img>
              <div className="route__user__sites__card__head__status__notactive__info" ref={dropdownRef}
                style={{
                  opacity: dropdownVisible ? `1` : `0`
                }}
              >
                {props.data.isTested ? (data.data === "SCRIPT_MISSING" ? <p>
                  Tracking script was not found on this page.<br />
                  If You use "Cloudflare", make sure to clear out the cache after adding the script.<br /><br />

                  If the site is rendered with frameworks such as React or Angular, it is normal to see this error. You can proceed and use this website regardless of the error.
                </p> : <p>
                  The site is offline or it couldnt be accessed.<br />
                  You can still try to use this site in Your campaigns.
                </p>) : <p>This website is not tested, edit and save the page to test it</p>}
              </div>
            </div>
          </>}
        </> : <>
          <div className="route__user__sites__card__head__status__notactive">
            <Spinner color="white" style={{ width: "16px", height: "16px" }} />
            <p>Checking...</p>
          </div>
        </>}
      </div>
    </div>}

    <div className="route__user__sites__card__flags">
        <span style={{
          color: props.data.trackRepeatingRequests ? (themeSelector === "dark" ? "#8aff8a" : "green") : (themeSelector === "dark" ? "#f29090" : "red"),
          marginRight: "10px"
        }}>[Track spam: {props.data.trackRepeatingRequests ? "Yes" : "No"}]</span>
        <span style={{
          color: props.data.trackHeatmaps ? (themeSelector === "dark" ? "#8aff8a" : "green") : (themeSelector === "dark" ? "#f29090" : "red"),
          marginRight: "10px"
        }}>[Track heatmaps: {props.data.trackHeatmaps ? "Yes" : "No"}]</span>
        <br />
        <span style={{
          color: (themeSelector === "dark" ? siteRiskColors : siteRiskColorsLight)(props.data.SiteRisk),
          marginRight: "10px"
        }}>[Risk: {siteRiskSelector[props.data.SiteRisk]}]</span>
    </div>

    <div className="route__user__sites__card__url" style={{
      marginBottom: props.data?.IntegrationData?.Facebook?.PixelID ? "0" : null
    }}>
      <a target="_blank" href={props.data.SiteURL} ref={textToCopy} >
        {props.data.SiteURL}
      </a>
      <img src="./images/sites/copy.svg" onClick={(e) => {
        e.stopPropagation();
        copyText();
      }}></img>
    </div>

    {props.data?.IntegrationData?.Facebook?.PixelID && <div className="route__user__sites__card__url">
      <a>
        FB PixelID: <span ref={textToCopy2}>{props.data?.IntegrationData?.Facebook?.PixelID}</span>
      </a>
      <img src="./images/sites/copy.svg" onClick={(e) => {
        e.stopPropagation();
        copyText(textToCopy2);
      }}></img>
    </div>}

    <div className="route__user__sites__card__img">
      <img src={props.data.SiteImage ?? "/images/image-missing.png"} onError={e => e.currentTarget.src = "/images/image-missing.png"}></img>
    </div>

    {props.showStats && <div className="route__user__sites__card__analytics">
      <div className="route__user__sites__card__analytics__item">
        <span>Clicks</span>
        <span>{props.data?._trackingData?.Clicks ?? "-"}</span>
      </div>
      <div className="route__user__sites__card__analytics__item">
        <span>Conversions</span>
        <span>{props.data?._trackingData?.Conversions ?? "-"}</span>
      </div>
      <div className="route__user__sites__card__analytics__item">
        <span>CR</span>
        <span>{props.data?._trackingData?.CR ? Number(props.data?._trackingData?.CR).toFixed(0) : "-"} %</span>
      </div>
    </div>}

  </div>
};

const GetSiteURL = (props) => {
  const [data, setData] = React.useState();
  const [utmParams, setUtmParams] = React.useState();
  const [selectedProfile, setSelectedProfile] = React.useState();

  const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
  const curTrackingProfilesSelector = useSelector(state => state?.trackingProfiles?.profiles?.filter?.(f => f?.ID !== "-2") ?? []);

  const getIntegrationImage = (type) => {
    switch (type) {
        case "scale-track": return "/images/logo.svg";
        case "facebook": return "/images/integrations/integration_header_facebook.svg";
        case "scale-track-sms": return "/images/integrations/integration_smsMarketing.svg";
        default: return "#";
    };
  };

  const getPrefix = type => {
    switch (type) {
      case "facebook": return "[Facebook] ";
      default: return "";
    };
  };

  const getURL = () => {
    if (!data) return "";
    if (data.status === "error") return "";

    let tmpURL = data.data;

    switch (selectedProfile.type) {
      case "facebook":
        let tmpFb = new URL(tmpURL);
        tmpFb.searchParams.set("stutm", "facebook");
        return tmpFb.toString();
      default: return tmpURL;
    };
  };

  const getUTMParams = () => {
    axios({
        method: "POST",
        url: `${backendModule.backendURL}/integrations/facebook/getUTMParams`,
        ...backendModule.axiosConfig
    }).then(res => {
        setUtmParams(res.data);
    }).catch(() => {
      setUtmParams(backendModule.genericError);
    });
  };

  React.useEffect(() => {
    if (!selectedProfile) return setUtmParams();
    if (selectedProfile?.type !== "facebook") return setUtmParams();

    getUTMParams();
  }, [selectedProfile]);

  React.useEffect(() => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/sites/getSiteURL`,
      data: {
        ID: props.ID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      setData(res.data);
    }).catch(() => {
      setData(backendModule.genericError);
    });
  }, []);

  return <div className="genericModal">
    <div className="genericModal__wrap">
      <div className="genericModal__wrap__head">
        <div className="genericModal__wrap__head__left">Get URL</div>
        <div className="genericModal__wrap__head__right" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={props.onClose}></div>
      </div>
      
      {data ? <>
        {data.status === "ok" ? <>
        
        <div className="genericModal__wrap__input genericModal__wrap__input--dropdown">
          <p>Select an account</p>
          <Dropdown
            theme={themeSelector}
            accent="#6C5DD3"
            inlinePlaceholder="Select an account"
            data={curTrackingProfilesSelector.map(ts => {
              return {name: <p className="genericModal__wrap__input__profileSelect">
                <img src={getIntegrationImage(ts.type)} />
                <span>{getPrefix(ts.type)}{ts.name}</span>
              </p>, search: ts.name, value: ts}
            })}
            onChange={e => setSelectedProfile(e?.value)}
          />
        </div>

        {selectedProfile && <div className="genericModal__wrap__textBlock">
          {getURL()}
        </div>}

        {(selectedProfile && selectedProfile?.type === "facebook") && <div className="genericModal__wrap__textBlock">
            {utmParams ? <>
              <span>Facebook URL Parameters:</span>
              <br />
              {utmParams.status === "ok" ? utmParams.data : "?"}
            </> : <Spinner style={{width: "24px", height: "24px"}} color="white" />}
        </div>}

        <div className="genericModal__wrap__buttons">
          <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Close</div>
        </div>

        </> : <p className="genericModal__wrap__infoP" style={{opacity: 1}}>There was an error while fetching the URL!</p>}
      </> : <Spinner color="white" />}
    </div>
  </div>
};

const TrackingScript = (props) => {

  function syntaxHighlight(json) {
    try {
        if (typeof json != 'string') {
            json = JSON.stringify(json, undefined, 2);
       }
       json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
       return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
           var cls = 'number';
           if (/^"/.test(match)) {
               if (/:$/.test(match)) {
                   cls = 'key';
               } else {
                   cls = 'string';
               }
           } else if (/true|false/.test(match)) {
               cls = 'boolean';
           } else if (/null/.test(match)) {
               cls = 'null';
           }
           return '<span class="' + cls + '">' + match + '</span>';
       });
    } catch {
        return json;
    };
}

  return <div className="route__user__sites__card__tracking">
    <div className="route__user__sites__card__tracking__wrap" style={{width: props.highlight ? "800px" : null}}>
      <div className="route__user__sites__card__tracking__wrap__head">
        <div className="route__user__sites__card__tracking__wrap__head__left">{props.heading ? props.heading : "Tracking code"}</div>
        <div className="route__user__sites__card__tracking__wrap__head__right" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={props.onClose}></div>
      </div>

      {props.highlight ?
        <pre className="route__user__sites__card__tracking__wrap__code" dangerouslySetInnerHTML={{__html: syntaxHighlight(props.data)}}></pre> : 

        <textarea className="route__user__sites__card__tracking__wrap__code">
          {props.data}
        </textarea>}
    </div>
  </div>
};

const TestFBPixel = props => {
  const [spinner, setSpinner] = React.useState(false);
  const [infoP, setInfoP] = React.useState({
    error: "",
    hadErrors: false,
    inputs: []
  });

  const testEventCodeRef = React.useRef();

  const testFBPixel = () => {
    if (spinner) return;

    setInfoP(ip => {return {...ip, hadErrors: false, inputs: []}});
    let data = {
      SiteID: props.ID,
      TestEventCode: testEventCodeRef.current.value
    };

    if (!data.TestEventCode) return setInfoP(ip => {return {...ip, hadErrors: true, inputs: ["code"], error: "Test event code can't be empty."}});

    setSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/integrations/facebook/testPixel`,
      data,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        return props.onClose();
      };
      return setInfoP(ip => {return {...ip, hadErrors: true, inputs: [], error: "Error while sending a test pixel request."}});
    }).catch(() => {
      return setInfoP(ip => {return {...ip, hadErrors: true, inputs: [], error: "Server timed out!"}});
    }).finally(() => {
      setSpinner(false);
    });
  };

  return <div className="genericModal">
    <div className="genericModal__wrap">
      <div className="genericModal__wrap__head">
        <div className="genericModal__wrap__head__left">Text Pixel</div>
        <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
      </div>

      <div className={`genericModal__wrap__input ${infoP.inputs.includes("code") ? "genericModal__wrap__input--error" : ""}`}>
        <p>Test event code</p>
        <input ref={testEventCodeRef} type="text" placeholder="Test event code (TEST****)" />
      </div>

      <div className="genericModal__wrap__buttons">
        <div className="genericModal__wrap__buttons__btn  genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Close</div>
        <div className="genericModal__wrap__buttons__btn" onClick={()=>!spinner && testFBPixel()}>
          {spinner ? <Spinner style={{width: "24px", height: "24px"}} color="white" /> : "Test"}
        </div>
      </div>

      <p className="route__user__sites__add__wrap__infoP" style={{
        opacity: infoP.hadErrors ? 1 : 0
      }}>{infoP.error}</p>
    </div>
  </div>
};

export default UserSitesCard;
export {TrackingScript};