import React from "react";
import "./index.scss";

import FilterBySearch from "../FilterBySearch";
import Spinner from "../../customComponents/Spinner";

const FilterByUserAccount = (props) => {
    const [data, setData] = React.useState();
    const [dropdownVisible, setDropdownVisible] = React.useState();
    const [selectedItem, setSelectedItem] = React.useState(props.defaultUser !== undefined ? props.defaultUser : undefined);
    const [userSelected, setUserSelected] = React.useState("you");
    const [integrationSelected, setIntegrationSelected] = React.useState();
    const [finalIntegrationSelected, setFinalIntegrationSelected] = React.useState();
    const [finalDisplay, setFinalDisplay] = React.useState(null);
    const [search, setSearch] = React.useState("");

    const curMaxHeightRef = React.useRef(300);
    const dropdownRef = React.useRef();
    const firstTimeRef = React.useRef(true);

    const getIntegrationImage = (type) => {
        switch (type) {
            case "scale-track": return "/images/logo.svg";
            case "facebook": return "/images/integrations/integration_header_facebook.svg";
            case "tiktok": return "/images/integrations/integration_header_tiktok.svg";
            case "mgid": return "/images/integrations/integration_header_mgid.svg";
            case "midas": return "/images/integrations/integration_midas.svg";
            case "adnow": return "/images/integrations/integration_adnow.svg";
            case "scale-track-sms": return "/images/integrations/integration_smsMarketing.svg";
            case "scale-track-social": return "/images/integrations/integration_socialCampaigns.svg";

            case "all": return "/images/integrations/integration_header_allConnected.svg";
            default: return "#";
        };
    };

    React.useEffect(() => {
        if (Array.isArray(props.data)) {
            return setData(props.data);
        };
        setData(null);
    }, [props.data]);

    React.useEffect(() => {
        if (!dropdownRef?.current) return;
        if (!curMaxHeightRef?.current) return;

        if (dropdownVisible) {
            dropdownRef.current.style.maxHeight = `${curMaxHeightRef.current}px`;
        } else {
            dropdownRef.current.style.maxHeight = 0;
        };
    }, [dropdownVisible, dropdownRef.current, curMaxHeightRef.current]);

    React.useEffect(() => {
        const windowClickHandler = () => {
            if (dropdownVisible) setDropdownVisible(false);
        };

        window.addEventListener("click", windowClickHandler);

        return () => {
            try {
                window.removeEventListener("click", windowClickHandler);
            } catch {};
        };
    }, [dropdownVisible]);

    React.useEffect(() => {
        if (firstTimeRef.current) {
            firstTimeRef.current = false;
            return;
        };
        if (!props.onChange) return;
        if (userSelected === "you") {
            props.onChange({UserID: null});
            setFinalDisplay({type: null, value: "You"});
            return;
        };
        if (!data) return;

        let curUser = data.find(d => d.ID === userSelected);
        if (!curUser) return;
        if (!integrationSelected) return;

        if (!["fb", "tt"].includes(integrationSelected)) {
            let curIntegration = curUser._profiles.find(p => p.ID === integrationSelected);
            if (!curIntegration) return;

            props.onChange({
                UserID: curUser.ID,
                IntegrationID: curIntegration.ID
            });
            setFinalDisplay({type: curIntegration.type, value: `${curUser.Username} -> ${curIntegration.name}`});
            return;
        };

        let curIntegration = curUser._profiles.find(p => p.ID === finalIntegrationSelected);
        if (!curIntegration) return;

        props.onChange({
            UserID: curUser.ID,
            IntegrationID: curIntegration.ID
        });
        setFinalDisplay({type: curIntegration.type, value: `${curUser.Username} -> ${curIntegration.name}`});
    }, [userSelected, integrationSelected, finalIntegrationSelected]);

    return <div className={`component__filters__filterByUserAccount ${data ? "component__filters__filterByUserAccount--hasData" : ""}`} onClick={e => {
        e.stopPropagation();
        setDropdownVisible(d => !d);
    }}>
        <div className="component__filters__filterByUserAccount__left">
            {data ? <>
                {props.headline && <div className="component__filters__filterByUserAccount__left__top">{props.headline}</div>}
                <div className="component__filters__filterByUserAccount__left__bottom">
                    {finalDisplay ? <>
                        {finalDisplay.type && <img src={getIntegrationImage(finalDisplay.type)} />}
                        {finalDisplay.value}
                    </> : "You"}
                </div>
            </> : <Spinner style={{width: "24px", height: "24px"}} color="white" />}
        </div>
        {data && <div className="component__filters__filterByUserAccount__right">
            <img src="/images/downArrow.svg" />
        </div>}


        <div
            ref={dropdownRef}
            className={`component__filters__filterByUserAccount__dropdown ${(userSelected && userSelected !== "you") ? "component__filters__filterByUserAccount__dropdown--userSelected" : ""} ${["fb", "tt"].includes(integrationSelected) ? "component__filters__filterByUserAccount__dropdown--integrationSelected" : ""} ${dropdownVisible ? "component__filters__filterByUserAccount__dropdown--active" : ""}`}
            onClick={e => e.stopPropagation()}
        >
            <div key="filter-user-you" className={`component__filters__filterByUserAccount__dropdown__left ${(userSelected && userSelected !== "you") ? "component__filters__filterByUserAccount__dropdown__left--active" : ""}`}>
                <FilterBySearch onChange={e => setSearch(e)} />

                {(!search || "you".includes(search.toLowerCase())) && <div className={`component__filters__filterByUserAccount__dropdown__left__item ${userSelected === "you" ? "component__filters__filterByUserAccount__dropdown__left__item--active" : ""}`} onClick={(e) => {
                    e.stopPropagation();
                    setSelectedItem(null);
                    setUserSelected("you");
                    setIntegrationSelected();
                    setFinalIntegrationSelected();
                    setDropdownVisible(false);
                }}>You</div>}

                {data && data.filter(usr => {
                    if (!search) return true;
                    if (usr.Username.toLowerCase().includes(search.toLowerCase())) return true;
                    return false;
                }).map(usr => {
                    return <div key={`filter-user-${usr.Username}`} className={`component__filters__filterByUserAccount__dropdown__left__item ${userSelected === usr.ID ? "component__filters__filterByUserAccount__dropdown__left__item--active" : ""}`} onClick={(e) => {
                        e.stopPropagation();
                        setUserSelected(usr.ID);
                        setIntegrationSelected();
                        setFinalIntegrationSelected();
                    }} style={{...(usr.style ?? {})}}>{usr.Username}</div>;
                })}
            </div>

            <div className="component__filters__filterByUserAccount__dropdown__middle">
                {(()=>{
                    if (!userSelected || userSelected === "you") return null;
                    let curUser = data.find(d => d.ID === userSelected);
                    if (!curUser) return null;

                    let facebookGenerated = false;
                    let tiktokGenerated = false;

                    return curUser._profiles.map(p => {
                        if (["facebook"].includes(p.type) && !p.generic) {
                            if (!facebookGenerated) {
                                facebookGenerated = true;
                                return <div key={`${curUser?.ID}-${p?.ID}`} className={`component__filters__filterByUserAccount__dropdown__middle__item ${integrationSelected === "fb" ? "component__filters__filterByUserAccount__dropdown__middle__item--active" : ""}`} onClick={e => {
                                    e.stopPropagation();
                                    setIntegrationSelected(p.ID.split("-")[0]);
                                    setFinalIntegrationSelected();
                                }}>
                                    <img src={getIntegrationImage(p?.type)} />
                                    <span>{p.type.charAt(0).toUpperCase()}{p.type.substring(1, p.type.length)}</span>
                                </div>
                            } else return null;
                        };
                        if (["tiktok"].includes(p.type) && !p.generic) {
                            if (!tiktokGenerated) {
                                tiktokGenerated = true;
                                return <div key={`${curUser?.ID}-${p?.ID}`} className={`component__filters__filterByUserAccount__dropdown__middle__item ${integrationSelected === "fb" ? "component__filters__filterByUserAccount__dropdown__middle__item--active" : ""}`} onClick={e => {
                                    e.stopPropagation();
                                    setIntegrationSelected(p.ID.split("-")[0]);
                                    setFinalIntegrationSelected();
                                }}>
                                    <img src={getIntegrationImage(p?.type)} />
                                    <span>{p.type.charAt(0).toUpperCase()}{p.type.substring(1, p.type.length)}</span>
                                </div>
                            } else return null;
                        };
                        return <div key={`${curUser?.ID}-${p?.ID}`} className={`component__filters__filterByUserAccount__dropdown__middle__item ${integrationSelected === p?.ID ? "component__filters__filterByUserAccount__dropdown__middle__item--active" : ""}`} onClick={e => {
                            e.stopPropagation();
                            setIntegrationSelected(p.ID);
                            setFinalIntegrationSelected();
                            if (!p?.ID?.startsWith("fb-") && !p?.ID?.startsWith("tt-")) {
                                setDropdownVisible(false);
                            } else {
                                if (p?.generic) setDropdownVisible();
                            };
                        }}>
                            <img src={getIntegrationImage(p?.type)} />
                            <span style={{color: p?.active === true ? "rgb(102, 238, 102)" : (p?.active === false ? "rgb(238, 102, 102)" : null)}}>{p?.name}</span>
                        </div>
                    })
                })()}
            </div>

            <div className="component__filters__filterByUserAccount__dropdown__right">
                {(()=>{
                    if (!["fb", "tt"].includes(integrationSelected)) return null;
                    if (!userSelected || userSelected === "you") return null;
                    let curUser = data.find(d => d.ID === userSelected);
                    if (!curUser) return null;

                    let finalProfiles = curUser._profiles.filter(p => p.ID.startsWith(`${integrationSelected}-`));

                    return finalProfiles.map(p => {
                        return <div className={`component__filters__filterByUserAccount__dropdown__right__item ${finalIntegrationSelected === p?.ID ? "component__filters__filterByUserAccount__dropdown__right__item--active" : ""}`} onClick={e => {
                            e.stopPropagation();
                            setFinalIntegrationSelected(p.ID);
                            setDropdownVisible(false);
                        }}>
                            <span>{p?.ID?.replace?.("fb-", "").replace("tt-", "")}</span>
                            <br />
                            <span style={{color: p?.active === true ? "rgb(102, 238, 102)" : (p?.active === false ? "rgb(238, 102, 102)" : null)}}>{p?.name}</span>
                        </div>
                    });
                })()}
            </div>
        </div>
    </div>
};

export default FilterByUserAccount;