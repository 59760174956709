import React from "react";
import "./index.scss";

import propTypes from "prop-types";

const ButtonWithDropdown = props => {
    const [dropdownVisible, setDropdownVisible] = React.useState();

    const curMaxHeightRef = React.useRef();
    const dropdownRef = React.useRef();

    React.useEffect(() => {
        if (!dropdownRef?.current) return;
        if (curMaxHeightRef.current) return;

        dropdownRef.current.maxHeight = null;
        if (!props.maxHeight) {
            let rect = dropdownRef.current.getBoundingClientRect();
            curMaxHeightRef.current = rect.height;
        } else {
            curMaxHeightRef.current = props.maxHeight
        };
        dropdownRef.current.style.maxHeight = 0;
        dropdownRef.current.style.opacity = 1;
    }, [dropdownRef.current]);

    React.useEffect(() => {
        if (!dropdownRef?.current) return;
        if (!curMaxHeightRef?.current) return;

        if (dropdownVisible) {
            dropdownRef.current.style.maxHeight = `${curMaxHeightRef.current}px`;
            dropdownRef.current.style.boxShadow = "0px 0px 19px 5px rgba(0, 0, 0, 0.40)";
        } else {
            dropdownRef.current.style.maxHeight = 0;
            dropdownRef.current.style.boxShadow = "none";
        };
    }, [dropdownVisible, dropdownRef.current, curMaxHeightRef.current]);

    React.useEffect(() => {
        const windowClickHandler = () => {
            if (dropdownVisible) setDropdownVisible(false);
        };
        if (props.onUpdate) props.onUpdate(dropdownVisible);

        window.addEventListener("click", windowClickHandler);

        if (props.onOpenChangeCB) props.onOpenChangeCB(!!dropdownVisible);

        return () => {
            try {
                window.removeEventListener("click", windowClickHandler);
            } catch {};
        };
    }, [dropdownVisible]);

    React.useEffect(() => {
        if (typeof(props.dropdownFunctions) === "function") props.dropdownFunctions({
            toggle: () => setDropdownVisible(d => !!d),
            close: () => setDropdownVisible(false),
            open: () => setDropdownVisible(true),
            get: () => dropdownVisible
        });
    }, [props.dropdownFunctions]);

    return <div className={`customComponents__buttonWithDropdown ${dropdownVisible ? "customComponents__buttonWithDropdown--active" : ""} ${props.headClassName ? props.headClassName : ""}`} onClick={e => {
        e.stopPropagation();
        setDropdownVisible(dv => !dv);
    }} style={{...(props.headStyle ?? {})}}>
        {props.data && <div ref={dropdownRef} className={`customComponents__buttonWithDropdown__dropdown ${props.className ?? ""}`} style={{...(props.style ?? {})}}>
            {props.data.map((elem, elemIdx) => {
                if (!elem) return;

                return <div key={`${Date.now()}-${elemIdx}`} className={`customComponents__buttonWithDropdown__dropdown__item ${elem?.active ? "customComponents__buttonWithDropdown__dropdown__item--active2" : ""}`} onClick={elem.onClick}>
                    {elem.name}
                </div>
            })}
        </div>}

        {props.image && <img src={props.image} />}
        {props.value}
    </div>
};

ButtonWithDropdown.propTypes = {
    headClassName: propTypes.string,
    className: propTypes.string,
    style: propTypes.string,
    headStyle: propTypes.string,
    data: propTypes.arrayOf(propTypes.shape({
        name: propTypes.string,
        onClick: propTypes.func,
        active: propTypes.oneOfType([propTypes.bool, propTypes.any])
    })),
    value: propTypes.string,
    image: propTypes.string,

    onOpenChangeCB: propTypes.func,
    dropdownFunctions: propTypes.func
};

export default ButtonWithDropdown;