let activeContextMenus = [];
let activeContent = null;

const addEventListenerCompactibility = (item, event, fn) => item.addEventListener(event, fn);
const removeEventListenerCompactibility = (item, event, fn) => item.removeEventListener(event, fn);

const getBoundingClientRectRelative = (element) => {
    if (!element.parentElement) return element.getBoundingClientRect();
    let childPos = element.getBoundingClientRect();
    let parentPos = element.parentElement.getBoundingClientRect();
    return {
        bottom: childPos.bottom - parentPos.bottom,
        top: childPos.top - parentPos.top,
        left: childPos.left - parentPos.left,
        right: childPos.right - parentPos.right,
        width: childPos.width,
        height: childPos.height,
        x: childPos.x - parentPos.x,
        y: childPos.y - parentPos.y
    };
};

export class ClassWatcher {

    constructor(targetNode, classToWatch, classAddedCallback, classRemovedCallback) {
        this.targetNode = targetNode
        this.classToWatch = classToWatch
        this.classAddedCallback = classAddedCallback
        this.classRemovedCallback = classRemovedCallback
        this.observer = null
        this.lastClassState = targetNode.classList.contains(this.classToWatch)

        this.init()
    }

    init() {
        this.observer = new MutationObserver(mutationsList => {
            for(let mutation of mutationsList) {
                if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                    let currentClassState = mutation.target.classList.contains(this.classToWatch)
                    if(this.lastClassState !== currentClassState) {
                        this.lastClassState = currentClassState
                        if(currentClassState) {
                            this.classAddedCallback()
                        }
                        else {
                            this.classRemovedCallback()
                        }
                    }
                }
            }
        })
        this.observe()
    }

    observe() {
        this.observer.observe(this.targetNode, { attributes: true })
    }

    disconnect() {
        this.observer.disconnect()
    }
};

let closeHandler = () => {
    closeAllContextMenus();
};

/**
 * Sets the wrapper that will be used to render context menus (usually main wrapper/content).
 * @function
 * @param {HTMLDivElement} content The content that will be used to display context menus.
*  @returns {void} - nothing
 */
export const setActiveContent = content => {
    if (activeContent) {
        try {activeContent.removeEventListener("click", closeHandler);} catch {};
        try {activeContent.removeEventListener("contextmenu", closeHandler);} catch {};
    };
    activeContent = content;
    activeContent.addEventListener("click", closeHandler);
    activeContent.addEventListener("contextmenu", closeHandler);

    return content;
};

/**
 * Creates a context menu on a user-defined element with provided context menu items.
 * @function
 * @throws Will throw an error if the class instance in the contextMenuItems array is not a type of ContextMenuItem.
 * @param {Element} element The element that will receive the context menu.
 * @param {ContextMenuItem[]} contextMenuItems List of context menu items that will appear in the context menu.
 * @returns {{
 * changeContextMenuItems: (newContextMenuItems: ContextMenuItem[]) => void,
 * getContextMenuItems: () => ContextMenuItem[],
 * removeContextMenu: () => void
 * }}
 */
export const registerContextMenu = (element, contextMenuItems = []) => {
    if (!activeContent) {
        let ac = document.querySelector(".root__content");
        if (!ac) throw "ACTIVE_CONTENT_MISSING";
        setActiveContent(ac);
    };
    if (!contextMenuItems.push) {
        throw "Context menu item must be an array!";
    };
    for (let contextItem of contextMenuItems){
        if (!(contextItem instanceof ContextMenuItem)) throw "Context menu item must be an ContextMenuItem object!";
    };

    const contextMenuCallback = e => {
        if (!activeContent) {
            let ac = document.querySelector(".root__content");
            if (!ac) throw "ACTIVE_CONTENT_MISSING";
            setActiveContent(ac);
        };
        
        e.stopImmediatePropagation();
        e.preventDefault();
        closeAllContextMenus();

        let computedParent = getBoundingClientRectRelative(activeContent);

        let contextMenuElement = document.createElement("div");
        contextMenuElement.classList.add("windowManager-contextMenu");
        contextMenuElement.setAttribute("data-depth", "1");

        contextMenuElement.style.top = e.clientY + "px";
        contextMenuElement.style.left = e.clientX + "px";
        contextMenuElement.style.maxWidth = computedParent.width + "px";
        contextMenuElement.style.maxHeight = computedParent.height + "px";

        contextMenuItems.forEach(item => {
            let contextChild = document.createElement("div");
            contextChild.classList.add("windowManager-contextMenu-item");
            
            let contextChildIcon = document.createElement("div");
            contextChildIcon.classList.add("windowManager-contextMenu-item-icon");
            contextChildIcon.innerHTML = item.svgIcon;

            let contextChildText = document.createElement("div");
            contextChildText.classList.add("windowManager-contextMenu-item-text");
            contextChildText.style.color = item.enabled ? "white" : "gray";
            contextChildText.innerText = item.name;

            if (item.enabled){
                addEventListenerCompactibility(contextChild, "click", e => {
                    e.stopImmediatePropagation();
                    if (item.onClick) item.onClick();
                    closeAllContextMenus();
                });
                addEventListenerCompactibility(contextChild, "mouseover", () => {
                    [...contextMenuElement.children].forEach(cmItem => cmItem.classList.remove("focused"));
                    contextChild.classList.add("focused");
                });
            };
            contextChild.appendChild(contextChildIcon);
            contextChild.appendChild(contextChildText);

            if (item.childItems.length > 0) {
                contextChild.classList.add("windowManager-contextMenu-item-withChildren");
                let contextArrowItem = document.createElement("div");
                contextArrowItem.classList.add("windowManager-contextMenu-item-arrow")
                contextArrowItem.innerHTML = "►";
                contextChild.appendChild(contextArrowItem);
            };

            contextMenuElement.appendChild(contextChild);

            registerContextMenuChild(contextChild, item.childItems);
        });

        activeContent.appendChild(contextMenuElement);
        activeContextMenus.push(contextMenuElement);
        
        let finalStyle = contextMenuElement.getBoundingClientRect();
        let transformWidth = false;
        let transformHeight = false;

        if (finalStyle.width > computedParent.width - e.clientX) transformWidth = true;
        if (finalStyle.height > computedParent.height - e.clientY) transformHeight = true;

        contextMenuElement.style.transform = `translate(${transformWidth ? "-100" : "0"}%, ${transformHeight ? "-100" : "0"}%)`
        contextMenuElement.animate([
            {height: "0px"},
            {height: finalStyle.height + "px"}
        ],{
            iterations: 1,
            duration: 200,
            easing: "ease-in-out",
            fill: "both"
        });
        
        return false;
    };
    addEventListenerCompactibility(element, "contextmenu", contextMenuCallback);

    return {
        changeContextMenuItems: (newContextMenuItems = []) => {
            for (let contextItem of contextMenuItems){
                if (!(contextItem instanceof ContextMenuItem)) throw "Context menu item must be an ContextMenuItem object!";
            };
            contextMenuItems = newContextMenuItems;
        },
        getContextMenuItems: () => contextMenuItems,
        removeContextMenu: () => {removeEventListenerCompactibility(element, "contextmenu",contextMenuCallback);}
    };
};

/**
 * Creates a context menu on a user-defined element with provided context menu items.
 * @function
 * @throws Will throw an error if the class instance in the contextMenuItems array is not a type of ContextMenuItem.
 * @param {Element} element The element that will receive the context menu.
 * @param {ContextMenuItem[]} contextMenuItems List of context menu items that will appear in the context menu.
 * @returns {void}
**/
const registerContextMenuChild = (element, contextMenuItems = [], depth = 2) => {
    if (!contextMenuItems.push) {
        throw "Context menu item must be an array!";
    };
    for (let contextItem of contextMenuItems){
        if (!(contextItem instanceof ContextMenuItem)) throw "Context menu item must be an ContextMenuItem object!";
    };
    if (contextMenuItems.length === 0) return;
    
    const contextMenuCallback = () => {
        let computedParent = getBoundingClientRectRelative(activeContent);

        let contextMenuElement = document.createElement("div");
        contextMenuElement.classList.add("windowManager-contextMenu");
        contextMenuElement.setAttribute("data-depth", String(depth));
        contextMenuElement.style.zIndex = String(Number(getComputedStyle(element).zIndex) + depth);

        let parentPosition = element.getBoundingClientRect();
        parentPosition.x = parentPosition.x + parentPosition.width;

        contextMenuElement.style.top = parentPosition.y + "px";
        contextMenuElement.style.left = parentPosition.x + "px";
        contextMenuElement.style.maxWidth = computedParent.width + "px";
        contextMenuElement.style.maxHeight = computedParent.height + "px";

        contextMenuItems.forEach(item => {
            let contextChild = document.createElement("div");
            contextChild.classList.add("windowManager-contextMenu-item");
            
            let contextChildIcon = document.createElement("div");
            contextChildIcon.classList.add("windowManager-contextMenu-item-icon");
            contextChildIcon.innerHTML = item.svgIcon;

            let contextChildText = document.createElement("div");
            contextChildText.classList.add("windowManager-contextMenu-item-text");
            contextChildText.style.color = item.enabled ? "white" : "gray";
            contextChildText.innerText = item.name;

            if (item.enabled){
                addEventListenerCompactibility(contextChild, "click", e => {
                    e.stopImmediatePropagation();
                    if (item.onClick) item.onClick();
                    closeAllContextMenus();
                });
                addEventListenerCompactibility(contextChild, "mouseover", () => {
                    [...contextMenuElement.children].forEach(cmItem => cmItem.classList.remove("focused"));
                    contextChild.classList.add("focused");
                });
            };
            registerContextMenuChild(contextChild, item.childItems, depth+1);

            contextChild.appendChild(contextChildIcon);
            contextChild.appendChild(contextChildText);

            if (item.childItems.length > 0) {
                contextChild.classList.add("windowManager-contextMenu-item-withChildren");
                let contextArrowItem = document.createElement("div");
                contextArrowItem.classList.add("windowManager-contextMenu-item-arrow")
                contextArrowItem.innerHTML = "►";
                contextChild.appendChild(contextArrowItem);
            };

            contextMenuElement.appendChild(contextChild);
        });

        activeContent.appendChild(contextMenuElement);
        activeContextMenus.push(contextMenuElement);

        let finalStyle = contextMenuElement.getBoundingClientRect();
        let transformWidth = false;
        let transformHeight = false;

        if (finalStyle.width > computedParent.width - parentPosition.x) transformWidth = true;
        if (finalStyle.height > computedParent.height - parentPosition.y) transformHeight = true;

        contextMenuElement.style.transform = `translate(${transformWidth ? "-200" : "0"}%, ${transformHeight ? "-100" : "0"}%)`
        
        return {contextMenuElement, finalStyle};
    };

    let childContextMenu = null;
    let classWatcher = new ClassWatcher(element, "focused", () => {
        childContextMenu = contextMenuCallback();
        childContextMenu.contextMenuElement.animate([
            {height: "0px"},
            {height: childContextMenu.finalStyle.height + "px"}
        ],{
            iterations: 1,
            duration: 200,
            easing: "ease-in-out",
            fill: "both"
        });
    }, () => {
        if (!childContextMenu) return;
        let curWorkingElement = childContextMenu.contextMenuElement;
        let curDepth = Number(curWorkingElement.getAttribute("data-depth"));
        activeContextMenus = activeContextMenus.filter(mnItem => {
            let mnItemDepth = Number(mnItem.getAttribute("data-depth"));
            if (curWorkingElement !== mnItem && curDepth >= mnItemDepth) {
                return true
            } else if (mnItemDepth > curDepth) {
                mnItem.animate([
                    {height: getComputedStyle(mnItem).height},
                    {height: "0px"}
                ],{
                    iterations: 1,
                    duration: 200,
                    easing: "ease-in-out",
                    fill: "both"
                }).onfinish = () => {
                    mnItem.remove();
                };
                return false;
            } else {
                return false
            };
        });
        curWorkingElement.classList.remove("focused");
        curWorkingElement.animate([
            {height: childContextMenu.finalStyle.height + "px"},
            {height: "0px"}
        ],{
            iterations: 1,
            duration: 200,
            easing: "ease-in-out",
            fill: "both"
        }).onfinish = () => {
            curWorkingElement.remove();
        };
        childContextMenu = null;
    });
};

/**
 * @class
 * @classdesc A class that is used when creating context menu items.
 */
export class ContextMenuItem /** @lends {ContextMenuItem} */{
    /**
     * Creates a new context menu object.
     * @function
     * @param {object} arg The object that is passed to the class instance.
     * @param {string} [arg.name] The name/text of the context menu item.
     * @param {string} [arg.svgIcon] The icon in SVG format represented as a string.
     * @param {boolean} [arg.enabled] Is the context menu clickable.
     * @param {ContextMenuItem[]} [arg.childItems] Child items for context menu.
     * @param {() => void} [arg.onClick] Function that will be fired when the context menu is clicked.
     * @param {string} [arg.tag] A tag representing the context menu item
     */
    constructor({
        name = "",
        svgIcon = null,
        enabled = true,
        childItems = [],
        onClick = () => {},
        tag = ""
    } = {}){
        this.name = name;
        this.svgIcon = svgIcon;
        this.enabled = enabled;
        this.childItems = childItems;
        this.onClick = onClick;
        this.tag = tag;
    };
};

/**
 * Closes all context menus.
 * @function
 * @returns {void}
 */
export const closeAllContextMenus = () => {
    activeContextMenus.forEach(item => {
        if (!item) return;
        item.style.pointerEvents = "none";
        let oldHeight = getComputedStyle(item).height;
        item.animate([
            {height: oldHeight ? oldHeight : "0px"},
            {height: "0px"}
        ],{
            iterations: 1,
            duration: 200,
            easing: "ease-in-out",
            fill: "both"
        }).onfinish = () => item ? item.remove() : null;
    });
};