import React from "react";
import "./index.scss";

import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";
import * as backendModule from "../../../modules/backendModule";
import { animateBox } from "../../../modules/componentAnimation";
import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen";

import { FilteredCustomTable } from "../../customComponents/Table";
import Spinner from "../../customComponents/Spinner";

import PreviewImageModal from "../PreviewImageModal";

import { ReactComponent as InfoTab_Img } from "./images/orders_info.svg";

const SMSMessagesModal = props => {
    const [data, setData] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [globalSpinner, setGlobalSpinner] = React.useState(false);
    const [spinner, setSpinner] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState();

    const timestampRef = React.useRef();
    const curDefer = useDefer();
    const curOnScreen = useOnScreen();

    const orderStatusCodesSelector = useSelector(state => state?.types?.smsMarketing?.orderStatusCodes ?? {});
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const wrapTableItem = item => <span style={{padding: "0 50px 0 0px"}}>{item}</span>;

    const getCampaignIDs = () => {
        return Array.isArray(props.item) ? props.item : [props.item.ID];
    };

    const generateKey = item => {
        if (item?.ID) return item.ID;

        let final = "";
        if (!item) return String(item);
        for (let key of Object.keys(item)) {
            if (typeof (item[key]) === "object") {
                final += generateKey(item[key]);
                continue;
            };
            final += String(item[key]);
        };
        return final;
    };

    const getData = (ts) => {
        const filters = [];

        if (props.search) {
            filters.push({
                or: [
                    
                ]
            })
        };

        if (Array.isArray(props.filters)) filters.push(...props.filters);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getSMSDetailsByCampaignID`,
            data: {
                filters,
                CampaignIDs: getCampaignIDs(),
                limit: 20
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length >= 20) {
                    setCanPaginate(true);
                } else {
                    setCanPaginate(false);
                };
            };
            setData(res.data);
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.axiosConfig);
        }).finally(() => {
            if (timestampRef.current !== ts) return;
            setGlobalSpinner(false);
        });
    };

    const continueData = (ts) => {
        setCanPaginate(false);
        if (!data) return;
        if (data.status !== "ok") return;
        if (data.data.lnegth === 0) return;

        const filters = [];

        if (props.search) {
            filters.push({
                or: [

                ]
            })
        };
        if (Array.isArray(props.filters)) filters.push(...props.filters);

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getSMSDetailsByCampaignID`,
            data: {
                CampaignIDs: getCampaignIDs(),
                limit: 20,
                offset: data.data.length,
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (timestampRef.current !== ts) return;
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
                if (res.data.data.length >= 20) {
                    setCanPaginate(true);
                } else {
                    setCanPaginate(false);
                };
            };
        }).catch(() => null).finally(() => {
            if (timestampRef.current !== ts) return;
            setSpinner(false);
            setGlobalSpinner(false);
        });
    };

    React.useEffect(() => {
        if (data) setGlobalSpinner(true);

        let ts = Date.now();
        timestampRef.current = ts;
        curDefer(() => {
            getData(ts);
        }, 500);
    }, [props.search, props.filters]);

    React.useEffect(() => {
        if (curOnScreen.isIntersecting && canPaginate) {
            try {
                curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
            } catch { };

            let ts = Date.now();
            timestampRef.current = ts;
            curDefer(() => {
                continueData(ts);
            }, 500);
        };
    }, [canPaginate, curOnScreen.isIntersecting]);

    return <div className="modals__smsMessagesModal__wrap__content" style={{
        gridTemplateColumns: !selectedItem ? "1fr auto" : "calc(100% - 800px) 800px",
        gridTemplateRows: "100%",
        padding: 0,
        maxHeight: "100%",
        minHeight: "100%",
        height: "100%"
    }} >
        <div className="modals__smsMessagesModal__wrap__content__left">
            <div className="modals__smsMessagesModal__wrap__content__left__tableWrap">
                <FilteredCustomTable
                    theme={themeSelector}
                    accent="#6C5DD3"
                    headers={["No.", "Date", "Phone number", "SMS Status", "CTR", ""]}
                    customColumns={["auto", "auto", "auto", "auto", "auto", "auto", "auto", "18px"]}
                    spinnerColor="#fff"
                    showSpinner={globalSpinner}
                    data={(() => {
                        let out = [];

                        if (!data) return [[{ keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black" }]];
                        if (data.status === "ok") {
                            for (let item of data.data) {
                                

                                out.push({
                                    events: {
                                        onClick: () => {
                                            setSelectedItem(item);
                                        }
                                    },
                                    columns: [
                                        { keyID: generateKey(item), type: "text", text: wrapTableItem(out.length + 1) },
                                        { keyID: generateKey(item), type: "text", text: wrapTableItem(moment(item.Date).toDate().toLocaleString()) },
                                        { keyID: generateKey(item), type: "text", text: wrapTableItem(item.PhoneNumber) },
                                        { keyID: generateKey(item), type: "text", text: wrapTableItem(orderStatusCodesSelector[Number(item.Status)]) },
                                        { keyID: generateKey(item), type: "text", text: wrapTableItem(`${Number(item.CTR).toFixed(0)} %`) },
                                        { keyID: generateKey(item), type: "custom", data: <img className="modals__smsMessagesModal__wrap__content__left__img" src="/images/campaigns/campaign_openInNewTab.svg" /> }
                                    ],
                                    style: {
                                        backgroundColor: item === selectedItem ? "rgb(75, 70, 113)" : null
                                    }
                                })
                            };
                        } else {
                            out.push([{ keyID: "noData-error", type: "text", text: "Error while fetching orders!", color: "#f96666" }]);
                        };

                        if (spinner) out.push([{ keyID: "pagination-spinner", type: "spinner", color: "white" }]);

                        if (out.length === 0) out.push([{ keyID: "noData-noData", type: "text", text: "Nothing to show for now." }]);

                        return out;
                    })()}
                />
            </div>

            {canPaginate && <div ref={curOnScreen.measureRef} className="modals__smsMessagesModal__wrap__content__left__paginate"></div>}
        </div>
        <SelectedSMS theme={themeSelector} item={selectedItem} onClose={() => setSelectedItem()} campaign={{ integration: props.integration, item: props.item }} />
    </div>;
};

const SelectedSMS = props => {
    const [activeTab, setActiveTab] = React.useState();
    const [phoneData, setPhoneData] = React.useState();

    const orderStatusCodesSelector = useSelector(state => state?.types?.smsMarketing?.orderStatusCodes ?? {});

    const tabsRef = React.useRef();

    const onClose = () => {
        setActiveTab();
        setPhoneData();
        props.onClose();
    };

    const getPhoneData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getSMSDetailsByPhoneNumber`,
            data: {
                PhoneNumber: props.item.PhoneNumber
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setPhoneData(res.data);
        }).catch(() => {
            setPhoneData(backendModule.genericError);
        });
    };

    const changeTab = (idx, skipFirst = false) => {
        if (activeTab === idx) return;

        if (!tabsRef.current) return setActiveTab(idx);

        let allItems = [];

        for (let item of tabsRef.current.querySelectorAll("div")) {
            allItems.push(new Promise(r => {
                item.animate([
                    { left: getComputedStyle(item).left },
                    { left: "150%" }
                ], {
                    duration: skipFirst ? 0 : 300,
                    iterations: 1,
                    fill: "both",
                    easing: "ease"
                }).onfinish = () => r();
            }))
        };

        Promise.allSettled(allItems).then(() => {
            setActiveTab(idx)
            if (!tabsRef.current) return;

            let curTab = tabsRef.current.querySelector(`[data-tab="${idx}"]`);
            if (!curTab) return;
            curTab.animate([
                { left: getComputedStyle(curTab).left },
                { left: "0" }
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        });
    };

    const wrapTableItem = item => <span style={{padding: "0 50px 0 0px"}}>{item}</span>;

    React.useEffect(() => {
        if (!props?.item) return;
        if (!props.item?.PhoneNumber) return;
        getPhoneData();
    }, [props.item]);

    React.useEffect(() => {
        if (!tabsRef.current) return;
        if (activeTab !== undefined) return;

        changeTab(0, true);
    });

    return <div className={`modals__smsMessagesModal__wrap__content__right ${props.item ? "modals__smsMessagesModal__wrap__content__right--active" : ""}`} style={{
        minHeight: 0,
        height: "100%"
    }}>
        <div className="modals__smsMessagesModal__wrap__content__right__wrap">

            {props.item && <>
                <div className="modals__smsMessagesModal__wrap__content__right__wrap__head">
                    <div className="modals__smsMessagesModal__wrap__content__right__wrap__head__top">
                        {props?.item?.PhoneNumber ?? "?"} {phoneData ? <span>{phoneData?.status === "ok" ? `(${phoneData.data.length})` : `(?)`}</span> : <Spinner style={{width: "16px", height: "16px"}} color="white" />}
                    </div>
                    <div className="modals__smsMessagesModal__wrap__content__right__wrap__head__bottom">
                        {orderStatusCodesSelector[Number(props.item.Status)]}
                    </div>

                    <div className="modals__smsMessagesModal__wrap__content__right__wrap__head__close" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={onClose}></div>
                </div>

                <div className="modals__smsMessagesModal__wrap__content__right__wrap__tabs">
                    <div className={`modals__smsMessagesModal__wrap__content__right__wrap__tabs__tab ${activeTab === 0 ? "modals__smsMessagesModal__wrap__content__right__wrap__tabs__tab--active" : ""}`} onClick={() => changeTab(0)}>
                        <InfoTab_Img />
                        <span>General info</span>
                    </div>
                </div>

                <div className="modals__smsMessagesModal__wrap__content__right__wrap__tabWrap" ref={tabsRef}>

                    <div className="modals__smsMessagesModal__wrap__content__right__wrap__tabWrap__item modals__smsMessagesModal__wrap__content__right__wrap__tabWrap__item__numberTable" data-tab={0}>
                        <FilteredCustomTable
                            canAnimate={false}
                            style={{left: 0}}
                            theme={props.theme}
                            accent="#6C5DD3"
                            headers={["No.", "Date", "CTR", "Offer"]}
                            customColumns={["auto", "auto", "auto", "auto"]}
                            spinnerColor="#fff"
                            data={(()=>{
                                let out = [];

                                if (!phoneData) return [[{keyID: "noData-spinner", type: "spinner", color: props.theme === "dark" ? "white" : "black"}]];
                                if (phoneData.status === "error") return [[{keyID: "noData-error", type: "text", text: "Error while fetching data!"}]];

                                for (let item of phoneData.data) {
                                    out.push([
                                        {keyID: item.ID, type: "text", text: wrapTableItem(phoneData.data.indexOf(item) + 1)},
                                        {keyID: item.ID, type: "text", text: wrapTableItem(moment(item.Date).toDate().toLocaleString())},
                                        {keyID: item.ID, type: "text", text: wrapTableItem(`${Number(item.CTR).toFixed(0)} %`)},
                                        {keyID: item.ID, type: "text", text: wrapTableItem(item.CampaignName)},
                                    ])
                                };

                                if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);
                                return out;
                            })()}
                        />
                    </div>
                </div>
            </>}

        </div>
    </div>
};

export default SMSMessagesModal;