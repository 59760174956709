import React from "react";
import "./index.scss";

import moment from "moment";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";

import { FilteredCustomTable } from "../../components/customComponents/Table";
import FilterByDate from "../../components/filters/FilterByDate";
import StyledButton from "../../components/styledComponents/Button";

let curTimeout = null;
const ShareCampaign = () => {
    const [data, setData] = React.useState();
    const [orders, setOrders] = React.useState({});
    const [dateFilters, setDateFilters] = React.useState();
    const [isFetching, setIsFetching] = React.useState(false);
    const [progress, setProgress] = React.useState(null);
    const [websiteWidth, setWebsiteWidth] = React.useState(1920);

    const timestampRef = React.useRef();
    const currencySignSelector = "€";

    const getData = async (ts) => {
        if (timestampRef.current !== ts) return;

        let token = String(window.location).split("share-campaign/");
        if (token.length !== 2) {
            setProgress();
            setData(backendModule.genericError);
            return;
        };

        let filters = [];
        if (dateFilters) {
            if (dateFilters?.start && dateFilters?.end) {
                filters.push({ name: "createdAt", op: "pdgeq", value: dateFilters.start.toDate().getTime() });
                filters.push({ name: "createdAt", op: "pdleq", value: dateFilters.end.toDate().getTime() });
            } else {
                filters.push({name: "createdAt", op: "deq", value: Date.now()});
            };
        } else {
            filters.push({name: "createdAt", op: "deq", value: Date.now()});
        };


        setProgress(null);
        setIsFetching(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/share/getShared`,
            data: {
                token: token[1],
                filters,
                utcOffset: (new Date()).getTimezoneOffset()
            },
            ...backendModule.axiosConfig
        }).then(res => setData(res.data)).catch(() => setData(backendModule.genericError)).finally(() => {
            setProgress();
            setIsFetching(false);
        });
    };

    const prepareTableData = (data, column, returnOnAny = false) => {
        let tmpCol = column.replace(/ /g, "_");
        if (tmpCol.endsWith("_IN")) tmpCol = tmpCol.substring(0, tmpCol.length - 3);

        if (tmpCol?.endsWith?.(".lastDate") && tmpCol?.startsWith?.("@")) {
            if (data === 0) return "Never";
            if (moment(data).isValid()) return moment(data).toDate().toLocaleString();
        };
        switch (tmpCol) {
            case "Revenue":
            case "Spent":
            case "Profit":
            case "EPV":
            case "CPA":
            case "CPAO":
            case "CPR":
            case "CPR1000":
            case "CPV":
            case "ADP":
            case "CPC":
            case "CPM":
                let tmpRevenue = Number(data);
                if (isNaN(tmpRevenue)) return "-";
                return `${tmpRevenue.toFixed(2)} ${currencySignSelector}`;
            case "CR":
            case "AR":
            case "CUR":
            case "CRR":
            case "ROI":
            case "DR":
            case "CTR":
                let tmpCR = Number(data);
                if (isNaN(tmpCR)) return "-";
                return `${tmpCR.toFixed(2)} %`;
            case "ROAS":
                let tmpROAS = Number(data);
                if (isNaN(tmpROAS)) return "-";
                return `${tmpROAS.toFixed(2)}x`;
            default:
                if (returnOnAny) return data;
                let tmp = Number(data);
                if (isNaN(tmp)) return data;
                return tmp.toLocaleString();
        };
    };

    const wrapTableItem = text => {
        return <span style={{marginRight: "20px"}}>{text}</span>
    };
    
    const formatKPINames = name => {
        return name.split("_").join(" ");
    };

    const orderData = (curData, table) => {
        if (!orders[table]) return curData;

        let final = [...curData].sort((a, b) => {
            let a1 = a[orders[table].name];
            let b1 = b[orders[table].name];

            let isAsc = orders[table]?.order === "asc";

            if (orders[table].name.startsWith("Date")) {
                a1 = moment(a1);
                b1 = moment(b1);
                return a1.isAfter(b1) ? (isAsc ? 1 : -1) : (isAsc ? -1 : 1);
            } else {
                return a1 > b1 ? (isAsc ? 1 : -1) : (isAsc ? -1 : 1);
            };
        });
        return final;
    };

    React.useEffect(() => {
        if (isFetching) return;
        if (!data) return;
        clearTimeout(curTimeout);
        curTimeout = setTimeout(() => {
            if (!progress) return setProgress({value: 0, maximum: 30});
            if (progress?.value === 30) {
                setProgress(null);
                let ts = Date.now();
                timestampRef.current = ts
                getData(ts);
                return;
            };
            return setProgress(p => {return {...p, value: p.value += 1}});
        }, 1000);

        return () => clearTimeout(curTimeout);
    }, [data, progress, isFetching]);

    React.useEffect(() => {
        let ts = Date.now();
        timestampRef.current = ts;
        getData(ts);

        setWebsiteWidth(window.innerWidth);
        let handler = () => {
            if (websiteWidth !== window.innerWidth) setWebsiteWidth(window.innerWidth);
        };
        window.addEventListener("resize", handler);
        return () => window.removeEventListener("resize", handler);
    }, [dateFilters]);

    return <div className="route__shareCampaign">
        <div className={`route__shareCampaign__progress ${!progress ? "route__shareCampaign__progress--animate" : ""}`} style={{pointerEvents: "none", opacity: progress !== undefined ? 1 : 0}}>
            {progress !== undefined && <div className="route__shareCampaign__progress__item" style={{
                width: !progress ? "30%" : `${100 / progress.maximum * progress.value}%`
            }}></div>}
        </div>

        {data?.status === "ok" && <div className="route__shareCampaign__wrap">
            <div className="route__shareCampaign__wrap__heading">
                <h2 className="route__shareCampaign__wrap__heading__head">
                    {data.data.Name}
                </h2>
                <p className="route__shareCampaign__wrap__heading__sub">{data.data.ID}</p>

                <FilterByDate defaultValue="today" onChange={setDateFilters} disableAll={true} disable24h={true} />
            </div>

            {data.data.Comment && <div className="route__shareCampaign__wrap__comment">
                {data.data.Comment}
            </div>}

            <div className="route__shareCampaign__wrap__kpi">
                {Object.keys(data.data.CampaignData).map(c => {
                    if (c.startsWith("#")) return null;
                    return <div className="route__shareCampaign__wrap__kpi__item">
                        <div className="route__shareCampaign__wrap__kpi__item__top">{formatKPINames(c)}</div>
                        <div className="route__shareCampaign__wrap__kpi__item__bottom">{prepareTableData(data.data.CampaignData[c] ?? 0, c)}</div>
                    </div>
                })}
            </div>

            {Object.keys(data.data.Tables).map(key => {
                return <div className="route__shareCampaign__wrap__table">
                    <h3>
                        {key}
                        <StyledButton style={{height: "30px", marginLeft: "30px"}} onClick={() => {
                            navigator.clipboard.writeText(data.data.Tables[key].Data.map(d => d["ID"]));
                        }}>Copy all IDs</StyledButton>
                    </h3>
                    <FilteredCustomTable
                        orderCB={e => {
                            if (e) {
                                setOrders(o => {return {...o, [key]: e}});
                            } else {
                                setOrders(o => {
                                    let tmp = {...o};
                                    delete tmp[key];
                                    return tmp;
                                });
                            };
                        }}
                        theme="dark"
                        accent="#6C5DD3"
                        customColumns={websiteWidth > 600 ? ["max-content", ...data.data.Tables[key].Headers.map(() => "max-content")] : ["1fr", "1fr"]}
                        headers={["No.", ...data.data.Tables[key].Headers]}
                        style={{columnGap: "40px"}}
                        data={(()=>{
                            let out = [];
                            let i = 0;

                            for (let item of orderData(data.data.Tables[key].Data, key)) {
                                i += 1;
                                if (!item) continue;
                                let tmp = [{keyID: item.ID, type: "text", text: wrapTableItem(i), style: {paddingRight: websiteWidth > 480 ? "50px" : null}}];
                                for (let itemKey of data.data.Tables[key].Headers) {
                                    if (["Campaign ads"].includes(key)) {
                                        tmp.push({keyID: item.ID, type: "text", text: wrapTableItem(prepareTableData(item[itemKey], itemKey, true))});
                                    } else {
                                        tmp.push({keyID: item.ID, type: "text", text: wrapTableItem(item[itemKey])});
                                    };
                                };
                                out.push(tmp);
                            };

                            if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);

                            return out;
                        })()}
                    />
                </div>
            })}
        </div>}
    </div>
};

export default ShareCampaign;