import React from "react";
import "./index.scss";

import axios from "axios";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import useOnScreen from "../../../modules/hooks/useOnScreen";

import useDefer from "../../../modules/hooks/useDefer";
import * as backendModule from "../../../modules/backendModule";
import * as basicStylesModule from "../../../modules/basicStylesModule";
import * as siteFunctionsActions from "../../../actions/siteFunctionsActions";
import { animateBox } from "../../../modules/componentAnimation";
import { getIntegrationImage } from "../../../modules/miscModule";

import FilterBySearch from "../../../components/filters/FilterBySearch";
import { FilteredCustomTable } from "../../../components/customComponents/Table";
import Spinner from "../../../components/customComponents/Spinner";

import YesNoModal from "../../../components/modals/YesNoModal";
import StyledButton from "../../../components/styledComponents/Button";
import PreviewImageModal from "../../../components/modals/PreviewImageModal";

const dataCountStartValue = {
    ["single-waiting"]: null,
    ["single-completed"]: null,
    ["bulk-waiting"]: null,
    ["bulk-completed"]: null
};
const AdminDataChange = () => {
    const [data, setData] = React.useState();
    const [dataCount, setDataCount] = React.useState(dataCountStartValue);
    const [users, setUsers] = React.useState();
    const [search, setSearch] = React.useState();
    const [activeTab, setActiveTab] = React.useState("single-waiting");
    const [canPaginate, setCanpaginate] = React.useState(false);

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const curDispatch = useDispatch();
    const dataDefer = useDefer();
    const curOnScreen = useOnScreen();

    const getSearchFilters = () => {
        let out = [];

        if (!search) return out;

        for (let item of search.split(" ")) {

            let userIDs = [];
            if (item && users?.status === "ok") {
                for (let usr of users.data) {
                    if (usr.Username.toLowerCase().startsWith(item.toLowerCase())) userIDs.push(usr.ID);
                };
            };

            out.push({or: [
                {name: "IntegrationID", op: "like", value: item},
                {name: "EntityID", op: "like", value: item},
                {name: "Column", op: "like", value: item},
                {name: "CreatedBy", op: "like", value: item},
                (userIDs.length > 0 ? {name: "CreatedBy", op: "in", value: userIDs} : null)
            ].filter(t => t)});
        };

        return [{and: out}];
    };

    const getCounts = async () => {
        let counts = {
            ["single-waiting"]: null,
            ["single-completed"]: null,
            ["bulk-waiting"]: null,
            ["bulk-completed"]: null
        };

        for (let key of Object.keys(counts)) {
            let filters = [];
            let tmp = key.split("-");

            filters.push({name: "isCompleted", op: "eq", value: tmp[1] === "completed"});

            await axios({
                method: "POST",
                url: `${backendModule.backendURL}/dataUpdateRequest/getAllRequestsCount`,
                data: {
                    filters: [
                        ...getSearchFilters(),
                        ...filters
                    ],
                    isBulk: tmp[0] === "bulk"
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") counts[key] = res.data.data;
            }).catch(() => null);
        };

        setDataCount(counts);
    };

    const getData = () => {
        let tmpFilters = activeTab.split("-");
        setCanpaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/dataUpdateRequest/getAllRequests`,
            data: {
                limit: 20,
                offset: 0,
                filters: [
                    ...getSearchFilters(),
                    {name: "isCompleted", op: "eq", value: tmpFilters[1] === "completed"}
                ],
                orders: [
                    {name: "updatedAt", order: "desc"}
                ],
                isBulk: tmpFilters[0] === "bulk"
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length >= 20) setCanpaginate(true);
            };
            setData({
                ...res.data,
                type: tmpFilters.join("-")
            });
        }).catch(() => {
            setData({
                ...backendModule.genericError,
                type: tmpFilters.join("-")
            });
        });
    };

    const continueData = () => {
        if (!data) return;
        if (data.status !== "ok") return;
        if (!canPaginate) return;

        let tmpFilters = activeTab.split("-");
        setCanpaginate(false);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/dataUpdateRequest/getAllRequests`,
            data: {
                limit: 20,
                offset: 0,
                filters: [
                    ...getSearchFilters(),
                    {name: "isCompleted", op: "eq", value: tmpFilters[1] === "completed"},
                    {name: "ID", op: "notIn", value: data.data.map(d => d.ID)}
                ],
                orders: [
                    {name: "updatedAt", order: "desc"}
                ],
                isBulk: tmpFilters[0] === "bulk"
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length >= 20) setCanpaginate(true);

                setData({
                    ...res.data,
                    data: [
                        ...data.data,
                        ...res.data.data
                    ],
                    type: tmpFilters.join("-")
                });
            };
        }).catch(() => {
            setData({
                ...backendModule.genericError,
                type: tmpFilters.join("-")
            });
        });
    };

    const getUsers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            data: {
                limit: null,
                offset: 0
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setUsers(res.data);
        }).catch(() => {
            setUsers(backendModule.genericError);
        });
    };

    const approveRequest = (e, ID) => {
        if (!data) return;
        if (data.status !== "ok") return;

        let ct = {currentTarget: e?.target};

        if (data.type !== activeTab) return;
        let tmpFilters = activeTab.split("-");
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/dataUpdateRequest/approveRequest`,
            data: {
                ID,
                isBulk: tmpFilters[0] === "bulk"
            },
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status === "ok") {
                setData(d => {
                    return {
                        ...d,
                        data: d.data.filter(dd => dd.ID !== ID)
                    };
                });
            } else {
                animateBox(ct, <YesNoModal
                    heading={"Error"}
                    text={"Error while applying data change request. Please check the information provided and try again later."}
                    buttonLeftHidden={true}
                    buttonRightText={"Ok"}
                    isRightButtonNormal={true}
                />);
            };
        }).catch(() => {
            animateBox(ct, <YesNoModal
                heading={"Error"}
                text={"Server timed out."}
                buttonLeftHidden={true}
                buttonRightText={"Ok"}
                isRightButtonNormal={true}
            />);
        });
    };

    const rejectRequest = ID => {
        if (!data) return;
        if (data.status !== "ok") return;

        if (data.type !== activeTab) return;
        let tmpFilters = activeTab.split("-");
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/dataUpdateRequest/removeRequest`,
            data: {
                ID,
                isBulk: tmpFilters[0] === "bulk"
            },
            ...backendModule.axiosConfig
        }).then(() => null).catch(() => null);

        setData(d => {
            return {
                ...d,
                data: d.data.filter(dd => dd.ID !== ID)
            };
        });
    };

    React.useEffect(() => {
        let handler = () => {
            setData();
            setDataCount(dataCountStartValue);

            dataDefer(() => {
                getData();
                getCounts();
            }, 1000);
        };

        handler();
        curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));

        return () => siteFunctionsActions.removeHeaderRefreshAction(handler);
    }, [search, activeTab]);

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        continueData();
    }, [canPaginate, curOnScreen.isIntersecting]);
    
    React.useEffect(() => {
        getUsers();
    }, []);

    return <div className="route__admin__dataChange">
        <FilterBySearch onChange={e => setSearch(e)} />

        <div className="route__admin__dataChange__kpi">

            <div className="route__admin__dataChange__kpi__item">
                <div className="route__admin__dataChange__kpi__item__top">Single requests waiting</div>
                <div className="route__admin__dataChange__kpi__item__bottom">
                    {dataCount["single-waiting"] !== null ? dataCount["single-waiting"] : <Spinner style={{width: "18px", height: "18px"}} color={themeSelector === "dark" ? "white" : "black"} />}
                    <div className={`route__admin__dataChange__kpi__item__bottom__btn ${activeTab === "single-waiting" ? "route__admin__dataChange__kpi__item__bottom__btn--active": ""}`} onClick={() => setActiveTab("single-waiting")}>View</div>
                </div>
            </div>

            <div className="route__admin__dataChange__kpi__item">
                <div className="route__admin__dataChange__kpi__item__top">Bulk requests waiting</div>
                <div className="route__admin__dataChange__kpi__item__bottom">
                    {dataCount["bulk-waiting"] !== null ? dataCount["bulk-waiting"] : <Spinner style={{width: "18px", height: "18px"}} color={themeSelector === "dark" ? "white" : "black"} />}
                    <div className={`route__admin__dataChange__kpi__item__bottom__btn ${activeTab === "bulk-waiting" ? "route__admin__dataChange__kpi__item__bottom__btn--active": ""}`} onClick={() => setActiveTab("bulk-waiting")}>View</div>
                </div>
            </div>

            <div className="route__admin__dataChange__kpi__item">
                <div className="route__admin__dataChange__kpi__item__top">Single requests completed</div>
                <div className="route__admin__dataChange__kpi__item__bottom">
                    {dataCount["single-completed"] !== null ? dataCount["single-completed"] : <Spinner style={{width: "18px", height: "18px"}} color={themeSelector === "dark" ? "white" : "black"} />}
                    <div className={`route__admin__dataChange__kpi__item__bottom__btn ${activeTab === "single-completed" ? "route__admin__dataChange__kpi__item__bottom__btn--active": ""}`} onClick={() => setActiveTab("single-completed")}>View</div>
                </div>
            </div>

            <div className="route__admin__dataChange__kpi__item">
                <div className="route__admin__dataChange__kpi__item__top">Bulk requests completed</div>
                <div className="route__admin__dataChange__kpi__item__bottom">
                    {dataCount["bulk-completed"] !== null ? dataCount["bulk-completed"] : <Spinner style={{width: "18px", height: "18px"}} color={themeSelector === "dark" ? "white" : "black"} />}
                    <div className={`route__admin__dataChange__kpi__item__bottom__btn ${activeTab === "bulk-completed" ? "route__admin__dataChange__kpi__item__bottom__btn--active": ""}`} onClick={() => setActiveTab("bulk-completed")}>View</div>
                </div>
            </div>

        </div>

        {(activeTab === "single-waiting" || activeTab === "single-completed") && <AdminDataChange_single type={activeTab} data={data} users={users} approveRequest={approveRequest} rejectRequest={rejectRequest} />}
        {(activeTab === "bulk-waiting" || activeTab === "bulk-completed") && <AdminDataChange_bulk type={activeTab} data={data} users={users} approveRequest={approveRequest} rejectRequest={rejectRequest} />}
        {canPaginate && <div style={{opacity: 0}} ref={curOnScreen.measureRef}></div>}
    </div>
};

const AdminDataChange_single = props => {
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    return <FilteredCustomTable
        accent="#6C5DD3"
        theme={themeSelector}
        headers={["From", "For date", "Integration", "Column", "New data", "Actions"]}
        data={(()=>{
            if (!props.data || !props.users || props.data?.type !== props.type) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
            if (props.data.status === "error" || props.users.status === "error") return [[{keyID: "noData-error", type: "text", text: "Error while fetching data", color: basicStylesModule.errorColor}]]

            let out = [];
            for (let item of props.data.data) {
                let cu = props.users.data.find(u => u.ID === item.CreatedBy);
                if (!cu) cu = {Username: "?"};
                let curIntegration = item.IntegrationID;
                if (curIntegration.startsWith("fb-")) {
                    curIntegration = curIntegration.substring(3, curIntegration.length);
                };

                out.push([
                    {keyID: item.ID, type: "text", text: cu.Username},
                    {keyID: item.ID, type: "text", text: moment(item.ForDate).toDate().toLocaleDateString()},
                    {keyID: item.ID, type: "custom", data: <p className="route__admin__dataChange__tableIntegration">
                        <img src={getIntegrationImage(item.IntegrationID)} />
                        <span>{curIntegration}</span>
                        <span style={{flexBasis: "100%"}}>{item.EntityID}</span>
                    </p>},
                    {keyID: item.ID, type: "text", text: item.Column},
                    {keyID: item.ID, type: "text", text: item.NewData},
                    (item.isCompleted ? null : {keyID: item.ID, type: "group", group: [
                        {keyID: item.ID, type: "button", text: "Approve", onClick: e => props.approveRequest(e, item.ID)},
                        {keyID: item.ID, type: "button", text: "Reject", style: {marginLeft: "5px"}, onClick: () => props.rejectRequest(item.ID)}
                    ]})
                ].filter(t => t));
            };

            if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);

            return out;
        })()}
        style={{
            maxWidth: "100%",
            overflow: "auto",
            marginTop: "20px"
        }}
    />
};

const AdminDataChange_bulk = props => {
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    return <FilteredCustomTable
        accent="#6C5DD3"
        theme={themeSelector}
        headers={["From", "Integration", "Date created", "Items changed", "Actions"]}
        customColumns={["1fr", "1fr", "1fr", "1fr", "auto"]}
        data={(()=>{
            if (!props.data || !props.users || props.data?.type !== props.type) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
            if (props.data.status === "error" || props.users.status === "error") return [[{keyID: "noData-error", type: "text", text: "Error while fetching data", color: basicStylesModule.errorColor}]]

            let out = [];
            for (let item of props.data.data) {
                let cu = props.users.data.find(u => u.ID === item.CreatedBy);
                if (!cu) cu = {Username: "?"};
                let curIntegration = item.IntegrationID;
                if (curIntegration.startsWith("fb-")) {
                    curIntegration = curIntegration.substring(3, curIntegration.length);
                };

                out.push([
                    {keyID: item.ID, type: "text", text: cu.Username},
                    {keyID: item.ID, type: "custom", data: <p className="route__admin__dataChange__tableIntegration">
                        <img src={getIntegrationImage(item.IntegrationID)} />
                        <span>{curIntegration}</span>
                        <span style={{flexBasis: "100%"}}>{item.EntityID}</span>
                    </p>},
                    {keyID: item.ID, type: "text", text: moment(item.createdAt).toDate().toLocaleDateString()},
                    {keyID: item.ID, type: "text", text: item.Data.length},
                    {keyID: item.ID, type: "group", group: [
                        {keyID: item.ID, type: "button", text: "View", onClick: e => animateBox(e, <AdminDataChange_bulk_modal approveRequest={props.approveRequest} rejectRequest={props.rejectRequest} item={item} />)},
                    ]}
                ]);
            };

            if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);

            return out;
        })()}
        style={{
            maxWidth: "100%",
            overflow: "auto",
            marginTop: "20px"
        }}
    />
};

const AdminDataChange_bulk_modal = props => {
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const wrapRef = React.useRef();

    const onClose = () => {
        if (wrapRef.current) {
            wrapRef.current.animate([
                {right: getComputedStyle(wrapRef.current).right},
                {right: "-100%"}
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };

        props.onClose();
    };

    React.useEffect(() => {
        if (!wrapRef.current) return;

        wrapRef.current.animate([
            {right: getComputedStyle(wrapRef.current).right},
            {right: 0}
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);

    return <div className="route__admin__dataChange__modal" onClick={() => onClose()}>
        <div ref={wrapRef} className="route__admin__dataChange__modal__wrap" style={{width: "calc(100% - 84px)"}} onClick={e => e?.stopPropagation()}>

            <div className="route__admin__dataChange__modal__wrap__top">
                <div className="route__admin__dataChange__modal__wrap__top__left">Bulk data change</div>

                <StyledButton style={{marginLeft: "auto", marginRight: "20px"}} onClick={(e) => {
                    animateBox(e, <PreviewImageModal image={props.item?.Image?.URL ?? "#"} />);
                }}>View proof</StyledButton>
                {!props.item.isCompleted && <>
                    <StyledButton style={{marginRight: "20px"}} onClick={() => {
                        props.approveRequest({currentTarget: document.querySelector(".route__admin__dataChange__kpi")}, props.item.ID);
                        onClose();
                    }}>Approve</StyledButton>
                    <StyledButton isSecondary={true} style={{marginRight: "50px"}} onClick={() => {
                        props.rejectRequest(props.item.ID);
                        onClose();
                    }}>Reject</StyledButton>
                </>}

                <div className="route__admin__dataChange__modal__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>

            <div className="route__admin__dataChange__modal__wrap__content">
                <FilteredCustomTable
                    accent="#6C5DD3"
                    theme={themeSelector}
                    headers={["Campaign", "Entity ID", "Date", "Column", "Data"]}
                    data={(() => {
                        let data = props.item.Data.map(item => {
                            return [
                                {keyID: item.CampaignID, type: "text", text: item._CampaignName},
                                {keyID: item.CampaignID, type: "text", text: item.EntityID},
                                {keyID: item.CampaignID, type: "text", text: moment(item.ForDate).toDate().toLocaleDateString()},
                                {keyID: item.CampaignID, type: "text", text: item.Column},
                                {keyID: item.CampaignID, type: "text", text: item.NewData},
                            ];
                        });

                        data.push([
                            {keyID: "total-sum", type: "text", text: "Total", isFooter: true},
                            {keyID: "total-sum", type: "text", text: "-", isFooter: true},
                            {keyID: "total-sum", type: "text", text: "-", isFooter: true},
                            {keyID: "total-sum", type: "text", text: "-", isFooter: true},
                            {keyID: "total-sum", type: "text", text: props.item.Data.reduce((acc, val) => acc + +(val?.NewData ?? 0),0), isFooter: true},
                        ]);

                        return data;
                    })()}
                />
            </div>

        </div>
    </div>
};

export default AdminDataChange;