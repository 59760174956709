import React from "react";
import "./index.scss";

import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import { animateBox } from "../../../../modules/componentAnimation";
import * as backendModule from "../../../../modules/backendModule";
import useOnScreen from "../../../../modules/hooks/useOnScreen";
import useDefer from "../../../../modules/hooks/useDefer";

import { FilteredCustomTable } from "../../../../components/customComponents/Table";
import Spinner from "../../../../components/customComponents/Spinner";
import Dropdown from "../../../../components/customComponents/Dropdown";

import YesNoModal from "../../../../components/modals/YesNoModal";

const SocialManager_Engage = () => {
    const [availableBudget, setAvailableBudget] = React.useState();
    const [data, setData] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);

    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "?");
    const orderStatusCodesSelector = useSelector(state => state?.types?.globalIntegrations?.jp?.orderStatusCodes ?? {});
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const timestampRef = React.useRef();
    const curOnScreen = useOnScreen();
    const curDefer = useDefer();

    const getStatusColors = (status) => {
        if (status === 100) return themeSelector === "dark" ? "rgb(102, 238, 102)" : "green";
        if (status >= 90 && status <= 99) return themeSelector === "dark" ? "rgb(238, 102, 102)" : "red";
        return themeSelector === "dark" ? "#FFF" : "#000";
    };

    const getData = ts => {
        setCanPaginate(false);
        if (ts !== timestampRef.current) return;

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/globalIntegrations/jp/getAllOrdersForUser`,
            data: {
                limit: 20,
                offset: 0
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (ts !== timestampRef.current) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) setCanPaginate(true);
            };

            setData(res.data);
        }).catch(() => {
            if (ts !== timestampRef.current) return;
            setData(backendModule.genericError);
        });
    };

    const continueData = ts => {
        if (!canPaginate) return;
        if (ts !== timestampRef.current) return;
        if (!data) return;
        if (data.status !== "ok") return;

        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/globalIntegrations/jp/getAllOrdersForUser`,
            data: {
                limit: 20,
                offset: data.data.length
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (ts !== timestampRef.current) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) setCanPaginate(true);

                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
            };

        }).catch(() => null);
    };

    const getBudget = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/globalIntegrations/jp/getQuota`,
            ...backendModule.axiosConfig
        }).then(res => {
            setAvailableBudget(res.data);
        }).catch(() => {
            setAvailableBudget(backendModule.genericError);
        });
    };

    React.useEffect(() => {
        curDefer(() => {
            if (!canPaginate) return;
            if (!curOnScreen.isIntersecting) return;
    
            try {
                curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
            } catch {};

            let ts = Date.now();
            timestampRef.current = ts;
            continueData(ts);
        }, 500);
    }, [curOnScreen.isIntersecting, canPaginate]);

    React.useEffect(() => {
        getBudget();
        getData();
    }, []);

    return <div className="route__socialManager__engage">

        <div className="route__socialManager__engage__kpi">
            <div className="route__socialManager__engage__kpi__item">
                <div className="route__socialManager__engage__kpi__item__top">Budget remaining</div>
                <div className="route__socialManager__engage__kpi__item__bottom">
                    {availableBudget ? <>
                        {availableBudget.status === "ok" ? `${Number(availableBudget.data).toFixed(2)} ${currencySignSelector}` : "?"}
                    </> : <Spinner style={{width: "32px", height: "32px"}} color="white" />}
                </div>
            </div>
        </div>

        <div className="route__socialManager__engage__head">
            <div className="route__socialManager__engage__head__left">

            </div>

            <div className="route__socialManager__engage__head__right">
                <div className="route__socialManager__engage__head__right__btn" onClick={e => animateBox(e, <CreateOrder onChange={() => {
                    let ts = Date.now();
                    timestampRef.current = ts;
                    getData(ts);
                }} />)}>
                    <img src="/images/icon_close.svg" style={{transform: "rotate(45deg)"}} />
                    <p>Create order</p>
                </div>
            </div>
        </div>

        <FilteredCustomTable
            theme={themeSelector}
            headers={["ID", "Quantity", "Total spent", "Status", "Date"]}
            customColumns={(new Array(5)).fill("auto")}
            style={{
                maxWidth: "100%",
                width: "100%"
            }}
            data={(()=>{
                let out = [];

                if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                if (data.status === "error") return [[{keyID: "noData-error", type: "text", text: "There was an error while fetching data!"}]];

                for (let item of data.data) {
                    out.push([
                        {keyID: item.ID, type: "text", text: item.ID},
                        {keyID: item.ID, type: "text", text: item.Quantity},
                        {keyID: item.ID, type: "text", text: `${item.Spent} ${currencySignSelector}`},
                        {keyID: item.ID, type: "text", text: orderStatusCodesSelector[item.Status], style: {color: getStatusColors(item.Status)}},
                        {keyID: item.ID, type: "text", text: moment(item.createdAt).toDate().toLocaleString()},
                        {keyID: item.ID, type: "groupNewline", group: [
                            {keyID: item.ID, type: "custom", data: <p className="route__socialManager__engage__links">
                                <span>Links</span>
                                {(()=>{
                                    let links = item?.Data?.links;
                                    if (!Array.isArray(links)) links = [];

                                    return links.map(l => <span>{l}</span>);
                                })()}
                            </p>}
                        ]}
                    ]);
                };

                if (out.length === 0) {
                    out.push([{ keyID: "noData-noData", type: "text", text: "Nothing to show..." }]);
                };
                if (canPaginate) {
                    out.push([
                        { keyID: "data-paginationSpinner", type: "spinner", color: "white" },
                        { keyID: "data-pagination", type: "custom", data: <div ref={curOnScreen.measureRef}></div> }
                    ]);
                };
                return out;
            })()}
        />

    </div>;
};

const CreateOrder = props => {
    const [timestamp, setTimestamp] = React.useState(Date.now());
    const [curQuota, setCurQuota] = React.useState();
    const [data, setData] = React.useState();
    const [selectedService, setSelectedService] = React.useState();
    const [spinner, setSpinner] = React.useState(false);
    const [urls, setUrls] = React.useState([]);

    const curCommentsRef = React.useRef();
    const curQuantityRef = React.useRef();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const [infoP, setInfoP] = React.useState({
        error: "",
        hadError: false,
        inputs: []
    });

    const prepareSummary = () => {
        let curService = findService(selectedService);

        return <div className="genericModal__wrap__summary" key={`select-service-facebook-summary-${timestamp}`}>
            <p>Summary:</p>
            <br />
            <p>Selected service: <span className="genericModal__wrap__summary__green">{curService?.Name}</span></p>
            <p>Selected items: <span className="genericModal__wrap__summary__green">{urls.length}</span></p>
            <br />
            <p>Total urls: <span className="genericModal__wrap__summary__green">{urls.length}</span></p>
            <p>Quantity per url: <span className="genericModal__wrap__summary__green">{curQuantityRef.current?.value}</span></p>
            <p>Total quantity: <span className="genericModal__wrap__summary__green">{curQuantityRef.current?.value * urls.length}</span></p>
            {curService?.Type === 1 && <>
                <p>Total comments: <span className={curCommentsRef.current?.value?.split("\n")?.filter?.(t => t)?.length ? "genericModal__wrap__summary__green" : "genericModal__wrap__summary__red"}>{curCommentsRef.current?.value?.split("\n")?.filter?.(t => t)?.length}</span></p>
            </>}

            <br />
            <p>Price: <span>{curService?.Price}</span></p>
            <p>Total price: <span>{curService?.Price * curQuantityRef.current?.value * urls.length}</span></p>
            <p>Current quota: {curQuota ? <span className={curQuota.status === "ok" ? "genericModal__wrap__summary__green" : "genericModal__wrap__summary__red"}>{curQuota.status === "ok" ? curQuota.data : "-"}</span> : <Spinner style={{width: "16px", height: "16px"}} color="white" />}</p>
            {curQuota?.status === "ok" && <p>Quota left after order: <span className={curQuota.data - (curService?.Price * curQuantityRef.current?.value * urls.length) > 0 ? "genericModal__wrap__summary__green" : "genericModal__wrap__summary__red"}>{curQuota.data - (curService?.Price * curQuantityRef.current?.value * urls.length)}</span></p>}
        </div>
    };

    const findService = ID => {
        if (!ID) return;
        if (!data) return;
        if (data.status !== "ok") return;

        return data.data.find(d => d.ID === ID);
    };

    const createOrder = () => {
        if (spinner) return;
        setInfoP(i => { return { ...i, inputs: [], hadErrors: false } });

        let AdditionalData = {};

        if (findService(selectedService)?.Type === 1) {
            AdditionalData["Comments"] = curCommentsRef?.current?.value?.split?.("\n")?.filter?.(t => t)
        };

        let data = {
            ServiceID: selectedService,
            Quantity: Number(curQuantityRef.current?.value),
            Urls: urls.map(u => u.trim()),
            AdditionalData
        };

        let curService = findService(selectedService);

        if (!data.ServiceID) return setInfoP(i => { return { ...i, inputs: ["type"], hadErrors: true, error: "Service is not selected." } });
        if (urls.length === 0) return setInfoP(i => { return { ...i, inputs: ["urls"], hadErrors: true, error: "At least 1 URL must be provided." } });
        if (!data.Quantity || isNaN(data.Quantity)) return setInfoP(i => { return { ...i, inputs: ["quantity"], hadErrors: true, error: "Quantity must be a number greater than 0." } });
        if (curService?.Type === 1) {
            if (!Array.isArray(AdditionalData["Comments"])) return setInfoP(i => { return { ...i, inputs: ["comments"], hadErrors: true, error: "At least 1 comment should be present." } });
            if (AdditionalData["Comments"].length === 0) return setInfoP(i => { return { ...i, inputs: ["comments"], hadErrors: true, error: "At least 1 comment should be present." } });
        };

        if (data.Quantity < curService.MinQuantity) return setInfoP(i => { return { ...i, inputs: ["quantity"], hadErrors: true, error: `Quantity must be greater than a minimum treshold (${curService.MinQuantity})` } });
        if (data.Quantity > curService.MaxQuantity) return setInfoP(i => { return { ...i, inputs: ["quantity"], hadErrors: true, error: `Quantity must be lower than a maximum treshold (${curService.MaxQuantity})` } });

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/globalIntegrations/jp/createOrderFromURL`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                return animateBox({currentTarget: document.querySelector(".genericModal__wrap__buttons__btn")}, <YesNoModal
                    heading="Order created"
                    text="Your order was created!"
                    buttonLeftHidden={true}
                    buttonRightText="Ok"
                    isRightButtonNormal={true}
                    buttonRightCallback={(args) => {
                        props.onClose();
                        args.close();
                    }}
                />);
            } else {
                switch (res.data.data) {
                    case "INTEGRATION_ERROR": return setInfoP(i => { return { ...i, inputs: [], hadErrors: true, error: "An error occured!" } });
                    case "QUANTITY_ERROR": return setInfoP(i => { return { ...i, inputs: ["quantity"], hadErrors: true, error: "Quantity has to be between minimum and maximum treshold!" } });
                    default: return setInfoP(i => { return { ...i, inputs: [], hadErrors: true, error: "An error occured while creating an order!" } });
                };
            };
        }).catch(() => {
            return setInfoP(i => { return { ...i, inputs: [], hadErrors: true, error: "Server timed out!" } });
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/globalIntegrations/jp/getQuota`,
            ...backendModule.axiosConfig
        }).then(res => {
            setCurQuota(res.data);
        }).catch(() => {
            setCurQuota(backendModule.genericError);
        });
    }, []);

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/globalIntegrations/jp/getAllServices`,
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    }, []);

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Select service</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            {data?.status !== "error" ? <>
                <div className={`genericModal__wrap__input ${infoP.inputs.includes("type") ? "genericModal__wrap__input--error" : ""}`}>
                    <p>Service</p>
                    <Dropdown
                        theme={themeSelector}
                        accent="#373a43"
                        data={data?.status === "ok" ? data.data.map(d => {
                            return {
                                name: `${d.Name} (Min: ${d.MinQuantity}, Max: ${d.MaxQuantity})`,
                                value: d.ID
                            }
                        }) : undefined}
                        onChange={e => setSelectedService(e?.value)}
                        selected={(()=>{
                            if (!data) return;
                            if (data.status !== "ok") return;

                            return data.data.indexOf(data.data.find(d => d.ID === selectedService));
                        })()}
                    />
                </div>

                {findService(selectedService) && <div className={`genericModal__wrap__input genericModal__wrap__input--text ${infoP.inputs.includes("urls") ? "genericModal__wrap__input--error" : ""}`}>
                    <p>URL-s (Seperated by new line)</p>
                    <textarea onChange={(e) => {
                        let endsWIithBreak = e.target.value.endsWith("\n");
                        setTimestamp(Date.now());
                        let final = e?.target?.value.split("\n").filter(t => t);
                        if (endsWIithBreak) final.push("");
                        setUrls(final);
                    }} value={urls.join("\n")}></textarea>
                </div>}

                {findService(selectedService) && <div className={`genericModal__wrap__input ${infoP.inputs.includes("quantity") ? "genericModal__wrap__input--error" : ""}`}>
                    <p>Quantity</p>
                    <input ref={curQuantityRef} onChange={() => setTimestamp(Date.now())} type="number" placeholder="Quantity" />
                </div>}

                {findService(selectedService)?.Type === 1 && <div className={`genericModal__wrap__input genericModal__wrap__input--text ${infoP.inputs.includes("comments") ? "genericModal__wrap__input--error" : ""}`}>
                    <p>Comments (Seperated by new line)</p>
                    <textarea onChange={() => setTimestamp(Date.now())} ref={curCommentsRef}></textarea>
                </div>}

                {selectedService && prepareSummary()}

                <p className="genericModal__wrap__infoP" style={{
                    opacity: infoP.hadErrors ? 1 : 0
                }}>{infoP.error}</p>

                <div className="genericModal__wrap__buttons">
                    <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Close</div>
                    {selectedService && <div className="genericModal__wrap__buttons__btn" onClick={() => !spinner && createOrder()}>{spinner ? <Spinner style={{width: "17px", height: "17px"}} color="white" /> : "Order"}</div>}
                </div>
            </> : <>
                <div className="genericModal__wrap__input">
                    <p>There was an error while fetching services.</p>
                </div>

                <div className="genericModal__wrap__buttons">
                    <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Close</div>
                </div>
            </>}
        </div>
    </div>
};

export default SocialManager_Engage;