import React from "react";
import "./index.scss";

import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import moment from "moment";

import * as siteFunctionsActions from "../../../actions/siteFunctionsActions";
import * as basicStylesModule from "../../../modules/basicStylesModule";
import * as backendModule from "../../../modules/backendModule";
import useOnScreen from "../../../modules/hooks/useOnScreen";
import useDefer from "../../../modules/hooks/useDefer";

import Spinner from "../../../components/customComponents/Spinner";
import FilterBySearch from "../../../components/filters/FilterBySearch";
import { FilteredCustomTable } from "../../../components/customComponents/Table";

import CampaignDashboard from "../../../components/modals/CampaignDashboard";
import { animateBox } from "../../../modules/componentAnimation";

const UserAccountManager_mediaBuyerCampaigns = () => {
    const [data, setData] = React.useState();
    const [users, setUsers] = React.useState();
    const [search, setSearch] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const timestampRef = React.useRef();

    const curOnScreen = useOnScreen();
    const curDefer = useDefer();
    const curDispatch = useDispatch();

    const parseSearch = () => {
        let out = [];
        if (!search) return out;

        let searchSplit = String(search).toLowerCase().split(" ").filter(s => s);
        if (searchSplit.length === 0) return out;

        for (let item of searchSplit) {
            let tmp = [];
            tmp.push({or: [
                {name: "ID", op: "like", value: item},
                {name: "CampaignName", op: "like", value: item},
                {name: "CreatedBy", op: "like", value: item}
            ]});
            out.push(...tmp);
        };

        return out.length === 0 ? out : [{and: out}];
    };

    const getData = ts => {
        if (timestampRef.current !== ts) return;
        setCanPaginate(false);

        setCanPaginate(false);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaignsWithoutData`,
            data: {
                allUsers: true,
                limit: 20,
                offset: 0,
                includeAdditionalData: true,
                filters: [
                    ...parseSearch()
                ],
                orders: [{name: "CampaignActive", order: "desc"}, {name: "createdAt", order: "desc"}]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length >= 20) setCanPaginate(true);
            };
            setData(res.data);
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        });
    };

    const continueData = ts => {
        if (timestampRef.current !== ts) return;

        if (!data) return;
        if (data.status !== "ok") return;

        setCanPaginate(false);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaignsWithoutData`,
            data: {
                allUsers: true,
                limit: 20,
                offset: 0,
                includeAdditionalData: true,
                filters: [
                    {name: "ID", op: "notIn", value: data.data.map(c => c.ID)},
                    ...parseSearch()
                ],
                orders: [{name: "CampaignActive", order: "desc"}, {name: "createdAt", order: "desc"}]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length >= 20) setTimeout(() => setCanPaginate(true), 500);
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
            };
        }).catch(() => null);
    };

    const getAllUsers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            data: {
                limit: null,
                offset: 0
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setUsers(res.data);
        }).catch(() => {
            setUsers(backendModule.genericError);
        });
    };

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        let ts = Date.now();
        timestampRef.current = ts;
        setCanPaginate(false);
        continueData(ts);
    }, [curOnScreen.isIntersecting, canPaginate]);

    React.useEffect(() => {
        const handler = () => {
            setData();
            let ts = Date.now();
            timestampRef.current = ts;
            curDefer(() => getData(ts));
        };

        curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));

        handler();

        return () => {
            curDispatch(siteFunctionsActions.removeHeaderRefreshAction(handler));
        };
    }, [search]);

    React.useEffect(() => {
        getAllUsers();
    }, []);

    return <div className="route__user__accountManager__mediaBuyerCampaigns">
        <FilterBySearch onChange={e => setSearch(e)} />

        <FilteredCustomTable
            accent="#6C5DD3"
            theme={themeSelector}
            headers={["", "ID", "Name", "Media buyer", "Active", "Created at"]}
            customColumns={(new Array(5)).fill("max-content")}
            style={{
                marginTop: "20px",
                columnGap: "40px",
                marginBottom: "40px"
            }}
            data={(()=>{
                if (!data || !users) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                if (data.status === "error" || users.status === "error") return [[{keyID: "noData-error", type: "text", text: "Error while fetching data", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]];
                
                let out = [];
                for (let item of data.data) {
                    let curUser = users.data.find(u => u.ID === item.CreatedBy);
                    curUser = curUser ? curUser.Username : "?";

                    out.push([
                        {keyID: item.ID, type: "text", text: <div className="route__user__accountManager__mediaBuyerCampaigns__icons">
                            <div className="route__user__accountManager__mediaBuyerCampaigns__icons__icon" onClick={() => {
                                animateBox(<CampaignURLs integrationID={item.IntegrationID} ids={[item.ID]} />)
                            }}>
                                <img src="/images/integrations/integration_link.svg" />
                            </div>
                            <div className="route__user__accountManager__mediaBuyerCampaigns__icons__icon" onClick={() => {
                                animateBox(<CampaignDashboard datePreset="today" integration={item.IntegrationType} integrationID={item.IntegrationID} item={item} />);
                            }}>
                                <img src="/images/campaigns/campaigns_chart.svg" />
                            </div>
                        </div>},
                        {keyID: item.ID, type: "text", text: item.ID},
                        {keyID: item.ID, type: "text", text: item.CampaignName},
                        {keyID: item.ID, type: "text", text: curUser},
                        {keyID: item.ID, type: "text", text: item.CampaignActive ? "Yes" : "No", style: {color: item.CampaignActive ? (themeSelector === "dark" ? basicStylesModule.successColor : basicStylesModule.successColorLight) : (themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight)}},
                        {keyID: item.ID, type: "text", text: moment(item.createdAt).toDate().toLocaleString()}
                    ])
                };

                if (canPaginate) out.push([{keyID: "pagination", type: "custom", data: <div ref={curOnScreen.measureRef} style={{height: "1px"}}></div>, style: {width: "100%", gridColumn: "1 / span all"}}])
                if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);
                return out;
            })()}
        />
    </div>
};

const CampaignURLs = props => {
    const [urls, setUrls] = React.useState();
    const [utmParams, setUTMParams] = React.useState();

    const selectedTrackingProfileSelector = useSelector(state => state?.trackingProfiles?.selectedProfile ?? null);

    const openTestURL = url => {
        try {
            let tmp = new URL(url);
            tmp.searchParams.set("sttest", "1");
            window.open(tmp.toString(), "_blank");
        } catch {};
    };

    const checkIfHasUTM = () => {
        if (!props.options?.hasUTM) return false;
        return true;
    };

    React.useEffect(() => {
        if (!props.ids) return props.onClose();
        if (!Array.isArray(props.ids)) return props.onClose();

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getCampaignURLs`,
            data: {
                IntegrationID: props.integrationID,
                IDs: props.ids
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setUrls(res.data);
        }).catch(() => {
            setUrls(backendModule.genericError);
        });
    }, []);

    React.useEffect(() => {
        if (!checkIfHasUTM()) return;

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/${props.options.utmString}/getUTMParams`,
            ...backendModule.axiosConfig
        }).then(res => {
            setUTMParams(res.data);
        }).catch(() => {
            setUTMParams(backendModule.genericError);
        });
    }, []);

    return <div className="route__user__campaigns__urls">
        <div className="route__user__campaigns__urls__wrap">
            <div className="route__user__campaigns__urls__wrap__head">
                <div className="route__user__campaigns__urls__wrap__head__left">Campaign URLs</div>
                <div className="route__user__campaigns__urls__wrap__head__right" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={props.onClose}></div>
            </div>

            {urls ? <>
                {urls.status === "ok" ? <>
                    {checkIfHasUTM() && <div className="route__user__campaigns__urls__wrap__utm">
                        {utmParams ? <>
                            <p>URL parameters:</p>
                            {utmParams.status === "ok" ? utmParams.data : "Error while fetching params!"}
                        </> : <Spinner style={{width: "32px", height: "32px"}} color="white" />}
                    </div>}

                    <div className="route__user__campaigns__urls__wrap__urls">
                        {Object.keys(urls.data).map(key => {
                            return <div className="route__user__campaigns__urls__wrap__urls__url">
                                <p className="route__user__campaigns__urls__wrap__urls__url__name">
                                    <span>{urls.data[key].name}</span>
                                    <span className="route__user__campaigns__urls__wrap__urls__url__tag" style={{
                                        color: urls.data[key].tagged ? "#93fb84" : "gray"
                                    }}>{urls.data[key].tagged ? "URL Tagged" : "URL Untagged"}</span>
                                    <span className="route__user__campaigns__urls__wrap__urls__url__tag" style={{
                                        color: urls.data[key].active ? "#93fb84" : "#fb8484"
                                    }}>{urls.data[key].active ? "Active" : "Inactive"}</span>
                                </p>

                                {urls.data[key]["pre-landing"] && <div className="route__user__campaigns__urls__wrap__urls__url__info">
                                    <div className="route__user__campaigns__urls__wrap__urls__url__textWrap">
                                        <p className="route__user__campaigns__urls__wrap__urls__url__textWrap__name">
                                            <span>Pre-landing URL</span>
                                            <div className="route__user__campaigns__urls__wrap__urls__url__textWrap__name__button" onClick={() => openTestURL(urls.data[key]["pre-landing"])}>Test</div>
                                        </p>
                                        <p className="route__user__campaigns__urls__wrap__urls__url__textWrap__link">{urls.data[key]["pre-landing"]}</p>
                                    </div>
                                </div>}

                                {urls.data[key]["landing"] && <div className="route__user__campaigns__urls__wrap__urls__url__info">
                                    <div className="route__user__campaigns__urls__wrap__urls__url__textWrap">
                                        <p className="route__user__campaigns__urls__wrap__urls__url__textWrap__name">
                                            <spann>Landing URL</spann>
                                            <div className="route__user__campaigns__urls__wrap__urls__url__textWrap__name__button" onClick={() => openTestURL(urls.data[key]["landing"])}>Test</div>
                                        </p>
                                        <p className="route__user__campaigns__urls__wrap__urls__url__textWrap__link">{urls.data[key]["landing"]}</p>
                                    </div>
                                </div>}
                            </div>
                        })}
                    </div>
                </> : <p className="route__user__campaigns__urls__wrap__error">There was an error while generating URLs!</p>}
            </> : <Spinner color="white" align="center" />}
        </div>
    </div>
};

export default UserAccountManager_mediaBuyerCampaigns;