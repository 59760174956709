import React from "react";
import "./index.scss";

import moment from "moment";
import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import { useSelector } from "react-redux";
import { animateBox } from "../../../modules/componentAnimation";
import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen";

import { FilteredCustomTable } from "../../customComponents/Table";
import Spinner from "../../customComponents/Spinner";
import RadioButton from "../../customComponents/RadioButton";
import StyledButton from "../../styledComponents/Button";

import PreviewImageModal from "../PreviewImageModal";

import { ReactComponent as JourneyTab_Img } from "./images/orders_journey.svg";
import { ReactComponent as ProductTab_Img } from "./images/orders_products.svg";
import { ReactComponent as ContactTab_Img } from "./images/orders_contact.svg";

const OrdersModal = (props) => {
    const [data, setData] = React.useState();
    const [dataSum, setDataSum] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [globalSpinner, setGlobalSpinner] = React.useState(false);
    const [spinner, setSpinner] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState();
    const [onlyConfirmed, setOnlyConfirmed] = React.useState(false);
    const [orders, setOrders] = React.useState();

    const curTimestmapRef = React.useRef(Date.now());
    const orderDefer = useDefer();
    const curDefer = useDefer();
    const curOnScreen = useOnScreen();

    const currencySelector = useSelector(state => state?.types?.currencySign ?? "?");
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const wrapTableItem = item => <span style={{padding: "0 50px 0 0px"}}>{item}</span>;

    const getCampaignIDs = () => {
        return Array.isArray(props.item) ? props.item : [props.item.ID];
    };

    const generateKey = item => {
        if (item?.ID) return item.ID;

        let final = "";
        if (!item) return String(item);
        for (let key of Object.keys(item)) {
            if (typeof (item[key]) === "object") {
                final += generateKey(item[key]);
                continue;
            };
            final += String(item[key]);
        };
        return final;
    };

    const getData = (ts) => {
        const filters = [];

        if (props.search) {
            filters.push({
                or: [
                    { name: "ClientData.FirstName", op: "like", value: props.search },
                    { name: "ClientData.LastName", op: "like", value: props.search },
                    { name: "ClientData.FirstLastName", op: "like", value: props.search },
                    { name: "ClientData.PhoneNumber", op: "like", value: props.search },

                    { name: "LocationData.country", op: "like", value: props.search },
                    { name: "LocationData.city", op: "like", value: props.search },

                    { name: "DeviceData.Device.device.type", op: "like", value: props.search },

                    { name: "NetworkData.IP", op: "like", value: props.search },

                    { name: "v_integration_scalelead", op: "like", value: props.search },
                    { name: "v_integration_scalecrmLead", op: "like", value: props.search },

                    { name: "IntegrationParams.st_source", op: "like", value: props.search }
                ]
            })
        };

        if (Array.isArray(props.filters)) filters.push(...props.filters);
        if (onlyConfirmed) filters.push({ name: "ClientData.ScalecrmLeadStatus", op: "eq", value: "100" });

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllOrders`,
            data: {
                filters,
                IntegrationType: props.integration,
                CampaignIDs: getCampaignIDs(),
                limit: 20,
                orders: orders ? [orders] : null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (curTimestmapRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    setCanPaginate(true);
                } else {
                    setCanPaginate(false);
                };
            };
            setData(res.data);
        }).catch(() => {
            if (curTimestmapRef.current !== ts) return;
            setData(backendModule.axiosConfig);
        }).finally(() => {
            if (curTimestmapRef.current !== ts) return;
            setGlobalSpinner(false);
        });
    };

    const continueData = (ts) => {
        setCanPaginate(false);
        if (!data) return;
        if (data.status !== "ok") return;
        if (data.data.lnegth === 0) return;

        const filters = [];

        if (props.search) {
            filters.push({
                or: [
                    { name: "ClientData.FirstName", op: "like", value: props.search },
                    { name: "ClientData.LastName", op: "like", value: props.search },
                    { name: "ClientData.FirstLastName", op: "like", value: props.search },
                    { name: "ClientData.PhoneNumber", op: "like", value: props.search },

                    { name: "LocationData.country", op: "like", value: props.search },
                    { name: "LocationData.city", op: "like", value: props.search },

                    { name: "DeviceData.Device.device.type", op: "like", value: props.search },

                    { name: "NetworkData.IP", op: "like", value: props.search },

                    { name: "v_integration_scalelead", op: "like", value: props.search },
                    { name: "v_integration_scalecrmLead", op: "like", value: props.search }
                ]
            })
        };
        if (Array.isArray(props.filters)) filters.push(...props.filters);
        if (onlyConfirmed) filters.push({ name: "ClientData.ScalecrmLeadStatus", op: "eq", value: "100" });

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllOrders`,
            data: {
                IntegrationType: props.integration,
                CampaignIDs: getCampaignIDs(),
                limit: 20,
                offset: data.data.length,
                filters,
                orders: orders ? [orders] : null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (curTimestmapRef.current !== ts) return;
            if (res.data.status === "ok") {
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
                if (res.data.data.length === 20) {
                    setCanPaginate(true);
                } else {
                    setCanPaginate(false);
                };
            };
        }).catch(() => null).finally(() => {
            if (curTimestmapRef.current !== ts) return;
            setSpinner(false);
            setGlobalSpinner(false);
        });
    };

    const getSums = () => {
        const filters = [];

        if (props.search) {
            filters.push({
                or: [
                    { name: "ClientData.FirstName", op: "like", value: props.search },
                    { name: "ClientData.LastName", op: "like", value: props.search },
                    { name: "ClientData.FirstLastName", op: "like", value: props.search },
                    { name: "ClientData.PhoneNumber", op: "like", value: props.search },

                    { name: "LocationData.country", op: "like", value: props.search },
                    { name: "LocationData.city", op: "like", value: props.search },

                    { name: "DeviceData.Device.device.type", op: "like", value: props.search },

                    { name: "NetworkData.IP", op: "like", value: props.search },

                    { name: "v_integration_scalelead", op: "like", value: props.search },
                    { name: "v_integration_scalecrmLead", op: "like", value: props.search }
                ]
            })
        };
        if (Array.isArray(props.filters)) filters.push(...props.filters);
        if (onlyConfirmed) filters.push({ name: "ClientData.ScalecrmLeadStatus", op: "eq", value: "100" });

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getOrdersSum`,
            data: {
                filters,
                IntegrationType: props.integration,
                CampaignIDs: getCampaignIDs()
            },
            ...backendModule.axiosConfig
        }).then(res => setDataSum(res.data)).catch(() => setDataSum(backendModule.genericError));
    };

    const convertTimeToString = time => {
        let seconds = +time;
        let minutes = 0;
        let hours = 0;

        while (seconds >= 60) {
            seconds -= 60;
            minutes++;
        };
        while (minutes >= 60) {
            minutes -= 60;
            hours++;
        };

        if (hours) return `${hours}h : ${minutes}m : ${seconds}s`;
        if (minutes) return `${minutes}m : ${seconds}s`;
        return `${seconds}s`;
    };

    const removeIPPadding = ip => {
        if (!ip) return ip;

        ip = String(ip);
        if (ip.startsWith("::ffff:")) ip = ip.replace("::ffff:", "");

        return ip;
    };

    React.useEffect(() => {
        if (data) setGlobalSpinner(true);

        let ts = Date.now();
        curTimestmapRef.current = ts;
        curDefer(() => {
            getData(ts);
            getSums();
        }, 500);
    }, [props.search, props.filters, orders, onlyConfirmed]);

    React.useEffect(() => {
        if (curOnScreen.isIntersecting && canPaginate) {
            try {
                curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
            } catch { };

            let ts = Date.now();
            curTimestmapRef.current = ts;
            curDefer(() => {
                continueData(ts);
            }, 500);
        };
    }, [canPaginate, curOnScreen.isIntersecting]);

    return <div className="modals__ordersModal__wrap__content" style={{
            gridTemplateColumns: !selectedItem ? "1fr auto" : "calc(100% - 600px) 600px",
            gridTemplateRows: "100%",
            padding: 0,
            maxHeight: "100%",
            minHeight: "100%",
            height: "100%"
        }} >
        <div className="modals__ordersModal__wrap__content__left">
            <div className="modals__ordersModal__wrap__content__left__filters">
                <p>
                    <RadioButton onChange={e => setOnlyConfirmed(!!e)} />
                    <span>Only confirmed conversions</span>
                </p>
            </div>
            <div className="modals__ordersModal__wrap__content__left__tableWrap">
                <FilteredCustomTable
                    theme={themeSelector}
                    accent="#6C5DD3"
                    headers={
                        props.integration === -2 ?
                            ["No.", "Date", "ClickID", "Country / City", "Type", "IP", "Duration", "OrderID", "Customer", "Gender", "Creative", "Total", "Device", "Source", ""] :
                            ["No.", "Date", "ClickID", "Country / City", "Type", "IP", "Duration", "OrderID", "Customer", "Gender", "Total", "Device", "Source", ""]
                    }
                    customColumns={
                        props.integration === -2 ? 
                            ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "18px"] :
                            ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "18px"]
                    }
                    spinnerColor="#fff"
                    showSpinner={globalSpinner}
                    orderCB={o => orderDefer(() => setOrders(o), 500)}
                    stickyFooter={-3}
                    stickyFooterClass="modals__sessionsModal__wrap__content__left"
                    stickyHeader={105}
                    stickyHeaderClass="modals__sessionsModal__wrap__content__left"
                    data={(() => {
                        let out = [];

                        if (!data) return [[{ keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black" }]];
                        if (data.status === "ok") {
                            for (let item of data.data) {
                                let clientName = `${item.ClientInfo.FirstName ?? ""}${item.ClientInfo.LastName ? ` ${item.ClientInfo.LastName}` : ""}`.trim();
                                if (!clientName) clientName = item.ClientInfo.FirstLastName;
                                if (!clientName) clientName = "-";

                                let curConversion = 0;
                                curConversion = Number(item.ConversionEarning);
                                if (isNaN(curConversion)) curConversion = 0;

                                let numberFormat = new Intl.NumberFormat("en-us", {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                });

                                let curOrderID = undefined;
                                if (item.ClientInfo.ID) {
                                    curOrderID = item.ClientInfo.ID;
                                } else if (item.ClientInfo["integration_scalecrm"]) {
                                    curOrderID = item.ClientInfo["integration_scalecrm"];
                                };

                                out.push({
                                    events: {
                                        onClick: () => {
                                            setSelectedItem(item);
                                        }
                                    },
                                    columns: [
                                        { keyID: generateKey(item), type: "text", text: wrapTableItem(<>
                                            {data.data.indexOf(item) + 1}
                                            {item.isTest ? <span style={{color: "#ffff0061", marginLeft: "10px"}}>[TEST]&nbsp;</span> : null}
                                            {item.isAPI ? <span style={{color: "rgb(108, 93, 211", marginLeft: "10px"}}>[API]&nbsp;</span> : null}
                                        </>) },
                                        { keyID: generateKey(item), type: "text", text: wrapTableItem(moment(item.createdAt).toDate().toLocaleString()) },
                                        { keyID: generateKey(item), type: "text", text: wrapTableItem(item.ID.substring(0, 10) + "..."), onClick: e => {e?.stopPropagation?.(); navigator.clipboard.writeText(item.ID)} },
                                        { keyID: generateKey(item), type: "text", text: wrapTableItem(`${item.ClientInfo.Country ?? item.ClientInfo.DetectedCountry} / ${item.ClientInfo.Location ?? item.ClientInfo.DetectedLocation}`) },
                                        { keyID: generateKey(item), type: "text", text: wrapTableItem(item.DeviceInfo?.DeviceType ?? "-") },
                                        { keyID: generateKey(item), type: "text", text: wrapTableItem(removeIPPadding(item.DeviceInfo?.IP) ?? "-") },
                                        { keyID: generateKey(item), type: "text", text: wrapTableItem(convertTimeToString(item.SessionDuration)) },
                                        { keyID: generateKey(item), type: "text", text: wrapTableItem(curOrderID ?? "N/A"), color: curOrderID ? undefined : "gray" },
                                        { keyID: generateKey(item), type: "text", text: wrapTableItem(clientName) },
                                        { keyID: generateKey(item), type: "text", text: wrapTableItem(item.ClientInfo.Gender ?? "-") },
                                        (props.integration === -2 ? {keyID: generateKey(item), type: "text", text: wrapTableItem(item.IntegrationData?.["stcrt"] ?? "-")} : null),
                                        { keyID: generateKey(item), type: "text", text: wrapTableItem(`${numberFormat.format(curConversion.toFixed(2))} ${currencySelector}`) },
                                        { keyID: generateKey(item), type: "text", text: wrapTableItem(`${item.DeviceInfo.DeviceBrand || "?"} / ${item.DeviceInfo.DeviceModel || "?"}`) },
                                        { keyID: generateKey(item), type: "text", text: wrapTableItem(item.IntegrationData?.st_source) },
                                        { keyID: generateKey(item), type: "custom", data: <img className="modals__ordersModal__wrap__content__left__img" src="/images/campaigns/campaign_openInNewTab.svg" /> }
                                    ].filter(t => t),
                                    style: {
                                        backgroundColor: item === selectedItem ? "rgb(75, 70, 113)" : null
                                    }
                                })
                            };
                        } else {
                            out.push([{ keyID: "noData-error", type: "text", text: "Error while fetching orders!", color: "#f96666" }]);
                        };

                        if (spinner) out.push({columns: [{ keyID: "pagination-spinner", type: "spinner", color: "white" }]});

                        if (out.length === 0) out.push({columns: [{ keyID: "noData-noData", type: "text", text: "Nothing to show for now." }]});

                        if (dataSum?.status === "ok") {
                            if (data?.status === "ok") {
                                if (data?.data?.length > 0) {
                                    let numberFormat = new Intl.NumberFormat("en-us", {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2
                                    });
                                    
                                    let curConversion = 0;
                                    curConversion = Number(dataSum.data.ConversionEarning);
                                    if (isNaN(curConversion)) curConversion = 0;

                                    out.push({
                                        isFooter: true,
                                        columns: [
                                            { keyID: "total-col", type: "text", text: "Total" },
                                            { keyID: "total-col", type: "text", text: "" },
                                            { keyID: "total-col", type: "text", text: "" },
                                            { keyID: "total-col", type: "text", text: "" },
                                            { keyID: "total-col", type: "text", text: "" },
                                            { keyID: "total-col", type: "text", text: wrapTableItem(convertTimeToString(dataSum?.data?.SessionDuration ?? 0)) },
                                            { keyID: "total-col", type: "text", text: "" },
                                            { keyID: "total-col", type: "text", text: "" },
                                            { keyID: "total-col", type: "text", text:  wrapTableItem(`${numberFormat.format(curConversion.toFixed(2))} ${currencySelector}`) },
                                            { keyID: "total-col", type: "text", text: "" },
                                            { keyID: "total-col", type: "text", text: "" }
                                        ]
                                    })
                                };
                            };
                        };

                        return out;
                    })()}
                />
            </div>

            {canPaginate && <div ref={curOnScreen.measureRef} className="modals__ordersModal__wrap__content__left__paginate"></div>}
        </div>
        <SelectedOrder theme={themeSelector} item={selectedItem} onClose={() => setSelectedItem()} campaign={{ integration: props.integration, item: props.item }} />
    </div>;
};

const SelectedOrder = props => {
    const [activeTab, setActiveTab] = React.useState();
    const [journeyData, setJourneyData] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const currencySelector = useSelector(state => state?.types?.currencySign ?? "-");
    const userInfoSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});
    const tabsRef = React.useRef();

    const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

    const onClose = () => {
        setActiveTab();
        setJourneyData();
        props.onClose();
    };

    const openSingleOrderCRM = () => {
        if (!props.item.ClientInfo["integration_scalecrm"]) return;

        window.open(`https://scale-crm.com/#/modals/viewLeads?ids=${props.item.ClientInfo["integration_scalecrm"]}`, "_blank");
    };

    const getDataImage = key => {
        switch (key) {
            case "ID": return "/images/campaign_orders/order_contact_id.svg"
            case "Address": return "/images/campaign_orders/order_contact_address.svg"
            case "Country": return "/images/campaign_orders/order_contact_country.svg"
            case "Location": return "/images/campaign_orders/order_contact_location.svg"
            case "DetectedCountry": return "/images/campaign_orders/order_contact_country.svg"
            case "DetectedLocation": return "/images/campaign_orders/order_contact_location.svg"
            case "Email": return "/images/campaign_orders/order_contact_email.svg"
            case "FirstName": return "/images/campaign_orders/order_contact_fullName.svg"
            case "LastName": return "/images/campaign_orders/order_contact_fullName.svg"
            case "FirstLastName": return "/images/campaign_orders/order_contact_fullName.svg"
            case "PhoneNumber": return "/images/campaign_orders/order_contact_phoneNumber.svg"
            case "Quantity": return "/images/campaign_orders/order_contact_quantity.svg"
            case "Gender": return "/images/campaign_orders/order_contact_gender.svg"
            case "MobileNumber": return "/images/campaign_orders/order_contact_mobileNumber.svg"
            case "Price": return "/images/campaign_orders/order_contact_price.svg"

            case "ScaleleadStatus": return "/images/campaign_orders/order_contact_scalelead.svg"
            case "ScaleleadStatusText": return "/images/campaign_orders/order_contact_scalelead.svg"
            case "integration_scalelead": return "/images/campaign_orders/order_contact_scalelead.svg"

            case "ScalecrmName": return "/images/campaign_orders/order_contact_scalecrm.svg"
            case "ScalecrmLeadStatus": return "/images/campaign_orders/order_contact_scalecrm.svg"
            case "ScalecrmDealStatus": return "/images/campaign_orders/order_contact_scalecrm.svg"
            case "ScalecrmLeadStatusText": return "/images/campaign_orders/order_contact_scalecrm.svg"
            case "ScalecrmDealStatusText": return "/images/campaign_orders/order_contact_scalecrm.svg"
            case "ScalecrmUpsell": return "/images/campaign_orders/order_contact_scalecrm.svg"
            case "integration_scalecrm": return "/images/campaign_orders/order_contact_scalecrm.svg"

            case "DetectedContinent":
            case "DetectedIspName":
            case "DetectedIspType":
            case "DetectedIsAnonymous":
            case "DetectedIsAnonymousProxy":
            case "DetectedIsAnonymousVPN":
            case "DetectedIsPublicProxy":
            case "DetectedIsResidentialProxy":
            case "DetectedIsSateliteProvider":
            case "DetectedIsTorExitNode":
            case "DetectedLatitude":
            case "DetectedLongitude":
                return "/images/campaign_orders/order_contact_geoip.svg";

            default: return "/images/campaign_orders/order_contact_info.svg";
        };
    };

    const getIntegrationImage = (key, isAPI) => {
        if (isAPI) return "/images/integrations/integration_header_api.svg";
        switch (key) {
            case null: return "/images/logo.svg";
            case 0: return "/images/integrations/integration_header_facebook.svg";
            case 1: return "/images/integrations/integration_header_mgid.svg";
            case 4: return "/images/integrations/integration_midas.svg";
            case 5: return "/images/integrations/integration_adnow.svg";
            case -2: return "/images/integrations/integration_smsMarketing.svg";
            case -3: return "/images/integrations/integration_socialCampaigns.svg";
            default: return "/images/image-missing.png";
        };
    };

    const translateDataKey = key => {
        let tmp = key.split("");
        let finalName = tmp.shift();

        for (let item of tmp) {
            if (uppercaseLetters.includes(item) && !(finalName[finalName.length-1].toUpperCase() === finalName[finalName.length-1])) {
                finalName += " ";
            };
            finalName += item;
        };

        return finalName;
    };

    const changeTab = (idx, skipFirst = false) => {
        if (activeTab === idx) return;

        if (!tabsRef.current) return setActiveTab(idx);

        let allItems = [];

        for (let item of tabsRef.current.querySelectorAll("div")) {
            allItems.push(new Promise(r => {
                item.animate([
                    { left: getComputedStyle(item).left },
                    { left: "150%" }
                ], {
                    duration: skipFirst ? 0 : 300,
                    iterations: 1,
                    fill: "both",
                    easing: "ease"
                }).onfinish = () => r();
            }))
        };

        Promise.allSettled(allItems).then(() => {
            setActiveTab(idx)
            if (!tabsRef.current) return;

            let curTab = tabsRef.current.querySelector(`[data-tab="${idx}"]`);
            if (!curTab) return;
            curTab.animate([
                { left: getComputedStyle(curTab).left },
                { left: "0" }
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        });
    };

    const journeyObjectToDivs = (obj, TrackID) => {
        let out = [];

        for (let key of Object.keys(obj)) {
            if (!key) continue;
            if (key === "session_record") {
                out.push(<div className="modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__textBlock">
                    <p>{translateDataKey(key)}</p>
                    <span>{obj[key]} <StyledButton style={{marginLeft: "10px", height: "30px", backgroundColor: "transparent", border: "1px solid #6c5dd3", padding: "0 20px"}} onClick={() => openRecord(TrackID, obj[key])}>View recording</StyledButton></span>
                </div>)
                continue;
            };
            if (typeof (obj[key]) === "object" && !Array.isArray(obj[key])) {
                let tmp = [];
                for (let key2 of Object.keys(obj[key])) {
                    tmp.push(<span>{key2}: {obj[key][key2]}</span>)
                };
                out.push(<div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__textBlock">
                    <p>{translateDataKey(key)}</p>
                    {tmp}
                </div>);
            } else {
                out.push(<div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__textBlock">
                    <p>{translateDataKey(key)}</p>
                    <span>{obj[key]}</span>
                </div>);
            };
        };

        return out;
    };

    const checkIfCurrentJourney = journey => {
        if (props.campaign.integration === journey.IntegrationType) {
            if (props.campaign.item.ID === journey.CampaignID) {
                return true;
            };
        };
        return false;
    };

    const sortClientKeys = keys => {
        let sortKeys = {
            ScaleleadStatus: 1,
            ScaleleadStatusText: 2,
            integration_scalelead: 3,
            ScalecrmName: 4,
            ScalecrmLeadStatus: 5,
            ScalecrmLeadStatusText: 6,
            ScalecrmDealStatus: 7,
            ScalecrmDealStatusText: 8,
            ScalecrmUpsell: 9,
            integration_scalecrm: 10,
        };
        let out = [...keys];

        out = out.sort((a, b) => {
            let aKey = sortKeys[a] ?? 0;
            let bKey = sortKeys[b] ?? 0;
            if (aKey > bKey) return 1;
            return -1;
        });

        return out;
    };

    const displayClientInfo = (info, key) => {
        switch (typeof (info)) {
            case "boolean":
                return <span style={{ color: info ? "#71ec71" : "#ec7171" }}>{info ? "Yes" : "No"}</span>
            case "object":
                if (Array.isArray(info)) return `${info.length} item${info.length > 1 ? "s" : ""}`;
                return `${Object.keys(info).length} item${Object.keys(info).length > 1 ? "s" : ""}`;
            default:
                if (key === "PhoneNumber") {
                    let tmp = String(info);
                    tmp = `${tmp.substring(0, 4)}${"*".repeat(tmp.substring(5, tmp.length).length)}`
                    return tmp
                };
                return String(info);
        };
    };

    const sumArticles = articles => {
        let total = 0;
        if (!articles || !Array.isArray(articles)) return 0;

        for (let art of articles) {
            total += Number(art.Price) * Number(art.Quantity);
        };

        return (new Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })).format(total)
    };

    const fillExtraSteps = (ClientInfo) => {
        let total = 0;
        let expected = 0;
        if (!ClientInfo?.Articles || !Array.isArray(ClientInfo?.Articles)) return 0;

        for (let art of ClientInfo?.Articles) {
            total += Number(art.Price) * Number(art.Quantity);
        };

        if (ClientInfo["Price"]) {
            try {
                expected = Number(ClientInfo["Price"]);
                if (isNaN(expected)) expected = total;
            } catch {
                expected = total;
            };
        } else {
            expected = total;
        };

        if (expected === total) return null;


        if (expected > total) {
            return <>
                <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__products__total1">Tax</div>
                <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__products__total2">{(new Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })).format(expected - total)} {ClientInfo?.Currency ?? "-"}</div>

                <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__products__total1">Total</div>
                <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__products__total2">{(new Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })).format(expected)} {ClientInfo?.Currency ?? "-"}</div>
            </>
        } else {
            return <>
                <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__products__total1">Discount</div>
                <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__products__total2">{(new Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })).format(total - expected)} {ClientInfo?.Currency ?? "-"}</div>

                <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__products__total1">Total</div>
                <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__products__total2">{(new Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })).format(expected)} {currencySelector}</div>
            </>;
        };
        return null;
    };

    const getSessionDuration = sd => {
        let hours = 0;
        let minutes = 0;
        let seconds = sd;

        while (seconds > 60) {
            minutes++;
            seconds -= 60;
        };
        while (minutes > 60) {
            hours++;
            minutes -= 60;
        };
        
        let tmp = `${seconds}s`;
        if (minutes > 0 || hours > 0) {
            tmp = `${minutes}m : ${tmp}`;
        };
        if (hours > 0) {
            tmp = `${hours}h : ${tmp}`;
        };

        return tmp;
    };

    const returnEvents = (events) => {
        let out = [];

        for (let item of events) {
            out.push(<span>{item.EventName}</span>);
            out.push(<div>
                {journeyObjectToDivs(item.EventValues)}
            </div>)
        };

        return out;
    };

    const openRecord = (ID, val) => {
        if (!ID || !val) return;
        let tmp = String(val).split(":");
        if (tmp.length < 2) return;

        let name = tmp.shift();
        let value = tmp.join(":");

        let startDate = moment().add(-20, "years").toDate().getTime();
        let endDate = moment().toDate().getTime();

        switch (name) {
            case "clarity":
                console.log(ID, name, value);
                window.open(`https://clarity.microsoft.com/projects/view/${value}/impressions?CustomUserId=is%3B${ID}&date=Custom&end=${endDate}&start=${startDate}`, "_blank");
            default: return;
        };
    };

    React.useEffect(() => {
        setJourneyData();
        if (!props.item) return;
        if (!props.item?.ID) return;

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getClientJourney`,
            data: {
                TrackID: props.item.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setJourneyData(res.data);
        }).catch(() => {
            setJourneyData(backendModule.genericError);
        });
    }, [props.item]);

    React.useEffect(() => {
        if (!tabsRef.current) return;
        if (activeTab !== undefined) return;

        changeTab(0, true);
    });

    return <div className={`modals__ordersModal__wrap__content__right ${props.item ? "modals__ordersModal__wrap__content__right--active" : ""}`} style={{
        minHeight: 0,
        height: "100%"
    }}>
        <div className="modals__ordersModal__wrap__content__right__wrap">

            {props.item && <>
                <div className="modals__ordersModal__wrap__content__right__wrap__head">
                    <div className="modals__ordersModal__wrap__content__right__wrap__head__top">
                        {(() => {
                            let clientName = `${props.item.ClientInfo.FirstName ?? ""}${props.item.ClientInfo.LastName ? ` ${props.item.ClientInfo.LastName}` : ""}`.trim();
                            if (!clientName) clientName = props.item.ClientInfo.FirstLastName;
                            if (!clientName) clientName = "-";

                            return clientName;
                        })()}
                    </div>
                    <div className="modals__sessionsModal__wrap__content__right__wrap__head__bottom">
                        Click ID: {props.item.ID ?? "-"}
                    </div>
                    <div className="modals__ordersModal__wrap__content__right__wrap__head__bottom" onClick={openSingleOrderCRM}>
                        Conversion ID: {(()=>{
                            let curOrderID = undefined;
                            if (props.item.ClientInfo.ID) {
                                curOrderID = props.item.ClientInfo.ID;
                            } else if (props.item.ClientInfo["integration_scalecrm"]) {
                                curOrderID = props.item.ClientInfo["integration_scalecrm"];
                            };
                            return curOrderID ? <span className="modals__ordersModal__wrap__content__right__wrap__head__bottom__order">{curOrderID}</span> : "?";
                        })()}
                    </div>

                    {props.item?.IntegrationData?.session_record && <div className="modals__sessionsModal__wrap__content__right__wrap__head__bottom">
                        <StyledButton style={{marginTop: "10px", height: "30px", backgroundColor: "transparent", border: "1px solid #6c5dd3"}} onClick={() => openRecord(props.item?.ID, props.item?.IntegrationData?.session_record)}>View recording</StyledButton>
                    </div>}

                    <div className="modals__ordersModal__wrap__content__right__wrap__head__close" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={onClose}></div>
                </div>

                <div className="modals__ordersModal__wrap__content__right__wrap__tabs">
                    <div className={`modals__ordersModal__wrap__content__right__wrap__tabs__tab ${activeTab === 0 ? "modals__ordersModal__wrap__content__right__wrap__tabs__tab--active" : ""}`} onClick={() => changeTab(0)}>
                        <JourneyTab_Img />
                        <span>Journey</span>
                    </div>

                    {(props.item?.ClientInfo?.Articles && userInfoSelector?.Flags?.isAdmin) && <div className={`modals__ordersModal__wrap__content__right__wrap__tabs__tab ${activeTab === 1 ? "modals__ordersModal__wrap__content__right__wrap__tabs__tab--active" : ""}`} onClick={() => changeTab(1)}>
                        <ProductTab_Img articles={props.item?.ClientInfo?.Articles} />
                        <span>Products</span>
                    </div>}

                    <div className={`modals__ordersModal__wrap__content__right__wrap__tabs__tab ${activeTab === 2 ? "modals__ordersModal__wrap__content__right__wrap__tabs__tab--active" : ""}`} onClick={() => changeTab(2)}>
                        <ContactTab_Img />
                        <span>Client info</span>
                    </div>
                </div>

                <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap" ref={tabsRef}>
                    <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item modals__ordersModal__wrap__content__right__wrap__tabWrap__item__journey" data-tab={0}>
                        {journeyData ? <>
                            {journeyData.status === "ok" ? <>
                                {journeyData.data.map((journey, journeyIdx) => {
                                    return <div className={`modals__ordersModal__wrap__content__right__wrap__tabWrap__item__journey__item`}>
                                        <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__journey__item__left">
                                            <img src={getIntegrationImage(journey.IntegrationType, journey.isAPI)} />
                                            {journeyIdx !== journeyData.data.length - 1 && <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__journey__item__left__line"></div>}
                                        </div>
                                        <div className={`modals__ordersModal__wrap__content__right__wrap__tabWrap__item__journey__item__right ${(journey.hasConverted && !journey.isPreLander) ? "modals__ordersModal__wrap__content__right__wrap__tabWrap__item__journey__item__right--converted" : ""}`}>
                                            {journey.isAPI && <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__textBlock">
                                                <p style={{color: "rgb(108, 93, 211)"}}>API</p>
                                            </div>}
                                            {checkIfCurrentJourney(journey) && <div className={`modals__ordersModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__textBlock modals__ordersModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__textBlock--active`}>
                                                <p>Current campaign</p>
                                            </div>}

                                            <div className="modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__textBlock">
                                                <p>Click ID</p>
                                                <span>{journey.ID}</span>
                                            </div>
                                            <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__textBlock">
                                                <p>Date created</p>
                                                <span>{(new Date(journey.createdAt).toLocaleString())}</span>
                                            </div>
                                            {journey.convertedAt && <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__textBlock">
                                                <p>Date converted</p>
                                                <span>{(new Date(journey.convertedAt).toLocaleString())}</span>
                                            </div>}
                                            <div className="modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__textBlock">
                                                <p>Session duration</p>
                                                <span>{getSessionDuration(journey.SessionDuration)}</span>
                                            </div>
                                            <div className="modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__textBlock">
                                                <p>Type</p>
                                                <span>{journey.isPreLander ? <span style={{color: themeSelector === "dark" ? "rgb(238, 214, 84)" : "red"}}>Pre-Landing page</span> : <span style={{color: themeSelector === "dark" ? "#69f978" : "green"}}>Landing (offer) page</span>}</span>
                                            </div>
                                            <div className="modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__textBlock">
                                                <p>URL</p>
                                                <span>{journey.SiteURL}</span>
                                            </div>
                                            <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__textBlock">
                                                <p>Has interacted</p>
                                                <span>{journey.hasInteracted ? "Yes" : "No"}</span>
                                            </div>
                                            <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__textBlock">
                                                <p>Has Converted</p>
                                                <span>{journey.hasConverted ? "Yes" : "No"}</span>
                                            </div>

                                            {journeyObjectToDivs(journey.IntegrationData, journey.ID)}

                                            {journey._image && <div className="modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__image" onClick={e => {
                                                animateBox(e, <PreviewImageModal image={journey._image} />);
                                            }}>
                                                <img src={journey._image} />    
                                            </div>}

                                            {Object.keys(journey.IntegrationParams ?? {}).length > 0 && <>
                                                <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__line"></div>
                                                {journeyObjectToDivs(journey.IntegrationParams, journey.ID)}
                                            </>}

                                            {journey.Events.length > 0 && <>
                                                <div className="modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__line"></div>
                                                <h4>Events</h4>
                                                <div className="modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__events">
                                                    {returnEvents(journey.Events)}
                                            </div>
                                            </>}
                                        </div>
                                    </div>
                                })}
                            </> : <p className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__journey__infoP">There was an error while fetching the journey!</p>}
                        </> : <Spinner style={{ width: "32px", height: "32px" }} color={props.theme === "dark" ? "white" : "black"} align="center" />}
                    </div>

                    <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item modals__ordersModal__wrap__content__right__wrap__tabWrap__item__products" data-tab={1}>
                        <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__products__head" style={{ gridColumn: "1 / 2" }}>Product</div>
                        <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__products__head" style={{ gridColumn: "3" }}>Quantity</div>
                        <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__products__head" style={{ gridColumn: "4" }}>Price</div>

                        {props?.item?.ClientInfo?.Articles?.map?.(art => {
                            return <>
                                <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__products__image">
                                    {art.Image && <img src={art.Image} />}
                                </div>
                                <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__products__text">{art.Name}</div>
                                <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__products__text">{(new Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })).format(art.Quantity)}</div>
                                <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__products__text">{(new Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })).format(art.Price)} {props.item?.ClientInfo?.Currency ?? "-"}</div>
                            </>;
                        })}

                        <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__products__line"></div>

                        <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__products__total1">Subtotal</div>
                        <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__products__total2">{sumArticles(props.item?.ClientInfo?.Articles)} {props.item?.ClientInfo?.Currency ?? "-"}</div>

                        {fillExtraSteps(props.item?.ClientInfo)}
                    </div>

                    <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item modals__ordersModal__wrap__content__right__wrap__tabWrap__item__contacts" data-tab={2}>
                        {sortClientKeys(Object.keys(props.item.ClientInfo)).map(key => {
                            return <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__contacts__contact">
                                <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__contacts__contact__left">
                                    <img src={getDataImage(key)} />
                                </div>
                                <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__contacts__contact__right">
                                    <span>{translateDataKey(key)}</span>
                                    <span>{displayClientInfo(props.item.ClientInfo[key], key)}</span>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </>}

        </div>
    </div>
};

export default OrdersModal;