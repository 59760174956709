import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";

const UserMediaBuyer = () => {
    const performanceManagersSelector = useSelector(state => state?.userData?.userData?.UserInfo?.PerformanceManagers ?? []);

    const viewAccount = userID => {
        if (!userID) return;

        window.open(`${window.location.origin}${window.location.origin.endsWith("/") ? "" : "/"}?_mediabuyer-viewbyid=${userID}`);
    };

    return <div className="route__mediaBuyer">
        <h3>Select an account</h3>

        <div className="route__mediaBuyer__list">
            {performanceManagersSelector.map(p => {
                return <div className="route__mediaBuyer__list__manager" onClick={() => viewAccount(p.ID)}>{p.Username}</div>
            })}
        </div>
    </div>
};

export default UserMediaBuyer;