export const updateTrackingProfiles = (profiles) => {
    return {
        type: "TRACKING_PROFILES_UPDATE",
        payload: profiles
    };
};

export const selectTrackingProfile = id => {
    return {
        type: "TRACKING_PROFILES_SELECT",
        payload: id
    };
};

export const updateTrackingTeams = (profiles) => {
    return {
        type: "TRACKING_TEAMS_UPDATE",
        payload: profiles
    };
};

export const selectTrackingTeam = id => {
    return {
        type: "TRACKING_TEAMS_SELECT",
        payload: id
    };
};

export const resetTrackingData = () => {
    return {
        type: "TRACKING_DATA_RESET"
    };
};