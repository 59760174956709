/** @param {string} line */
const parseLine = line => {
    let out = [];
    let isInQuotes = false;
    let quoteChar = "";
    let buffer = "";

    for (let char of line.split("")) {
        if (char === '"' || char === '"') {
            if (!isInQuotes) {
                quoteChar = char;
                isInQuotes = true;
                continue;
            } else {
                if (quoteChar === char) {
                    quoteChar = "";
                    isInQuotes = false;
                    continue;
                };
            };
        } else if (char === ",") {
            if (!isInQuotes) {
                out.push(buffer);
                buffer = "";
                continue;
            };
        } else {
            buffer += char;
        };
    };
    if (buffer.length > 0) out.push(buffer);

    return out;
};

const parseData = data => {
    let out = [];

    let lines = data.split("\n");
    lines.forEach(item => {
        let parsed = parseLine(item);
        if (parsed.length > 0) out.push(parsed);
    }); 

    return out;
};

export const parseCSV = (data) => {
    return new Promise(resolve => {
        resolve(parseData(data));
    });
};

export const arrayToCSVString = (arr) => {
    return new Promise(resolve => {
        try {
            let ws = [];
            if (arr.length > 0) {
                let tmp = arr[0];
                ws.push(`${Object.keys(tmp).join(",")}\n`);
            };
            for (let item of arr) {
                ws.push(`${(()=>{
                    let final = "";
                    for(let key of Object.keys(item)) {
                        if (!String(item[key]).includes(",")) {
                            final += `${String(item[key])},`;
                        } else {
                            let useQuote = '';
                            if (String(item[key]).includes('"')) {
                                useQuote = "'";
                            } else {
                                useQuote = '"';
                            };
                            final += `${useQuote}${String(item[key])}${useQuote},`
                        };
                    };
                    if (final.endsWith(",")) final = final.substring(0, final.length-1);
                    return final;
                })()}\n`)
            };
            resolve({status: "ok", data: ws.join("")});
        } catch {
            return resolve({status: "error", data: "READ_WRITE ERROR"});
        }
    });
};