import React from "react";
import "./index.scss";

import PropTypes from "prop-types";

const RadioButton = props => {
    const [checked, setChecked] = React.useState(props.checked ?? props.defaultValue ?? false);

    const firstTimeRef = React.useRef(true);
    const isCheckDisabled = props.disabled ? true : false;

    React.useEffect(() => {
        if (checked === null || checked === undefined) return;
        if (firstTimeRef.current) {
            firstTimeRef.current = false;
            return;
        };

        if (props.onChange) props.onChange(checked);
    }, [checked]);

    React.useEffect(() => {
        if (props.checked === null || props.checked === undefined) return;
        if (props.checked === checked) return;

        setChecked(!!props.checked);
    }, [props.checked]);

    return <div
        className={`customComponents__radio ${checked ? "customComponents__radio--active" : ""} ${props.className ?? ""}`}
        style={{...(props.style ?? {})}}
        onClick={() => !isCheckDisabled && setChecked(c => !c)}
    ></div>
};

RadioButton.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    checked: PropTypes.bool,
    onChange: PropTypes.func
}

export default RadioButton;