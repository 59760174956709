import React from "react";
import "./index.scss";
import FilterBySearch from "../../filters/FilterBySearch";
import PropTypes from "prop-types";
import Spinner from "../../customComponents/Spinner";

import { getBoundingClientRectRelative } from "../../../modules/miscModule";

const AdvancedDropdown = (props) => {

    const [selectedItem, setSelectedItem] = React.useState();
    const [dropdownVisible, setDropdownVisible] = React.useState(false);
    const [search, setSearch] = React.useState();
    const [spinner, setSpinner] = React.useState(false);

    const curMaxHeightRef = React.useRef();
    const curItemCountRef = React.useRef(0);
    const dropdownRef = React.useRef();
    const mainRef = React.useRef();

    const parseSearch = () => {
        if (!search) return props.data;
        let out = [];
        let tmpSearch = search.toLowerCase();
        for (let item of props.data) {
            if (typeof(item.name) === "string"  || typeof(item.name) === "number") {
                if (item.name.toLowerCase().includes(tmpSearch)) {
                    out.push(item);
                    continue;
                };
            };
            if (typeof(item.text) === "string" || typeof(item.text) === "number") {
                if (item.text.toLowerCase().includes(tmpSearch)) {
                    out.push(item);
                    continue;
                };
            };
            if (typeof(item.search) === "string" || typeof(item.search) === "number") {
                if (item.search.toLowerCase().includes(tmpSearch)) {
                    out.push(item);
                    continue;
                };
            };
        };

        return out;
    };

    React.useEffect(() => {
        if (!props.data) return;
        if (!dropdownRef?.current) return;
        // if (curMaxHeightRef.current) return;
        if (dropdownVisible) return;

        if (curItemCountRef.current === props.data.length) return;
        curItemCountRef.current = props.data.length;

        dropdownRef.current.style.maxHeight = null;
        let rect = dropdownRef.current.getBoundingClientRect();
        curMaxHeightRef.current = rect.height + 10;
        dropdownRef.current.style.maxHeight = 0;

        // handle top offset
        if (!mainRef.current) return;
        let mainRect = mainRef.current.getBoundingClientRect();
        dropdownRef.current.style.top = `calc(${mainRect.height}px - 1px)`;
    }, [dropdownRef.current, props.data]);

    React.useEffect(() => {
        if (!dropdownRef?.current) return;
        if (!curMaxHeightRef?.current) return;

        if (dropdownVisible) {
            if (curMaxHeightRef.current > 300){
                curMaxHeightRef.current = 300;
            };
            dropdownRef.current.style.maxHeight = `${curMaxHeightRef.current}px`;
        } else {
            dropdownRef.current.style.maxHeight = 0;
        };

        if (dropdownVisible) {
            try {
                //handle Y overflow
                dropdownRef.current.style.transform = null;
                let finalRect = dropdownRef.current.getBoundingClientRect();
                let mainRect = mainRef.current.getBoundingClientRect();

                let finalHeight = finalRect.y + curMaxHeightRef.current;
                if ((finalHeight + mainRect.height + 1) > window.innerHeight) {
                    let diff = window.innerHeight - (finalHeight + mainRect.height + 1);
                    diff += 30;
                    dropdownRef.current.style.transform = `translateY(${diff}px)`;
                };
            } catch {};
        };
    }, [dropdownVisible, dropdownRef.current, curMaxHeightRef.current]);

    React.useEffect(() => {
        if (selectedItem === null || selectedItem === undefined) return;

        if (props.onChange) props.onChange(props.data[selectedItem]);
    }, [selectedItem])

    React.useEffect(() => {
        const windowClickHandler = (e) => {
            if (!dropdownVisible) return;
            if (e?.target?.classList?.toString().includes("component__customComponent__advancedDropdown")) {
                return
            };
            if (dropdownVisible) setDropdownVisible(false);
        };

        window.addEventListener("click", windowClickHandler, true);

        return () => {
            try {
                window.removeEventListener("click", windowClickHandler, true);
            } catch {};
        };
    }, [dropdownVisible]);

    React.useEffect(() => {
        if (props.selected === null || props.selected === undefined) return;
        if (!Array.isArray(props.data)) return;

        let foundItem = props.data[props.selected];
        if (foundItem) setSelectedItem(props.selected);
    }, [props.selected, props.data]);

    React.useEffect(() => {
        let defaultItem;
        if (props.selected !== null && props.selected !== undefined) return;
        if(props.defaultIndex !== null && props.defaultIndex !== undefined){
            defaultItem = props.defaultIndex;
        }else if(props.placeholder){
            defaultItem = props.placeholder
        }

        setSelectedItem(defaultItem);
    }, [])

    React.useEffect(() => {
        if (props.functionsCB) {
            props.functionsCB({
                reset: () => {
                    setSelectedItem(props.placeholder ?? undefined)
                },
                set: idx => {
                    setSelectedItem(idx)
                },
                showSpinner : (bool) => {
                    setSpinner(bool)
                }
            });
        };
    }, [])

    return <div ref={mainRef} className={props.className ? `component__customComponent__advancedDropdown ${props.className}` : 'component__customComponent__advancedDropdown'} style={{
        ...(props.style ? props.style : {})
    }}
    onClick={(e) => {
        e.stopPropagation();
        setDropdownVisible(!dropdownVisible);
    }}>
        <div className="component__customComponent__advancedDropdown__left">
            <div className="component__customComponent__advancedDropdown__left__top"
                style={{
                    ...(props.dropdownButtonHeadlineStyle ? props.dropdownButtonHeadlineStyle : {})
                }}>
                <p>{props.headline}</p>
            </div>

            <div className="component__customComponent__advancedDropdown__left__bottom"> 

                {(props.data === null || props.data === undefined) ? <Spinner style={{ width: "16px", height: "16px" }} color="white" /> : 
                <>
                    {props.data[selectedItem] ? 
                        <>
                            {(props.data[selectedItem].image && !Array.isArray(props.data[selectedItem].image)) && <img src={props.data[selectedItem].image} style={{...(props.dropdownElementImageStyle ?? {})}} />}
                            {(props.data[selectedItem].image && Array.isArray(props.data[selectedItem].image)) && props.data[selectedItem].image.map(i => <img src={i} style={{...(props.dropdownElementImageStyle ?? {})}} />)}
                            <p style={{...(props.dropdownButtonItemStyle ? props.dropdownButtonItemStyle : {}), ...(props.data[selectedItem]?.style ?? {})}}>{props.data[selectedItem].name} </p>
                        </> : 
                            <p className="component__customComponent__advancedDropdown__left__bottom__placeholder"
                            style={{...(props.dropdownButtonItemStyle ? props.dropdownButtonItemStyle : {})}}>{props.placeholder}</p>
                        }
                </>}
            </div>
        </div>

        <div className="component__customComponent__advancedDropdown__right">
            <img src="/images/downArrow.svg"  className={`${dropdownVisible ? "component__customComponent__advancedDropdown__right--active" : ""}`} />
        </div>

        <div className={ dropdownVisible ? `component__customComponent__advancedDropdown__dropdown component__customComponent__advancedDropdown__dropdown--padding` : `component__customComponent__advancedDropdown__dropdown`} ref={dropdownRef} 
        style={{...(props.dropdownContainerStyle ? props.dropdownContainerStyle : {})}}>
            {(props.data === null || props.data === undefined) ?
                <Spinner style={{ width: "16px", height: "16px", margin:'16px auto' }} color="white" /> :
                <>
                    {props.data.length === 0 ?
                    <p className="component__customComponent__advancedDropdown__dropdown__nodata">There is no data</p> :
                    <>
                        { props.showSearch ? <div className="component__customComponent__advancedDropdown__dropdown__search " onClick={(e) => {
                            e.stopPropagation();
                        }}>
                            <FilterBySearch inputClassName="component__customComponent__advancedDropdown__dropdown__search--search" onChange={(e) => setSearch(e)}/>
                        </div>: ''}
                        {parseSearch().map((attr, attrIdx) => {
                            return <div style={{...(attr?.style ?? {})}} key={`filterByDropdown-${attr.key ?? attrIdx}`} className={`component__customComponent__advancedDropdown__dropdown__item
                            ${selectedItem === attrIdx ? "component__customComponent__advancedDropdown__dropdown__item--active" : ""}`} onClick={(e) => {
                                setSelectedItem(props.data.indexOf(props.data.find(d => d === attr)));
                                e.stopPropagation();
                                setDropdownVisible(false);
                            }}>
                                {(attr.image && !Array.isArray(attr.image)) ? <div className="component__customComponent__advancedDropdown__dropdown__item__left">
                                    <img src={attr.image} style={{...(props.dropdownElementImageStyle ?? {})}} />
                                </div> : ''}
                                {(attr.image && Array.isArray(attr.image)) && <div className="component__customComponent__advancedDropdown__dropdown__item__left">
                                    {attr.image.map(i => {
                                        return <img src={i} style={{...(props.dropdownElementImageStyle ?? {})}} />
                                    })}
                                </div>}

                                <div className="component__customComponent__advancedDropdown__dropdown__item__right">
                                    {attr.name ? <p className="component__customComponent__advancedDropdown__dropdown__item__right__head" style={{
                                    ...(props.dropdownContainerItemHeadStyle ? props.dropdownContainerItemHeadStyle : {})
                                }}>{attr.name}</p> : ''}

                                    {attr.text ? <p className="component__customComponent__advancedDropdown__dropdown__item__right__text" style={{
                                    ...(props.dropdownContainerItemTextStyle ? props.dropdownContainerItemTextStyle : {})
                                }}>{attr.text}</p> : ''}
                                </div>
                            </div>
                        })}
                    </>}
                </>
            }
        </div>
    </div>
}

AdvancedDropdown.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.any,
        image: PropTypes.any,
        text: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.any,
        search: PropTypes.string,
        style: PropTypes.object
    })),
    dropdownContainerItemTextStyle: PropTypes.object,
    dropdownContainerItemHeadStyle: PropTypes.object,
    dropdownContainerStyle: PropTypes.object,
    dropdownButtonItemStyle: PropTypes.object,
    dropdownButtonHeadlineStyle: PropTypes.object,
    dropdownElementImageStyle: PropTypes.object,
    style: PropTypes.object,
    selected: PropTypes.number,
    placeholder: PropTypes.string,
    headline: PropTypes.string,
    defaultIndex : PropTypes.number,
    className : PropTypes.string,
    functionsCB : PropTypes.func,
    onChange : PropTypes.func,
    showSearch : PropTypes.bool
}

export default AdvancedDropdown;
