import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen";

import { FilteredCustomTable } from "../../customComponents/Table";

const CampaignTrackEventsModal = props => {
    const [data, setData] = React.useState();
    const [eventData, setEventData] = React.useState();
    const [eventsPaginate, setEventsPaginate] = React.useState(false);
    const [globalSpinner, setGlobalSpinner] = React.useState(false);
    const [globalSpinner2, setGlobalSpinner2] = React.useState(false);

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const selectedProfileSelector = useSelector(state => state?.trackingProfiles?.selectedProfile ?? "-1");

    const timestampRef = React.useRef();
    const curDefer = useDefer();
    const curOnScreen = useOnScreen();

    const wrapTableItem = item => <span style={{padding: "0 50px 0 0px"}}>{item}</span>;

    const generateKey = item => {
        if (item?.ID) return item.ID;

        let final = "";
        if (!item) return String(item);
        for (let key of Object.keys(item)) {
            if (typeof (item[key]) === "object") {
                final += generateKey(item[key]);
                continue;
            };
            final += String(item[key]);
        };
        return final;
    };

    const getData = async (ts) => {
        if (timestampRef.current !== ts) return;

        let trackEvents = await axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/events/getAllForUser`,
            ...backendModule.axiosConfig
        }).then(res => {
            return res.data;
        }).catch(() => {
            return backendModule.genericError;
        });

        if (trackEvents.status === "error") return setData(backendModule.genericError);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
            data: {
                IntegrationID: selectedProfileSelector,
                limit: 1,
                trackFilters: [
                    ...(Array.isArray(props.filters) ? props.filters : []),
                    ...(props.search ? [{or: [
                        {name: "@EventName", op: "like", value: props.search},
                        {name: "@TrackingID", op: "like", value: props.search}
                    ]}] : [])
                ],
                filters: [
                    {name: "ID", op: "eq", value: props.ID}
                ],
                TableHeaders: trackEvents.data.map(t => {
                    return `@${t.EventName}.count`;
                })
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            setData(res.data);
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        }).finally(() => {
            if (timestampRef.current !== ts) return;
            setGlobalSpinner(false);
        });
    };

    const getEvents = ts => {
        if (timestampRef.current !== ts) return;

        let filters = [];
        if (Array.isArray(props.filters)) filters.push(...props.filters);
        if (props.search) {
            filters.push({or: [
                {name: "EventName", op: "like", value: props.search},
                {name: "TrackingID", op: "like", value: props.search}
            ]});
        };

        setEventsPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/events/getEvents`,
            data: {
                CampaignID: props.ID,
                offset: 0,
                limit: 20,
                orders: [{name: "createdAt", order: "desc"}],
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length >= 20) setEventsPaginate(true);
            };
            setEventData(res.data);
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setEventData(backendModule.genericError);
        }).finally(() => {
            setGlobalSpinner2(false);
        });
    };

    const continueEvents = ts => {
        if (timestampRef.current !== ts) return;
        if (!eventData) return;
        if (eventData.status === "error") return;
        if (!eventsPaginate) return;

        let filters = [
            {name: "ID", op: "notIn", value: eventData.data.map(d => d.ID)}
        ];
        if (Array.isArray(props.filters)) filters.push(...props.filters);
        if (props.search) {
            filters.push({or: [
                {name: "EventName", op: "like", value: props.search},
                {name: "TrackingID", op: "like", value: props.search}
            ]});
        };

        setEventsPaginate(false);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/events/getEvents`,
            data: {
                CampaignID: props.ID,
                offset: 0,
                limit: 20,
                orders: [{name: "createdAt", order: "desc"}],
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length >= 20) {
                    setEventsPaginate(true);
                };
                setEventData(ed => {
                    return {
                        ...ed,
                        data: {
                            ...ed.data,
                            ...res.data.data
                        }
                    };
                });
            };
        }).catch(() => null);
    };

    const getEventPills = event => {
        let out = [];

        for (let key of Object.keys(event.EventValues)) {
            out.push(<div className="modals__campaignTrackEventsModal__wrap__content__left__tableWrap__pills__pill">
                <span>{key}:&nbsp;</span>
                <span>{event.EventValues[key]}</span>
            </div>)
        };

        if (out.length === 0) return null;
        return <div className="modals__campaignTrackEventsModal__wrap__content__left__tableWrap__pills">{out}</div>
    };

    React.useEffect(() => {
        if (!eventsPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        setEventsPaginate(false);
        let ts = Date.now();
        timestampRef.current = ts;
        curDefer(() => {
            continueEvents(ts);
        }, 500);
    }, [curOnScreen.isIntersecting, eventsPaginate]);

    React.useEffect(() => {
        if (data) setGlobalSpinner(true);
        if (eventData) setGlobalSpinner2(true);

        let ts = Date.now();
        timestampRef.current = ts;
        curDefer(() => {
            getData(ts);
            getEvents(ts);
        }, 500);
    }, [props.search, props.filters]);

    return <div className="modals__campaignTrackEventsModal__wrap__content" style={{
        gridTemplateColumns: "1fr",
        gridTemplateRows: "100%",
        padding: 0,
        maxHeight: "100%",
        minHeight: "100%",
        height: "100%"
    }} >
        <div className="modals__campaignTrackEventsModal__wrap__content__left">
            <div className="modals__campaignTrackEventsModal__wrap__content__left__tableWrap">
                <h3>Campaign event count</h3>
                <FilteredCustomTable
                    theme={themeSelector}
                    accent="#6C5DD3"
                    headers={["Event name", "Total count"]}
                    customColumns={["auto", "auto"]}
                    showSpinner={globalSpinner}
                    spinnerColor={"#fff"}
                    data={(()=>{
                        if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];

                        let out = [];

                        if (data.status === "ok") {
                            if (data.data.length === 1) {
                                for (let key of Object.keys(data.data[0]?.TableData ?? {})) {
                                    let tmpName = key.substring(1, key.length).split(".")[0];
                                    out.push([
                                        {keyID: data.data[0].ID, type: "text", text: tmpName},
                                        {keyID: data.data[0].ID, type: "text", text: data.data[0].TableData[key]}
                                    ])
                                };
                            };
                        } else {
                            out.push([{ keyID: "noData-error", type: "text", text: "Error while fetching events!", color: "#f96666" }]);
                        };

                        if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);

                        return out;
                    })()}
                />
            </div>

            <div style={{
                margin: "30px 0",
                width: "100%",
                height: "2px",
                backgroundColor: themeSelector === "dark" ? "#373A43" : "#E2E2E2"
            }}></div>

            <div className="modals__campaignTrackEventsModal__wrap__content__left__tableWrap">
                <h3>Event data</h3>
                <FilteredCustomTable
                    theme={themeSelector}
                    accent="#6C5DD3"
                    headers={["Date", "Event name", "Tracking ID"]}
                    customColumns={["auto", "auto"]}
                    showSpinner={globalSpinner2}
                    spinnerColor={"#fff"}
                    data={(()=>{
                        if (!eventData) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];

                        let out = [];

                        if (eventData.status === "ok") {
                            for (let item of eventData.data) {
                                out.push([
                                    {keyID: item.ID, type: "text", text: moment(item.createdAt).toDate().toLocaleString()},
                                    {keyID: item.ID, type: "text", text: item.EventName},
                                    {keyID: item.ID, type: "text", text: item.TrackingID},
                                    {keyID: item.ID, type: "groupNewline", group: [
                                        {keyID: item.ID, type: "custom", data: getEventPills(item)}
                                    ]}
                                ]);
                            };
                        } else {
                            out.push([{ keyID: "noData-error", type: "text", text: "Error while fetching events!", color: "#f96666" }]);
                        };

                        if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);

                        return out;
                    })()}
                />
                {eventsPaginate && <div ref={curOnScreen.measureRef} style={{width: "1px", height: "1px", opacity: 0}}></div>}
            </div>
        </div>
    </div>;
};

export default CampaignTrackEventsModal;