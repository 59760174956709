import React from "react";
import "./index.scss";

const DataDeletionPolicy = () => {

    React.useEffect(() => {
        let tmp = document.querySelector(".component__contentWrapper");
        if (!tmp) return;

        tmp.scrollTo(0, 0);
    }, []);

    return <div className="route__dataDeletionPolicy">
        <h1>Data Deletion Instructions</h1>

        <p>Scale-Track is just a web based application and we do not save your personal data on our server. According to the Facebook Platform rules, we have to provide User Data Deletion Callback URL or Data Deletion Instructions URL. If you want to delete your activities for the Scale-Track, following these instructions:</p>

        <img src="/images/landing/data-delete.png" />

        <ul>
            <li>Go to Your Facebook Account's Setting & Privacy. Click " Setting ".</li>
            <li>Then, go to " Apps and Websites" and you will see all of your Apps activities.</li>
            <li>Select the option box for Scale-Track</li>
            <li>Click " Remove" button.</li>
        </ul>

        <p>If you wish to delete user account data, you have to request us to delete your account. Subject to your Scale-Track user account is no longer required in the future, please send your request with account registered email to <a href="mailto:info@scale-track.com">info@scale-track.com</a>. Your account will be deleted and all data will no longer be saved. </p>
    </div>
};

export default DataDeletionPolicy;