import React from "react";
import "./index.scss";

import { useSelector, useDispatch } from "react-redux";
import useDefer from "../../modules/hooks/useDefer";
import useOnScreen from "../../modules/hooks/useOnScreen";
import axios from "axios";
import moment from "moment";

import * as backendModule from "../../modules/backendModule";
import * as siteFunctionsActions from "../../actions/siteFunctionsActions";

import { FilteredCustomTable } from "../../components/customComponents/Table";
import Spinner from "../../components/customComponents/Spinner";
import FilterBySearch from "../../components/filters/FilterBySearch";
import FilterByDate from "../../components/filters/FilterByDate";

const SocialMediaManagerViewOffers = () => {
    const [data, setData] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [search, setSearch] = React.useState();
    const [dateFilter, setDateFilter] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const timestampRef = React.useRef();
    const curDispatch = useDispatch();
    const curDefer = useDefer();
    const searchDefer = useDefer();
    const curOnScreen = useOnScreen();

    const getData = (ts) => {
        if (timestampRef.current !== ts) return;
        if (canPaginate) setCanPaginate(false);

        let filters = [];
        if (search) {
            let tmp = [];
            for (let s of search.split(" ")) {
                tmp.push({name: "CampaignName", op: "like", value: search});
            };
            filters.push({or: tmp});
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaignsSocialMedia`,
            data: {
                limit: 20,
                offset: 0,
                filters,
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;

            setData(res.data);
            if (res.data.status === "ok") {
                if (res.data.data.length >= 20) setCanPaginate(true);
            };
        }).catch(() => {
            if (timestampRef.current !== ts) return;

            setData(backendModule.axiosConfig);
        });
    };

    const continueData = () => {
        if (!data) return;
        if (data.status !== "ok") return;
        if (!canPaginate) return;
        setCanPaginate(false);

        let filters = [];
        if (search) {
            let tmp = [];
            for (let s of search.split(" ")) {
                tmp.push({name: "CampaignName", op: "like", value: search});
            };
            filters.push({or: tmp});
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaignsSocialMedia`,
            data: {
                limit: 20,
                offset: data.data.length,
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length >= 20) setCanPaginate(true);

                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
            };
        }).catch(() => null);
    };

    const openURL = url => {
        window.open(url, "_blank");
    };

    React.useEffect(() => {
        const handler = () => {
            let ts = Date.now();
            timestampRef.current = ts;
            curDefer(() => {
                getData(ts);
            }, 500);
        };

        if (!data) handler();
        curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));

        return () => {
            try {
                curDispatch(siteFunctionsActions.removeHeaderRefreshAction(handler));
            } catch {};
        };
    }, [data, search]);

    React.useEffect(() => {
        if (!data) return;

        let ts = Date.now();
        timestampRef.current = ts;
        curDefer(() => {
            getData(ts);
        }, 500);
    }, [search]);

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        setCanPaginate(false);
        curDefer(() => continueData(), 500);
    }, [canPaginate, curOnScreen.isIntersecting]);
    
    return <div className="route__socialMediaManager">
        <div className="route__socialMediaManager__filters" style={{gridTemplateColumns: `1fr`}}>
            <FilterBySearch onChange={e => searchDefer(() => setSearch(e), 1000)} />
        </div>
        <br />

        <FilteredCustomTable
            accent="#6C5DD3"
            theme={themeSelector}
            headers={["Campaign", ""]}
            customColumns={["200px", "auto"]}
            data={(()=>{
                if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]]
                if (data?.status === "error") return [[{keyID: "noData-error", type: "text", text: "Error while fetching data!"}]];

                let out = [];
                for (let item of data.data) {
                    out.push([
                        (item.CampaignURL ? {keyID: item.ID, type: "button", text: "Open website", onClick: () => openURL(item.CampaignURL), style: {width: "70px"}} : null),
                        {keyID: item.ID, type: "text", text: item.CampaignName}
                    ].filter(t => t))
                };

                if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);

                return out;
            })()}
        />

        {canPaginate && <div style={{opacity: 0}} ref={curOnScreen.measureRef} />}
    </div>
};

export default SocialMediaManagerViewOffers