import React from "react";
import "./index.scss";

import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";

import * as backendModule from "../../../modules/backendModule";
import * as basicStylesModule from "../../../modules/basicStylesModule";

import Spinner from "../../../components/customComponents/Spinner";
import Dropdown from "../../../components/customComponents/Dropdown";
import StyledButton from "../../../components/styledComponents/Button";
import Checkbox from "../../customComponents/Checkbox";
import FilterBySearch from "../../../components/filters/FilterBySearch";
import { FilteredCustomTable } from "../../../components/customComponents/Table";

const ImportCampaignsFromAccountModal = props => {
    const [expanded, setExpanded] = React.useState(false);
    const [curState, setCurState] = React.useState({index: 1, data: null});
    const [continueBtn, setContinueBtn] = React.useState(null);
    const [selectAllBtn, setSelectAllBtn] = React.useState(null);

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const wrapRef = React.useRef();
    const contentRef = React.useRef();

    const changeState = (newIndex = curState.index, newData = curState.data, newExpanded = expanded) => {
        // # reset
        setContinueBtn();
        setSelectAllBtn();

        if (!contentRef.current) {
            setExpanded(newExpanded);
            return setCurState({index: newIndex, data: newData});
        };

        wrapRef.current.style.pointerEvents = "none";
        contentRef.current.animate([
            {opacity: getComputedStyle(contentRef.current).opacity},
            {opacity: 0}
        ], {duration: 150, iterations: 1, easing: "ease", fill: "both"}).onfinish = () => {
            setCurState({index: newIndex, data: newData});
            setExpanded(newExpanded);

            wrapRef.current.style.pointerEvents = null;
            contentRef.current.animate([
                {opacity: getComputedStyle(contentRef.current).opacity},
                {opacity: 1}
            ], {duration: 150, iterations: 1, easing: "ease", fill: "both"})
        };
    };

    const onClose = () => {
        if (wrapRef.current) {
            wrapRef.current.animate([
                {right: getComputedStyle(wrapRef.current).right},
                {right: "-100%"}
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };

        if (props.onChange) props.onChange();
        props.onClose();
    };

    React.useEffect(() => {
        if (!wrapRef.current) return;

        wrapRef.current.animate([
            {right: getComputedStyle(wrapRef.current).right},
            {right: 0}
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);

    return <div className="modal__importCampaignsFromAccount" onClick={() => onClose()}>
        <div className="modal__importCampaignsFromAccount__wrap" ref={wrapRef} onClick={e => e.stopPropagation()} style={{width: expanded ? "calc(100% - 84px)" : null}}>

            <div className="modal__importCampaignsFromAccount__wrap__top">
                <div className="modal__importCampaignsFromAccount__wrap__top__left">Import campaigns from account</div>

                {selectAllBtn && <StyledButton style={{
                    marginRight: "20px",
                    marginLeft: "auto",
                    height: "30px"
                }} onClick={selectAllBtn} isSecondary={true}>Select all</StyledButton>}
                {continueBtn && <StyledButton style={{
                    marginRight: "20px",
                    marginLeft: selectAllBtn ? null : "auto",
                    height: "30px"
                }} onClick={continueBtn}>Continue</StyledButton>}

                <div className="modal__importCampaignsFromAccount__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>

            <div className="modal__importCampaignsFromAccount__wrap__content" ref={contentRef}>
                {curState.index === 1 && <ImportCampaignsFromAccountModal_1 theme={themeSelector} integrationType={props.integrationType} changeState={changeState} integrationID={props.toIntegrationID} />}
                {curState.index === 2 && <ImportCampaignsFromAccountModal_2 theme={themeSelector} integrationType={props.integrationType} changeState={changeState} data={curState.data} continue={c => setContinueBtn(() => c)} selectall={c => setSelectAllBtn(() => c)} expand={() => !expanded && setExpanded(true)} />}
                {curState.index === 3 && <ImportCampaignsFromAccountModal_3 theme={themeSelector} integrationType={props.integrationType} changeState={changeState} data={curState.data} onClose={onClose} />}
                {curState.index === 4 && <ImportCampaignsFromAccountModal_4 theme={themeSelector} integrationType={props.integrationType} changeState={changeState} data={curState.data} onClose={onClose} integrationID={props.toIntegrationID} />}
                {curState.index === 5 && <ImportCampaignsFromAccountModal_5 theme={themeSelector} integrationType={props.integrationType} changeState={changeState} data={curState.data} onClose={onClose} />}
            </div>
        </div>
    </div>
};

const ImportCampaignsFromAccountModal_1 = props => {
    const [data, setData] = React.useState();
    const [selectedIntegration, setSelectedIntegration] = React.useState();

    const selectAccount = () => {
        if (!selectedIntegration) return;
        return props.changeState(2, selectedIntegration);
    };

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaignIntegrationIDs`,
            data: {
                IntegrationType: props.integrationType,
                filters: [
                    {name: "IntegrationID", op: "neq", value: props.integrationID}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    }, []);

    return <>
        {data ? <>
            {data.status === "ok" ? <>
                <p>Select an account from which you will be importing the campaigns</p>
                <p>NOTE: All accounts are from the same integration type, you can't clone a campaign from another type.</p>

                <br/>
                <br/>
                <Dropdown
                    accent="#6C5DD3"
                    theme={props.theme}
                    inlinePlaceholder="Select an account"
                    data={data.data.map(d => {
                        return {key: d.ID, name: <p style={{display: "flex", flexDirection: "column"}}>
                            <span>{d.ID}</span>
                            <span style={{color: "gray"}}>Last used: {d.LastUsed ? (new Date(d.LastUsed)).toLocaleString() : "Never"}</span>
                        </p>, value: d}
                    })}
                    onChange={e => setSelectedIntegration(e?.value)}
                />
                <br />

                <StyledButton
                    style={{width: "100%"}}
                    isDisabled={!selectedIntegration}
                    onClick={selectAccount}
                >Continue</StyledButton>
            </> : <>
                <p style={{color: basicStylesModule.errorColor}}>There was an error while fetching accounts</p>
            </>}
        </> : <>
            <p>Fetching your accounts, this might take a moment...</p>
            <br />
            <Spinner color="white" />
        </>}
    </>;
};

const ImportCampaignsFromAccountModal_2 = props => {
    const [campaigns, setCampaigns] = React.useState();
    const [search, setSearch] = React.useState();
    const [toClone, setToClone] = React.useState([]);

    const onContinue = () => {
        if (Array.isArray(toClone)) return props.changeState(3, toClone, false);
    };
    const onSelectAll = () => {
        if (campaigns) {
            if (campaigns.status === "ok") {
                let selAll = false;
                for (let c of campaigns.data) {
                    if (!toClone.includes(c.ID)) {
                        selAll = true;
                        break;
                    };
                };
                if (selAll) {
                    setToClone(campaigns.data.map(c => c.ID));
                } else {
                    setToClone([]);
                };
            };
        };
    };

    React.useEffect(() => {
        props.continue(onContinue);
        props.selectall(onSelectAll);
    }, [toClone, campaigns]);

    React.useEffect(() => {
        if (campaigns?.status === "ok") {
            props.expand();
        };
    }, [campaigns]);

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaignsWithoutData`,
            data: {
                filters: [
                    {name: "ID", op: "in", value: props.data.CampaignIDs}
                ],
                orders: [{name: "CampaignActive", order: "desc"}],
                limit: null,
                offset: 0
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setCampaigns(res.data);
        }).catch(() => {
            setCampaigns(backendModule.genericError);
        });
    }, []);

    return <>
        {campaigns ? <>
            {campaigns.status === "ok" ? <>
                <FilterBySearch onChange={e => setSearch(e)} />
                <br/>
                <FilteredCustomTable
                    accent="#6C5DD3"
                    theme={props.theme}
                    headers={["Clone", "Name", "Active", "Last updated at"]}
                    customColumns={["80px", "auto", "auto", "auto", "auto"]}
                    style={{columnGap: "20px"}}
                    data={(()=>{
                        let out = [];

                        for (let item of campaigns.data) {
                            if (search) {
                                if (!item.CampaignName.toLowerCase().includes(search.toLowerCase())) continue;
                            };
                            out.push([
                                {keyID: item.ID, type: "custom", data: <Checkbox
                                    checked={toClone.includes(item.ID)}
                                    onChange={e => {
                                        if (e && !toClone.includes(item.ID)) return setToClone(tc => [...tc, item.ID]);
                                        if (!e && toClone.includes(item.ID)) return setToClone(tc => tc.filter(tcf => tcf !== item.ID));
                                    }}
                                />},
                                {keyID: item.ID, type: "text", text: item.CampaignName},
                                {keyID: item.ID, type: "text", text: item.CampaignActive ? "Yes": "No", style: {color: item.CampaignActive ? basicStylesModule.successColor : basicStylesModule.errorColor}},
                                {keyID: item.ID, type: "text", text: moment(item.updatedAt).toDate().toLocaleString()},
                            ]);
                        };

                        if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);
                        return out;
                    })()}
                />
            </> : <>
                <p style={{color: basicStylesModule.errorColor}}>There was an error while fetching campaigns.</p>
            </>}
        </> : <>
            <p>Fetching campaigns...</p>
            <br />
            <Spinner color="white" />
        </>}
    </>;
};

const ImportCampaignsFromAccountModal_3 = props => {
    const [deleteNumbers, setDeleteNumbers] = React.useState(true);
    const [cloneWebsites, setCloneWebsites] = React.useState(true);

    return <>
        {props.data.length === 0 ? <>
            <p>You haven't selected any campaigns</p>
            <p>Nothing to clone...</p>
            <br />
            <StyledButton style={{width: "100%"}} onClick={props.onClose}>Close</StyledButton>
        </> : <>
            <p style={{color: basicStylesModule.successColor}}>You have selected {props.data.length} campaigns</p>
            <br />
            <p>You can choose to manually remove old numbers from the campaigns, or let the system do that for you.</p>
            <br />
            <p>NOTE: New number will be added at the end of the campaign wether you chose to delete the old one or not.</p>
            <br />
            <br />
            <p>NOTE2: If you choose to clone websites, the system will automatically create cloned CMS campaigns before using them.</p>
            <p>This is useful if you want to quickly prepare a new account with all the campaigns ready in case that the other one errors out.</p>
            <p style={{color: basicStylesModule.errorColor}}>WATCH OUT: this wont clone the campaigns that don't have CMS websites selected!!! (they will throw an error)</p>
            <br />
            <br />

            <p style={{display: "flex", alignItems: "center", gap: "5px", flexWrap: "wrap"}}>
                <Checkbox checked={deleteNumbers} onChange={e => setDeleteNumbers(!!e)} />
                <p>Remove numbers automatically</p>
            </p>
            <br />
            <p style={{display: "flex", alignItems: "center", gap: "5px", flexWrap: "wrap"}}>
                <Checkbox checked={cloneWebsites} onChange={e => setCloneWebsites(!!e)} />
                <p>Clone websites from CMS</p>
            </p>
            <br />
            <StyledButton style={{width: "100%"}} onClick={() => {
                props.changeState(4, {campaigns: props.data, deleteNumbers, cloneWebsites })
            }}>Start cloning</StyledButton>
        </>}
    </>
};

const ImportCampaignsFromAccountModal_4 = props => {
    const [finalData, setFinalData] = React.useState({});
    const [finalWebsites, setFinalWebsites] = React.useState({});

    const getProgress = () => {
        let count = props.data.campaigns.length + Object.keys(finalWebsites).length;

        let progress = 0;
        for (let key of Object.keys(finalData)) {
            if (finalData[key] !== undefined) progress += 1;
        };
        for (let key of Object.keys(finalWebsites)) {
            if (finalWebsites[key] !== undefined) progress += 1;
        };

        return {max: count, progress};
    };

    React.useEffect(() => {
        let fn = async () => {
            let allCampaigns = await axios({
                method: "POST",
                url: `${backendModule.backendURL}/campaigns/getAllCampaignsWithoutData`,
                data: {
                    filters: [
                        {name: "ID", op: "in", value: props.data.campaigns}
                    ],
                    limit: null
                },
                ...backendModule.axiosConfig
            }).then(res => {
                return res.data;
            }).catch(() => {
                return backendModule.genericError;
            });
            if (allCampaigns.status === "error") {
                let tmp = {};
                for (let item of props.data.campaigns) {
                    tmp[item] = false;
                };
                setFinalData(tmp);
                return;
            } else {
                if (props.data.cloneWebsites) {
                    let tmp = {};
                    for (let item of allCampaigns.data) {
                        if (item.PreLandingSiteID) tmp[item.PreLandingSiteID] = undefined;
                        if (item.LandingSiteID) tmp[item.LandingSiteID] = undefined;
                    };
                    setFinalWebsites(tmp);
                };
            };
    
            for (let item of props.data.campaigns) {
                let existingCampaign = allCampaigns.data.find(c => c.ID === item);
                if (!existingCampaign) {
                    setFinalData(fd => {return {fd, [item]: false}});
                    continue;
                };
                let newName = existingCampaign.CampaignName;
                if (props.data.deleteNumbers) {
                    newName = newName.replace(/ \d{5}$/g, "");
                };

                let curLandingID = existingCampaign.LandingSiteID;
                let curPrelandingID = existingCampaign.PreLandingSiteID;

                if (props.data.cloneWebsites) {
                    let toClone = {};
                    if (curLandingID === curPrelandingID) {
                        toClone[curLandingID] = null;
                    } else {
                        toClone[curLandingID] = null;
                        if (curPrelandingID) toClone[curPrelandingID] = null;
                    };

                    for (let key of Object.keys(toClone)) {
                        await axios({
                            method: "POST",
                            url: `${backendModule.backendURL}/globalIntegrations/scalecms/cloneCampaignFromSiteID`,
                            data: {
                                SiteID: key
                            },
                            ...backendModule.axiosConfig
                        }).then(res => {
                            if (res.data.status === "ok") {
                                toClone[key] = res.data.data;
                            };
                        }).catch(() => null);
                    };

                    if (
                        !toClone[curLandingID] ||
                        (curPrelandingID && !toClone[curPrelandingID])
                    ) {
                        setFinalData(fd => {return {...fd, [item]: false}});
                        setFinalWebsites(fd => {return {...fd, [curLandingID]: false}});
                        if (curPrelandingID) setFinalWebsites(fd => {return {...fd, [curPrelandingID]: false}});
                        continue;
                    };

                    let cl = curLandingID;
                    let cpl = curPrelandingID
                    setFinalWebsites(fd => {
                        let out = {...fd};
                        out[cl] = true;
                        if (cpl) out[cpl] = true;

                        return out;
                    });
                    curLandingID = toClone[curLandingID];
                    if (curPrelandingID) curPrelandingID = toClone[curPrelandingID];
                };

                let finalCreate = await axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/campaigns/addCampaign`,
                    data: {
                        Active: existingCampaign.CampaignActive,
                        CostPerClick: existingCampaign.CostPerClick,
                        IntegrationID: props.integrationID,
                        Name: newName,
                        TaggedTraffic: true,
    
                        LandingSiteID: existingCampaign.LandingSiteID,
                        PreLandingSiteID: curPrelandingID
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "ok") return true;
                    return false;
                }).catch(() => {
                    return false;
                });
    
                setFinalData(fd => {return {...fd, [item]: finalCreate}});
            };
        };
        fn();
    }, []);

    React.useEffect(() => {
        if (Object.keys(finalData).length === props.data.campaigns.length) {
            props.changeState(5, finalData)
        };
    }, [finalData]);

    return <>
        <p>Cloning campaigns...</p>
        <p style={{color: "gray"}}>DO NOT CLOSE THIS WINDOW</p>

        <div className="modal__importCampaignsFromAccount__wrap__content__progress">
            <div style={{width: `${100 / getProgress().max * getProgress().progress}%`}}></div>
            <span>{getProgress().progress} / {getProgress().max}</span>
        </div>

        <br />

        {props.data.cloneWebsites && <>
            <h3 style={{marginBottom: "10px"}}>Websites</h3>
            {Object.keys(finalWebsites).map(c => {
                return <p style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    marginBottom: "10px",
                    color: finalWebsites[c] !== undefined ? (finalWebsites[c] ? basicStylesModule.successColor : basicStylesModule.errorColor) : null
                }}>
                    {finalWebsites[c] === undefined && <Spinner style={{width: "18px", height: "18px"}} color="white" />}
                    {c}
                </p>
            })}
        </>}

        <h3 style={{marginBottom: "10px"}}>Campaigns</h3>
        {props.data.campaigns.map(c => {
            return <p style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginBottom: "10px",
                color: finalData[c] !== undefined ? (finalData[c] ? basicStylesModule.successColor : basicStylesModule.errorColor) : null
            }}>
                {finalData[c] === undefined && <Spinner style={{width: "18px", height: "18px"}} color="white" />}
                {c}
            </p>
        })}
    </>
};

const ImportCampaignsFromAccountModal_5 = props => {
    return <>
        <p>Campaign cloning process is complete!</p>
        <br />
        <p>Total cloned: {Object.keys(props.data).length}</p>
        <br />
        <p style={{color: basicStylesModule.successColor}}>Total successful: {Object.keys(props.data).reduce((acc, val) => acc + (props.data[val] ? 1 : 0),0)}</p>
        <br />
        <p style={{color: basicStylesModule.errorColor}}>Total failed: {Object.keys(props.data).reduce((acc, val) => acc + (!props.data[val] ? 1 : 0),0)}</p>
        <br />
        <StyledButton style={{width: "100%"}} onClick={props.onClose}>Close</StyledButton>
        <br />
        {Object.keys(props.data).reduce((acc, val) => acc + (!props.data[val] ? 1 : 0),0) > 0 && <>
            <p>The following IDs are from failed campaigns:</p>
            {Object.keys(props.data).map(key => {
                if (props.data[key]) return null;
                return <p>{key}</p>
            })}
        </>}
    </>
};

export default ImportCampaignsFromAccountModal;