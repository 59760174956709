import React from "react";
import "./index.scss";

import axios from "axios";
import { useSelector } from "react-redux";
import * as backendModule from "../../../../../modules/backendModule";

import StyledInput from "../../../../../components/styledComponents/Input";
import Spinner from "../../../../../components/customComponents/Spinner";

const AddIntegration_Mgid = (props) => {
    const [curState, setCurState] = React.useState({state: 1, data: null});
    // 1 - check existing tokens
    // 2 - add token if none
    // 3 - display utm params

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const mainRef = React.useRef();

    const onClose = () => {
        if (props.onChange) props.onChange();
        if (!mainRef?.current) return props.onClose();

        mainRef.current.animate([
            {right: getComputedStyle(mainRef.current).right},
            {right: "-100%"}
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
        return props.onClose();
    };

    React.useEffect(() => {
        if (!mainRef?.current) return;

        mainRef.current.animate([
            {right: getComputedStyle(mainRef.current).right},
            {right: 0}
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [mainRef.current]);

    
    return <div className="route__user__integrations__addMgid" onClick={onClose}>
        <div className="route__user__integrations__addMgid__wrap" ref={mainRef} onClick={e => e.stopPropagation()}>
            <div className="route__user__integrations__addMgid__wrap__head">
                <p className="route__user__integrations__addMgid__wrap__head__text">Mgid ads</p>
                <div className="route__user__integrations__addMgid__wrap__head__close" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={onClose}></div>
            </div>

            <div className="route__user__integrations__addMgid__wrap__data">
                {curState ? <>
                    <div className="route__user__integrations__addMgid__wrap__data__steps">
                        <div className={`route__user__integrations__addMgid__wrap__data__steps__step ${curState.state === 1 ? "route__user__integrations__addMgid__wrap__data__steps__step--active" : ""} ${curState.state > 1 ? "route__user__integrations__addMgid__wrap__data__steps__step--completed" : ""}`.trim()}>
                            <p className="route__user__integrations__addMgid__wrap__data__steps__step__num">1</p>
                            <p className="route__user__integrations__addMgid__wrap__data__steps__step__text">Connect</p>
                        </div>

                        <div className={`route__user__integrations__addMgid__wrap__data__steps__step ${curState.state === 2 ? "route__user__integrations__addMgid__wrap__data__steps__step--active" : ""} ${curState.state > 2 ? "route__user__integrations__addMgid__wrap__data__steps__step--completed" : ""}`.trim()}>
                            <p className="route__user__integrations__addMgid__wrap__data__steps__step__num">2</p>
                            <p className="route__user__integrations__addMgid__wrap__data__steps__step__text">Finish</p>
                        </div>
                    </div>
                    
                    {curState.state === 1 && <AddIntegration_Mgid_1 setCurState={setCurState} />}
                    {curState.state === 2 && <AddIntegration_Mgid_2 token={curState.data} setCurState={setCurState} onClose={onClose} />}
                </> : <Spinner color={themeSelector === "dark" ? "white" : "dark"} align="center" />}
            </div>
        </div>
    </div>
};

const AddIntegration_Mgid_1 = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [checkData, setCheckData] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const mainRef = React.useRef();
    const emailRef = React.useRef();
    const passwordRef = React.useRef();

    const createIntegration = () => {
        if (spinner) return;
        setInfoP("");

        if (!emailRef.current.value || !passwordRef.current.value) return setInfoP("Email or password missing");

        let data = {
            Integration: 1,
            Key: `${emailRef.current.value}:${passwordRef.current.value}`
        };

        if (!data.Key || data.Key === ":") return setInfoP("API token missing");

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/addIntegration`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.setCurState({state: 2, data: null});
            } else {
                if (res.data.data === "ALREADY_EXISTS") {
                    return setInfoP("An integration of this type already exists.");
                } else if (res.data.data === "TEST_FAILED") {
                    return setInfoP("Token test failed, Your Email or password is invalid!");
                } else {
                    return setInfoP("An error occured while creating an integration");
                };
            };
        }).catch(() => {
            return setInfoP("Server timed out while creating an integration!");
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        if (!mainRef.current) return;

        mainRef.current.animate([
            {left: getComputedStyle(mainRef.current).left},
            {left: 0}
        ], {duration: 300, iterations: 1, easing: "ease", fill: "both"});
    }, [mainRef.current]);

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/checkIntegration`,
            data: {
                Integration: 1
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.setCurState({state: 2, data: null});
            } else {
                return setCheckData(true);
            };
        }).catch(() => {
            return setCheckData(true);
        });
    }, []);

    if (!checkData) return <div className="route__user__integrations__addMgid__wrap__data__step1" ref={mainRef}>
        <p>Checking for existing integrations...</p>

        <Spinner color={themeSelector === "dark" ? "white" : "black"} align="center" />
    </div>

    return <div className="route__user__integrations__addMgid__wrap__data__step1" ref={mainRef}>
        <p className="route__user__integrations__addMgid__wrap__data__step1__text">
            To create a Mgid integration, you will need to do 2 things:
            <br />
            <br />
            <span>1) Add your Email and Password here</span>
            <br />
            <span>2) You will get tracking parameters to place on your campaigns</span>
        </p>

        <p>Enter your Email:</p>
        <StyledInput ref={emailRef} placeholder="Email" />

        <p>Enter your Password:</p>
        <StyledInput ref={passwordRef} placeholder="Password" type="password" />

        <div className="route__user__integrations__addMgid__wrap__data__step1__btn" onClick={createIntegration}>
            {spinner ? <Spinner color={themeSelector === "dark" ? "white" : "black"} style={{width: "24px", height: "24px"}} /> : "Continue"}
        </div>

        {infoP && <p className="route__user__integrations__addMgid__wrap__infoP">{infoP}</p>}
    </div>
};

const AddIntegration_Mgid_2 = props => {
    const [data, setData] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const mainRef = React.useRef();

    React.useEffect(() => {
        if (!mainRef.current) return;

        mainRef.current.animate([
            {left: getComputedStyle(mainRef.current).left},
            {left: 0}
        ], {duration: 300, iterations: 1, easing: "ease", fill: "both"});
    }, [mainRef.current]);

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/mgid/getUTMParams`,
            data: {
                Integration: 1
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    }, []);
    
    return <div className="route__user__integrations__addMgid__wrap__data__step3" ref={mainRef}>
        {data ? <>
            {data.status === "ok" ? <>
                <p>Put the following URL parameters in mgid campaigns:</p>
                <div className="route__user__integrations__addMgid__wrap__data__step3__code">{data.data}</div>

                <p>Your account is all set up!</p>

                <button className="route__user__integrations__addMgid__wrap__data__step3__close" onClick={props.onClose}>Finish</button>
            </> : <p className="route__user__integrations__addMgid__wrap__infoP">There was an error while getting the URL parameters!</p>}
        </> : <Spinner color={themeSelector === "dark" ? "white" : "black"} style={{width: "64px", height: "64px"}} />}
    </div>
};

export default AddIntegration_Mgid;