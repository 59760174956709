import React, { createRef } from "react";
import "./index.scss";

import axios from "axios";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import useDefer from "../../../../modules/hooks/useDefer";
import { chartColorSets, chartBackgroundColorSets } from "../../../../modules/miscModule";
import { animateBox } from "../../../../modules/componentAnimation";
import * as backendModule from "../../../../modules/backendModule";
import * as siteFunctionsActions from "../../../../actions/siteFunctionsActions";

import AdvancedDropdown from "../../../../components/customComponents/AdvancedDropdown";
import Spinner from "../../../../components/customComponents/Spinner";
import StyledButton from "../../../../components/styledComponents/Button";

import FilterByDate from "../../../../components/filters/FilterByDate";
import FilterColumns from "../../../../components/filters/FilterColumns";
import FilterByUserAccount from "../../../../components/filters/FilterByUserAccount";
import { FilteredCustomTable } from "../../../../components/customComponents/Table";

import { availableCampaignColumns as FacebookColumns } from "../../Campaigns/Facebook";
import { availableCampaignColumns as OrganicColumns } from "../../Campaigns/Organic";
import { availableCampaignColumns as SMSColumns } from "../../Campaigns/SMSMarketing";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

const MarketingReport = () => {
    // totalLeads, goodLeads, profit, spent

    const [chartColumns, setChartColumns] = React.useState();
    const [chartColumnsTimestamp, setChartColumnsTimestamp] = React.useState(Date.now());

    const [allUsers, setAllUsers] = React.useState();
    const [selectedUser, setSelectedUser] = React.useState(null);
    const [data, setData] = React.useState();
    const [dataCopy, setDataCopy] = React.useState();
    const [dateFilters, setDateFilters] = React.useState();
    const [selectedCampaign, setSelectedCampaign] = React.useState(null);
    const [selectedAdset, setSelectedAdset] = React.useState(null);

    const timestampRef = React.useRef();

    const selectedTrackingProfileSelector = useSelector(state => state?.trackingProfiles?.selectedProfile ?? null);
    const allTrackingProfilesSelector = useSelector(state => state?.trackingProfiles?.profiles ?? []);
    const currencySignSelector = useSelector(state =>  state?.types?.currencySign ?? "?");
    const userInfoSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});

    const curDefer = useDefer();
    const curDispatch = useDispatch();

    const returnPossibleChartColumns = () => {
        let curSelector = selectedTrackingProfileSelector;
        if (selectedUser) {
            if (selectedUser?.IntegrationID) {
                curSelector = selectedUser.IntegrationID;
            };
        };

        if (!curSelector) return [];
        if (curSelector === "-1") return OrganicColumns;
        if (curSelector === "-2") return SMSColumns;
        if (curSelector.startsWith("fb-")) return FacebookColumns;

        return [];
    };

    const filterCampaigns = () => {
        if (!data) return data;
        if (data.status !== "ok") return data;
        if (!selectedCampaign || selectedAdset) return data;

        let out = data.data.filter(d => d.ID === selectedCampaign);
        return {...data, data: out};
    };

    const getTableIntegrationValues = (value, key) => {
        let curSelector = selectedTrackingProfileSelector;
        if (selectedUser) {
            if (selectedUser?.IntegrationID) {
                curSelector = selectedUser.IntegrationID;
            };
        };

        if (String(curSelector).startsWith("-")) {
            return Array.isArray(value) ? value : [];
        } else  {
            return Array.isArray(value?.[key]) ? value[key] : [];
        };
    };

    const checkAdsets = () => {
        if (!selectedCampaign) return [];
        let tmp = selectedAdset ? dataCopy : data;
        if (!tmp) return [];
        if (!tmp.data) return [];

        if (selectedUser) {
            if (selectedUser?.UserID === "all-team-users") return [];
        };

        let out = [];
        for (let item of tmp.data) {
            if (item.ID === selectedCampaign) {
                if (Array.isArray(item._adsets)) out.push(...item._adsets);
                break;
            }
        };

        return out;
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom'
            },
            title: {
                display: false
            },
        },
        scales: {
            yAxes: {
                grid: {
                    color: "#373A43"
                }
            },
            xAxes: {
                grid: {
                    color: "#373A43"
                }
            }
        }
    };

    const prepareTableData = (data, column) => {
        switch (column) {
            case "Revenue":
            case "Spent":
            case "Profit":
            case "EPV":
            case "CPA":
            case "CPAO":
            case "CPC":
                let tmpRevenue = Number(data);
                if (isNaN(tmpRevenue)) return "-";
                return `${tmpRevenue.toFixed(2)} ${currencySignSelector}`;
            case "CR":
            case "AR":
            case "CUR":
            case "CRR":
            case "ROI":
            case "CTR":
                let tmpCR = Number(data);
                if (isNaN(tmpCR)) return "-";
                return `${tmpCR.toFixed(2)} %`;
            case "ROAS":
                let tmpROAS = Number(data);
                if (isNaN(tmpROAS)) return "-";
                return `${tmpROAS.toFixed(2)}x`;
            default:
                let tmp = Number(data);
                if (isNaN(tmp)) return data;
                return tmp.toLocaleString();
        };
    };

    const getData = (ts) => {
        if (timestampRef.current !== ts) return;
        if (data) setData();
        if (!selectedTrackingProfileSelector) return;
        if (dateFilters === undefined || dateFilters === null) return; // prevent "all" filter

        let trackFilters = [];
        let filters = [];
        if (dateFilters) {
            if (dateFilters?.start && dateFilters?.end) {
                trackFilters.push({ name: "createdAt", op: "pdgeq", value: dateFilters.start.toDate().getTime() });
                trackFilters.push({ name: "createdAt", op: "pdleq", value: dateFilters.end.toDate().getTime() });
            };
        };

        let uInfo = {
            UserID: null,
            IntegrationID: selectedTrackingProfileSelector,
            ...(String(selectedTrackingProfileSelector).startsWith("-") ? {} : {
                IntegrationType: "campaigns"
            })
        };
        if (uInfo?.IntegrationType === "campaigns" && selectedAdset) {
            filters.push({name: "fb-id", op: "eq", value: selectedAdset});
            uInfo.IntegrationType = "adsets";
        };

        if (selectedUser) {
            for (let key of Object.keys(selectedUser)) {
                uInfo[key] = selectedUser[key];
            };
            if (uInfo?.IntegrationID) {
                if (!uInfo.IntegrationID?.startsWith?.("-")) {
                    if (!selectedAdset) {
                        uInfo.IntegrationType = "campaigns";
                    } else {
                        filters.push({name: "fb-id", op: "eq", value: selectedAdset});
                        uInfo.IntegrationType = "adsets";
                    };
                };
            };
        };

        let th = [
            "Conversions",
            "Approved",
            "AR",
            "Cost",
            "Revenue",
            "Spent",
            "Profit",
            "CPA",
            "Visits",
            "CR",
            "CTR",
            "CPC",

            "Date_day", "Date_month", "Date_year",

            "ROI", "ROAS",

            "--SiteID"
        ];
        if (Array.isArray(chartColumns)) {
            let tcc = [...chartColumns];
            while (tcc.length > 5) tcc.pop();
            th.push(...tcc.map(c => {
                c = c.replace(/ /g, "_");
                if (c.endsWith("_ST") || c.endsWith("_FB")) c = c.substring(0, c.length - 3);
                return c;
            }))
        };

        let curDates = getDates();
        if (curDates.length === 1) {
            th.push("Date_hour");
        };

        th = [...new Set(th)];

        if (selectedUser) {
            if (selectedUser.UserID === "all-team-users") {
                return getDataTeam(ts, {
                    ...uInfo,
                
                    trackFilters: [
                        ...trackFilters
                    ],
                    filters,
                    smsFilters: trackFilters,
                    trackGroupByDate: true,
                    offset: 0,
                    limit: null,
                    TableHeaders: th,
                    fetchNextNode: true
                });
            };
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
            data: {
                ...uInfo,
                
                trackFilters: [
                    ...trackFilters
                ],
                filters,
                smsFilters: trackFilters,
                trackGroupByDate: true,
                offset: 0,
                limit: null,
                TableHeaders: th,
                fetchNextNode: true
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            setData(res.data);
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.axiosConfig);
        });
    };

    const getDataTeam = async (ts, info) => {
        if (!allUsers) return setData(backendModule.genericError);
        if (allUsers.status === "error") return setData(backendModule.genericError);

        let finalUsers = [
            {
                ID: userInfoSelector.ID,
                _profiles: allTrackingProfilesSelector
            },
            ...allUsers.data
        ];

        if (ts !== timestampRef.current) return;
        let out = [];
        if (!["fb-all"].includes(info?.IntegrationID)) {

            await Promise.allSettled(finalUsers.map(usr => new Promise(async r => {
                let tmpReq = {...info, UserID: usr.ID};
    
                await axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
                    data: tmpReq,
                    ...backendModule.axiosConfig
                }).then(final => {
                    if (final.data.status === "ok") out.push(final.data.data);
                }).finally(r);
            })));

        } else {
            if (info.IntegrationID === "fb-all") {
                await Promise.allSettled(finalUsers.map(usr => {
                    return usr._profiles.filter(p => p.ID.startsWith("fb-")).map(profile => new Promise(async r => {
                        let tmpReq = {...info, UserID: usr.ID, IntegrationID: profile.ID};

                        await axios({
                            method: "POST",
                            url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
                            data: tmpReq,
                            ...backendModule.axiosConfig
                        }).then(final => {
                            if (final.data.status === "ok") out.push(final.data.data);
                        }).finally(r);
                    }));
                }).flat(5).filter(t => t));
            };
        };

        if (ts !== timestampRef.current) return;
        setData({status: "ok", data: out.flat(5)});
    };

    const getAllUsers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsersTeamLead`,
            data: {
                excludeSelf: true,
                includeProfiles: true,
                filters: [
                    {name: "CreatedBy", op: "eq", value: userInfoSelector?.ID},
                    {name: "Flags:isSocialMediaManager", op: "eq", value: false}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setAllUsers(res.data);
        }).catch(() => {
            setAllUsers(backendModule.genericError);
        });
    };

    const getKPIData = column => {
        if (!data) return "-";
        if (data.status !== "ok") return "-";
        let finalData = filterCampaigns();

        let total = 0;
        let int = "ST";
        if (column === "Spent") int = "INT";

        if (column === "Cost") {
            let totalSpent = 0;
            let totalConversions = 0;

            for (let item of finalData.data) {
                totalSpent += getTableIntegrationValues(item.TableData["Spent"], "INT").reduce((acc, val) => acc + +val.Value, 0);
                totalConversions += getTableIntegrationValues(item.TableData["Conversions"], "ST").reduce((acc, val) => acc + +val.Value, 0);
            };
            if (totalConversions === 0) totalConversions = 1;
            return prepareTableData(Number(totalSpent / totalConversions).toFixed(2), "Spent");
        };
        if (column === "Profit") {
            let totalSpent = 0;
            let totalRevenue = 0;

            for (let item of finalData.data) {
                totalSpent += getTableIntegrationValues(item.TableData["Spent"], "INT").reduce((acc, val) => acc + +val.Value, 0);
                totalRevenue += getTableIntegrationValues(item.TableData["Revenue"], "ST").reduce((acc, val) => acc + +val.Value, 0);
            };
            return prepareTableData(Number(totalRevenue - totalSpent).toFixed(2), "Spent");
        };
        
        for (let item of finalData.data) {
            // dont forget to handle ST and INT
            if (item.TableData[column]) {
                // handle AR -_-
                if (column === "AR") {
                    let tmp = getTableIntegrationValues(item.TableData[column], int).reduce((acc, val) => acc + +val.Value, 0);
                    tmp /= getTableIntegrationValues(item.TableData[column], int).length;
                    total += tmp;
                } else {
                    total += getTableIntegrationValues(item.TableData[column], int).reduce((acc, val) => acc + +val.Value, 0);
                };
            };
        };

        if (column === "AR") total /= finalData.data.length;

        return prepareTableData(total, column);
    };

    const getAggregateDataByDate = (date, column) => {
        if (!data) return "-";
        if (data.status === "error") return "-";
        let finalData = filterCampaigns();

        let [dd, h] = date ? date.split(" ") : [null, null];
        if (h) h = Number(h);
        let [d, m, y] = dd ? dd.split("-") : [null, null, null];
        if (date) [d, m, y] = [+d, +m, +y];
        
        if ([
            "Conversions", "AR", "Approved", "Revenue", "Spent", "Profit",
            "Visits", "CTR", "CPC", "ROI", "ROAS"
        ].includes(column)) {
            let int = "ST";
            if (["Spent", "CTR", "CPC"].includes(column)) int = "INT";
            let total = 0;
            let totalCount = 0;
            for (let item of finalData.data) {
                if (item.TableData[column]) {
                    for (let final of getTableIntegrationValues(item.TableData[column], int)) {
                        if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                            if (h !== null && (h !== final.Date_hour)) continue;
                            total += final.Value ? +final.Value : 0;
                            totalCount += 1;
                        }
                    };
                };
            };
            if (["AR", "CR", "CTR", "CPC", "ROI", "ROAS"].includes(column)) total /= totalCount;
            if (isNaN(total)) total = 0;
            return prepareTableData(total, column);
        };

        if (["PaymentPerLead"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            for (let item of finalData.data) {
                if (item.TableData["Approved"]) {
                    for (let final of getTableIntegrationValues(item.TableData["Approved"], "ST")) {
                        if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                            if (h !== null && (h !== final.Date_hour)) continue;
                            totalLeads += +final.Value;
                        };
                    };
                };
                if (item.TableData["Revenue"]) {
                    for (let final of getTableIntegrationValues(item.TableData["Revenue"], "ST")) {
                        if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                            if (h !== null && (h !== final.Date_hour)) continue;
                            totalSpent += +final.Value;
                        };
                    };
                };
            };
            let final = 0;
            if (!totalSpent || !totalLeads) {
                final = 0;
            } else {
                final = totalSpent / totalLeads;
            };

            return prepareTableData(final, "Spent");
        };

        if (["CostPerLead"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            for (let item of finalData.data) {
                if (item.TableData["Approved"]) {
                    for (let final of getTableIntegrationValues(item.TableData["Approved"], "ST")) {
                        if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                            if (h !== null && (h !== final.Date_hour)) continue;
                            totalLeads += +final.Value;
                        };
                    };
                };
                if (item.TableData["Spent"]) {
                    for (let final of getTableIntegrationValues(item.TableData["Spent"], "INT")) {
                        if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                            if (h !== null && (h !== final.Date_hour)) continue;
                            totalSpent += +final.Value;
                        };
                    };
                };
            };
            if (totalLeads <= 0) totalLeads = 1;
            let final = 0;
            if (!totalSpent || !totalLeads) {
                final = 0;
            } else {
                final = totalSpent / totalLeads;
            };
            return prepareTableData(final, "Spent");
        };

        if (["CostPerAnyLead"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            for (let item of finalData.data) {
                if (item.TableData["Conversions"]) {
                    for (let final of getTableIntegrationValues(item.TableData["Conversions"], "ST")) {
                        if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                            if (h !== null && (h !== final.Date_hour)) continue;
                            totalLeads += +final.Value;
                        };
                    };
                };
                if (item.TableData["Spent"]) {
                    for (let final of getTableIntegrationValues(item.TableData["Spent"], "INT")) {
                        if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                            if (h !== null && (h !== final.Date_hour)) continue;
                            totalSpent += +final.Value;
                        };
                    };
                };
            };
            if (totalLeads <= 0) totalLeads = 1;
            let final = 0;
            if (!totalSpent || !totalLeads) {
                final = 0;
            } else {
                final = totalSpent / totalLeads;
            };
            return prepareTableData(final, "Spent");
        };

        if (["CR"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            for (let item of finalData.data) {
                if (item.TableData["Visits"]) {
                    for (let final of getTableIntegrationValues(item.TableData["Visits"], "ST")) {
                        if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                            if (h !== null && (h !== final.Date_hour)) continue;
                            totalLeads += +final.Value;
                        };
                    };
                };
                if (item.TableData["Conversions"]) {
                    for (let final of getTableIntegrationValues(item.TableData["Conversions"], "ST")) {
                        if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                            if (h !== null && (h !== final.Date_hour)) continue;
                            totalSpent += +final.Value;
                        };
                    };
                };
            };
            let final = 0;
            if (!totalSpent || !totalLeads) {
                final = 0;
            } else {
                final = 100 / totalLeads * totalSpent;
            };
            return prepareTableData(final, "CR");
        };

        if (["Profit"].includes(column)) {
            let totalRevenue = 0;
            let totalSpent = 0;
            for (let item of finalData.data) {
                if (item.TableData["Revenue"]) {
                    for (let final of getTableIntegrationValues(item.TableData["Revenue"], "ST")) {
                        if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                            if (h !== null && (h !== final.Date_hour)) continue;
                            totalRevenue += +final.Value;
                        };
                    };
                };
                if (item.TableData["Spent"]) {
                    for (let final of getTableIntegrationValues(item.TableData["Spent"], "INT")) {
                        if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                            if (h !== null && (h !== final.Date_hour)) continue;
                            totalSpent += +final.Value;
                        };
                    };
                };
            };
            let final = totalRevenue - totalSpent;

            return prepareTableData(final, "Spent");
        };
    };

    const getDates = (includeHours = false) => {
        if (!data) return [];
        if (!dateFilters) return;
        if (data.status === "error") return [];

        let tmp = [];

        let ds = moment(dateFilters.start);
        let de = moment(dateFilters.end);

        tmp = [];
        while (ds.isBefore(de)) {
            tmp.push(ds.format("DD-MM-YYYY"));
            ds = ds.add(1, "days");
        };

        if (tmp.length === 1 && includeHours) {
            tmp = [];

            ds = moment(dateFilters.start);
            de = moment(dateFilters.end);

            while (ds.isBefore(de)) {
                tmp.push(ds.format("DD-MM-YYYY HH"));
                ds = ds.add(1, "hours");
            };
        };

        return tmp;
    };

    const getChartData = () => {
        if (!data) return {datasets: [], labels: []};
        if (data.status !== "ok") return {datasets: [], labels: []};
        let finalData = filterCampaigns();

        // totalLeads, goodLeads, profit, spent
        let labels = getDates(true);
        if (!Array.isArray(labels)) return {datasets: [], labels: []};

        let datasets = [];

        let tcc = Array.isArray(chartColumns) ? chartColumns : [];
        let count = 0;
        for (let item of tcc) {
            if (datasets.length === 5) break;

            datasets.push({
                fill: false,
                label: item,
                data: [],
                borderColor: chartColorSets[count],
                backgroundColor: chartBackgroundColorSets[count]
            });
            count += 1;
        };

        let hourFound = false;
        for (let dates of labels) {
            let [dd, h] = dates.split(" ");
            if (h) {
                h = Number(h);
                hourFound = true;
            };
            let [d, m, y] = dd.split("-");
            [d, m, y] = [+d, +m, +y];


            let totals = {};
            for (let item of datasets) totals[item.label] = 0;

            for (let item of finalData.data) {
                for (let ds of datasets) {
                    let finalColumn = ds.label.replace(/ /g, "_");
                    let int = "ST";
                    if (finalColumn.endsWith("_FB")) int = "INT";
                    if (finalColumn.endsWith("_ST") || finalColumn.endsWith("_FB")) finalColumn = finalColumn.substring(0, finalColumn.length - 3);
                    if (item.TableData[finalColumn]) {
                        for (let obj of getTableIntegrationValues(item.TableData[finalColumn], int)) {
                            if (obj.Date_year === y && obj.Date_month === m && obj.Date_day === d) {
                                if (h !== null && (obj.Date_hour !== h)) continue;
                                totals[ds.label] += +obj.Value;
                            };
                        };
                    };
                };
            };
            for (let ds of datasets) {
                ds.data.push(totals[ds.label]);
            };
        };


        if (!hourFound) {
            labels = labels.map(l => moment(l, "DD-MM-YYYY").toDate().toLocaleDateString());
        } else {
            labels = labels.map(l => moment(l, "DD-MM-YYYY HH").format("HH:00 A"));
        };
        return {datasets, labels};
    };

    React.useEffect(() => {
        let handler = () => {
            let ts = Date.now();
            timestampRef.current = ts;
            getData(ts);
        };

        curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));
        curDefer(handler, 500);

        return () => curDispatch(siteFunctionsActions.removeHeaderRefreshAction(handler));
    }, [selectedTrackingProfileSelector, dateFilters, selectedUser, selectedAdset, chartColumns]);

    React.useEffect(() => {
        if (selectedAdset) setSelectedAdset(null);
    }, [selectedCampaign]);

    React.useEffect(() => {
        if (selectedCampaign) setSelectedCampaign();
    }, [selectedUser]);

    React.useEffect(() => {
        if (!userInfoSelector?.Flags?.isTeamLeader) return;
        if (allUsers) return;

        getAllUsers();
    }, [userInfoSelector]);

    React.useEffect(() => {
        if (selectedAdset) return;
        setDataCopy(data);
    }, [data]);

    React.useEffect(() => {
        setSelectedCampaign(null);
        setSelectedAdset(null);
    }, [selectedTrackingProfileSelector]);

    React.useEffect(() => {
        setChartColumns();
        let curSelector = selectedTrackingProfileSelector;
        if (selectedUser) {
            if (selectedUser?.IntegrationID) {
                curSelector = selectedUser.IntegrationID
            };
        };

        if (!curSelector) return setChartColumns();
        if (curSelector.startsWith("fb-")) {
            setChartColumns(["Conversions ST", "CARC ST", "Profit ST", "Spent FB"]);
        } else {
            setChartColumns(["Conversions", "Approved", "Profit", "Spent"]);
        };
        setChartColumnsTimestamp(Date.now());
    }, [selectedTrackingProfileSelector, selectedUser]);

    return <div className="route__reports__marketing">
        <div className={`route__reports__marketing__filters ${checkAdsets().length > 0 ? "route__reports__marketing__filters--adsets" : ""} ${userInfoSelector?.Flags?.isTeamLeader ? " route__reports__marketing__filters--additional" : ""}`}>
            {userInfoSelector?.Flags?.isTeamLeader && <FilterByUserAccount
                headline="Select user / account"
                data={(()=>{
                    if (!allUsers) return null;
                    if (allUsers.status === "error") return [];
                    return [
                        {
                            ID: "all-team-users",
                            Username: "My team",
                            _profiles: [
                                {ID: "-1", type: "scale-track", name: "Generic tracking"},
                                {ID: "-2", type: "scale-track-sms", name: "SMS marketing"},
                                {ID: "-3", type: "scale-track-social", name: "Social campaigns"},
                                {ID: "fb-all", type: "facebook", name: "Facebook", generic: true}
                            ]
                        },
                        ...allUsers.data
                    ];
                })()}
                onChange={e => setSelectedUser(e)}
                defaultUser={null}
            />}

            <AdvancedDropdown
                headline="Campaign"
                data={((selectedAdset ? dataCopy : data)?.status === "ok" ? [{key: "all-campaigns", name: "All campaigns", value: null},...(selectedAdset ? dataCopy : data).data.sort((a, b) => {
                    if (!a.CampaignActive && b.CampaignActive) return 1;
                    return -1;
                }).map(elem => {
                    return {
                        key: elem.ID,
                        search: `${elem.CampaignName} (${elem.CampaignActive ? "Active" : "Inactive"})`,
                        name: <p style={{
                            color: String(elem.ID).endsWith("_NA") ? "yellow" : null
                        }}>{elem.CampaignName} <span style={{
                            color: elem.CampaignActive ? "rgb(102, 238, 102)" : "rgb(238, 102, 102)"
                        }}>({elem.CampaignActive ? "Active" : "Inactive"})</span></p>,
                        value: elem.ID
                    }
                })] : null)}
                showSearch={true}
                onChange={e => {
                    if (selectedCampaign !== e?.value) setSelectedCampaign(e?.value);
                }}
                selected={(()=>{
                    if (!selectedCampaign) return 0;
                })()}
            />

            {checkAdsets().length > 0 && <AdvancedDropdown
                headline="Adset"
                data={[
                    {key: "all-adsets", name: "All adsets", value: null},
                    ...checkAdsets().map(set => {
                        return {key: set.ID, name: <span style={{
                            color: String(set.ID).endsWith("NA") ? "yellow" : null
                        }}>{set.Name}</span>, value: set.ID, search: set.Name}
                    })
                ]}
                onChange={e => setSelectedAdset(e?.value)}
                selected={(()=>{
                    if (!selectedAdset) return 0;
                    let tmp = checkAdsets();
                    return tmp.indexOf(tmp.find(tmpi => tmpi.ID === selectedAdset)) + 1;
                })()}
            />}

            <FilterByDate
                defaultValue="30days"
                onChange={e => setDateFilters(e)}
                disableAll={true}
            />
        </div>

        <div className="route__reports__marketing__kpi">

            <div className="route__reports__marketing__kpi__item">
                <div className="route__reports__marketing__kpi__item__top">Conversions</div>
                <div className="route__reports__marketing__kpi__item__bottom">
                    {(data && data?.status === "ok") ? <>
                        {getKPIData("Conversions")}
                    </> : <Spinner style={{width: "32px", height: "32px"}} color="white" />}
                </div>
            </div>

            <div className="route__reports__marketing__kpi__item">
                <div className="route__reports__marketing__kpi__item__top">Approve rate</div>
                <div className="route__reports__marketing__kpi__item__bottom">
                    {data ? <>
                        {getKPIData("AR")}
                    </> : <Spinner style={{width: "32px", height: "32px"}} color="white" />}
                </div>
            </div>

            <div className="route__reports__marketing__kpi__item">
                <div className="route__reports__marketing__kpi__item__top">Cost</div>
                <div className="route__reports__marketing__kpi__item__bottom">
                    {(data && data?.status === "ok") ? <>
                        {getKPIData("Cost")}
                    </> : <Spinner style={{width: "32px", height: "32px"}} color="white" />}
                </div>
            </div>

            <div className="route__reports__marketing__kpi__item">
                <div className="route__reports__marketing__kpi__item__top">Revenue</div>
                <div className="route__reports__marketing__kpi__item__bottom">
                    {(data && data?.status === "ok") ? <>
                        {getKPIData("Revenue")}
                    </> : <Spinner style={{width: "32px", height: "32px"}} color="white" />}
                </div>
            </div>

            <div className="route__reports__marketing__kpi__item">
                <div className="route__reports__marketing__kpi__item__top">Spent</div>
                <div className="route__reports__marketing__kpi__item__bottom">
                    {(data && data?.status === "ok") ? <>
                        {getKPIData("Spent")}
                    </> : <Spinner style={{width: "32px", height: "32px"}} color="white" />}
                </div>
            </div>

            <div className="route__reports__marketing__kpi__item">
                <div className="route__reports__marketing__kpi__item__top">Profit</div>
                <div className="route__reports__marketing__kpi__item__bottom">
                    {(data && data?.status === "ok") ? <>
                        {getKPIData("Profit")}
                    </> : <Spinner style={{width: "32px", height: "32px"}} color="white" />}
                </div>
            </div>

        </div>

        {chartColumns && <div className="route__reports__marketing__columns">
            <FilterColumns
                key={`filter-columns-${selectedTrackingProfileSelector}-${chartColumnsTimestamp}`}
                defaultValue={chartColumns}
                columns={returnPossibleChartColumns()}
                onChange={e => setChartColumns(e)}
                style={{
                    width: "200px",
                    marginLeft: "auto",
                    marginBottom: "10px"
                }}
            />
        </div>}

        <div className="route__dashboard__chart">
            <div className={`route__dashboard__chart__spinner ${!data ? "route__dashboard__chart__spinner--active" : ""}`}>
                <Spinner color="white" />
            </div>

            {chartColumns && <Line
                style={{width: "100%", height: "300px"}}
                options={options}
                data={getChartData()}
            />}
        </div>

        <div className="route__reports__marketing__table genericTableWrap genericTableWrap--sticky">
            <FilteredCustomTable
                columnBorders={data?.status === "ok"}
                headers={[
                    <div>
                        <p className="genericTableWrap__campaignWithImg__headingPadded"></p>
                        Date
                    </div>, <span>Leads</span>, <span title="Confirmed lead rate">CLR</span>,
                    <span title="Confirmed leads">CLs</span>, <span title="Payment per lead">PPL</span>,
                    <span title="Cost per lead">CPL</span>, <span title="Cost per approved lead">CPAL</span>,
                    `Revenue`, `Spent`, `Profit`,
                    "Visits", <span title="Conversion rate">CR</span>, <span title="Click-through rate">CTR</span>, <span title="Cost per click">CPC</span>,
                    <span title="Return on investment">ROI</span>, <span title="Return on ad spend">ROAS</span>
                ]}
                key="user-report-marketing-table"
                accent="#6C5DD3"
                theme="dark"
                style={{
                    columnGap: "35px"
                }}
                stickyHeader={685}
                stickyHeaderClass="component__contentWrapper"
                stickyFooter={-22}
                stickyFooterClass="component__contentWrapper"
                spinnerColor="#fff"
                showSpinner={!data}
                customColumns={[
                    "200px",
                    ...(new Array(15).fill("auto"))
                ]}
                data={(()=>{
                    if (!data) return [];
                    if (data.status === "error") return [[{keyID: "noData-error", type: "text", text: "Error while fetching data!"}]];

                    let cDates = getDates(true);
                    if (!cDates) return [];

                    let out = [];
                    for (let date of cDates) {
                        out.push({
                            columns: [
                                {keyID: date, type: "custom", data: <p className="genericTableWrap__campaignWithImg">
                                    <div className="genericTableWrap__campaignWithImg__padded"></div>
                                    <p>
                                        <span style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{date.includes(" ") ? moment(date, "DD-MM-YYYY HH").format("HH:00 A") : moment(date, "DD-MM-YYYY").toDate().toLocaleDateString()}</span>
                                        <span></span>
                                    </p>
                                </p>, style: {width: null}},
                                {keyID: date, type: "text", text: getAggregateDataByDate(date, "Conversions")},
                                {keyID: date, type: "text", text: getAggregateDataByDate(date, "AR")},
                                {keyID: date, type: "text", text: getAggregateDataByDate(date, "Approved")},
                                {keyID: date, type: "text", text: getAggregateDataByDate(date, "PaymentPerLead")},
                                {keyID: date, type: "text", text: getAggregateDataByDate(date, "CostPerAnyLead")},
                                {keyID: date, type: "text", text: getAggregateDataByDate(date, "CostPerLead")},
                                {keyID: date, type: "text", text: getAggregateDataByDate(date, "Revenue")},
                                {keyID: date, type: "text", text: getAggregateDataByDate(date, "Spent")},
                                {keyID: date, type: "text", text: getAggregateDataByDate(date, "Profit")},
                                {keyID: date, type: "text", text: getAggregateDataByDate(date, "Visits")},
                                {keyID: date, type: "text", text: getAggregateDataByDate(date, "CR")},
                                {keyID: date, type: "text", text: getAggregateDataByDate(date, "CTR")},
                                {keyID: date, type: "text", text: getAggregateDataByDate(date, "CPC")},
                                {keyID: date, type: "text", text: getAggregateDataByDate(date, "ROI")},
                                {keyID: date, type: "text", text: getAggregateDataByDate(date, "ROAS")},
                            ]
                        });
                    };

                    if (out.length > 0) {
                        let curAR = Number(getAggregateDataByDate(null, "AR").split(" ")[0]) || 0;
                        if (isNaN(curAR)) {
                            curAR = getAggregateDataByDate(null, "AR");
                        } else {
                            if (curAR > 0) curAR /= out.length;
                            curAR = `${curAR.toFixed(2)} %`;
                        };
                        out.push({
                            isFooter: true,
                            columns: [
                                {keyID: "footer-1", type: "custom", data: <p className="genericTableWrap__campaignWithImg"><div className="genericTableWrap__campaignWithImg__padded"></div>Total</p>, style: {width: null}},
                                {keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Conversions")},
                                {keyID: "footer-1", type: "text", text: curAR},
                                {keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Approved")},
                                {keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "PaymentPerLead")},
                                {keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CostPerAnyLead")},
                                {keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CostPerLead")},
                                {keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Revenue")},
                                {keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Spent")},
                                {keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Profit")},
                                {keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Visits")},
                                {keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CR")},
                                {keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CTR")},
                                {keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CPC")},
                                {keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "ROI")},
                                {keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "ROAS")},
                            ]
                        })
                    };

                    return out;
                })()}
            />
        </div>
    </div>
};

const BonusCalculator = (props) => {
    const [leadsGenerated, setLeadsGenerated] = React.useState(null);
    const [bonusReceived, setBonusReceived] = React.useState("-");

    const dailyLeadsRef = React.useRef();
    const leadCostRef = React.useRef();
    const arRef = React.useRef();
    const pplRef = React.useRef();

    const leadGenCalc = () => {
        let dailyTotal = +dailyLeadsRef.current.value;
        if (!dailyLeadsRef) return setLeadsGenerated(null);
        dailyTotal = Number(dailyTotal);
        if (isNaN(dailyTotal)) return setLeadsGenerated(null);

        dailyTotal *= 30;

        if (dailyTotal === leadsGenerated) {
            bonusCalc();
        } else {
            setLeadsGenerated(dailyTotal);
        };
    };
    const bonusCalc = () => {
        if (!leadsGenerated) return setBonusReceived("-");

        let leadCost = Number(leadCostRef.current.value);
        if (isNaN(leadCost)) return setBonusReceived("-");

        let ar = Number(arRef.current.value);
        if (isNaN(ar)) return setBonusReceived("-");

        let ppl = Number(pplRef.current.value);
        if (isNaN(ppl)) return setBonusReceived("-");

        let spent = leadsGenerated * leadCost;
        let revenue = Math.floor((leadsGenerated / 100) * ar) * ppl;
        let total = revenue - spent;

        if (total >= 50000) {
            setBonusReceived(Math.floor(total / 100 * 20));
        } else if (total >= 45000) {
            setBonusReceived(5000);
        } else if (total >= 40000) {
            setBonusReceived(4500);
        } else if (total >= 35000) {
            setBonusReceived(4000);
        } else if (total >= 30000) {
            setBonusReceived(3500);
        } else if (total >= 25000) {
            setBonusReceived(2500);
        } else if (total >= 20000) {
            setBonusReceived(2000);
        } else if (total >= 15000) {
            setBonusReceived(1500);
        } else if (total >= 10000) {
            setBonusReceived(750);
        } else if (total >= 5000) {
            setBonusReceived(250);
        } else {
            setBonusReceived("Min profit for bonus is 5k!");
        };

    };

    React.useEffect(() => {
        bonusCalc();
    }, [leadsGenerated]);

    return <div className="route__reports__marketing__calculator genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Calculator</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            <br />
            <div className="genericModal__wrap__input">
                <p>If i generate <input onKeyUp={leadGenCalc} ref={dailyLeadsRef} type="number" style={{width: "100px"}} /> leads daily with the cost of <input onKeyUp={leadGenCalc} ref={leadCostRef} type="number" style={{width: "100px"}} /> eur per lead, I will generate <span style={{color: "orange"}}>{leadsGenerated ?? "-"}</span> leads monthly, with the AR of <input onKeyUp={leadGenCalc} ref={arRef} type="number" style={{width: "100px"}} />% and the payment per lead <input onKeyUp={leadGenCalc} ref={pplRef} type="number" style={{width: "100px"}} /> eur
                <br />
                <br />
                I will get <span style={{color: "orange"}}>{bonusReceived}</span> in bonus!</p>
            </div>

        </div>

    </div>
};

export default MarketingReport;
export { BonusCalculator };