import React from "react";
import "./index.scss";

import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";

import * as backendModule from "../../../../modules/backendModule";
import * as trackEventsModule from "../../../../modules/trackEventsModule";
import { getTrackingProfileImage } from "../../../../modules/miscModule";
import { availableCampaignColumns } from "../../Campaigns/Organic";

import FilterByColumns from "../../../../components/filters/FilterColumns";
import FilterByDate from "../../../../components/filters/FilterByDate";

import StyledButton from "../../../../components/styledComponents/Button";
import StyledInput from "../../../../components/styledComponents/Input";
import AdvancedDropdown from "../../../../components/customComponents/AdvancedDropdown";
import Spinner from "../../../../components/customComponents/Spinner";
import { FilteredCustomTable } from "../../../../components/customComponents/Table";

import { parseCSV } from "../../../../modules/csvParserModule";

const FacebookExport = () => {
    const [displayError, setDisplayError] = React.useState();
    const [completed, setCompleted] = React.useState();
    const [completedSuc, setCompletedSuc] = React.useState();
    const [dateFilters, setDateFilters] = React.useState();
    const [data, setData] = React.useState();
    const [spinner, setSpinner] = React.useState(false);
    const [orders, setOrders] = React.useState();
    const importFromCSV = file => {


        if (!file) return;

        let fr = new FileReader();
        fr.onload = async e => {

            
            parseCSV(e.target.result).then(csv => {
                let headers = csv[0]
                let succesfull = 0;
                for (let i = 1; i < csv.length; i++) {
                    let data = {};
                    for (let infos = 0; infos < csv[i].length; infos++) {
                        let headerName;
                        switch (headers[infos]) {
                            case "Ad Set Name":
                                headerName = "AdSetName";
                                break;
                            case "Gender":
                                headerName = "Gender";
                                break;
                            case "Age":
                                headerName = "Age";
                                break;
                            case "Ad name":
                                headerName = "AdName";
                                break;
                            case "Amount spent (EUR)":
                                headerName = "AmountSpent";
                                break;
                            case "Result type":
                                headerName = "ResultType";
                                break;
                            case "Cost per result":
                                headerName = "CPR";
                                break;
                            case "CPC (cost per link click)":
                                headerName = "CPC";
                                break;
                            case "Results":
                                headerName = "Results";
                                break;
                            case "Reach":
                                headerName = "Reach";
                                break;
                            case "Outbound CTR (click-through rate)":
                                headerName = "OutboundCTR";
                                break;
                            case "Impressions":
                                headerName = "Impressions";
                                break;
                            case "Unique outbound clicks":
                                headerName = "UniqueOutboundClicks";
                                break;
                            case "Outbound clicks":
                                headerName = "OutboundClicks";
                                break;
                            case "Reporting starts":
                                headerName = "ReportStarts";
                                break;
                            case "Reporting ends":
                                headerName = "ReportEnds";
                                break;
                            default: break;
                        }
                        data[headerName] = csv[i][infos]
                    }
                    setTimeout(() => {
                        axios({
                            method: "POST",
                            url: `${backendModule.backendURL}/facebookExport/addFacebookExports`,
                            data,
                            ...backendModule.axiosConfig
                        }).then(res => {
                            if (res.data.status === "ok") {
                                succesfull++;
                            }
                            setCompletedSuc(succesfull)
                            setCompleted(`${i}/${csv.length - 1} (${(i / (csv.length - 1) * 100).toFixed(0)} %)`)
                        }).catch(() => {

                        });
                    }, i * 100)

                }
            }).catch(() => {
                displayError("CSV Read error", "There was an error while reading the file as a CSV!");
            });
        };
        fr.readAsText(file);
    };

    const getData = () => {
        let filters = [
            { name: "ReportStarts", op: "pdgeq", value: dateFilters?.start?.toDate().getTime() },
            { name: "ReportStarts", op: "pdleq", value: dateFilters?.end?.toDate().getTime() }
        ];

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/facebookExport/getFacebookExportStatistics`,
            data: {
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data)
        }).catch(() => {

        });
    }

    React.useEffect(() => {
        getData()
    }, [dateFilters])

    const orderData = (d, headers) => {
        if (!orders) return d;

        let headerIndex = headers.indexOf(orders.name);

        if (headerIndex === -1) return d;

        let asc = orders.order === "asc" ? 1 : -1;
        let desc = orders.order === "asc" ? -1 : 1;

        const getElementText = elem => {
            if (Array.isArray(elem?.props?.children)) {
                return getElementText(elem?.props?.children[elem.props.children.length - 1]);
            };

            return String(elem?.props?.children ?? elem);
        };

        return [...d].sort((a, b) => {
            let a1 = a[headerIndex];
            let b1 = b[headerIndex];

            if (!a1 || !b1) return 0;

            let a1Compare = a1?.text;
            if (a1?.text?.["$$typeof"]) a1Compare = getElementText(a1.text);
            let b1Compare = b1?.text;
            if (b1?.text?.["$$typeof"]) b1Compare = getElementText(b1.text);

            a1Compare = String(a1Compare);
            b1Compare = String(b1Compare);

            if (a1Compare.endsWith("%")) a1Compare = Number(a1Compare.split(" ")[0]);
            if (b1Compare.endsWith("%")) b1Compare = Number(b1Compare.split(" ")[0]);

            if (!isNaN(a1Compare) && !isNaN(b1Compare)) {
                a1Compare = Number(a1Compare);
                b1Compare = Number(b1Compare);
                return a1Compare > b1Compare ? asc : desc;
            } else {
                return a1Compare > b1Compare ? asc : desc;
            };
        });
    };














    // if (!allOffers || !allSites || !allCampaigns || !trackEvents) return <Spinner color={themeSelector === "dark" ? "white" : "black"} />
    // if (allOffers?.status !== "ok" || allSites?.status !== "ok" || allCampaigns?.status !== "ok") return <p>There was an error while fetching initial data!</p>
    return <div className="route__reports_facebookExport">
        <div className="route__reports_facebookExport__headers">
            <p>Prilozite csv file: <input type="file" accept="text/csv" onChange={e => importFromCSV(e.target.files[0] ?? null)} /></p>
            {completed && <p>Progres: {completed}</p>}
            {completedSuc !== null && completedSuc !== undefined ? <p>Uspjesno spremljeno: {completedSuc}</p> : <p></p>}
            <FilterByDate disable24h={true} disableAll={true} defaultValue="yesterday" style={{ width: "300px" }} onChange={e => setDateFilters(e)} />
        </div>
        <div className={`route__reports_breakdown__table ${data?.status === "ok" ? "route__reports_breakdown__table--ok" : ""}`}>
            {data ? (spinner ? <Spinner color={"black"} /> : (data?.status === "ok" && data.data[0] ? <FilteredCustomTable
                theme={"dark"}
                accent="#6C5DD3"
                style={{
                    width: "auto",
                    minWidth: "100%",
                    columnGap: "40px"
                }}
                orderCB={o => setOrders(o)}
                customColumns={(new Array(Object.keys(data.data[0]).length)).fill("max-content")}
                headers={[
                    "AdSetName",
                    "AdName",
                    "Gender",
                    "Age",
                    "AmountSpent",
                    "ResultType",
                    "CPR",
                    "CPC",
                    "Results",
                    "Reach",
                    "CTR",
                    "Impressions",
                    "UniqueOutboundClicks",
                    "OutboundClicks",
                ]}
                data={(() => {
                    let out = [];
                    let ID = 0;
                    for (let constraint of data.data) {
                        ID++;
                        let tmp = [];

                        for (let h of [
                            "AdSetName",
                            "AdName",
                            "Gender",
                            "Age",
                            "AmountSpent",
                            "ResultType",
                            "CPR",
                            "CPC",
                            "Results",
                            "Reach",
                            "CTR",
                            "Impressions",
                            "UniqueOutboundClicks",
                            "OutboundClicks",
                        ]) {
                            // parse each header as its own element
                            if (h === "Results" || h === "Impressions" || h === "UniqueOutboundClicks" || h === "OutboundClicks") {
                                tmp.push({ keyID: String(ID), type: "text", text: Number(constraint[h]).toFixed(0) ?? <span style={{ color: "gray" }}>(Not found)</span> });
                            } else if (h === "Age" || h === "CampaignID" || h === "AdSetName" || h === "AdName" || h === "ResultType") {
                                tmp.push({ keyID: String(ID), type: "text", text: constraint[h] ?? <span style={{ color: "gray" }}>(Not found)</span> });
                            } else if (h === "Gender") {
                                tmp.push({ keyID: String(ID), type: "text", text: constraint[h] ? "Male" : "Female" ?? <span style={{ color: "gray" }}>(Not found)</span> });
                            } else {
                                tmp.push({ keyID: String(ID), type: "text", text: Number(constraint[h]).toFixed(5) ?? <span style={{ color: "gray" }}>(Not found)</span> });
                            }


                        };

                        out.push(tmp);
                    };

                    if (out.length === 0) out.push([{ keyID: "noData-noData", type: "custom", data: "Nothing to show..." }]);
                    if (orders) out = orderData(out, [
                        "AdSetName",
                        "AdName",
                        "Gender",
                        "Age",
                        "AmountSpent",
                        "ResultType",
                        "CPR",
                        "CPC",
                        "Results",
                        "Reach",
                        "CTR",
                        "Impressions",
                        "UniqueOutboundClicks",
                        "OutboundClicks",
                    ]);
                    return out;
                })()}
            /> : <p>There was an error while preparing data!</p>)) : spinner ? <Spinner color={"black"} /> : <p>Click on "Apply Filters" to fetch new data.</p>}
        </div>




    </div>
};

export default FacebookExport;