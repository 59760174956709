import React from "react";
import "./index.scss";

import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { getTrackingProfileImage } from "../../../modules/miscModule";
import * as backendModule from "../../../modules/backendModule";
import * as siteFunctionsActions from "../../../actions/siteFunctionsActions";
import { animateBox } from "../../../modules/componentAnimation";

import { FilteredCustomTable } from "../../../components/customComponents/Table";
import ButtonWithDropdown from "../../../components/customComponents/ButtonWithDropdown";
import Spinner from "../../../components/customComponents/Spinner";
import AdvancedDropdown from "../../../components/customComponents/AdvancedDropdown";

import YesNoModal from "../../../components/modals/YesNoModal";

const TeamLeadUserGroups = () => {
    const [data, setData] = React.useState();
    const [selectedUsers, setSelectedUsers] = React.useState([]);

    const checkboxFunctionsRef = React.useRef();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const curDispatch = useDispatch();

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/userGroups/getAllGroups`,
            data: {
                extended: true
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    const editGroup = (e, id) => {
        if (!id) return;
        if (!data) return;
        if (data.status !== "ok") return;

        let curGroup = data.data.find(g => g.ID === id);

        animateBox(e, <AddGroup edit={curGroup} onChange={getData} />);
    };

    const removeGroups = (e, ids) => {
        if (ids.length === 0) return;

        animateBox(e, <YesNoModal
            heading="Are you sure?"
            text={["You are about to remove ", <span style={{ color: "#6C5DD3" }}>{ids.length} groups</span>, ", this action is irreversible!"]}
            isRightButtonNormal={true}
            buttonRightCallback={async arg => {
                arg.disabledAll(true);
                arg.spinner(true);
                let hadError = false;

                for (let item of ids) {
                    await axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/userGroups/removeGroup`,
                        data: {
                            ID: item
                        },
                        ...backendModule.axiosConfig
                    }).then(res => {
                        if (res.data.status !== "ok") hadError = true;
                    }).catch(() => {
                        hadError = true;
                    });
                };

                arg.disabledAll(false);
                arg.spinner(false);
                arg.close();
                getData();

                if (hadError) {
                    let tmp = document.querySelector(".route__teamLead__userGroups__btns__right__btn");
                    if (!tmp) return;

                    animateBox({ currentTarget: tmp }, <YesNoModal
                        heading="Error"
                        text="There were errors while removing some groups."
                        buttonLeftHidden={true}
                        isRightButtonNormal={true}
                        buttonRightText="Ok"
                    />);
                };
            }}
        />);
    };

    React.useEffect(() => {
        getData();

        let handler = () => {
            getData();
        };

        curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));
        return () => {
            curDispatch(siteFunctionsActions.removeHeaderRefreshAction(handler));
        };
    }, []);

    return <div className="route__teamLead__userGroups">
        <div className="route__teamLead__userGroups__btns">
            <div className="route__teamLead__userGroups__btns__left">
                <div className={`route__teamLead__userGroups__btns__left__selected ${selectedUsers.length > 0 ? "route__teamLead__userGroups__btns__left__selected--active" : ""}`}>
                    {`${selectedUsers.length} groups selected`}
                    <div
                        className="route__teamLead__userGroups__btns__left__selected__btn"
                        style={{ backgroundImage: `url("/images/icon_close.svg")` }}
                        onClick={() => checkboxFunctionsRef.current?.reset()}
                    ></div>
                </div>
            </div>

            <div className="route__teamLead__userGroups__btns__right">
                {selectedUsers.length > 0 && <ButtonWithDropdown
                    image="/images/icon_edit.svg"
                    value="More actions"
                    data={[
                        (selectedUsers.length === 1 && { name: "Edit", onClick: e => editGroup(e, selectedUsers[0]) }),
                        { name: `Remove ${selectedUsers.length} groups`, onClick: (e) => removeGroups(e, selectedUsers) }
                    ]}
                />}

                {selectedUsers.length === 0 && <div className="route__teamLead__userGroups__btns__right__btn" onClick={e => {
                    animateBox(e, <AddGroup onChange={() => getData()} />);
                }}>
                    <img src="/images/icon_close.svg" style={{ transform: "rotate(45deg)" }} />
                    <span>Add group</span>
                </div>}
            </div>
        </div>
        <div className="route__teamLead__userGroups__tableWrap">
            <FilteredCustomTable
                key="admin-usergroups-table"
                checkboxCB={(data?.status === "ok" && data?.data?.length > 0) ? setSelectedUsers : false}
                checkboxFunctions={cf => checkboxFunctionsRef.current = cf()}
                accent="#6C5DD3"
                theme={themeSelector}
                headers={["ID", "Name", "Users"]}
                data={(() => {
                    let out = [];

                    if (!data) {
                        out.push([{ keyID: "noData-spinner", type: "spinner", color: "white" }]);
                    } else {
                        if (data.status === "ok") {
                            for (let item of data.data) {
                                out.push([
                                    { keyID: String(item.ID), type: "text", text: item.ID },
                                    { keyID: String(item.ID), type: "text", text: item.GroupName },
                                    { keyID: String(item.ID), type: "text", text: item.Users.length },
                                    {
                                        keyID: String(item.ID), type: "groupNewline", group: [
                                            {
                                                keyID: String(item.ID), type: "custom", data: <div className="route__teamLead__userGroups__accounts">
                                                    <p>Shared accounts:</p>
                                                    {item.Accounts.map(i => <span>{i}</span>)}
                                                </div>
                                            }
                                        ]
                                    }
                                ]);
                            };
                        } else {
                            out.push([{ keyID: "noData-error", type: "text", text: "An error occured!" }]);
                        };
                    };

                    if (out.length === 0) {
                        out.push([{ keyID: "noData-noData", type: "text", text: "Nothing to show..." }]);
                    };
                    return out;
                })()}
            />
        </div>

    </div>
};

const GroupMembers = props => {
    const [selectedUsers, setSelectedUsers] = React.useState(props.selectedUsers ?? []);
    const [curUser, setCurUser] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const curUserInfoSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});

    let curResetRef = React.useRef();

    const allUsers = props.allUsers ?? [];

    const onClose = props.onClose;

    return <div className="route__teamLead__userGroups__groupMembers">
        <div className="route__teamLead__userGroups__groupMembers__wrap">
            <div className="route__teamLead__userGroups__add__wrap__head">
                <div className="route__teamLead__userGroups__add__wrap__head__left">Group members</div>
                <div className="route__teamLead__userGroups__add__wrap__head__right" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={onClose}></div>
            </div>

            <div className="route__teamLead__userGroups__groupMembers__wrap__drop">
                <AdvancedDropdown
                    data={allUsers.filter(au => {
                        if (selectedUsers.includes(au.ID)) return false;
                        if (au.ID === curUserInfoSelector?.ID) return false;

                        return true;
                    }).map(usr => {
                        return { name: usr.Username, value: usr.ID, key: usr.ID }
                    })}
                    placeholder="Select a user..."
                    headline="User"
                    style={{
                        backgroundColor: themeSelector === "dark" ? "#373A43" : "#fff"
                    }}
                    showSearch={true}
                    onChange={e => setCurUser(e?.value)}
                    functionsCB={r => curResetRef.current = r}
                />
                <div className="route__teamLead__userGroups__groupMembers__wrap__drop__button" onClick={() => {
                    if (!curUser) return;
                    setSelectedUsers(su => [...su, curUser]);
                    setCurUser();
                    try {
                        curResetRef.current?.reset?.();
                    } catch { };
                }}>Add</div>
            </div>

            <FilteredCustomTable
                accent="#6C5DD3"
                theme={themeSelector}
                headers={["Username", "Email", ""]}
                data={(() => {
                    let out = [];

                    for (let item of selectedUsers) {
                        let foundUser = allUsers.find(au => au.ID === item);

                        out.push([
                            { keyID: String(foundUser.ID ?? item), type: "text", text: foundUser?.Username ?? "?" },
                            { keyID: String(foundUser.ID ?? item), type: "text", text: foundUser?.Email ?? "?" },
                            {
                                keyID: String(foundUser.ID ?? item), type: "button", text: "Remove", onClick: e => {
                                    setSelectedUsers(s => s.filter(si => si !== item))
                                }
                            }
                        ])
                    };

                    if (out.length === 0) out.push([{ keyID: "noData-usr", type: "text", text: "Nothing to show..." }]);
                    return out;
                })()}
            />

            <div className="route__teamLead__userGroups__add__wrap__buttons" style={{ marginTop: "20px" }}>
                <div className="route__teamLead__userGroups__add__wrap__buttons__btn route__teamLead__userGroups__add__wrap__buttons__btn--secondary" onClick={onClose}>Cancel</div>
                <div className="route__teamLead__userGroups__add__wrap__buttons__btn" onClick={() => {
                    if (props.onChange) props.onChange(selectedUsers);
                    onClose();
                }}>Save</div>
            </div>
        </div>
    </div>
};

const SharedAccounts = props => {
    const [selectedProfiles, setSelectedProfiles] = React.useState(props.selectedProfiles ?? []);
    const [curProfile, setCurProfile] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const trackingProfilesSelector = useSelector(state => state?.trackingProfiles?.profiles ?? []);

    let curResetRef = React.useRef();

    const onClose = props.onClose;

    return <div className="route__teamLead__userGroups__groupMembers">
        <div className="route__teamLead__userGroups__groupMembers__wrap" style={{ width: "800px" }}>
            <div className="route__teamLead__userGroups__add__wrap__head">
                <div className="route__teamLead__userGroups__add__wrap__head__left">Shared accounts</div>
                <div className="route__teamLead__userGroups__add__wrap__head__right" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={onClose}></div>
            </div>

            <div className="route__teamLead__userGroups__groupMembers__wrap__drop">
                <AdvancedDropdown
                    data={trackingProfilesSelector.filter(au => !selectedProfiles.includes(au.ID)).map(profile => {
                        return { name: profile.name, value: profile.ID, key: profile.ID, image: getTrackingProfileImage(profile.type) }
                    })}
                    placeholder="Select an account..."
                    headline="Account"
                    style={{
                        backgroundColor: themeSelector === "dark" ? "#373A43" : "#fff"
                    }}
                    showSearch={true}
                    onChange={e => setCurProfile(e?.value)}
                    functionsCB={r => curResetRef.current = r}
                />
                <div className="route__teamLead__userGroups__groupMembers__wrap__drop__button" onClick={() => {
                    if (!curProfile) return;
                    setSelectedProfiles(su => [...su, curProfile]);
                    setCurProfile();
                    try {
                        curResetRef.current?.reset?.();
                    } catch { };
                }}>Add</div>
            </div>

            <FilteredCustomTable
                accent="#6C5DD3"
                theme={themeSelector}
                headers={["ID", "Name", ""]}
                customColumns={["auto", "auto", "100px"]}
                data={(() => {
                    let out = [];

                    for (let item of selectedProfiles) {
                        let foundProfile = trackingProfilesSelector.find(au => au.ID === item);

                        out.push([
                            { keyID: String(foundProfile?.ID ?? item), type: "text", text: foundProfile?.ID ?? "?" },
                            {
                                keyID: String(foundProfile?.ID ?? item), type: "custom", data: <div className="route__teamLead__userGroups__groupMembers__wrap__tableImg">
                                    {<img src={getTrackingProfileImage(foundProfile?.type)} />}
                                    <span>{foundProfile?.name ?? "?"}</span>
                                </div>
                            },
                            {
                                keyID: String(foundProfile?.ID ?? item), type: "button", text: "Remove", onClick: e => {
                                    setSelectedProfiles(s => s.filter(si => si !== item))
                                }
                            }
                        ])
                    };

                    if (out.length === 0) out.push([{ keyID: "noData-usr", type: "text", text: "Nothing to show..." }]);
                    return out;
                })()}
            />

            <div className="route__teamLead__userGroups__add__wrap__buttons" style={{ marginTop: "20px" }}>
                <div className="route__teamLead__userGroups__add__wrap__buttons__btn route__teamLead__userGroups__add__wrap__buttons__btn--secondary" onClick={onClose}>Cancel</div>
                <div className="route__teamLead__userGroups__add__wrap__buttons__btn" onClick={() => {
                    if (props.onChange) props.onChange(selectedProfiles);
                    onClose();
                }}>Save</div>
            </div>
        </div>
    </div>
};

const AddGroup = props => {
    const [allUsers, setAllUsers] = React.useState();
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState({
        hadError: false,
        error: "",
        inputs: []
    });
    const [selectedUsers, setSelectedUsers] = React.useState(props.edit?.Users ?? []);
    const [selectedProfiles, setSelectedProfiles] = React.useState(props.edit?.IntegrationIDs ?? []);

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const groupNameRef = React.useRef();
    const groupDescRef = React.useRef();

    const onClose = (force = false) => {
        if (spinner && !force) return;

        return props.onClose();
    };

    const getAllUsers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsersTeamLead`,
            ...backendModule.axiosConfig
        }).then(users => {
            setAllUsers(users.data);
        }).catch(() => {
            setAllUsers(backendModule.genericError);
        });
    };

    const addGroup = () => {
        setInfoP(i => { return { ...i, hadError: false, inputs: [] } })

        let data = {
            Name: groupNameRef.current.value,
            Description: groupDescRef.current.value,
            Users: selectedUsers ?? [],
            IntegrationIDs: selectedProfiles
        };

        if (!data.Name) return setInfoP(i => { return { ...i, hadError: true, inputs: ["name"], error: "Name can't be empty" } });
        if (!data.IntegrationIDs) return setInfoP(i => { return { ...i, hadError: true, inputs: ["profile"], error: "You must select a profile" } });

        if (props.edit) {
            data["ID"] = props.edit.ID;
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/userGroups/${props.edit ? "editGroup" : "addGroup"}`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (props.onChange) props.onChange();
                props.onClose();
            } else {
                return setInfoP(i => { return { ...i, hadError: true, inputs: [], error: `Error while ${props.edit ? "editing" : "adding"} a group.` } });
            };
        }).catch(() => {
            return setInfoP(i => { return { ...i, hadError: true, inputs: [], error: "Server timed out." } });
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        getAllUsers();
    }, []);

    return <div className="route__teamLead__userGroups__add">
        <div className="route__teamLead__userGroups__add__wrap">
            <div className={`route__teamLead__userGroups__add__wrap__spinner ${!allUsers ? "route__teamLead__userGroups__add__wrap__spinner--active" : ""}`}>
                <Spinner style={{ width: "64px", height: "64px" }} color={themeSelector === "dark" ? "white" : "black"} />
            </div>

            {allUsers?.status !== "error" ? <>
                <div className="route__teamLead__userGroups__add__wrap__head">
                    <div className="route__teamLead__userGroups__add__wrap__head__left">{props.edit ? "Edit" : "Add"} group</div>
                    <div className="route__teamLead__userGroups__add__wrap__head__right" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={onClose}></div>
                </div>

                <div className={`route__teamLead__userGroups__add__wrap__input ${infoP.inputs.includes("name") ? "route__teamLead__userGroups__add__wrap__input--error" : ""}`}>
                    <p>Name</p>
                    <input ref={groupNameRef} type="text" placeholder="Name" defaultValue={props.edit?.GroupName} />
                </div>
                <div className={`route__teamLead__userGroups__add__wrap__input ${infoP.inputs.includes("description") ? "route__teamLead__userGroups__add__wrap__input--error" : ""}`}>
                    <p>Description</p>
                    <textarea ref={groupDescRef} placeholder="Description" defaultValue={props.edit?.GroupDescription} />
                </div>

                <div className="route__teamLead__userGroups__add__wrap__users">
                    <p>Shared accounts ({selectedProfiles.length})</p>

                    <div className="route__teamLead__userGroups__add__wrap__users__btn" onClick={e => {
                        animateBox(e, <SharedAccounts selectedProfiles={selectedProfiles} onChange={setSelectedProfiles} />)
                    }}>View</div>
                </div>

                <div className="route__teamLead__userGroups__add__wrap__users">
                    <p>Members ({selectedUsers.length})</p>

                    <div className="route__teamLead__userGroups__add__wrap__users__btn" onClick={e => {
                        animateBox(e, <GroupMembers allUsers={allUsers?.data ?? []} selectedUsers={selectedUsers} onChange={setSelectedUsers} />)
                    }}>View</div>
                </div>

                <div className="route__teamLead__userGroups__add__wrap__buttons">
                    <div className="route__teamLead__userGroups__add__wrap__buttons__btn route__teamLead__userGroups__add__wrap__buttons__btn--secondary" onClick={onClose}>Cancel</div>
                    <div className="route__teamLead__userGroups__add__wrap__buttons__btn" onClick={addGroup}>
                        {spinner ? <Spinner style={{ width: "24px", height: "24px" }} color={themeSelector === "dark" ? "white" : "black"} /> : "Save"}
                    </div>
                </div>

                <p className="route__admin__users__add__wrap__infoP" style={{
                    opacity: infoP.hadError ? 1 : 0
                }}>{infoP.error}</p>
            </> : <>
                <div className="route__teamLead__userGroups__add__wrap__head">
                    <div className="route__teamLead__userGroups__add__wrap__head__left">Error while fetching users</div>
                </div>

                <div className="route__teamLead__userGroups__add__wrap__buttons">
                    <div className="route__teamLead__userGroups__add__wrap__buttons__btn route__teamLead__userGroups__add__wrap__buttons__btn--secondary" onClick={onClose}>Close</div>
                </div>
            </>}
        </div>
    </div>
};

export default TeamLeadUserGroups;