import React from "react";

import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";

import * as backendModule from "../../../modules/backendModule";
import * as basicStylesModule from "../../../modules/basicStylesModule";
import useOnScreen from "../../../modules/hooks/useOnScreen";
import { animateBox } from "../../../modules/componentAnimation";
import { countries } from "../../../modules/countryModule";

import { FilteredCustomTable } from "../../../components/customComponents/Table";
import AdvancedDropdown from "../../../components/customComponents/AdvancedDropdown";
import StyledInput from "../../../components/styledComponents/Input";
import StyledButton from "../../../components/styledComponents/Button";

import Spinner from "../../../components/customComponents/Spinner";

const UserCreatives_AS = () => {
    const [data, setData] = React.useState();
    const [historyID, setHistoryID] = React.useState();
    const [spinner, setSpinner] = React.useState(false);

    const [searches, setSearches] = React.useState([null]);
    const [selectedCountry, setSelectedCountry] = React.useState(null);
    const [selectedType, setSelectedType] = React.useState(null);

    const seenBetweenStartRef = React.useRef();
    const seenBetweenEndRef = React.useRef();

    const ageStartRef = React.useRef();
    const ageEndRef = React.useRef();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const curUserSelector = useSelector(state => state?.userData?.userData ?? {});

    const getData = (newPage = false, ID = null) => {
        if (spinner) return;
        let searchData = {}

        if (ID) {
            setHistoryID(ID);
            searchData["ID"] = ID;
        } else {
            let finalSearches = searches.filter(s => s);
            if (finalSearches.length > 0) searchData["searches"] = finalSearches;
    
            if (selectedCountry) searchData["countries"] = [selectedCountry];
    
            if (seenBetweenStartRef.current.value && seenBetweenEndRef.current.value) {
                let startDate = moment(seenBetweenStartRef.current.value, "YYYY-MM-DD");
                let endDate = moment(seenBetweenEndRef.current.value, "YYYY-MM-DD");
    
                if (startDate.isValid() && endDate.isValid()) {
                    if (startDate.isAfter(endDate)) [startDate, endDate] = [endDate, startDate];
    
                    searchData["seenBetween"] = [startDate.add(5, "hours").toDate().getTime(), endDate.add(5, "hours").toDate().getTime()];
                };
            };
    
            if (selectedType) searchData["mediaType"] = selectedType;;
    
            
            if (ageStartRef.current.value !== "" && ageEndRef.current.value !== "") {
                let ageStart = Number(ageStartRef.current.value);
                let ageEnd = Number(ageEndRef.current.value);
                if (!isNaN(ageStart) && !isNaN(ageEnd)) {
                    if (ageStart > ageEnd) [ageStart, ageEnd] = [ageEnd, ageStart];
                    searchData["ages"] = [ageStart, ageEnd];
                };
            } else if (ageStartRef.current.value !== "") {
                let ageStart = Number(ageStartRef.current.value);
                if (!isNaN(ageStart)) searchData["ages"] = [ageStart];
            };
    
            if (newPage) {
                if (!data) return;
                if (data.status !== "ok") return;
                let curPage = data.data.length / 10;
                curPage += 1;
                searchData["page"] = curPage;
            };
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/globalIntegrations/as/getAds`,
            data: {data: searchData},
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "error") return setData(backendModule.genericError);

            if (newPage) {
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
            } else {
                return setData(res.data);
            };
        }).catch(() => {
            return setData(backendModule.genericError);
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="route__user__creatives__as">
        <div className="route__user__creatives__as__spinner" style={{
            opacity: spinner ? 1 : 0,
            pointerEvents: spinner ? "all" : "none"
        }}>
            <Spinner color={themeSelector === "dark" ? "white" : "black"} />
        </div>
        <div className="route__user__creatives__as__filters">

            {!historyID && searches.map((s, sIdx) => <div className="route__user__creatives__as__filters__item" key={`ad-search-${sIdx}`}>
                <AdvancedDropdown
                    headline="Search type"
                    data={[
                        {key: "ad_text", name: "Ad text", value: "texts"},
                        {key: "comments", name: "Comments", value: "comments"},
                        {key: "advertisers", name: "Advertiser name", value: "advertisers"},
                        {key: "urls", name: "URL", value: "urls"},
                        {key: "lp_urls", name: "Landing page URL", value: "lp_urls"},
                        {key: "page_text", name: "Landing page text", value: "page_text"}
                    ]}
                    selected={(()=>{
                        if (!s) return 0;
                        if (!s.type) return 0;
                        let types = ["ad_text", "comments", "advertisers", "urls", "lp_urls", "page_text"];
                        return types.indexOf(s.type);
                    })()}
                    onChange={e => {
                        if (e?.value === s?.type) return;
                        setSearches(ss => ss.map((ssm, ssmIdx) => {
                            if (ssmIdx !== sIdx) return ssm;
                            let tmp = {...(ssm ?? {}), type: e?.value}
                            return tmp;
                        }));
                    }}
                />
                <StyledInput alternateStyle={true} placeholder="Search..." value={s?.value ?? ""} onChange={e => {
                    let val = e?.target?.value ?? "";
                    setSearches(ss => {
                        return [
                            ...ss.filter((_, ssIdx) => ssIdx < sIdx),
                            {...(s ?? {}), value: val},
                            ...ss.filter((_, ssIdx) => ssIdx > sIdx)
                        ];
                    });
                }} />
                {sIdx === searches.length - 1 && <StyledButton onClick={e => setSearches(ss => [...ss, null])}>Add search</StyledButton>}
                {sIdx !== searches.length - 1 && <StyledButton style={{background: "#D35D5D"}} onClick={() => setSearches(ss => ss.filter((_, ssIdx) => ssIdx !== sIdx))} >Remove search</StyledButton>}
            </div>)}

            {!historyID && <div className="route__user__creatives__as__filters__item" style={{gridTemplateColumns: "1fr", gridTemplateRows: "max-content"}}>
                <AdvancedDropdown
                    headline="Country"
                    showSearch={true}
                    data={[
                        {key: "all-countries", name: "All countries", value: null},
                        ...countries.map(c => {
                            return {
                                key: c.code,
                                name: c.name,
                                value: c.code
                            };
                        })
                    ]}
                    selected={(()=>{
                        if (!selectedCountry) return 0;
                        return countries.indexOf(countries.find(c => c.code === selectedCountry)) + 1;
                    })()}
                    onChange={e => selectedCountry !== e?.value && setSelectedCountry(e?.value)}
                />
            </div>}

            {!historyID && <div className="route__user__creatives__as__filters__item" style={{gridTemplateColumns: "1fr", gridTemplateRows: "max-content"}}>
                <AdvancedDropdown
                    headline="Type"
                    data={[
                        {key: "any", name: "Any", value: null},
                        {key: "image", name: "Images", value: "photo"},
                        {key: "video", name: "Videos", value: "video"}
                    ]}
                    selected={(()=>{
                        if (!selectedType) return 0;
                        if (selectedType === "photo") return 1;
                        if (selectedType === "video") return 2;
                    })()}
                    onChange={e => e?.value !== selectedType && setSelectedType(e?.value)}
                />
            </div>}

            {!historyID && <div className="route__user__creatives__as__filters__item" style={{gridTemplateColumns: "repeat(3, max-content)"}}>
                <p style={{display: "flex", alignItems: "center"}}>Seen between</p>
                <StyledInput ref={seenBetweenStartRef} alternateStyle={true} type="date" />
                <StyledInput ref={seenBetweenEndRef} alternateStyle={true} type="date" />
            </div>}

            {!historyID && <div className="route__user__creatives__as__filters__item" style={{gridTemplateColumns: "repeat(3, max-content)"}}>
                <p style={{display: "flex", alignItems: "center"}}>Age</p>
                <StyledInput ref={ageStartRef} defaultValue="" alternateStyle={true} type="text" />
                <StyledInput ref={ageEndRef} defaultValue="" alternateStyle={true} type="text" />
            </div>}

            {historyID && <div className="route__user__creatives__as__filters__history">
                <p>Viewing history {historyID}</p>
            </div>}

            <div className="route__user__creatives__as__filters__item" style={{gridTemplateRows: "50px", gridTemplateColumns: "max-content max-content"}}>
                {!historyID && <StyledButton onClick={() => getData(false)}>Search ads</StyledButton>}
                {historyID && <StyledButton onClick={() => {
                    setData();
                    setHistoryID();
                }}>Reset</StyledButton>}
                {curUserSelector?.UserInfo?.Flags?.isAdmin && <StyledButton onClick={() => animateBox(<UserCreatives_AS_viewHistory onChange={e => {
                    getData(false, e);
                }} />)}>View history</StyledButton>}
            </div>

        </div>

        {data && <>
            {data.status === "ok" ? <div className="route__user__creatives__as__content">
                {data.data.map(item => {
                    return <div className="route__user__creatives__as__content__item">
                        <div className="route__user__creatives__as__content__item__head">
                            <div className="route__user__creatives__as__content__item__head__left">
                                <img src={item.isIg ? "/images/instagram_temp.webp" : "/images/integrations/integration_facebook.svg"} />
                                <img src={item.actor?.profilePicture} />
                            </div>
                            <div className="route__user__creatives__as__content__item__head__right">
                                <p>{item?.actor?.username}</p>
                                <p className="route__user__creatives__as__content__item__head__right__date">{item?.createdOn}</p>
                            </div>
                            <div className="route__user__creatives__as__content__item__head__btn" onClick={() => {
                                window.open(`https://facebook.com/${item?.actor?.userId}/posts/${item.id}`, "_blank");
                            }}>
                                {/* <img src="/images/icon_search.svg" /> */}
                                <img src="/images/integrations/integration_header_api.svg" />
                            </div>
                        </div>

                        {item?.mainAttachment?.attachmentTitle && <h3 className="route__user__creatives__as__content__item__title">{item.mainAttachment.attachmentTitle}</h3>}
                        <div className="route__user__creatives__as__content__item__text">{item.text}</div>

                        {item?.mainAttachment && <div className="route__user__creatives__as__content__item__content">
                            {String(item.mainAttachment?.type).toLowerCase() === "photo" && <img src={item.mainAttachment?.imageUrl} />}
                            {String(item.mainAttachment?.type).toLowerCase() === "video" && <video controls src={item.mainAttachment?.videoUrl} />}
                        </div>}

                        <div className="route__user__creatives__as__content__item__reactions">
                            <div className="route__user__creatives__as__content__item__reactions__item">
                                <img src="/images/integrations/facebook_assets/like.svg" />
                                <span>{item?.snapshot?.likeNum ?? 0}</span>
                            </div>
                            <div className="route__user__creatives__as__content__item__reactions__item">
                                <img src="/images/integrations/facebook_assets/love.svg" />
                                <span>{item?.snapshot?.loveNum ?? 0}</span>
                            </div>
                            <div className="route__user__creatives__as__content__item__reactions__item">
                                <img src="/images/integrations/facebook_assets/care.svg" />
                                <span>{item?.snapshot?.careNum ?? 0}</span>
                            </div>
                            <div className="route__user__creatives__as__content__item__reactions__item">
                                <img src="/images/integrations/facebook_assets/haha.svg" />
                                <span>{item?.snapshot?.hahaNum ?? 0}</span>
                            </div>
                            <div className="route__user__creatives__as__content__item__reactions__item">
                                <img src="/images/integrations/facebook_assets/wow.svg" />
                                <span>{item?.snapshot?.wowNum ?? 0}</span>
                            </div>
                            <div className="route__user__creatives__as__content__item__reactions__item">
                                <img src="/images/integrations/facebook_assets/sad.svg" />
                                <span>{item?.snapshot?.sadNum ?? 0}</span>
                            </div>
                            <div className="route__user__creatives__as__content__item__reactions__item">
                                <img src="/images/integrations/facebook_assets/angry.svg" />
                                <span>{item?.snapshot?.angryNum ?? 0}</span>
                            </div>
                        </div>
                        <div className="route__user__creatives__as__content__item__reactions">
                            <div className="route__user__creatives__as__content__item__reactions__item">
                                <img src="/images/integrations/facebook_assets/comment.svg" />
                                <span>{item.snapshot?.commentsNum ?? 0}</span>
                            </div>
                            <div className="route__user__creatives__as__content__item__reactions__item">
                                <img src="/images/integrations/integration_link.svg" />
                                <span>{item.snapshot?.shareNum ?? 0}</span>
                            </div>
                        </div>

                        <div className="route__user__creatives__as__content__item__countries">
                            {item.countries.length > 5 ? `${item.countries.length} countries` : item.countries.join(", ")}
                        </div>
                    </div>
                })}
                {data.data.length === 0 && <p style={{gridRow: "1 / span all"}}>Nothing to show...</p>}
                {(data?.status === "ok" && data.data.length % 10 === 0 && data.data.length > 0 && !historyID) && <StyledButton style={{minHeight: "100px"}} onClick={() => getData(true)}>Load more</StyledButton>}
            </div> : <p style={{color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}>There was an error while fetching data</p>}
        </>}
    </div>
};

const UserCreatives_AS_viewHistory = props => {
    const [data, setData] = React.useState();
    const [canPaginate, setCanpaginate] = React.useState(false);
    const [allUsers, setAllUsers] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const curOnScreen = useOnScreen();
    const wrapRef = React.useRef();
    const timestampRef = React.useRef();

    const onClose = () => {
        if (wrapRef.current) {
            wrapRef.current.animate([
                { right: getComputedStyle(wrapRef.current).right },
                { right: "-100%" }
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };

        props.onClose();
    };

    const getData = ts => {
        if (timestampRef.current !== ts) return;

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/globalIntegrations/as/getSearchHistory`,
            data: {
                limit: 20,
                offset: 0
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length >= 20) setCanpaginate(true);
            };
            setData(res.data);
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        });
    };

    const continueData = ts => {
        if (!data) return;
        if (data.status !== "ok") return;
        if (!canPaginate) return;
        if (timestampRef.current !== ts) return;

        setCanpaginate(false);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/globalIntegrations/as/getSearchHistory`,
            data: {
                limit: 20,
                offset: 0,
                filters: [
                    {name: "ID", op: "notIn", value: data.data.map(d => d.ID)}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
                if (res.data.data.length >= 20) setCanpaginate(true);
            };
        }).catch(() => null);
    };

    const getAllUsers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            ...backendModule.axiosConfig
        }).then(users => {
            setAllUsers(users.data);
        }).catch(() => {
            setAllUsers(backendModule.genericError);
        });
    };

    const parseRequestData = req => {
        let out = [];
        if (!req) return out;
        if (typeof(req) !== "object") return out;

        const tryStringify = (key, str) => {
            if (!str) return str;
            if (typeof(str) !== "object") return str;
            if (key === "searches" && str?.type) return `[${str.type}] -> ${str.value}`;

            try {
                return JSON.stringify(str);
            } catch {};
            return "-";
        };

        for (let key of Object.keys(req)) {
            if (Array.isArray(req[key])) {
                out.push(<p>
                    <span>{key}</span>
                    {(req[key].map(k => <span>{tryStringify(key, k)}</span>))}
                </p>)
            } else {
                out.push(<p>
                    <span>{key}</span>
                    <span>{req[key]}</span>
                </p>)
            };
        };

        return <p className="route__user__creatives__as__viewHistory__pills">{out}</p>;
    };

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        setCanpaginate(false);
        let ts = Date.now();
        timestampRef.current = ts;
        continueData(ts);
    }, [canPaginate, curOnScreen.isIntersecting]);

    React.useEffect(() => {
        if (!wrapRef.current) return;

        wrapRef.current.animate([
            { right: getComputedStyle(wrapRef.current).right },
            { right: 0 }
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);

    React.useEffect(() => {
        let ts = Date.now();
        timestampRef.current = ts;
        getData(ts);
    }, []);

    React.useEffect(() => {
        getAllUsers();
    }, []);

    return <div className="route__user__creatives__as__viewHistory" onClick={() => onClose()}>
        <div className="route__user__creatives__as__viewHistory__wrap" ref={wrapRef} onClick={e => e?.stopPropagation()}>

            <div className="route__user__creatives__as__viewHistory__wrap__top">
                <div className="route__user__creatives__as__viewHistory__wrap__top__left">Search history</div>
                <div className="route__user__creatives__as__viewHistory__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>

            <div className="route__user__creatives__as__viewHistory__wrap__content">
                <FilteredCustomTable
                    accent="#6C5DD3"
                    theme={themeSelector}
                    headers={["No.", "History ID", "Username", "Date", ""]}
                    customColumns={["50px", "auto", "auto", "auto", "150px"]}
                    style={{
                        width: "100%",
                        columnGap: "40px"
                    }}
                    filters={[
                        {name: "ID", friendlyName: "History ID", type: "string"},
                        (allUsers?.status === "ok" ? {name: "CreatedBy", friendlyName: "Username", type: "custom", varType: "string", data: allUsers.data.map(u => {
                            return {text: u.Username, value: u.ID}
                        })} : null),
                        {name: "createdAt", friendlyName: "Date", type: "date"}
                    ].filter(t => t)}
                    data={(()=>{
                        if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                        if (data.status === "error") return [[{keyID: "noData-error", type: "custom", data: "There was an error while fetching data", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]];

                        let out = [];
                        let num = 0;
                        for (let item of data.data) {
                            num++;
                            out.push([
                                {keyID: item.ID, type: "text", text: num},
                                {keyID: item.ID, type: "text", text: item.ID},
                                {keyID: item.ID, type: "text", text: item._User?.Username},
                                {keyID: item.ID, type: "text", text: moment(item.createdAt).toDate().toLocaleString()},
                                {keyID: item.ID, type: "button", text: "View", onClick: () => {
                                    props.onChange(item.ID);
                                    onClose();
                                }},
                                {keyID: item.ID, type: "groupNewline", group: [
                                    {keyID: item.ID, type: "custom", data: parseRequestData(item.Request)}
                                ]}
                            ]);
                        };

                        if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);
                        return out;
                    })()}
                />
                {canPaginate && <div style={{width: "100%", height: "1px", opacity: 0}} ref={curOnScreen.measureRef}></div>}
            </div>
        </div>
    </div>
}

export default UserCreatives_AS;