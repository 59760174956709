import React from "react";
import "./index.scss";

import axios, { HttpStatusCode } from "axios";
import { useSelector } from "react-redux";

import * as backendModule from "../../../modules/backendModule";
import * as basicStylesModule from "../../../modules/basicStylesModule";
import { animateBox } from "../../../modules/componentAnimation";

import FilterBySearch from "../../../components/filters/FilterBySearch";
import { FilteredCustomTable } from "../../../components/customComponents/Table";
import AdvancedDropdown from "../../../components/customComponents/AdvancedDropdown";
import StyledButton from "../../../components/styledComponents/Button";
import Spinner from "../../../components/customComponents/Spinner";
import Dropdown from "../../../components/customComponents/Dropdown";

import YesNoModal from "../../../components/modals/YesNoModal";

const AdminDataStore = () => {
    const [data, setData] = React.useState();
    const [selectedStore, setSelectedStore] = React.useState();

    const dropdownFunctionsRef = React.useRef();

    const getAllStores = () => {
        setData();
        setSelectedStore();
        if (dropdownFunctionsRef.current?.reset) dropdownFunctionsRef.current.reset?.();
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/dataStore/getAll`,
            data: {
                limit: null
            },
            ...backendModule.axiosConfig
        }).then(res => setData(res.data)).catch(() => backendModule.genericError);
    };

    const removeStore = (ID) => {
        if (!data) return;
        if (data.status !== "ok") return;

        let curDs = data.data.find(d => d.ID === ID);
        if (!curDs) return;
        animateBox(<YesNoModal
            heading="Are you sure?"
            text={[`You are about to remove the datastore `, <span style={{color: "#6a5bcf"}}>{curDs.Name}</span>, <br />, "All data will also be removed", <br />, "Are You sure?"]}
            buttonLeftText="No"
            buttonRightText="Yes"
            isRightButtonNormal={true}
            buttonRightCallback={async args => {
                args.disabledAll(true);
                args.spinner(true);

                await axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/dataStore/remove`,
                    data: {
                        ID
                    },
                    ...backendModule.axiosConfig
                }).catch(() => null);

                args.disabledAll(false);
                args.spinner(false);
                args.close();

                getAllStores();
            }}
        />)
    };

    React.useEffect(() => {
        getAllStores();
    }, []);

    return <div className="route__admin__dataStore">
        <div className={`route__admin__dataStore__head`}>
            <AdvancedDropdown
                headline="Select data store"
                data={data ? (data?.status === "ok" ? data.data.map(d => {
                    return {key: d.ID, name: d.Name, value: d.ID}
                }) : []) : undefined}
                onChange={e => selectedStore !== e?.value && setSelectedStore(e?.value)}
                selected={(()=>{
                    if (!data) return null;
                    if (data.status !== "ok") return null;

                    return data.data.indexOf(data.data.find(d => d.ID === selectedStore));
                })()}
                functionsCB={cb => dropdownFunctionsRef.current = cb}
            />
            {/* {selectedStore && <StyledButton isSecondary={true} style={{height: "100%"}} onClick={() => removeStore(selectedStore)}>Remove store</StyledButton>} */}
            <StyledButton style={{height: "100%"}} onClick={() => {
                animateBox(<AdminDataStore_addStore onChange={() => getAllStores()} />)
            }}>Add store</StyledButton>
        </div>

        {selectedStore && <AdminDataStore_dataTable ID={selectedStore} key={selectedStore} />}
    </div>
};

const AdminDataStore_dataTable = props => {
    const [data, setData] = React.useState();
    const [search, setSearch] = React.useState();
    const [orders, setOrders] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const removeRow = (RowID) => {
        animateBox(<YesNoModal
            heading="Are you sure?"
            text="Removal of the row is irreversible!"
            buttonLeftText="No"
            buttonRightText="Yes"
            isRightButtonNormal={true}
            buttonRightCallback={async args => {
                args.disabledAll(true);
                args.spinner(true);

                await axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/dataStore/removeRow`,
                    data: {
                        ID: props.ID,
                        RowID
                    },
                    ...backendModule.axiosConfig
                }).then(() => null).catch(() => null);

                args.disabledAll(false);
                args.spinner(false);
                args.close();
                getData();
            }}
        />);
    };

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/dataStore/getByID`,
            data: {
                ID: props.ID
            },
            ...backendModule.axiosConfig
        }).then(res => setData(res.data)).catch(() => setData(backendModule.genericError));
    };

    const prepareButtons = (RowID) => {
        return <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
            <div className="route__admin__dataStore__btn" style={{backgroundImage: `url("/images/icon_editColumn.svg")`}} onClick={() => {
                animateBox(<AdminDataStore_addRow ID={props.ID} headers={data.data.Headers} onChange={() => getData()} rowID={RowID} columns={data.data.Rows[RowID]} />);
            }}></div>
            <div className="route__admin__dataStore__btn" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={() => removeRow(RowID)}></div>
        </div>
    };

    const checkSearch = (columns) => {
        if (!search) return true;

        let searchSplit = search.split(" ").map(s => s.trim().toLowerCase()).filter(f => f);
        let found = false;
        for (let key of Object.keys(columns)) {
            for (let s of searchSplit) {
                if (String(columns[key]).toLowerCase().includes(s)) {
                    found = true;
                    break;
                };
            };
            if (found) break;
        };

        return found;
    };

    const performSort = (data, headers) => {
        let finalOrd = orders;

        if (!finalOrd) return data;
        if (!finalOrd?.name || !finalOrd?.order) return data;
        if (finalOrd?.name === "Date" || finalOrd?.name === "Offer") return data;

        
        let curHeaderIndex = headers.indexOf(finalOrd.name);
        if (curHeaderIndex < 0) return data;

        const checkExists = item => item !== null && item !== undefined;
        const removeExtra = item => {
            return item;
        };
        return data.sort((a, b) => {
            if (
                (
                    !checkExists(a[curHeaderIndex]?.text) ||
                    !checkExists(b[curHeaderIndex]?.text)
                ) &&
                (
                    !checkExists(a[curHeaderIndex]?.group?.[0]?.text) ||
                    !checkExists(b[curHeaderIndex]?.group?.[0]?.text)
                )
            ) return -1;

            let a1 = null;
            let b1 = null;

            if (Array.isArray(a[curHeaderIndex]?.group)) {
                a1 = removeExtra(a[curHeaderIndex]?.group?.[0]?.text);
            } else {
                a1 = removeExtra(a[curHeaderIndex].text);
            };
            if (Array.isArray(b[curHeaderIndex]?.group)) {
                b1 = removeExtra(b[curHeaderIndex]?.group?.[0]?.text);
            } else {
                b1 = removeExtra(b[curHeaderIndex].text);
            };

            if (!isNaN(Number(a1)) && !isNaN(Number(b1))) {
                if (finalOrd.order === "asc") {
                    if (+a1 > +b1) return 1; else return -1;
                } else {
                    if (+b1 > +a1) return 1; else return -1;
                };
            } else {
                if (finalOrd.order === "asc") {
                    return (a1 > b1) ? 1 : -1;
                } else {
                    return (b1 > a1) ? 1 : -1;
                };
            };
        });
    };

    React.useEffect(() => {
        getData();
    }, []);

    if (!data) return <Spinner style={{width: "32px", height: "32px"}} color={themeSelector === "dark" ? "white" : "black"} />;
    if (data.status === "error") return <p>There was an error while fetching data!</p>
    return <>
        <div className="route__admin__dataStore__head" style={{marginBottom: 0}}>
            <FilterBySearch onChange={e => search !== e && setSearch(e)} />
            <StyledButton style={{height: "100%"}} onClick={() => {
                animateBox(<AdminDataStore_addRow ID={props.ID} headers={data.data.Headers} onChange={() => getData()} />);
            }}>Add row</StyledButton>
        </div>
        <FilteredCustomTable
            orderCB={o => setOrders(o)}
            theme={themeSelector}
            accent="#6C5DD3"
            canAnimate={false}
            style={{
                marginTop: "20px",
                columnGap: "30px"
            }}
            customHeaders={{
                "No.": <span style={{color: "gray"}}>No.</span>
            }}
            headers={["", "No.", ...data.data.Headers]}
            customColumns={["max-content", ...data.data.Headers.map(() => "max-content")]}
            data={(()=>{
                let out = [];

                for (let item of data.data.Rows) {
                    if (!checkSearch(item)) continue;

                    let idx = data.data.Rows.indexOf(item);
                    let tmp = [
                        {keyID: String(idx), type: "text", text: prepareButtons(idx)},
                        {keyID: String(idx), type: "text", text: idx + 1, style: {color: "gray"}}
                    ];
                    for (let h of data.data.Headers) {
                        let color = null;
                        let str = String(item[h]).toLowerCase();
                        if (str === "inactive") {
                            color = themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight;
                        } else if (str === "active") {
                            color = themeSelector === "dark" ? basicStylesModule.successColor : basicStylesModule.successColorLight;
                        } else if (str === "farming") {
                            color = "#fd7e14";
                        } else if (str === "ready") {
                            color = "#007bff";
                        } else if (str === "disabled") {
                            color = "#6c757d";
                        };
                        tmp.push({keyID: String(idx), type: "text", text: item[h], style: {color}});
                    };
                    out.push(tmp);
                };

                if (out.length > 0) {
                    out = performSort(out, ["", "No.", ...data.data.Headers]);
                };
                if (out.length === 0) out.push([{keyID: "noData-noData", type: "custom", data: "Nothing to show..."}]);
                return out;
            })()}
        />
    </>
};

const AdminDataStore_addStore = props => {
    const [headers, setHeaders] = React.useState([""]);
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const nameRef = React.useRef();

    const addStore = () => {
        if (spinner) return;
        let data = {
            Name: nameRef.current.value,
            Headers: headers.filter(f => f)
        };

        setInfoP("");
        if (!data.Name) return setInfoP("Name can't be empty");
        if (data.Headers.length === 0) return setInfoP("At least 1 column must be present");

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/dataStore/create`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.onChange();
                props.onClose();
            } else {
                setInfoP("Data store with this name already exists!");
            };
        }).catch(() => {
            setInfoP("Server timed out!");
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Add store</div>
                <div className="genericModal__wrap__head__right" onClick={props.onClose} style={{backgroundImage: `url("/images/icon_close.svg")`}}></div>
            </div>

            <div className="genericModal__wrap__input">
                <p>Name</p>
                <input ref={nameRef} type="text" placeholder="Name of the datastore" />
            </div>
            <div className="genericModal__wrap__input">
                <p>Column names</p>
                {headers.map((h, hIdx) => {
                    return <input value={h} type="text" placeholder="Column name" onChange={e => {
                        let val = e.target.value;
                        setHeaders(newH => {
                            let tmp = [...newH];
                            tmp[hIdx] = val;
                            tmp = tmp.filter(f => f);
                            if (tmp.length === 0) {
                                tmp.push("");
                            } else if (tmp[tmp.length-1]) {
                                tmp.push("");
                            };

                            return tmp;
                        });
                    }} />
                })}
            </div>

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Close</div>
                <div className="genericModal__wrap__buttons__btn" onClick={() => addStore()}>
                    {spinner ? <Spinner style={{width: "17px", height: "17px"}} color={themeSelector === "dark" ? "white" : "black"} /> : "Add"}
                </div>
            </div>

            {infoP && <p className="genericModal__wrap__infoP" style={{opacity: 1}}>{infoP}</p>}
        </div>
    </div>
};

const AdminDataStore_addRow = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");
    const [columns, setColumns] = React.useState(props.columns ?? {});

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const addRow = () => {
        setInfoP("");

        let finalColumns = {};
        for (let h of props.headers) {
            finalColumns[h] = columns[h] ?? ""
        };

        let hasData = false;
        for (let h of props.headers) {
            if (finalColumns[h]) {
                hasData =  true;
                break;
            };
        };

        if (!hasData) return setInfoP("Can't add an empty row!");

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/dataStore/${(props.rowID !== null && props.rowID !== undefined) ? "editRow" : "addRow"}`,
            data: {
                ID: props.ID,
                Columns: finalColumns,
                RowID: props.rowID ?? undefined
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.onChange();
                props.onClose();
            } else {
                setInfoP("There was an error while creating a new row!");
            };
        }).catch(() => {
            setInfoP("Server timed out");
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">{(props.rowID !== null && props.rowID !== undefined) ? "Edit" : "Add"} row</div>
                <div className="genericModal__wrap__head__right" onClick={props.onClose} style={{backgroundImage: `url("/images/icon_close.svg")`}}></div>
            </div>

            {props.headers.map(h => {
                if (h === "Status") {
                    return <div className="genericModal__wrap__input">
                        <p>{h}</p>
                        <Dropdown
                            theme={themeSelector}
                            accent="#6C5DD3"
                            data={[
                                {name: "Active", value: "Active"},
                                {name: "Disabled", value: "Disabled"},
                                {name: "Ready", value: "Ready"},
                                {name: "BM Used by media buyer", value: "BM Used by media buyer"}
                            ]}
                            onChange={e => {
                                setColumns(c => {
                                    let tmp = {...c};
                                    tmp[h] = e?.value;
                                    return tmp;
                                });
                            }}
                            selected={(()=>{
                                let curValue = columns[h];
                                if (!curValue) return null;

                                switch (curValue) {
                                    case "Active": return 0;
                                    case "Disabled": return 1;
                                    case "Ready": return 2;
                                    case "BM Used by media buyer": return 3;
                                    default: return null;
                                };
                            })()}
                        />
                    </div>
                };
                return <div className="genericModal__wrap__input">
                    <p>{h}</p>
                    <input type="text" placeholder={h} value={columns[h]} onChange={e => {
                        let val = e.target.value;
                        setColumns(c => {
                            let tmp = {...c};
                            tmp[h] = val;
                            return tmp;
                        });
                    }} />
                </div>
            })}

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Close</div>
                <div className="genericModal__wrap__buttons__btn" onClick={() => addRow()}>
                    {spinner ? <Spinner style={{width: "17px", height: "17px"}} color={themeSelector === "dark" ? "white" : "black"} /> : ((props.rowID !== null && props.rowID !== undefined) ? "Edit" : "Add")}
                </div>
            </div>

            {infoP && <p className="genericModal__wrap__infoP" style={{opacity: 1}}>{infoP}</p>}
        </div>
    </div>
};

export default AdminDataStore;