import React from "react";
import "./index.scss";

import { useNavigate } from "react-router-dom";
import animateModule from "../../modules/animateModule";

const LandingPage = () => {
    let curNavigate = useNavigate();


    const internalScroll = (item) => {
        let tmp = document.querySelector(item);
        if(!tmp) return;
        tmp.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start'});
    }

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    return <div className="route__landing" id="home">
        <div className="route__landing__wrapper">
            <div className="route__landing__wrapper__first" style={{backgroundImage: 'url(/images/landing/firstSection.svg)'}}>
                <div className="route__landing__wrapper__first__top">
                    <p className="route__landing__wrapper__first__top__headline">Explore the possibilities of tracking  with Scale Track</p>
                    <p className="route__landing__wrapper__first__top__message">Unleashing the potential of advanced tracking solutions</p>
                    <div className="route__landing__wrapper__first__top__getStarted" onClick={() => {internalScroll('#pricing')}}>
                        <p>get started</p>
                    </div>
                    <div className="route__landing__wrapper__first__top__main">
                        <img className="route__landing__wrapper__first__top__main__big" src="/images/landing/firstSectionMain.svg"></img>
                        <img className="route__landing__wrapper__first__top__main__small" src="/images/landing/main__image__small.png"></img>
                    </div>
                </div>
                <div className="route__landing__wrapper__first__bot">
                    <p className="route__landing__wrapper__first__bot__headline">Scale Track was designed to address the most significant challenges in paid media that hinder performance, profitability, and revenue generation for media buyers and agencies.</p>
                    <p className="route__landing__wrapper__first__bot__message"> Experience the revealing power of Scale Track as it uncovers the truth behind your advertisements.</p>
                    <div className="route__landing__wrapper__first__bot__cards">
                        <div className="route__landing__wrapper__first__bot__cards__left">
                            <FirstSectionCard 
                                backgroundURL='url(/images/landing/card_1_border.svg)'
                                headline='Performance marketing'
                                message='Scale Track was designed to address the most significant challenges in paid media that hinder performance, profitability, and revenue generation for media buyers and agencies. '
                                imgSRC="/images/landing/card_1_img.svg"
                            />
                            <FirstSectionCard 
                                backgroundURL='url(/images/landing/card_2_border.svg)'
                                headline='Paid media & integration'
                                message='Boost your paid media campaigns with seamless integrations. Gain valuable insights, optimize strategies, and achieve marketing goals effortlessly. '
                                imgSRC="/images/landing/card_2_img.svg"
                            />
                        </div>
                        <div className="route__landing__wrapper__first__bot__cards__right">
                            <FirstSectionCard 
                                backgroundURL='url(/images/landing/card_3_border.svg)'
                                headline='Facebook marketers'
                                message='Eliminate data gaps, unlock real-time insights, and maximize your ROI. Take control of your Facebook ads and drive exceptional results with precision targeting.'
                                imgSRC="/images/landing/card_3_img.svg"
                            />
                            <FirstSectionCard 
                                backgroundURL='url(/images/landing/card_4_border.svg)'
                                headline='Marketing & ad agencies'
                                message='Scale Track streamlines reporting and simplifies the user interface to handle the complex tasks, allowing you to harness data effectively and achieve desired outcomes.'
                                imgSRC="/images/landing/card_4_img.svg"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="route__landing__wrapper__second" id="features">
                <div className="route__landing__wrapper__second__top">
                    <div className="route__landing__wrapper__second__top__left">
                        <div className="route__landing__wrapper__second__top__left__bg">
                            <img className="route__landing__wrapper__second__top__left__bg__big" src="/images/landing/second_top_assets_bg2.png"></img>
                            <img className="route__landing__wrapper__second__top__left__bg__small" src="/images/landing/second__image__1270px.png"></img>
                        </div>
                    </div>
                    <div className="route__landing__wrapper__second__top__right">
                        <p className="route__landing__wrapper__second__top__right__headline">Supercharging performance marketing</p>
                        <p className="route__landing__wrapper__second__top__right__message">Harnessing the force of first-party data, Scale Track empowers you to turbocharge performance with unmatched speed, agility, and precision.</p>
                        <p className="route__landing__wrapper__second__top__right__item">
                            <img src="/images/landing/correct_circle.svg" />
                            <span>Unleash scalable ad budgets with unparalleled accuracy</span>
                        </p>
                        <p className="route__landing__wrapper__second__top__right__item">
                            <img src="/images/landing/correct_circle.svg" />
                            <span>Optimize campaigns seamlessly within the platform for amplified ROI</span>
                        </p>
                        <p className="route__landing__wrapper__second__top__right__item">
                            <img src="/images/landing/correct_circle.svg" />
                            <span>Experience real-time insights on actual vs. reported ROAS</span>
                        </p>
                        <p className="route__landing__wrapper__second__top__right__item">
                            <img src="/images/landing/correct_circle.svg" />
                            <span>Attribute success across diverse ad channels effortlessly</span>
                        </p>
                        <p className="route__landing__wrapper__second__top__right__item">
                            <img src="/images/landing/correct_circle.svg" />
                            <span>Precise execution and anticipatory analysis and presentation of results</span>
                        </p>
                    </div>
                </div>
                <div className="route__landing__wrapper__second__bot">
                    <div className="route__landing__wrapper__second__bot__left">
                        <SectionExpandingBotItem 
                            headline="Predictive data metrics" 
                            message="Pinpoint your best-performing ads, best-selling products, and ad creatives."
                        />
                        <SectionExpandingBotItem 
                            headline="Customer journeys" 
                            message="Gain Comprehensive Insights into Campaign Results: Understand What, How, and Where Success is Driven."
                        />
                    </div>
                    <div className="route__landing__wrapper__second__bot__right">
                        <SectionExpandingBotItem 
                            headline="Effortlessly Monitor Ad Performance" 
                            message="View Your Best and Worst Performing Ad Variations and Copy on a Single Creative Dashboard."
                        />
                        <SectionExpandingBotItem 
                            headline="Campaign budget optimization" 
                            message="Seamlessly Adjust Spending Based on First-Party Conversion Metrics within the Platform."
                        />
                    </div>
                </div>
            </div>
            <div className="route__landing__wrapper__third">
                <div className="route__landing__wrapper__third__left">
                    <p className="route__landing__wrapper__third__left__headline">Seamless integrations with top advertising platforms</p>
                    <p className="route__landing__wrapper__third__left__message">Maximize your reach, target the right audience, and drive results with ease. Our integrated solution empowers you to make data-driven decisions and achieve exceptional advertising performance.</p>
                    <p className="route__landing__wrapper__third__left__item">
                        <img src="/images/landing/correct_circle.svg" />
                        <span>Facebook Integration</span>
                    </p>
                    <p className="route__landing__wrapper__third__left__item">
                        <img src="/images/landing/correct_circle.svg" />
                        <span>TikTok Integration</span>
                    </p>
                    <p className="route__landing__wrapper__third__left__item">
                        <img src="/images/landing/correct_circle.svg" />
                        <span>Google Ads Integration</span>
                    </p>
                    <p className="route__landing__wrapper__third__left__item">
                        <img src="/images/landing/correct_circle.svg" />
                        <span>Native Ads Integration</span>
                    </p>
                </div>
                <div className="route__landing__wrapper__third__right" >
                    <p className="route__landing__wrapper__third__right__message">Unleash the Power of Cross-Platform Advertising with Seamless Integration and Unparalleled Insights</p>
                    <div className="route__landing__wrapper__third__right__img">
                        <img src="/images/landing/third_assets_main.svg"></img>
                    </div>
                </div>
            </div>
            <div className="route__landing__wrapper__fourth">
                <div className="route__landing__wrapper__fourth__top">
                    <div className="route__landing__wrapper__fourth__top__left">
                        <p className="route__landing__wrapper__fourth__top__left__headline">A Game-Changing Tool for Facebook Marketers</p>
                        <p className="route__landing__wrapper__fourth__top__left__message">Unleash the Power of Scale Track: A Game-Changing Tool for Facebook Marketers</p>
                        <p className="route__landing__wrapper__fourth__top__left__item">
                            <img src="/images/landing/correct_circle.svg" />
                            <span>Extend look-back windows: restore up to 365 Days of historical data</span>
                        </p>
                        <p className="route__landing__wrapper__fourth__top__left__item">
                            <img src="/images/landing/correct_circle.svg" />
                            <span>Accurate ROAS unveiled: compare Scale Track vs. Facebooks reported metrics</span>
                        </p>
                        <p className="route__landing__wrapper__fourth__top__left__item">
                            <img src="/images/landing/correct_circle.svg" />
                            <span>Optimize with precision: fine-tune campaigns, ad sets, and budgets</span>
                        </p>
                        <p className="route__landing__wrapper__fourth__top__left__item">
                            <img src="/images/landing/correct_circle.svg" />
                            <span>Identify top-performing Facebook creatives with Laser focus</span>
                        </p>
                        <p className="route__landing__wrapper__fourth__top__left__item">
                            <img src="/images/landing/correct_circle.svg" />
                            <span>Accurately attribute Facebook conversions across multiple channels</span>
                        </p>
                    </div>
                    <div className="route__landing__wrapper__fourth__top__right">
                        <img src="/images/landing/fourth_right.png"></img>
                    </div>
                </div>
                <div className="route__landing__wrapper__fourth__bot" style={{display: "none"}}>
                    <div className="route__landing__wrapper__fourth__bot__left">
                        <SectionExpandingBotItem 
                            headline="Accurate Facebook ROAS" 
                            message="Discover the reality of click data with Scale Track: uncover the truth beyond Facebook's accuracy."
                        />
                        <SectionExpandingBotItem 
                            headline="Accurate Facebook ROAS" 
                            message="Discover the reality of click data with Scale Track: uncover the truth beyond Facebook's accuracy."
                        />
                    </div>
                    <div className="route__landing__wrapper__fourth__bot__right">
                        <SectionExpandingBotItem 
                            headline="Accurate Facebook ROAS" 
                            message="Discover the reality of click data with Scale Track: uncover the truth beyond Facebook's accuracy."
                        />
                        <SectionExpandingBotItem 
                            headline="Accurate Facebook ROAS" 
                            message="Discover the reality of click data with Scale Track: uncover the truth beyond Facebook's accuracy."
                        />
                    </div>
                </div>
            </div>
            <div className="route__landing__wrapper__payment" id="pricing">
                <p className="route__landing__wrapper__payment__message">Get started with SCALE TRACK</p>
                <p className="route__landing__wrapper__payment__headline">Payment plan</p>
                <div className="route__landing__wrapper__payment__cards">
                    <PaymentCard 
                        borderImageURL="/images/landing/rect__green.svg"
                        headline="Free trial"
                        headlineColor="#7ADB78"
                        message="Supercharge your advertising performance with a free 1-week trial"
                        price="0"
                        imgSRC="/images/landing/correct_circle.svg"
                        desc1="Simple Campaign Tracking with history up to 30 days"
                        desc2="Advanced Analytics and Reporting for 1 user"
                        desc3="Conversion Attribution and Optimization on campaign level"
                    />
                    <PaymentCard 
                        borderImageURL="/images/landing/rect__purple.svg"
                        headline="Standard"
                        headlineColor="#AC6AFF"
                        message="Boost Your Advertising Performance with our Standard Subscription"
                        price="50"
                        discount="true"
                        discountAmount = "-20%"
                        monthly="true"
                        imgSRC="/images/landing/correct_circle.svg"
                        desc1="Comprehensive Ad Campaign Tracking with detailed targeting for up to 90 days"
                        desc2="Advanced Analytics and Reporting for a team of 5 people"
                        desc3="Conversion Attribution and Optimization on adset level"
                    />
                    <PaymentCard 
                        borderImageURL="/images/landing/rect__orange.svg"
                        headline="Premium"
                        headlineColor="#DD734F"
                        message="Maximize Advertising Performance: Upgrade to Premium for Unmatched Results"
                        price="120"
                        discount="true"
                        discountAmount = "-20%"
                        monthly="true"
                        imgSRC="/images/landing/correct_circle.svg"
                        desc1="Comprehensive Ad Campaign Tracking with detailed targeting for up to 365 days"
                        desc2="Advanced Analytics and Reporting for an unlimited amount of teams / users"
                        desc3="Conversion Attribution and Optimization on ad level"
                    />
                </div>
            </div>
            <div className="route__landing__wrapper__started">
                <div className="route__landing__wrapper__started__background">
                    <img src="/images/landing/started_background.png"></img>
                </div>
                <p className="route__landing__wrapper__started__message">Get started with SCALE TRACK</p>
                <p className="route__landing__wrapper__started__headline">Embrace the Future of Tracking Platforms with Scale Track</p>
                <p className="route__landing__wrapper__started__now">Start Now and Harness the Power of Scale Track for Seamless Tracking Solutions</p>
                <div className="route__landing__wrapper__started__button" onClick={() => animateNavigate("/register")}>
                    <p>get started</p>
                </div>
            </div>
        </div>
    </div>
};

const PaymentCard = (props) => {
    let curNavigate = useNavigate();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    return <div className='payment__cards__item'>
        <img className='payment__cards__item__border' src={props.borderImageURL}></img>
        <p className="payment__cards__item__headline" style={{color: props.headlineColor}}>{props.headline}</p>
        <p className="payment__cards__item__message">{props.message}</p>
        <div className="payment__cards__item__price">
            <p className="payment__cards__item__price__main"><span>$</span>{props.price}{props.monthly && <span className="payment__cards__item__price__main__monthly">/month</span>}</p>
            { props.discount && <div className="payment__cards__item__price__discount">
                <p>{props.discountAmount}</p>
            </div>}
        </div>
        <div className="payment__cards__item__getstarted" onClick={() => animateNavigate("/register")}>
            <p>get started</p>
        </div>
        <p className="payment__cards__item__desc"><img src={props.imgSRC} />{props.desc1}</p>
        <p className="payment__cards__item__desc"><img src={props.imgSRC} />{props.desc2}</p>
        <p className="payment__cards__item__desc"><img src={props.imgSRC} />{props.desc3}</p>
    </div>
}

const FirstSectionCard = (props) => {
    return <div className="sectionCard__item" style={{backgroundImage: props.backgroundURL}}>
        <p className="sectionCard__item__headline">{props.headline}</p>
        <p className="sectionCard__item__message">{props.message}</p>
        <img src={props.imgSRC}></img>
    </div>
}

const SectionExpandingBotItem = (props) => {
    return <div className="second__sectionBot__item">
        <p className="second__sectionBot__item__headline">{props.headline}</p>
        <p className="second__sectionBot__item__message">{props.message}</p>
    </div>
}


export default LandingPage;