import React from "react";
import "./index.scss";

const PrivacyPolicy = () => {

    React.useEffect(() => {
        let tmp = document.querySelector(".component__contentWrapper");
        if (!tmp) return;

        tmp.scrollTo(0, 0);
    }, []);

    return <div className="route__privacyPolicy">
        <p><b>Last Updated on August 1, 2023</b></p>
        <h1>Our Privacy Commitment</h1>

        <p>Welcome to Scale-Track! Scale-Track is committed to respecting the privacy rights and concerns of all users to Scale-Track.com and our SaaS platform or “Software(s)” or “Platform”.</p>

        <p>We have established and implemented this Privacy Policy to inform visitors to the Scale-Track website and Platform as to how we use and protect the information we collect through the Software(s).</p>

        <p>By visiting our website or the Scale-Track Platform, you consent to Scale-Track’s collection, use, storage, deletion and disclosure of information relating to you as set forth in this Privacy Policy as per your regional requirements.</p>

        <p>This Privacy Policy is effective as of the date set forth above and is only applicable to the Software(s) and not to any other web Software that you may be able to access from the Software(s) which may have its own data collection and use practices and policies.</p>

        <p>This privacy policy answers the following questions:</p>

        <ul>
            <li>What information do we collect about you and how do we use it?</li>
            <li>What are cookies, web beacons, and pixels and how do we use them?</li>
            <li>Will we share your personal information with third parties?</li>
            <li>What choices do you have about collecting and using your personal information?</li>
            <li>What security measures do we take to safeguard your personal information?</li>
            <li>Consent To Sharing Data with other eCommerce and Social Media Channels</li>
            <li>EEA/EU and California Privacy</li>
        </ul>

        <p className="route__privacyPolicy__underline">What information do we collect about you and how do we use it?</p>

        <h2>Types of information we collect</h2>
        <p>Personal information means information that can be used to identify and contact you. You do not need to submit personal information to access certain areas of the Software(s), but if you do not want to disclose certain personal information to us, you will not be able to access certain aspects of the Scale-Track Platform.</p>
        <p>We collect personal information when you create an account through the Scale-Track Platform; purchase software services through the Software; work with our team to optimize the software services we provide to you, or receive customer or technical support.</p>
        <p>To order software services from Scale-Track through the Software(s) and create an account for the Scale-Track Platform, we require you to provide your name, company name, email address, billing address, phone number, web software URL, title, and additional billing information depending on your preferred method of payment, such as your credit card number and payment method expiration date. You will also need to create a screen name in order to create an account. We also collect information about your company, such as analytics, ad tracking, traffic statics, marketing budgets, conversion rates, sales data and other information related to the visibility, layout, reputation, popularity and success of online marketing campaigns.</p>

        <h2>Cookies, Web Beacons and Pixels</h2>
        <p>“Cookies”, “Web Beacons” and “Pixels” are small bits of electronic information that the Software sends to a visitor’s browser and are stored on the visitor’s hard drive. Like many web Softwares, we employ cookies or pixels in certain areas of the Software(s) to allow us to provide information that is targeted to you and to make your online experience more convenient and your advertising more effective.</p>
        <p>Additionally, analytics tools that are managed by third parties which may appear on the Software(s) use cookies and pixels. These analytics tools use cookies and pixels to collect non-personal information while you utilize the Software(s). You may not realize this information is being collected. In some cases, we may have access to information from cookies and pixels used by these analytics tools on the Software(s) and this information may permit us to identify other information related to eCommerce transactions.</p>
        <p>Cookies and/or pixels are set by the operator of a website (in this case, Scale-Track Platform) and are called “first party cookies”. Cookies set by parties other than Scale-Track are called “third-party cookies”. Third-Party cookies enable functionality to be provided on or through the Website (for example advertising, interaction with social media sites, analytics, search engine, etc.). Setting these third-party cookies can recognize your computer or mobile device both when you visit our Website, and also when you visit certain other websites.</p>

        <h2>Why do we use cookies?</h2>
        <p>Scale-Track utilizes first-party and third-party cookies for multiple reasons. Some cookies are required for technical reasons in order for our Website, Platform and Services to operate, and we refer to these as “necessary” cookies. Other cookies also enable Scale-Track Software to track and target the interests of users and enhance the experience of our customers. Third parties serve cookies through Websites for advertising, analytics, and other purposes.</p>

        <h2>Strictly necessary Cookies</h2>
        <p>A strictly necessary cookie is a type of cookie that is used by the website to function properly, without which the site would not work. This type of cookie does not collect any personally identifiable information about you and does not track your browsing habits.</p>
        <p>These cookies are strictly necessary to provide the Service to you through our Platform. Strictly necessary cookies allow you to use our Services and enable access to various features such as a unique user session and enable access to secure areas.</p>

        <h2>Performance Cookies</h2>
        <p>Performance cookies are cookies used specifically for gathering data on how visitors use a website, which pages of a website are visited most often, or if they get error messages on web pages. These cookies monitor only the performance of the site as the user interacts with it. These cookies are used to enhance and improve the performance of our Websites but are not essential. These cookies collect information that helps us understand how the Websites and Services are being used by our customers, how effective our marketing campaigns are. These cookies may collect IP addresses and may generate a unique identifier to analyze how frequently you visit our Websites, and also gather other metrics such as page views.</p>

        <h2>Functionality Cookies</h2>
        <p>Functionality cookies allow websites to remember the user’s site preferences and choices they make on the site including username, region, and language. This allows the website to provide personalized features like local news stories and weather if you share your location. These cookies enable our Websites to provide enhanced functionality and features. Although these cookies are not strictly necessary, if you disable them, certain functionality may not be available to you.</p>

        <h2>Targeting Cookies</h2>
        <p>Targeting and advertising cookies are specifically designed to gather information from you on your device to display advertisements to you based on relevant topics that interest you. Advertisers will place these cookies on a website with the website operator’s permission. Targeting cookies are used by our advertising and marketing partners and work primarily through uniquely identifying your device (e.g., advertising ID, IP address, geolocation), and are used to build a profile of your interests and show you relevant ads on other websites you may visit. If you do not allow these cookies, you will experience less targeted advertising.</p>

        <h2>Other Tracking Technologies</h2>
        <p>Cookies are not the only way to track visitors to a website. Scale-Track and our third-party partners may use other similar technologies such as “tracking pixels” and “web beacons”. This allows our third-party partners to monitor traffic patterns between websites, communicate with cookies, understand if you have come from an advertisement displayed on a third-party Website, monitor the success of marketing campaigns, and improve site performance. These technologies typically rely on cookies to function, so disabling cookies in your browser will impair their functionality. These other tracking technologies include:</p>
        <p>Web Beacons (“Tracking Pixels”) Web beacons are small graphic images, also known as “Internet tags” or “clear gifs,” embedded in web pages. Web beacons may be used, for example, to count the number of visitors to the Website, to monitor how users navigate the Website, to count content views, customer journey, attribution models, and more.</p>

        <h2>Embedded Scripts</h2>
        <p>An embedded script is programming code designed to collect information about your interactions with the Website. It is temporarily downloaded onto your computer from our web server, or from a third party with which we work, and is active only while you are connected to the Website, and deleted or deactivated thereafter.</p>
        <p>Our Use of Personal Information on Behalf of our Clients</p>
        <p>Scale-Track’s use of personal information we collect from, or on behalf of, our clients to provide services to our clients at their direction. We use personal information only as directed or authorized by our client. Typically, we are directed or authorized to use personal information collected on behalf of the client to:</p>

        <ul>
            <li>Help customize marketing strategies</li>
            <li>Personalize marketing content for customers</li>
            <li>Target customer marketing campaigns</li>
            <li>Analyze and enhance communications and strategies (including by identifying advertising channels)</li>
            <li>Analyze results from marketing or advertising campaigns</li>
        </ul>


        <h2>How can to control cookies?</h2>
        <p>You have the right to decide whether to allow cookies when using our Websites and Services. Most browsers allow you to control cookies as part of their settings and preferences. Please refer to your specific browser for instructions on how to disable, limit and delete cookies.</p>

        <h2>Why do you use cookies?</h2>
        <p>We use first-party and third-party cookies for several reasons. Some cookies are required for technical reasons in order for our Website and Services to operate, and we refer to these as “essential” or “strictly necessary” cookies. Other cookies also enable us to track and target the interests of users and to enhance the experience of our Service. Third parties serve cookies through our Websites for advertising, analytics, and other purposes.</p>

        <h2>Log information</h2>
        <p>Our servers automatically record information that your browser sends whenever you visit the Software(s). Additionally, some of the Scale-Track Platform may collect information regarding you and your customer’s computer system. This information includes Internet Protocol address, browser type and version, domain names, physical location, which Scale-Track Platform you use and when and how long you use them. We use this information to monitor and analyze how users use the Scale-Track Platform, to provide customer service, to maintain and improve the Scale-Track Platform, to help prevent fraud and misuse and for other security purposes.</p>

        <h2>Children’s privacy</h2>
        <p>We do not knowingly collect personal information from children under 13. We take children’s privacy seriously and encourage parents to play an active role in their children’s online experience at all times.</p>
        <p className="route__privacyPolicy__underline">Will we share your personal information with third parties?</p>

        <h2>Sharing of information</h2>
        <p>The Platform may use non-personal information on an anonymous basis to provide analyses of users in the aggregate to prospective partners, advertisers, and other third parties.</p>
        <p>In addition, we will share the personal information we collect from the Platform under the following circumstances:</p>
        <p className="route__privacyPolicy_underline">Protection of rights:</p>
        <p>The Platform will share personal information if we have a good faith belief that (i) access, use, preservation, or disclosure of such information is reasonably necessary to satisfy any applicable law, regulation, or legal processes, such as a court order or subpoena, credit card rules or a request by law enforcement or governmental authorities, (ii) such action is appropriate to enforce the Terms of Use or Terms of Service, including any investigation of potential violations thereof, (iii) such action is necessary to detect, prevent, or otherwise address fraud, security or technical issues, or (iv) such action is appropriate to protect the rights, property or safety of Scale-Track, its employees, users of the Scale-Track Platform or others.</p>
        <p className="route__privacyPolicy__underline">Asset Transfers:</p>
        <p> If we become involved in a merger, acquisition or other transaction involving the sale of some or all of Scale-Track’s assets, user information, including personal information collected from the Platform through the use of the Scale-Track Platform, could be included in the transferred assets. Should such an event occur, we will use reasonable means to notify you, either through email and/or a prominent notice on the Software(s).</p>
        <p className="route__privacyPolicy__underline">Service providers:</p>
        <p>The Platform may share personal information with service providers that we engage for the purpose of processing information on our and your behalf, fraud prevention, bill collection, software, search engine optimization, sales and technology services. For example, we have partnered with other companies to process payments. While providing services for us, these companies may access your personal information. To the extent applicable, we require these entities to only use your personal information in connection with the services they perform for us and not for their own benefit.</p>
        <p>Additional Information You Should Know About Third Parties.</p>
        <p>This Privacy Policy does not cover the privacy practices of third-party software accessed through the Platform. For example, links to websites operated by third parties may collect information about you on the Internet. We encourage such companies to adopt and follow their own privacy policies, but we are not responsible for their collection or use of your personal information. You should refer to the privacy policies and statements of other web services or contact their individual webmasters to obtain information regarding their online collection and use of consumer data.</p>
        <p className="route__privacyPolicy__underline">What choices do you have about the collection and use of your personal information?</p>
        <p>Control of Your Information</p>
        <p>You may update or delete your personal information or modify your account preferences for the Scale-Track Platform by accessing the “My Account” area of the Scale-Track Platform or sending us an email to <a href="mailto:info@scale-track.com">info@scale-track.com</a> requesting us to update or delete your personal information. Please note that when you delete your account, we may retain your account information thereafter for the purpose of internal account management and fraud prevention activities.</p>
        <p>Opting Out of Receiving Communications from Us</p>
        <p>If you no longer want to receive our newsletter, e-mails or other announcements, you may unsubscribe by writing to us at support@Scale-Track.com or replying to the unsubscribe link at the bottom of our emails. Please note that you cannot unsubscribe from certain correspondence from us.</p>

        <h2>What security measures do we take to safeguard your personal information?</h2>
        <p>The personal information that you provide to us is stored on servers, which are located in secured facilities with restricted access, and protected by protocols and procedures designed to ensure the security of such information. In addition, we restrict access to personal information to Scale-Track employees, independent contractors and agents who need to know this information in order to develop, operate and maintain the Scale-Track Platform and provide you software services. However, no server, computer or communications network or system, or data transmission over the Internet can be guaranteed to be 100% secure. As a result, while we strive to protect user information, we cannot ensure or warrant the security of any information you transmit to us or through the use of any of the Scale-Track Platform(s) and you acknowledge and agree that you provide such information and engage in such transmissions at your own risk. Once we receive a transmission from you, we will endeavor to maintain its security on our systems.</p>

        <h2>Consent To Sharing Data with other eCommerce and Social Media Channels</h2>
        <p>Notwithstanding anything to the contrary in our Privacy Policy or Terms & Conditions, to optimize your advertising performance the Scale-Track Platform will share any lead and customer data that you provide to us (“Sales Data”) with Meta, Inc. (“Facebook”) and Alphabet (“Google”) other media channels through offline conversion measurement solution and pixel/beacon integration. By using conversion data, we will be able to track the timing of your transactions that occur through offline channels after people see or engage with the advertising channel. Although the Platform will share your Sales Data with these channels, such as Facebook and Google we will agree not to share your Sales Data with third parties or other advertisers. By utilizing the Scale-Track Platform and/or “Optimize”, you agree to the following:</p>
        <p>You authorize Scale-Track to share your Sales Data with the channel (i.e. Facebook and Google), and you further authorize Scale-Track to act as your agent to use your Sales Data and bind you to the Channels’ (i.e. Facebook’s and Google’s) Offline Conversion Tracking Terms of Service (this is found inside your advertising Channel or Facebook and Google account when you authorize offline conversions.)</p>
        <p>You represent and warrant to Scale-Track that you have provided appropriate notice explaining how Sales Data may be used for offline conversion measurement to and secured any necessary consent regarding such use from the data subjects, including as needed to comply with all applicable laws, regulations, and industry guidelines, and you represent and warrant that you have all necessary rights and permissions to use the Sales Data.</p>
        <p>You agree that to the fullest extent permitted by law, in no event shall Scale-Track, or any of its members, officers, directors, employees, or agents be liable for damages under contract, tort, strict liability, negligence, or any other legal or equitable theory arising out of Scale-Tracks’ use of your Sales Data or provision of your Sales Data to your Channel, Facebook, and Google.</p>
        <p>To the fullest extent permitted by law, you agree to indemnify, defend and hold harmless Scale-Track, and each of its members, officers, directors, employees, and agents from and against all losses, expenses, damages and costs, including reasonable attorneys’ fees, arising out of Scale-Tracks’ use of your Sales Data or provision of your Sales Data to Channels or Facebook or any breach of your representations and warranties hereunder.</p>
        <p>You consent to transact business electronically in compliance with the Federal Electronic Signatures in Global and National Commerce Act and similar state laws, particularly the Uniform Electronic Transactions Act, and thereby authorize the creation of legally binding and enforceable agreements among you, Scale-Track and utilizing electronic records and signatures.</p>

        <h2>Links to External Sites</h2>
        <p>The Scale-Track service may contain links to other websites, products, or services that we do not own or operate. The Scale-Track service also may contain links to third-party sites such as social networking services. If you choose to visit or use any third-party sites or products or services available on or through such third-party sites, please be aware that this Policy will not apply to your activities or any information you disclose while using those third-party sites or any products or services available on or through such third-party sites. We are not responsible for the privacy practices of these third-party sites or any products or services on or through them. Additionally, please be aware that the Scale-Track service may contain links to websites and services that we operate but that are governed by different privacy policies. We encourage you to carefully review the privacy policies applicable to any website or service you visit other than Scale-Track before providing any personal information on them.</p>
        <p>You can learn more about cookies at the following third-party website:</p>
        <p><a href="http://www.allaboutcookies.org/" target="_blank">All About Cookies: http://www.allaboutcookies.org/</a></p>

        <h2>EEA/EU Data Subject Data Protection Rights Under the General Data Protection Regulation (GDPR) The European Union General Data Protection Regulation (GDPR), enacted on May 25, 2018, and effective from May 25, 2018, is a data protection law with enhanced rights for individuals and a more stringent regime for regulating the way companies handle personal data. The below provides an overview of EEA/EU Data Subject Data Protection Rights Under the General Data Protection Regulation (GDPR).</h2>
        <p>GDPR is broad in scope and uses broad definitions. “Personal data” is any information that relates to an identified or identifiable living individual (data subject) such as a name, email address, tax ID number, online identifier, etc. “Processing” data includes actions such as collecting, recording, storing, and transferring data.</p>
        <p>If you are a resident of the European Economic Area (EEA) or European Union (EU), you have certain data protection rights. We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Information.</p>
        <p>We are committed to protecting the personal information that we hold and do our best to ensure that the security of all systems we run is secured. We encourage you to contact us should there be a need to make any requests regarding our personal information.</p>
        <p>Under certain circumstances, you have the following individual rights:</p>

        <ul>
            <li>You have the right to request access to your Personal Information (commonly known as a “data subject access request”). This enables you to receive a copy of the Personal Information we hold about you and to check that we are lawfully processing it. We may need time to communicate with you, but we will do so within 30 days. Please note that we need verification of the accuracy of your request before agreeing to provide access.</li>
            <li>If you believe we hold any inaccurate, incomplete or misleading information about you, please make a data subject access request in writing to our corporate address. If your request is reasonable and valid, we will use our best endeavors to provide you with a copy of the share of personal information that we hold about you.</li>
            <li>You may ask us to erase your Personal Information in certain circumstances, for example, if you have successfully exercised your right to object to processing, where we have made a mistake, or where we are required to erase your Personal Information to comply with local law. Note, however, that we may not always be able to comply with your request, for specific legal reasons.</li>
            <li>You have the right to object where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground, as you feel it impacts on your fundamental rights and freedoms. In certain circumstances, we may need to process your information. This is done legally and for a specific reason.</li>
            <li>If you would like to have your Personal Information restricted or deleted, give us a notice of restriction or deletion. This enables you to ask us to temporarily stop the processing of your Personal Information in the following scenarios: (a) if you have concerns about the accuracy of your information and want to have it rectified; (b) where you believe our use of your information may be unlawful but you do not want us to erase it; (c) where you need us to hold the information for the purposes of defending or exercising your rights with respect to a legal claim even though we may no longer need it; or (d) you have objected to our use of your information but we need to verify whether we have overriding legitimate grounds to keep it.</li>
            <li>You can request us to transfer your Personal Information to you or to a third party. We will provide your Personal Information in a structured, commonly used, machine-readable format at your request. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you, and not when it was processed in the course of our legitimate activities.</li>
            <li>You have the right to withdraw consent at any time where we are relying on consent to process your Personal Information. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain services to you. We will advise you if this is the case at the time you withdraw your consent.</li>
            <li>You may complain to the Supervisory Authority in your country about our collection and/or use of your Personal Information. In some countries, the Data Protection Authority is also the regulator for this sector. If you think we have collected and used your information in a way that violates your privacy or rights, you can contact your local data protection authority in one of the countries in Europe (EU member states), Asia (Singapore, South Korea) or North America (United States).</li>
            <li>If you wish to exercise any of the rights set out above, please contact us using the contact details below</li>
        </ul>

        <p>We will not charge a fee for Personal Information access, however, we reserve our right to request a reasonable fee for the provision of this service. Alternatively, we may refuse to respond in these instances where the requests are not made in writing and demonstrate an annoyance of repeated follow-up requests. Alternatively, we may refuse to comply with your request in such circumstances. We may require you to provide us with additional information about yourself to verify your identity before we process your request. This is a security measure designed to prevent unauthorized access to your Personal Information. We may also contact you to ask for further information in relation to your requests in order to speed up the process.</p>
        <p>For more information on the European Country Commercial Guide you can visit the following third-party link:</p>
        <p><a href="https://www.privacyshield.gov/article?id=European-Union-Data-Privatization-and-Protection#:~:text=The%20EU%20General%20Data%20Protection,to%20companies%20of%20all%20sizes." target="_blank">https://www.privacyshield.gov/article?id=European-Union-Data-Privatization-and-Protection#:~:text=The%20EU%20General%20Data%20Protection,to%20companies%20of%20all%20sizes.</a></p>

        <h2>California Consumer Privacy Act</h2>
        <p>California Resident Rights: The California Consumer Privacy Act (the “CCPA”) provides certain rights to California consumers, including the following:</p>

        <ul>
            <li>Right to Know: You have the right to request that we disclose to you the Personal Information we collect, use, or disclose, and information about our data practices.</li>
            <li>Right to Request Deletion: You have the right to request that we delete the Personal Information that we have collected from you.</li>
            <li>Right to Non-Discrimination: We will not discriminate against you for exercising any of these rights.</li>
        </ul>

        <p>These rights are not absolute, and there may be cases when we decline your request as permitted by law or as required to carry out services to you. Information Collected: As explained elsewhere in this Privacy Policy, we may collect the following types of information from you, your device(s), or from third parties. To the best of our knowledge, we have not collected any other types of personal information via this website in the last twelve months.</p>

        <ul>
            <li>identifiers, such as cookies, log files, browser Information, device Information, and security information;</li>
            <li>internet, network, or geolocation information;</li>
            <li>business and pricing inquiry information;</li>
            <li>commercial information, such as contact, account, order, shipping, and payment information;</li>
            <li>personal information, including name, phone number, mailing address, email address, and, in certain circumstances, employment affiliation or history; and</li>
            <li>information derived from other categories of website interactions, which could include your preferences, interests, and other information used to personalize your experience.</li>
        </ul>

        <p>We may use, retain, and disclose this information for the business purposes described in this Privacy Policy. We consider the use of this information to be within our legitimate business interests.</p>
        <p className="route__privacyPolicy__underline">Information Sharing:</p>
        <p>Scale-Track does not sell personal information collected on this website. However, as explained elsewhere in this Privacy Policy, we may share Personal Information with authorized service providers or business partners to carry out certain services or requests. We may also share personal information if you use our services to interact with third parties or direct us to disclose your Personal Information to third parties.</p>
        <p className="route__privacyPolicy__underline">Right to Know and Deletion:</p>
        <p>To exercise your “right to know” or “right to delete,” California residents may contact us by emailing support@Scale-Track.com, or by mailing a request to the address listed below. To protect your personal information, we will verify your identity by a method appropriate to the type of request you are making.</p>
        <p>Other California Disclosures</p>
        <p className="route__privacyPolicy__underline">Shine the Light Disclosures:</p>
        <p>California law permits you to request information regarding the disclosure of your personal information to third parties for the third parties’ direct marketing purposes. Scale-Track does not share information with unaffiliated third parties for their marketing benefit. For additional information, California residents may contact us at the address below using the reference “Shine the Light Disclosure.”</p>        
        <p className="route__privacyPolicy__underline">Do Not Track:</p>
        <p>California law requires that websites disclose their practices with respect to the use of tracking technology by third parties and whether the site honors browser “Do Not Track” signals. Third parties are not allowed to collect personal information from this website for their own benefit. However, this site does not currently recognize or respond to “Do Not Track” browser settings or signals.</p>

        <h2>Mediation</h2>
        <p>You also agree that in the event any dispute or claim arises out of or relating to this Privacy Policy you and Scale-Track will attempt in good faith to negotiate a written resolution of the matter directly between the parties. You agree that if the matter remains unresolved for forty-five (45) days after notification (via certified mail or personal delivery) that a dispute exists, all parties shall join in mediation services in Los Angeles, California with a mutually agreed mediator in an attempt to resolve the dispute. Should you file any arbitration claims, or any administrative or legal actions without first having attempted to resolve the matter by mediation, then you agree that you will not be entitled to recover attorney’s fees, even if you would otherwise be entitled to them.</p>
        <p>Notification of Changes. This Privacy Policy may change from time to time and we will post all changes on this page.</p>

        <h2>QUESTIONS/COMMENTS/CONCERNS:</h2>
        <p>If you have any questions about the contents of this page, or simply wish to reach us for any other reason, you may do so by using our Contact information located at Scale-Track.com, or below:</p>

        <p>Scale, LLC,</p>
        <p>Business Center,</p>
        <p>Sharjah Publishing City , Sharjah, UAE </p>
        <p><a href="mailto:info@scale-track.com">info@scale-track.com</a></p>
    </div>
};

export default PrivacyPolicy;