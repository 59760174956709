import React from "react";
import "./index.scss";

const PreviewImageModal = props => {
    return <div className="modal__previewImage" onClick={e => e.stopPropagation()}>
        <div className="modal__previewImage__wrap">
            <div className="modal__previewImage__wrap__head">
                <div className="modal__previewImage__wrap__head__left">Preview</div>
                <div className="modal__previewImage__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            <div className="modal__previewImage__wrap__img">
                <img src={props.image ?? "/images/image-missing.png"} onError={e => e.target.src = "/images/image-missing.png"} />
            </div>
        </div>
    </div>
};

export default PreviewImageModal;