import React from "react";
import "./index.scss";

import axios from "axios";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import animateModule from "../../../modules/animateModule";
import * as basicStylesModule from "../../../modules/basicStylesModule";
import * as backendModule from "../../../modules/backendModule";
import * as siteFunctionsActions from "../../../actions/siteFunctionsActions";
import { parseCSV } from "../../../modules/csvParserModule";
import { animateBox } from "../../../modules/componentAnimation";
import { countries } from "../../../modules/countryModule";
import useOnScreen from "../../../modules/hooks/useOnScreen";
import RadioButton from "../../../components/customComponents/RadioButton";

import { FilteredCustomTable } from "../../../components/customComponents/Table";
import FilterBySearch from "../../../components/filters/FilterBySearch";
import Spinner from "../../../components/customComponents/Spinner";
import YesNoModal from "../../../components/modals/YesNoModal";

const UserOfferCopies = () => {
    const [data, setData] = React.useState();
    const [search, setSearch] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState();
    const [activeOffers, setActiveOffers] = React.useState(true);

    const timestampRef = React.useRef();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const curDispatch = useDispatch();
    const curNavigate = useNavigate();
    const curOnScreen = useOnScreen();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".root__content "));

    const getOffers = (ts) => {
        if (timestampRef.current !== ts) return;
        if (canPaginate) setCanPaginate(false);

        let filters = [];
        if (search) {
            let tmp = [];
            for (let item of search.split(" ")) {
                item = item.trim();
                tmp.push({or: [
                    {name: "OfferName", op: "like", value: item},
                    {name: "Country", op: "like", value: item},
                    {name: "OfferType", op: "like", value: item},
                    {name: "OfferCondition", op: "like", value: item},
                ]})
            };
            filters.push({and: tmp});
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffersWithCopies`,
            data: {
                extended: false,
                limit: null,
                filters: [
                    ...filters,
                    (activeOffers ? {name: "isActive", op: "eq", value: true} : null)
                ].filter(f => f)
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length >= 20) setCanPaginate(true);
            };
            setData(res.data);
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        });
    };

    const continueOffers = (ts) => {
        if (!data) return;
        if (data.status !== "ok") return;
        if (timestampRef.current !== ts) return;
        if (!canPaginate) return;
        setCanPaginate(false);

        let filters = [];
        if (search) {
            let tmp = [];
            for (let item of search.split(" ")) {
                item = item.trim();
                tmp.push({or: [
                    {name: "OfferName", op: "like", value: item},
                    {name: "Country", op: "like", value: item},
                    {name: "OfferType", op: "like", value: item},
                    {name: "OfferCondition", op: "like", value: item},
                ]})
            };
            filters.push({and: tmp});
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffersWithCopies`,
            data: {
                extended: false,
                limit: 20,
                filters: [
                    ...filters,
                    {name: "ID", op: "notIn", value: data.data.map(d => d.ID)}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length >= 20) setCanPaginate(true);
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
            };
        }).catch(() => null);
    };

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen?.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        let ts = Date.now();
        timestampRef.current = ts;
        continueOffers(ts);
    }, [canPaginate, curOnScreen.isIntersecting]);

    React.useEffect(() => {
        let handler = () => {
            let ts = Date.now();
            timestampRef.current = ts;
            getOffers(ts);
        };

        handler();
        curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));

        return () => {
            try {curDispatch(siteFunctionsActions.removeHeaderRefreshAction(handler))} catch {};
        };     
    }, [search, activeOffers]);

    return <div className="route__userOfferCopies">
        <FilterBySearch onChange={e => setSearch(e)} />

        <div className="route__userOfferCopies__filters">
            <p>
                <RadioButton defaultValue={activeOffers} onChange={e => e !== activeOffers && setActiveOffers(!!e)} />
                <span>Show only active offers</span>
            </p>
        </div>

        <FilteredCustomTable
            theme={themeSelector}
            accent="#6C5DD3"
            headers={["Offer name", "Country", "Type", "Condition"]}
            data={(()=>{
                if (!data) return [{columns: [{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]}];
                if (data.status === "error") return [{columns: [{keyID: "noData-error", type: "text", text: "Error while fetching data", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]}];

                let out = [];
                for (let item of data.data) {
                    out.push({
                        events: {
                            onClick: () => animateNavigate(`/offer-copies/${item.ID}`)
                        },
                        columns: [
                            {keyID: item.ID, type: "text", text: item.OfferName},
                            {keyID: item.ID, type: "text", text: item.Country ? (() => {
                                let curCountry = countries.find(c => c.code === item.Country);

                                if (!curCountry) return "?";
                                return <p className="route__user__offers__tableWrap__country">
                                    <img src={`/images/countryFlags/${curCountry.code.toLowerCase()}.png`} />
                                    <span>{curCountry.name}</span>
                                </p>
                            })() : "-"},
                            {keyID: item.ID, type: "text", text: item.OfferType ?? "-"},
                            {keyID: item.ID, type: "text", text: item.OfferCondition ?? "-"}
                        ]
                    })
                };

                if (out.length === 0) out.push({columns: [{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]});

                return out;
            })()}
        />
        {canPaginate && <div style={{width: "1px", height: "1px", opacity: 0}} ref={curOnScreen.measureRef}></div>}
    </div>
};

const UserCopiesForOffer = () => {
    const [data, setData] = React.useState();
    const [search, setSearch] = React.useState("");
    const [canPaginate, setCanPaginate] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const userSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});

    const timestampRef = React.useRef();
    const curParams = useParams();
    const curOnScreen = useOnScreen();
    const curDispatch = useDispatch();

    const getData = (ts) => {
        if (timestampRef.current !== ts) return;
        if (!curParams?.id) return;
        if (canPaginate) setCanPaginate(false);

        let filters = [];
        if (search) {
            let finalSearch = [];
            for (let item of search.split(" ")) {
                finalSearch.push({or: [
                    {name: "CreativeCopy", op: "like", value: item},
                    {name: "PrimaryCopy", op: "like", value: item},
                    {name: "CTACopy", op: "like", value: item},
                ]});
            };
            filters.push({and: finalSearch});
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/copies/getAll`,
            data: {
                OfferID: curParams?.id,
                limit: 20,
                filters: [
                    ...filters,
                    {name: "OfferID", op: "eq", value: curParams?.id ?? "-"}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length >= 20) setCanPaginate(true);
            };
            setData(res.data);
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        });
    };

    const continueData = (ts) => {
        if (timestampRef.current !== ts) return;
        if (!data) return;
        if (data.status !== "ok") return;
        if (!curParams?.id) return;
        if (!canPaginate) return;
        setCanPaginate(false);

        let filters = [];
        if (search) {
            let finalSearch = [];
            for (let item of search.split(" ")) {
                finalSearch.push({or: [
                    {name: "CreativeCopy", op: "like", value: item},
                    {name: "PrimaryCopy", op: "like", value: item},
                    {name: "CTACopy", op: "like", value: item},
                ]});
            };
            filters.push({and: finalSearch});
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/copies/getAll`,
            data: {
                OfferID: curParams?.id,
                limit: 20,
                filters: [
                    ...filters,
                    {name: "ID", op: "notIn", value: data.data.map(d => d.ID)},
                    {name: "OfferID", op: "eq", value: curParams?.id ?? "-"}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length >= 20) setCanPaginate(true);
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
            };
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        });
    };

    const wrapTableItem = text => {
        return <span style={{padding: "0 20px"}}>{text}</span>
    };

    const removeCopy = (e, item) => {
        animateBox(e, <YesNoModal
            heading="Are You sure?"
            text="Are You sure? Removal of the copy is irreversible!"
            isRightButtonNormal={true}
            buttonRightCallback={args => {
                args.disabledAll(true)
                args.spinner(true);
                
                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/offers/copies/remove`,
                    data: {
                        ID: item.ID
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "ok") {
                        let ts = Date.now();
                        timestampRef.current = ts;
                        getData(ts);
                        args.close();
                    } else {
                        args.errorMessage("There was an error while removing the copy!");
                    };
                }).catch(() => {
                    args.errorMessage("Server timed out!");
                }).finally(() => {
                    args.disabledAll(false);
                    args.spinner(false);
                });
            }}
        />);
    };

    const showError = (heading, text) => {
        let tmp = document.querySelector(".route__userOfferCopies__buttons > .route__userOfferCopies__buttons__button");
        if (!tmp) return;

        animateBox({currentTarget: tmp}, <YesNoModal
            heading={heading}
            text={text}
            buttonLeftHidden={true}
            buttonRightText="Ok"
            isRightButtonNormal={true}
        />);
    };

    const csvImport = file => {
        let fr = new FileReader();
        fr.onload = async e => {
            let parsed = await parseCSV(e.target.result);
            if (!Array.isArray(parsed)) return showError("CSV Invalid", "CSV File provided is invalid");
            if (parsed.length <= 1) return showError("CSV Empty", "CSV File is empty");

            let finalData = [];
            let noInfos = 0;
            for (let item of parsed) {
                let out = {
                    OfferID: curParams?.id,
                    CreativeCopy: item[0] ?? "",
                    PrimaryCopy: item[1] ?? "",
                    CTACopy: item[2] ?? ""
                };
                if (!out["CreativeCopy"] && !out["PrimaryCopy"] && !out["CTACopy"]) {
                    noInfos ++;
                    continue;
                };

                finalData.push(out);
            };
            if (finalData.length === 0) return showError("CSV Empty", `Nothing to import (${noInfos} errors found)`);

            let tmp = document.querySelector(".root__content");
            if (!tmp) return;

            animateBox({currentTarget: tmp}, <YesNoModal
                heading="CSV Import"
                text={`You are about to import ${finalData.length} items, and ignoring ${noInfos} rows with errors. Are You sure?`}
                isRightButtonNormal={true}
                buttonRightCallback={async args => {
                    args.spinner(true);
                    args.disabledAll(true);

                    let success = 0;
                    let fail = 0;

                    args.setProgress({value: 0, max: finalData.length});
                    for (let item of finalData) {
                        await axios({
                            method: "POST",
                            url: `${backendModule.backendURL}/offers/copies/add`,
                            data: item,
                            ...backendModule.axiosConfig
                        }).then(res => {
                            if (res.data.status === "ok") return success++;
                            fail++;
                        }).catch(() => fail++);
                        args.setProgress({value: success+fail, max: finalData.length});
                    };
                    args.spinner(false);
                    args.disabledAll(false);
                    args.setProgress();
                    args.close();

                    showError("CSV Import Success", `Success!, Imported ${success} rows, ${fail} rows failed to import`);
                    let ts = Date.now();
                    timestampRef.current = ts;
                    getData(ts);
                }}
            />);
        };
        fr.readAsText(file);
    };

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen?.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        setCanPaginate(false);
        let ts = Date.now();
        timestampRef.current = ts;
        continueData(ts);
    }, [canPaginate, curOnScreen.isIntersecting]);

    React.useEffect(() => {
        let handler = () => {
            let ts = Date.now();
            timestampRef.current = ts;
            getData(ts);
        };

        handler();
        curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));

        return () => {
            try {curDispatch(siteFunctionsActions.removeHeaderRefreshAction(handler))} catch {};
        };  
    }, [search]);

    return <div className="route__userOfferCopies route__userOfferCopies--list">
        {(userSelector?.Flags?.isAdmin || userSelector?.Flags?.canManageOfferCopies) && <div className="route__userOfferCopies__buttons">
            <div className="route__userOfferCopies__buttons__button" onClick={e => {
                e.currentTarget.parentNode.querySelector("[data-type=csv-import]").click();
            }}>
                <img src="/images/icon_export.svg" />
                <span>Import from CSV</span>
            </div>
            <input type="file" accept="text/csv" style={{display: "none"}} data-type="csv-import" onChange={e => {
                if (e.target.files.length === 1) csvImport(e.target.files[0]);
            }} />
            <div className="route__userOfferCopies__buttons__button" onClick={e => {
                animateBox(e, <AddCopy OfferID={curParams?.id} onChange={() => {
                    let ts = Date.now();
                    timestampRef.current = ts;
                    getData(ts);
                }} />);
            }}>
                <img src="/images/icon_close.svg" style={{ transform: "rotate(45deg)" }} />
                <span>Add copy</span>
            </div>
        </div>}
        <FilterBySearch onChange={e => setSearch(e)} />
        <FilteredCustomTable
            theme={themeSelector}
            accent="#6C5DD3"
            headers={["Date", "Copy text", "Actions"]}
            customColumns={["max-content", "1fr", "max-content"]}
            style={{width: "100%", marginTop: "20px", columnGap: "40px"}}
            data={(()=>{
                if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                if (data.status === "error") return [{columns: [{keyID: "noData-error", type: "text", text: "Error while fetching data", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]}];

                let out = [];
                for (let item of data.data) {
                    out.push([
                        {keyID: item.ID, type: "text", text: moment(item.createdAt).toDate().toLocaleString(), style: {paddingRight: "20px"}},
                        {keyID: item.ID, type: "custom", data: <div style={{width: "100%"}}>
                            {item.CreativeCopy && <>
                                <h4 style={{width: "100%"}}>Creative copy</h4>
                                <p style={{marginBottom: "20px", wordBreak: "break-all", width: "100%", maxWidth: "500px"}}>{item.CreativeCopy}</p>
                            </>}
                            {item.PrimaryCopy && <>
                                <h4 style={{width: "100%"}}>Primary copy</h4>
                                <p style={{marginBottom: "20px", wordBreak: "break-all", width: "100%", maxWidth: "500px"}}>{item.PrimaryCopy}</p>
                            </>}
                            {item.CTACopy && <>
                                <h4 style={{width: "100%"}}>CTA copy</h4>
                                <p style={{marginBottom: "20px", wordBreak: "break-all", width: "100%", maxWidth: "500px"}}>{item.CTACopy}</p>
                            </>}
                        </div>},
                        ((userSelector?.Flags?.isAdmin || userSelector?.Flags?.canManageOfferCopies) ? {keyID: item.ID, type: "group", group: [
                            {keyID: item.ID, type: "button", text: "Edit", style: {marginRight: "10px"}, onClick: e => {
                                animateBox(e, <AddCopy item={item} onChange={(e) => {
                                    let curIndex = data.data.indexOf(item);
                                    setData(d => {
                                        return {
                                            ...d,
                                            data: [
                                                ...d.data.filter((_, df) => df < curIndex),
                                                {...item, ...e},
                                                ...d.data.filter((_, df) => df > curIndex)
                                            ]
                                        };
                                    });
                                }} />)
                            }},
                            {keyID: item.ID, type: "button", text: "Remove", onClick: e => removeCopy(e, item)},
                        ]} : null)
                    ].filter(f => f));
                };

                if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);
                return out;
            })()}
        />
        {canPaginate && <div ref={curOnScreen.measureRef} style={{width: "1px", height: "1px", opacity: 0}}></div>}
    </div>
};

export const AddCopy = props => {
    const [infoP, setInfoP] = React.useState({
        text: "",
        hadError: false
    });
    const [spinner, setSpinner] = React.useState(false);

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const creativeRef = React.useRef();
    const primaryRef = React.useRef();
    const ctaRef = React.useRef();

    const addCopy = () => {
        if (spinner) return;

        let data = {
            OfferID: props?.OfferID,
            CreativeCopy: creativeRef.current.value,
            PrimaryCopy: primaryRef.current.value,
            CTACopy: ctaRef.current.value
        };

        setInfoP(ip => {return {...ip, hadError: false}});
        if (!data.CreativeCopy && !data.PrimaryCopy && !data.CTACopy) {
            return setInfoP(ip => {return {...ip, hadError: true, text: "At least one copy has to be filled"}});
        };

        if (props.item) data["ID"] = props.item["ID"];

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/copies/${props.item ? "edit" : "add"}`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (typeof(props?.onChange) === "function") props.onChange(data);
                props.onClose();
            } else {
                setInfoP(ip => {return {...ip, hadError: true, text: `An error occured while ${props.item ? "editing" : "adding"} a copy!`}});
            };
        }).catch(() => {
            setInfoP(ip => {return {...ip, hadError: true, text: "Server timed out!"}});
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">{props.item ? "Edit" : "Add"} copy</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            <div className="genericModal__wrap__input genericModal__wrap__input--text">
                <p>Creative copy</p>
                <textarea ref={creativeRef} defaultValue={props?.item?.CreativeCopy}></textarea>
            </div>
            <div className="genericModal__wrap__input genericModal__wrap__input--text">
                <p>Primary copy</p>
                <textarea ref={primaryRef} defaultValue={props?.item?.PrimaryCopy}></textarea>
            </div>
            <div className="genericModal__wrap__input genericModal__wrap__input--text">
                <p>CTA copy</p>
                <textarea ref={ctaRef} defaultValue={props?.item?.CTACopy}></textarea>
            </div>

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Cancel</div>
                <div className="genericModal__wrap__buttons__btn" onClick={() => !spinner && addCopy()}>
                    {spinner ? <Spinner style={{width: "17px", height: "17px"}} color={themeSelector === "dark" ? "white" : "black"} /> : (props.item ? "Edit" : "Add")}
                </div>
            </div>

            {infoP.text && <div className="genericModal__wrap__infoP" style={{opacity: infoP.hadError ? 1 : 0}}>
                {infoP.text}
            </div>}
        </div>
    </div>
};

export default UserOfferCopies;
export { UserCopiesForOffer };