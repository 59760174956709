import React from "react";
import "./index.scss";

import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";
import * as backendModule from "../../../modules/backendModule";
import * as basicStylesModule from "../../../modules/basicStylesModule";
import { animateBox } from "../../../modules/componentAnimation";
import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen";

import { FilteredCustomTable } from "../../customComponents/Table";
import Spinner from "../../customComponents/Spinner";

const CampaignLandingRotationsModal = props => {
    const [data, setData] = React.useState();
    const [website, setWebsite] = React.useState();
    const [cmsData, setCMSData] = React.useState();
    const [trackData, setTrackData] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const wrapTableItem = item => <span style={{padding: "0 50px 0 0px"}}>{item}</span>;

    const generateKey = item => {
        if (item?.ID) return item.ID;

        let final = "";
        if (!item) return String(item);
        for (let key of Object.keys(item)) {
            if (typeof (item[key]) === "object") {
                final += generateKey(item[key]);
                continue;
            };
            final += String(item[key]);
        };
        return final;
    };

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getCampaignByID`,
            data: {
                ID: props.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") getWebsite(res.data.data.LandingSiteID);
            return setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    const getWebsite = (ID) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/sites/getAllSites`,
            data: {
                extended: true,
                filters: [
                    {name: "ID", op: "eq", value: ID}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status !== "ok") return setWebsite(res.data);
            if (res.data.data.length !== 1) return setWebsite(backendModule.genericError);
            if (!res.data.data[0]?.IntegrationData?.ScaleCMS) return setWebsite(backendModule.genericError);
            getCMSData(res.data.data[0]?.IntegrationData?.ScaleCMS?.LandingList ?? []);
            setWebsite({status: "ok", data: res.data.data[0]});
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    const getCMSData = (w) => {
        if (!Array.isArray(w)) w = [];

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/globalIntegrations/scalecms/getWebsites`,
            data: {
                limit: 999999,
                offset: 0,
                filters: [
                    {name: "ID", op: "in", value: w}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setCMSData(res.data);
        }).catch(() => {
            return setCMSData(backendModule.genericError);
        });
    };

    const getTrackData = async () => {
        let allWebsites = cmsData.data.map(c => c.ID);

        let filters = [];
        if (Array.isArray(props.filters)) filters.push(...props.filters);

        let out = {};
        for (let item of allWebsites) {
            await axios({
                method: "POST",
                url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
                data: {
                    IntegrationID: data.data.IntegrationID,
                    filters: [
                        {name: "ID", op: "eq", value: data.data.ID}
                    ],
                    trackFilters: [
                        {name: "createdAt", op: "pdgeq", value: data.data.AdditionalData?.scalecms_rotation?.startedAt},
                        (data.data.AdditionalData?.scalecms_rotation?.completedAt ? {name: "createdAt", op: "pdleq", value: data.data.AdditionalData?.scalecms_rotation?.completedAt} : null),
                        {name: "IntegrationParams.st_cmslpid", op: "eq", value: String(item)},
                        ...filters
                    ].filter(t => t),
                    TableHeaders: [
                        "Visits",
                        data.data.AdditionalData?.scalecms_rotation?.Column
                    ]
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status !== "ok") return;
                if (res.data.data.length !== 1) return;

                out[item] = {
                    Visits: +(res.data.data[0].TableData?.["Visits"] ?? 0),
                    Value: +(res.data.data[0].TableData?.[data.data.AdditionalData?.scalecms_rotation?.Column] ?? 0)
                };
            }).catch(() => null);
        };

        setTrackData({status: "ok", data: out});
    };

    const prepareRowColors = () => {
        if (!website) return [];
        if (website.status !== "ok") return [];
        let out = [];

        if (!data.data.AdditionalData?.scalecms_rotation?.wonSite) return out;
        for (let item of (website.data.IntegrationData.ScaleCMS?.LandingList ?? [])) {
            if (String(item) === String(data.data.AdditionalData?.scalecms_rotation?.wonSite)) {
                out.push("rgba(50, 66, 46, 0.57)");
            } else {
                out.push("rgba(66, 46, 46, 0.57)");
            };
        };

        return out;
    };

    React.useEffect(() => {
        if (
            data?.status === "ok" &&
            cmsData?.status === "ok" &&
            website?.status === "ok"
        ) getTrackData();
    }, [data, cmsData, website]);

    React.useEffect(() => {
        getData();
    }, [props.filters]);

    return <div className="modals__campaignLandingRotationsModal__wrap__content" style={{
        gridTemplateColumns: "1fr auto",
        gridTemplateRows: "100%",
        padding: 0,
        maxHeight: "100%",
        minHeight: "100%",
        height: "100%"
    }} >
        <div className="modals__campaignLandingRotationsModal__wrap__content__left">
            {data ? <>
                {data.status === "ok" ? <>
                    <p>Required visits: {data.data.AdditionalData?.scalecms_rotation?.Visits} ({data.data.AdditionalData?.scalecms_rotation?.VisitsType === "combined" ? "combined" : "per website"})</p>
                    <p>Column: {data.data.AdditionalData?.scalecms_rotation?.ColumnType} {data.data.AdditionalData?.scalecms_rotation?.Column}</p>
                    <p>Started at: {moment(data.data.AdditionalData?.scalecms_rotation?.startedAt).toDate().toLocaleString()}</p>
                    <p>Completed: {data.data.AdditionalData?.scalecms_rotation?.completedAt ? <span style={{color: basicStylesModule.successColor}}>Yes</span> : <span style={{color: basicStylesModule.errorColor}}>No</span>}</p>
                </> : <p style={{color: basicStylesModule.errorColor}}>There was an error while fetching data</p>}
            </> : <Spinner color="white" />}

            <br />
            <br />

            {data?.status === "ok" && <div className="modals__campaignLandingRotationsModal__wrap__content__left__tableWrap">
                <FilteredCustomTable
                    colors={prepareRowColors()}
                    theme={themeSelector}
                    accent="#6C5DD3"
                    headers={["Website", "Visits", "Column", "Value"]}
                    style={{width: "100%"}}
                    data={(()=>{
                        if (!website || !cmsData) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                        if (website.status === "error" || cmsData.status === "error") return [[{keyID: "noData-error", type: "custom", data: "There was an error while fetching websites!", style: {color: basicStylesModule.errorColor}}]];
                        if (!website.data.IntegrationData?.ScaleCMS) {
                            return [[{keyID: "noData-noData", type: "custom", data: "Campaign lander is not meant for rotation, have you misconfigured anything?!"}]];
                        };
                        let out = [];
                        for (let item of (website.data.IntegrationData.ScaleCMS?.LandingList ?? [])) {
                            let foundCMSWebsite = cmsData.data.find(c => c.ID === item);
                            let tData = null;
                            let tVisits = null;
                            if (trackData) {
                                if (trackData.status === "ok") {
                                    tVisits = 0;
                                    tData = 0;
                                    // TODO: prepare data
                                    if (trackData.data[item]) {
                                        tData = trackData.data[item].Value;
                                        tVisits = trackData.data[item].Visits;
                                    };
                                } else {
                                    tData = "Error!";
                                    tVisits = "Error!";
                                };
                            };
                            out.push([
                                {keyID: item, type: "text", text: `[${item}] ${foundCMSWebsite?.Name ?? "?"}`},
                                (tVisits !== null ? {keyID: item, type: "text", text: tVisits} : {keyID: item, type: "spinner", color: "white", size: "18px"}),
                                {keyID: item, type: "text", text: data.data.AdditionalData?.scalecms_rotation?.Column},
                                (tData !== null ? {keyID: item, type: "text", text: tData} : {keyID: item, type: "spinner", color: "white", size: "18px"})
                            ]);
                        };

                        if (out.length === 0) return [[{keyID: "noData-noData", type: "custom", data: "Nothing to show, if the campaign is new, this is normal behavior."}]];
                        let sortBigger = data.data.AdditionalData?.scalecms_rotation?.ColumnType === "bigger";

                        out = out.sort((a, b) => {
                            if (a.length !== 4) return -1;
                            if (sortBigger) return Number(a[3].text) > Number(b[3].text) ? -1 : 1;
                            
                            return Number(a[3].text) > Number(b[3].text) ? 1 : -1;
                        });
                        return out;
                    })()}
                />
            </div>}
        </div>
    </div>;
};

export default CampaignLandingRotationsModal;