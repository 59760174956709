import React from "react";
import "./index.scss";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import { genericStatusColorPills } from "../../../../modules/miscModule";
import * as siteFunctionsActions from "../../../../actions/siteFunctionsActions";
import * as backendModule from "../../../../modules/backendModule";
import { animateBox } from "../../../../modules/componentAnimation";
import useDefer from "../../../../modules/hooks/useDefer";
import useOnScreen from "../../../../modules/hooks/useOnScreen";

import YesNoModal from "../../../../components/modals/YesNoModal";

import { FilteredCustomTable } from "../../../../components/customComponents/Table";
import ButtonWithDropdown from "../../../../components/customComponents/ButtonWithDropdown";
import Dropdown from "../../../../components/customComponents/Dropdown";
import Spinner from "../../../../components/customComponents/Spinner";

const AdminGlobalIntegrations_JP = () => {
    const [activeTab, setActiveTab] = React.useState(0);

    const contentRef = React.useRef();

    const setTab = (t) => {
        if (activeTab === t) return;

        contentRef.current.animate([
            {opacity: getComputedStyle(contentRef.current).opacity},
            {opacity: 0}
        ], {
            duration: 150,
            iterations: 1,
            fill: "both",
            easing: "ease"
        }).onfinish = () => {
            setActiveTab(t);

            contentRef.current.animate([
                {opacity: getComputedStyle(contentRef.current).opacity},
                {opacity: 1}
            ], {
                duration: 150,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };
    };

    return <div className="route__admin__globalIntegrationsJP">
        <div className="route__admin__globalIntegrationsJP__tabs">
            <div className={`route__admin__globalIntegrationsJP__tabs__tab ${activeTab === 0 ? "route__admin__globalIntegrationsJP__tabs__tab--active" : ""}`} onClick={() => setTab(0)}>Profile / Usage</div>
            <div className={`route__admin__globalIntegrationsJP__tabs__tab ${activeTab === 1 ? "route__admin__globalIntegrationsJP__tabs__tab--active" : ""}`} onClick={() => setTab(1)}>Services</div>
        </div>

        <div className="route__admin__globalIntegrationsJP__content" ref={contentRef}>
            {(()=>{
                switch (activeTab) {
                    case 0: return <>
                        <AdminGlobalIntegrations_JP__Profile />
                        <AdminGlobalIntegrations_JP__Orders />
                    </>
                    case 1: return <AdminGlobalIntegrations_JP__Services />
                    default: return null;
                };
            })()}
        </div>
    </div>
};

const AdminGlobalIntegrations_JP__Profile = () => {
    const [data, setData] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const curDispatch = useDispatch();

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/globalIntegrations/jp/getStats`,
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    React.useEffect(() => {
        getData();

        const handler = () => getData();

        curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));

        return () => curDispatch(siteFunctionsActions.removeHeaderRefreshAction(handler));
    }, []);

    return <div className="route__admin__globalIntegrationsJP__profile">

        <div className="route__admin__globalIntegrationsJP__profile__kpi">
            <div className="route__admin__globalIntegrationsJP__profile__kpi__item">
                <div className="route__admin__globalIntegrationsJP__profile__kpi__item__top">
                    <div className="route__admin__globalIntegrationsJP__profile__kpi__item__top__left">
                        <p>Balance</p>
                    </div>
                </div>
                <div className="route__admin__globalIntegrationsJP__profile__kpi__item__bottom">
                    <div className="route__admin__globalIntegrationsJP__profile__kpi__item__bottom__left">
                        {data ? <>
                            {data.status === "ok" ? `${data.data.Balance} €` : "?"}
                        </> : <Spinner style={{width: "42px", height: "42px"}} color={themeSelector === "dark" ? "white" : "black"} />}
                    </div>
                </div>
            </div>

            <div className="route__admin__globalIntegrationsJP__profile__kpi__item">
                <div className="route__admin__globalIntegrationsJP__profile__kpi__item__top">
                    <div className="route__admin__globalIntegrationsJP__profile__kpi__item__top__left">
                        <p>Total orders</p>
                    </div>
                </div>
                <div className="route__admin__globalIntegrationsJP__profile__kpi__item__bottom">
                    <div className="route__admin__globalIntegrationsJP__profile__kpi__item__bottom__left">
                        {data ? <>
                            {data.status === "ok" ? data.data.TotalOrders : "?"}
                        </> : <Spinner style={{width: "42px", height: "42px"}} color={themeSelector === "dark" ? "white" : "black"} />}
                    </div>
                </div>
            </div>

            <div className="route__admin__globalIntegrationsJP__profile__kpi__item">
                <div className="route__admin__globalIntegrationsJP__profile__kpi__item__top">
                    <div className="route__admin__globalIntegrationsJP__profile__kpi__item__top__left">
                        <p>Pending orders</p>
                    </div>
                </div>
                <div className="route__admin__globalIntegrationsJP__profile__kpi__item__bottom">
                    <div className="route__admin__globalIntegrationsJP__profile__kpi__item__bottom__left">
                        {data ? <>
                            {data.status === "ok" ? data.data.TotalOrders - data.data.CompletedOrders - data.data.FailedOrders : "?"}
                        </> : <Spinner style={{width: "42px", height: "42px"}} color={themeSelector === "dark" ? "white" : "black"} />}
                    </div>
                </div>
            </div>

            <div className="route__admin__globalIntegrationsJP__profile__kpi__item">
                <div className="route__admin__globalIntegrationsJP__profile__kpi__item__top">
                    <div className="route__admin__globalIntegrationsJP__profile__kpi__item__top__left">
                        <p>Completed orders</p>
                    </div>
                </div>
                <div className="route__admin__globalIntegrationsJP__profile__kpi__item__bottom">
                    <div className="route__admin__globalIntegrationsJP__profile__kpi__item__bottom__left">
                        {data ? <>
                            {data.status === "ok" ? data.data.CompletedOrders : "?"}
                        </> : <Spinner style={{width: "42px", height: "42px"}} color={themeSelector === "dark" ? "white" : "black"} />}
                    </div>
                </div>
            </div>

            <div className="route__admin__globalIntegrationsJP__profile__kpi__item">
                <div className="route__admin__globalIntegrationsJP__profile__kpi__item__top">
                    <div className="route__admin__globalIntegrationsJP__profile__kpi__item__top__left">
                        <p>Failed orders</p>
                    </div>
                </div>
                <div className="route__admin__globalIntegrationsJP__profile__kpi__item__bottom">
                    <div className="route__admin__globalIntegrationsJP__profile__kpi__item__bottom__left">
                        {data ? <>
                            {data.status === "ok" ? data.data.FailedOrders : "?"}
                        </> : <Spinner style={{width: "42px", height: "42px"}} color={themeSelector === "dark" ? "white" : "black"} />}
                    </div>
                </div>
            </div>

        </div>

    </div>
};

const AdminGlobalIntegrations_JP__Services = props => {
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [selectedData, setSelectedData] = React.useState([]);

    const checkboxFunctionsRef = React.useRef();
    const curDispatch = useDispatch();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const jpServiceTypeSelector = useSelector(state => state?.types?.globalIntegrations?.jp?.services ?? {});

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/globalIntegrations/jp/getAllServices`,
            data: {
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        }).finally(() => {
            try {
                setSelectedData([]);
                checkboxFunctionsRef.current?.reset();
            } catch {};
        });
    };

    const editService = e => {
        if (selectedData.length !== 1) return;
        if (!data) return;
        if (data.status !== "ok") return;

        let foundService = data.data.find(dt => dt.ID === selectedData[0]);
        if (!foundService) return;

        animateBox(e, <AdminGlobalIntegrations_JP__Services__AddService edit={foundService} onChange={() => {
            getData();
        }} />);
    };

    const deleteService = e => {
        animateBox(e, <YesNoModal
            heading="Are You sure?"
            text={[
                "You are about to remove ",
                <span style={{ color: "rgb(108, 93, 211)" }}>{selectedData.length} services</span>,
                ". If you remove these services, they cant be used anymore, and all data regarding usage will be removed aswell. ",
                "This action is irreversible, are You sure?"
            ]}
            isRightButtonNormal={true}
            buttonRightCallback={async arg => {
                arg.disabledAll(true);
                arg.spinner(true);

                for (let item of selectedData) {
                    await axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/globalIntegrations/jp/removeService`,
                        data: {
                            ID: item
                        },
                        ...backendModule.axiosConfig
                    }).then(() => null).catch(() => null);
                };

                if (data?.status === "ok") {
                    setData(d => {
                        return {
                            ...d,
                            data: d.data.filter(dt => !selectedData.includes(dt.ID))
                        };
                    });
                };
                arg.disabledAll(false);
                arg.spinner(false);
                arg.close();

                try {
                    setSelectedData([]);
                    checkboxFunctionsRef.current?.reset();
                } catch {};
            }}
        />);
    };

    React.useEffect(() => {
        getData();

        let handler = () => getData();

        curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));

        return () => curDispatch(siteFunctionsActions.removeHeaderRefreshAction(handler));
    }, [filters]);

    return <div className="route__admin__globalIntegrationsJP__services">
        <div className="route__admin__globalIntegrationsJP__services__buttons">
            {selectedData.length === 0 && <div className="route__user__campaigns__head__right__btn" onClick={e => animateBox(e, <AdminGlobalIntegrations_JP__Services__AddService onChange={() => {
                getData();
            }} />)}>
                <img src="/images/icon_close.svg" style={{ transform: "rotate(45deg)" }} />
                <p>Add service</p>
            </div>}
            {selectedData.length > 0 && <ButtonWithDropdown
                value="More actions"
                image="/images/icon_edit.svg"
                data={[
                    (selectedData.length === 1 ? { name: "Edit", onClick: e => editService(e) } : null),
                    { name: `Remove ${selectedData.length} services`, onClick: e => deleteService(e) },
                ].filter(t => t)}
            />}
        </div>

        <FilteredCustomTable
            showSpinner={false}
            checkboxCB={(data?.status === "ok" && data?.data?.length > 0) ? e => setSelectedData(e) : undefined}
            checkboxFunctions={cf => checkboxFunctionsRef.current = cf()}
            headers={["ServiceID", "Name", "Type", "Average price"]}
            accent="#6C5DD3"
            theme={themeSelector}
            filters={[
                { name: "ServiceID", friendlyName: "Service ID", type: "string" },
                { name: "Name", friendlyName: "Service name", type: "string" },
                { name: "Type", friendlyName: "Service type", type: "custom", varType: "number", data: Object.keys(jpServiceTypeSelector).map(key => {
                    return {text: jpServiceTypeSelector[key], value: key};
                }) },
                { name: "Price", friendlyName: "Price", type: "number" }
            ]}
            filterCB={f => setFilters(f)}
            data={(()=>{
                if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: "white"}]];
                if (data.status === "error") return [[{keyID: "noData-error", type: "text", text: "Error while fetching data"}]];

                let out = [];
                for (let item of data.data) {
                    out.push([
                        {keyID: item.ID, type: "text", text: item.ServiceID},
                        {keyID: item.ID, type: "text", text: item.Name},
                        {keyID: item.ID, type: "text", text: jpServiceTypeSelector[String(item.Type)]},
                        {keyID: item.ID, type: "text", text: `${item.Price} €`},
                    ])
                };

                if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);

                return out;
            })()}
        />
    </div>
};

const AdminGlobalIntegrations_JP__Services__AddService = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState({
        error: "",
        hadError: false,
        inputs: []
    });
    const [serviceType, setServiceType] = React.useState(props?.edit?.Type ? String(props.edit.Type) : undefined);
    
    const serviceIDRef = React.useRef();
    const serviceNameRef = React.useRef();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const jpServiceTypeSelector = useSelector(state => state?.types?.globalIntegrations?.jp?.services ?? {});

    const onClose = () => {
        if (spinner) return;
        props.onClose();
    };

    const addService = () => {
        setInfoP(i => { return { ...i, hadError: false, inputs: [] } });
        let data = {
            ServiceID: serviceIDRef.current.value,
            Type: serviceType,
            Name: serviceNameRef.current.value
        };
        if (props.edit) data["ID"] = props.edit["ID"];

        if (!data.ServiceID) return setInfoP(i => { return { ...i, hadError: true, inputs: ["id"], error: "Service ID can't be empty." } });
        if (!data.Name) return setInfoP(i => { return { ...i, hadError: true, inputs: ["name"], error: "Service name can't be empty." } });
        if (!data.Type) return setInfoP(i => { return { ...i, hadError: true, inputs: ["type"], error: "Service type can't be empty." } });

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/globalIntegrations/jp/${props.edit ? "editService" : "addService"}`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.onChange();
                props.onClose();
            } else {
                return setInfoP(i => { return { ...i, hadError: true, inputs: [], error: "Error while adding a service (Service ID invalid probably?)" } });
            };
        }).catch(() => {
            return setInfoP(i => { return { ...i, hadError: true, inputs: [], error: "Server timed out!" } });
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="route__admin__globalIntegrationsJP__services__add">
        <div className="route__admin__globalIntegrationsJP__services__add__wrap">
            <div className="route__admin__globalIntegrationsJP__services__add__wrap__head">
                <div className="route__admin__globalIntegrationsJP__services__add__wrap__head__left">{props?.edit ? "Edit" : "Add"} service</div>
                <div className="route__admin__globalIntegrationsJP__services__add__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            <div className={`route__user__campaigns__add__wrap__input ${infoP.inputs.includes("id") ? "route__user__campaigns__add__wrap__input--error" : ""}`}>
                <p>Service ID (From JP &gt; Services)</p>
                <input defaultValue={props.edit?.ServiceID} ref={serviceIDRef} type="text" placeholder="Service ID" />
            </div>
            <div className={`route__user__campaigns__add__wrap__input ${infoP.inputs.includes("name") ? "route__user__campaigns__add__wrap__input--error" : ""}`}>
                <p>Service name</p>
                <input defaultValue={props.edit?.Name} ref={serviceNameRef} type="text" placeholder="Service name" />
            </div>
            <div className={`route__user__campaigns__add__wrap__input ${infoP.inputs.includes("type") ? "route__user__campaigns__add__wrap__input--error" : ""}`}>
                <p>Service type</p>
                <Dropdown
                    accent="#6C5DD3"
                    theme={themeSelector}
                    inlinePlaceholder="Service type"
                    data={Object.keys(jpServiceTypeSelector).map(key => {
                        return {name: jpServiceTypeSelector[key], value: Number(key)}
                    })}
                    onChange={e => setServiceType(e?.value)}
                    selected={(() => {
                        if (serviceType === null || serviceType === undefined) return;
                        let tmp = Object.keys(jpServiceTypeSelector);
                        return tmp.indexOf(String(serviceType))
                    })()}
                />
            </div>


            <div className="route__admin__globalIntegrationsJP__services__add__wrap__buttons">
                <div className="route__admin__globalIntegrationsJP__services__add__wrap__buttons__btn route__admin__globalIntegrationsJP__services__add__wrap__buttons__btn--secondary" onClick={onClose}>Close</div>
                <div className="route__admin__globalIntegrationsJP__services__add__wrap__buttons__btn" onClick={addService}>
                    {spinner ? <Spinner style={{ width: "24px", height: "24px" }} color="#fff" /> : "Save"}
                </div>
            </div>

            <p className="route__user__campaigns__add__wrap__infoP" style={{
                opacity: infoP.hadError ? 1 : 0
            }}>{infoP.error}</p>
        </div>
    </div>
};

const AdminGlobalIntegrations_JP__Orders = props => {
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [spinner, setSpinner] = React.useState(false);
    const [canPaginate, setCanPaginate] = React.useState(false);

    const curOnScreen = useOnScreen();
    const curDefer = useDefer();
    const curDispatch = useDispatch();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const orderStatusCodesSelector = useSelector(state => state?.types?.globalIntegrations?.jp?.orderStatusCodes ?? {});
    const singleOrderStatusCodesSelector = useSelector(state => state?.types?.globalIntegrations?.jp?.singleOrderStatusCodes ?? {});

    const curTimestampRef = React.useRef(Date.now());

    const getData = (ts) => {
        if (curTimestampRef.current !== ts) return;

        setCanPaginate(false);
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/globalIntegrations/jp/getAllOrders`,
            data: {
                limit: 20,
                offset: 0,
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (curTimestampRef.current !== ts) return;
            setData(res.data);

            if (res.data.status === "ok") {
                if (res.data.data.length === 20) setCanPaginate(true);
            };
        }).catch(() => {
            if (curTimestampRef.current !== ts) return;
            setData(backendModule.genericError);
        }).finally(() => {
            setSpinner(false);
        });
    };

    const continueData = ts => {
        if (!data) return;
        if (data.status !== "ok") return;
        if (!canPaginate) return;

        setSpinner(true);
        setCanPaginate(false);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}globalIntegrations/jp/getAllOrders`,
            data: {
                limit: 20,
                offset: data.data.length,
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (ts !== curTimestampRef.current) return;
            if (res.data.status !== "ok") return;

            setData(d => {
                return {
                    ...d,
                    data: [
                        ...d.data,
                        ...res.data.data
                    ]
                };
            });
            if (res.data.data.length === 20) setCanPaginate(true);
        }).catch(() => null).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        let ts = Date.now();
        curTimestampRef.current = ts;
        curDefer(() => continueData(ts), 500);
    }, [curOnScreen.isIntersecting, canPaginate]);

    React.useEffect(() => {
        let ts = Date.now();
        curTimestampRef.current = ts;
        curDefer(() => getData(ts), 100);

        const handler = () => {
            let ts = Date.now();
            curTimestampRef.current = ts;
            curDefer(() => getData(ts), 0);
        };

        curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));

        return () => curDispatch(siteFunctionsActions.removeHeaderRefreshAction(handler));
    }, [filters]);

    return <div className="route__admin__globalIntegrationsJP__orders">
        <FilteredCustomTable
            style={{width: "100%"}}
            spinnerColor={themeSelector === "dark" ? "white" : "black"}
            showSpinner={spinner}
            accent="#6C5DD3"
            theme={themeSelector}
            filters={[
                {name: "ID", friendlyName: "Order ID", type: "string"},
                {name: "IntegrationID", friendlyName: "Facebook account ID", type: "string"},
                {name: "Quantity", friendlyName: "Quantity", type: "number"},
                {name: "Spent", friendlyName: "Money spent", type: "number"},
                {name: "Status", friendlyName: "Order status", type: "custom", varType: "number", data: Object.keys(orderStatusCodesSelector).map(key => {
                    return {text: orderStatusCodesSelector[key], value: key};
                })},
                {name: "FullyUpdated", friendlyName: "Fully updated", type: "boolean"},
                {name: "createdAt", friendlyName: "Date created", type: "date"}
            ]}
            filterCB={f => setFilters(f)}
            headers={["Date", "Service ID", "Service name", "Orders", "Quantity / Combined", "Spent", "Status", "Fully updated"]}
            data={(()=>{
                if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: "white"}]];
                if (data.status === "error") return [[{keyID: "noData-error", type: "text", text: "Error while fetching data!"}]];

                let out = [];

                for (let item of data.data) {
                    out.push([
                        {keyID: item.ID, type: "text", text: (new Date(item.createdAt)).toLocaleString()},
                        {keyID: item.ID, type: "text", text: item._JPServiceID},
                        {keyID: item.ID, type: "text", text: item._ServiceName},
                        {keyID: item.ID, type: "text", text: item.OrderIDs.length},
                        {keyID: item.ID, type: "text", text: `${item.Quantity} / ${item.Quantity * item.OrderIDs.length}`},
                        {keyID: item.ID, type: "text", text: `${item.Spent} €`},
                        {keyID: item.ID, type: "text", text: orderStatusCodesSelector[item.Status] ?? "-"},
                        {keyID: item.ID, type: "text", text: item.FullyUpdated ? <span style={{color: themeSelector === "dark" ? "#69f978" : "green"}}>Yes</span> : <span style={{color: themeSelector === "dark" ? "rgb(255, 136, 136)" : "red"}}>No</span>},
                        {keyID: item.ID, type: "groupNewline", group: [
                            {keyID: item.ID, type: "text", text: <p className="route__admin__globalIntegrationsJP__orders__pills">
                                <strong>Info:</strong>
                                <span>UserID: {item.CreatedByTeamUserID ?? item.CreatedBy}</span>
                                <span>ServiceID (internal): {item.ServiceID}</span>
                                <span>Facebook account ID: {item.IntegrationID}</span>
                            </p>}
                        ]},
                        {keyID: item.ID, type: "groupNewline", group: [
                            {keyID: item.ID, type: "text", text: <p className="route__admin__globalIntegrationsJP__orders__pills">
                                <strong>Orders:</strong>
                                {item.OrderIDs.map(order => {
                                    return <span style={{backgroundColor: genericStatusColorPills(order.Status)}}>{order.ID}: {singleOrderStatusCodesSelector[order.Status] ?? "-"}</span>
                                })}
                            </p>}
                        ]},
                        {keyID: item.ID, type: "groupNewline", group: [
                            {keyID: item.ID, type: "text", text: <p className="route__admin__globalIntegrationsJP__orders__pills">
                                <strong>Links:</strong>
                                {item.OrderIDs.map(order => {
                                    return <span style={{backgroundColor: "#373A43"}}>{order.ID}: {order.URL}</span>
                                })}
                            </p>}
                        ]}
                    ]);
                };

                if (out.length === 0) out.push([
                    {keyID: "noData-noData", type: "text", text: "Nothing to show..."}
                ]);

                if (spinner) out.push([
                    {keyID: "data-spinner", type: "spinner", color: "white"}
                ]);

                return out;
            })()}
        />
        {canPaginate && <div ref={curOnScreen.measureRef} style={{width: "1px", height: "1px", opacity: 0}}></div>}
    </div>
};

export default AdminGlobalIntegrations_JP;