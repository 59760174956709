import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";

import * as backendModule from "../../../modules/backendModule";
import { animateBox } from "../../../modules/componentAnimation";
import { countries } from "../../../modules/countryModule";

import Spinner from "../../../components/customComponents/Spinner";
import StyledButton from "../../../components/styledComponents/Button";
import Dropdown from "../../../components/customComponents/Dropdown";

import YesNoModal from "../../../components/modals/YesNoModal";
import { CreativeModal, AddCreative } from "../Creatives/creativesST";

let workTimeout = null;
const UserCreativeDesigner = () => {
    const [kpiData, setKPIData] = React.useState();
    const [timestamp, setTimestamp] = React.useState(Date.now());
    const [curJob, setCurJob] = React.useState();
    const [spinner, setSpinner] = React.useState(false);

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const playNotificationSound = () => {
        try {
            let a = new Audio(`/audio/notification.mp3`);
            a.autoplay = false;
            a.loop = false;

            a.addEventListener("ended", () => {
                a.remove();
            });

            a.play();
        } catch {};
    };

    const convertSecondsToString = seconds => {
        if (!seconds) return "-";

        let s = Number(Number(seconds).toFixed(0));
        let m = 0;
        let h = 0;

        while (s >= 60) {
            m += 1;
            s -= 60;
            
            if (m >= 60) {
                h += 1;
                m -= 60;
            };
        };

        let out = [];
        if (h > 0) out.push(`${h}h`);
        if (m > 0 || h > 0) out.push(`${m}m`);
        out.push(`${s}s`);

        return out.join(" : ");
    };

    const finishWork = ids => {
        if (!Array.isArray(ids)) return;
        if (ids.length === 0) return;
        if (!curJob) return;

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/requests/finishWork`,
            data: {
                ID: curJob.ID,
                Creatives: ids
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setCurJob();
                setTimestamp(Date.now());
            } else {
                animateBox(<YesNoModal
                    heading="Error"
                    text="Creatives were uploaded, but the server failed to mark the job as completed. Give the Request ID to the administrator for further help."
                    buttonLeftHidden={false}
                    buttonRightText="Ok"
                    isRightButtonNormal={true}
                />);
            };
        }).catch(() => {
            animateBox(<YesNoModal
                heading="Error"
                text="Server timed out!"
                buttonLeftHidden={false}
                buttonRightText="Ok"
                isRightButtonNormal={true}
            />);
        }).finally(() => {
            setSpinner(false);
        });
    };

    const getCurCreative = () => {
        if (curJob._creative) return;
        if (!curJob.ExistingCreativeID) return;

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/getAllCreatives`,
            data: {
                filters: [
                    {name: "ID", op: "eq", value: curJob.ExistingCreativeID}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setCurJob(cj => {
                return {...cj, _creative: res.data};
            });
        }).catch(() => {
            setCurJob(cj => {
                return {...cj, _creative: backendModule.genericError};
            });
        });
    };

    // get KPI data
    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/requests/getDesignerStats`,
            ...backendModule.axiosConfig
        }).then(res => setKPIData(res.data)).catch(() => setKPIData(backendModule.genericError));
    }, [timestamp]);

    React.useEffect(() => {
        clearTimeout(workTimeout);

        const getJob = () => {
            if (curJob) return;

            axios({
                method: "POST",
                url: `${backendModule.backendURL}/creatives/requests/requestWork`,
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    setCurJob(res.data.data);
                    playNotificationSound();
                } else {
                    workTimeout = setTimeout(getJob, 5000);
                };
            }).catch(() => {
                workTimeout = setTimeout(getJob, 5000);
            });
        };

        setTimeout(getJob, 2000);

        return () => {
            clearTimeout(workTimeout);
        };
    }, [curJob]);

    React.useEffect(() => {
        if (!curJob) return;
        if (curJob._creative) return;
        if (curJob.ExistingCreativeID) {
            getCurCreative();
        };
    }, [curJob]);

    return <div className="route__user__creativeDesigner">
        <div className="route__user__creativeDesigner__kpi">
            <div className="route__user__creativeDesigner__kpi__item">
                <p>Completed today</p>
                <p>{kpiData ? (kpiData?.status === "ok" ? kpiData.data.Completed : "-") : <Spinner style={{width: "42px", height: "42px"}} color={themeSelector === "dark" ? "white" : "black"} />}</p>
            </div>
            <div className="route__user__creativeDesigner__kpi__item">
                <p>Rejected today</p>
                <p>{kpiData ? (kpiData?.status === "ok" ? kpiData.data.Rejected : "-") : <Spinner style={{width: "42px", height: "42px"}} color={themeSelector === "dark" ? "white" : "black"} />}</p>
            </div>
            <div className="route__user__creativeDesigner__kpi__item">
                <p>Avg. completion time</p>
                <p>{kpiData ? (kpiData?.status === "ok" ? convertSecondsToString(kpiData.data.AverageTime) : "-") : <Spinner style={{width: "42px", height: "42px"}} color={themeSelector === "dark" ? "white" : "black"} />}</p>
            </div>
        </div>

        {curJob ? <div className="route__user__creativeDesigner__job">
            <h2>Current job:</h2>
            <p>
                <span>Request ID:</span>
                <span>{curJob.ID}</span>
            </p>
            {curJob.ExistingCreativeID ? <p>
                <span>Job type:</span>
                <span style={{display: "flex", alignItems: "center", gap: "10px"}}>
                    Update existing creative
                    <br />
                    <StyledButton
                        style={{height: "100%"}}
                        isSpinner={!curJob._creative}
                        onClick={() => {
                            if (!curJob._creative) return;

                            let curCreative = null;
                            if (curJob._creative?.status === "ok") {
                                if (curJob._creative.data?.length === 1) {
                                    curCreative = curJob._creative.data[0];
                                };
                            };

                            if (!curCreative) return animateBox(<YesNoModal
                                heading="Error"
                                text="There was an error while fetching the creative"
                                buttonLeftHidden={true}
                                buttonRightText="Ok"
                                isRightButtonNormal={true}
                            />);

                            curCreative._allCreatives = [curCreative];
                            animateBox(<CreativeModal
                                performanceDateFilter={{
                                    start: moment().add(-6, "days").startOf("day"),
                                    end: moment().endOf("day")
                                }}
                                item={curCreative}
                                allUsers={[]}
                            />);
                        }}
                    >View creative</StyledButton>
                </span>
            </p> : <p>
                    <span>Job Type:</span>
                    <span>Create a new creative</span>
                </p>}
            <p>
                <span>Dimensions:</span>
                <span>Width: {curJob.Dimension_Width}px, Height: {curJob.Dimension_Height}px</span>
            </p>
            <p>
                <span>Countries:</span>
                <span className="route__user__creativeDesigner__job__pills">
                    {curJob.Countries.map(c => {
                        let curCountry = countries.find(cc => cc.code === c);
                        curCountry = curCountry ? curCountry.name : c;
                        return <div className="route__user__creativeDesigner__job__pills__pill">
                            <img src={`/images/countryFlags/${String(c).toLowerCase()}.png`} />
                            <span>{curCountry}</span>
                        </div>
                    })}
                </span>
            </p>


            <p>
                <span style={{alignSelf: "flex-start"}}>Description:</span>
                <span>{curJob.Description}</span>
            </p>
            <div style={{display: "flex", gap: "10px"}}>
                <StyledButton isSpinner={spinner} onClick={() => {
                    animateBox(<AddCreative
                        countries={curJob.Countries}
                        customGroupID={curJob.ExistingCreativeID || null}
                        onChange={ids => {
                            finishWork(ids);
                        }}
                    />);
                }}>Upload & finish</StyledButton>
                <StyledButton isSpinner={spinner} isSecondary={true} onClick={() => {
                    animateBox(<UserCreativeDesigner_reject
                        ID={curJob.ID}
                        onChange={() => {
                            setCurJob();
                            setTimestamp(Date.now());
                        }}
                        
                    />);
                }}>Reject</StyledButton>
            </div>
        </div> : <>
            <p>Waiting for a job</p>
            <p>Stay on this page to keep receiving jobs</p>
            <br />
            <br />
            <Spinner color={themeSelector === "dark" ? "white" : "black"} />
        </>}
    </div>
};

const UserCreativeDesigner_reject = props => {
    const [spinner, setSpinner] = React.useState();
    const [selectedStatus, setSelectedStatus] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const creativeRequestsStatusCodes = useSelector(state => state?.types?.creativeRequestsStatusCodes ?? {});

    const rejectJob = () => {
        if (!selectedStatus) return;
        if (spinner) return;

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/requests/rejectWork`,
            data: {
                ID: props.ID,
                Status: selectedStatus
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (props.onChange) props.onChange();
                props.onClose();
            } else {
                animateBox(<YesNoModal
                    heading="Error"
                    text="There was an error while saving the job. Try again later"
                    buttonLeftHidden={false}
                    buttonRightText="Ok"
                    isRightButtonNormal={true}
                />);
            };
        }).catch(() => {
            animateBox(<YesNoModal
                heading="Error"
                text="Server timed out!"
                buttonLeftHidden={false}
                buttonRightText="Ok"
                isRightButtonNormal={true}
            />);
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Reject job</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            <div className="genericModal__wrap__input">
                <p>Reject reason</p>
                <Dropdown
                    theme={themeSelector}
                    accent="#6C5DD3"
                    data={Object.keys(creativeRequestsStatusCodes).filter(key => {
                        if (Number(key) < 90 || Number(key) > 99) return false;
                        return true;
                    }).map(key => {
                        return {name: creativeRequestsStatusCodes[key], value: key}
                    })}
                    onChange={e => {
                        setSelectedStatus(e?.value);
                    }}
                />
            </div>

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Close</div>
                <div className="genericModal__wrap__buttons__btn" onClick={() => !spinner && rejectJob()}>
                    {spinner ? <Spinner style={{width: "17px", height: "17px"}} color={themeSelector === "dark" ? "white" : "black"} /> : "Reject"}
                </div>
            </div>
        </div>
    </div>
};

export default UserCreativeDesigner