import React from "react";
import "./index.scss";
import {useNavigate} from "react-router-dom";
import animateModule from "../../modules/animateModule";


const LandingHeader = () => {

    let curNavigate = useNavigate();

    const hamburger = React.useRef();
    const firstLine = React.useRef();
    const middleLine = React.useRef();
    const lastLine = React.useRef();
    const dropdownContent = React.useRef();

    const [dropdownOpened, setDropdownOpened] = React.useState(false);

    const [windowSize, setWindowSize] = React.useState(window.innerWidth)

    const animateNavigate = to => {
        animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));
        if(windowSize < 769){
            if (dropdownOpened) setDropdownOpened(false)
        }
    };

    const internalScroll = (item) => {
        let tmp = document.querySelector(item);
        if(!tmp) return;
        tmp.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start'});
        if(windowSize < 769){
            setDropdownOpened(!dropdownOpened);
        }
    }

    React.useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize(window.innerWidth);
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    })

    return <div className="route__landingHeader">
        <div className="route__landingHeader__wrapper">
            <div className="route__landingHeader__wrapper__inner">
                <div className="route__landingHeader__wrapper__inner__logo" onClick={() => {animateNavigate("/")}}>
                    <img src="/images/landing/Logo.svg" ></img>
                </div>

                <div className="route__landingHeader__wrapper__inner__nav route__landingHeader__wrapper__inner--hideOnMobile">
                    <a onClick={() => {internalScroll('#home')}}>home</a>
                    <a onClick={() => {internalScroll('#features')}}>features</a>
                    <a onClick={() => {internalScroll('#pricing')}}>pricing</a>
                </div>

                <div className="route__landingHeader__wrapper__inner__right route__landingHeader__wrapper__inner--hideOnMobile">
                    <a  onClick={() => animateNavigate("/register")}>new account</a>
                    <div className="route__landingHeader__wrapper__inner__right__login" onClick={() => animateNavigate("/login")}>
                        <p>log in</p>
                    </div>
                </div>

                <div className="route__landingHeader__wrapper__inner__mobile">
                    <div className={`route__landingHeader__wrapper__inner__mobile__box
                                    ${dropdownOpened && 'route__landingHeader__wrapper__inner__mobile__box--opened'} `} ref={hamburger} onClick={() => setDropdownOpened(d => !d)}>
                        <div className={`route__landingHeader__wrapper__inner__mobile__box__line route__landingHeader__wrapper__inner__mobile__box__line--first 
                                        ${dropdownOpened && 'inner__mobile__box__line--first--opened'} `} ref={firstLine}></div>
                        <div className={`route__landingHeader__wrapper__inner__mobile__box__line route__landingHeader__wrapper__inner__mobile__box__line--middle
                                        ${dropdownOpened && 'inner__mobile__box__line--middle--opened'}`} ref={middleLine}></div>
                        <div className={`route__landingHeader__wrapper__inner__mobile__box__line route__landingHeader__wrapper__inner__mobile__box__line--last
                                        ${dropdownOpened && 'inner__mobile__box__line--last--opened'}`} ref={lastLine}></div>
                    </div>
                    <div className={`route__landingHeader__wrapper__inner__mobile__content
                                    ${dropdownOpened && 'route__landingHeader__wrapper__inner__mobile__content--opened'} `} ref={dropdownContent}>
                        <a onClick={() => {internalScroll('#home')}}>home</a>
                        <a onClick={() => {internalScroll('#features')}}>features</a>
                        <a onClick={() => {internalScroll('#pricing')}}>pricing</a>
                        <a onClick={() => animateNavigate("/register")} className="route__landingHeader__wrapper__inner__mobile__content--new">new account</a>
                        <div className="route__landingHeader__wrapper__inner__mobile__content__login" onClick={() => animateNavigate("/login")}>
                            <p>log in</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
};


export default LandingHeader;