import React from "react";
import "./index.scss";

import axios from "axios";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import * as backendModule from "../../../modules/backendModule.js";
import * as siteFunctionsActions from "../../../actions/siteFunctionsActions.js";
import { getIntegrationImage } from "../../../modules/miscModule.js";
import { animateBox } from "../../../modules/componentAnimation.js";
import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen.js";

import * as basicStylesModule from "../../../modules/basicStylesModule.js";
import { FilteredCustomTable } from "../../../components/customComponents/Table";
import StyledButton from "../../../components/styledComponents/Button";
import Spinner from "../../../components/customComponents/Spinner";
import FilterByDate from "../../../components/filters/FilterByDate";
import PreviewImageModal from "../../../components/modals/PreviewImageModal";

const UserAccountBalances = () => {
    const [data, setData] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState();

    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "?");
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const curDispatch = useDispatch();
    const curDefer = useDefer();
    const curOnScreen = useOnScreen();
    const timestampRef = React.useRef();

    const getData = (ts) => {
        if (timestampRef.current !== ts) return;
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/balances/getAllBalances`,
            data: {
                limit: 20,
                offset: 0
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            setData(res.data);
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) setCanPaginate(true);
            };
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        });
    };

    const continueData = (ts) => {
        if (!data) return;
        if (data.status !== "ok") return;
        if (timestampRef.current !== ts) return;
        if (!canPaginate) return;
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/balances/getAllBalances`,
            data: {
                limit: 20,
                offset: 0,

                filters: [
                    {name: "CreatedBy", op: "notIn", value: data.data.map(d => d.CreatedBy)}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) setCanPaginate(true);

                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
            };
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        });
    };

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        curDefer(() => {
            let ts = Date.now();
            timestampRef.current = ts;
            continueData(ts);
        }, 500);
    }, [canPaginate, curOnScreen.measureRef]);

    React.useEffect(() => {
        let handler = () => {
            let ts = Date.now();
            timestampRef.current = ts;
            getData(ts);
        };

        handler();
        curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));

        return () => curDispatch(siteFunctionsActions.removeHeaderRefreshAction(handler));
    }, []);

    return <div className="route__user__accountBalances">
        <FilteredCustomTable
            accent="#6C5DD3"
            theme={themeSelector}
            headers={["User", "Balances"]}
            customColumns={["300px", "1fr"]}
            data={(()=>{
                if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                if (data.status === "error") return [[{keyID: "noData-error", type: "text", text: "There was an error while fetching data", style: {color: basicStylesModule.errorColor}}]];

                let out = [];

                for (let item of data.data) {
                    out.push([
                        {keyID: item.CreatedBy, type: "text", text: item._User.Username},
                        {keyID: item.CreatedBy, type: "custom", data: <div className="route__user__accountBalances__accounts">
                            {item.Accounts.map(acc => {
                                return <div className="route__user__accountBalances__accounts__account">
                                    <img src={getIntegrationImage(acc.IntegrationID)} />
                                    <span>{acc.AccountName}&nbsp;|&nbsp;</span>
                                    <span>{Number(acc.Balance).toFixed(2)} {currencySignSelector}&nbsp;|&nbsp;</span>
                                    {(acc.canHaveProof && !acc.LastMonthProof) && <span style={{color: basicStylesModule.errorColor}}>Missing last month proof! &nbsp;|&nbsp;</span>}
                                    <StyledButton onClick={e => animateBox(e, <AddBalance account={acc} onChange={() => {
                                        let ts = Date.now();
                                        timestampRef.current = ts;
                                        getData(ts);
                                    }} />)}>Add balance</StyledButton>
                                    <StyledButton onClick={e => animateBox(e, <BalanceHistory balanceID={acc.BalanceID} />)}>Top-up history</StyledButton>
                                    {acc.canHaveProof && <StyledButton onClick={e => animateBox(e, <ProofHistory balanceID={acc.BalanceID} />)}>Proof history</StyledButton>}
                                </div>;
                            })}
                        </div>}
                    ]);
                };

                return out;
            })()}
        />
        {canPaginate && <div ref={curOnScreen.measureRef} style={{width: "1px", height: "1px", opacity: 0}}></div>}
    </div>
};

const AddBalance = props => {
    const [infoP, setInfoP] = React.useState({
        error: "",
        hadErrors: false,
        inputs: []
    });
    const [spinner, setSpinner] = React.useState(false);

    const amountRef = React.useRef();

    const addAmount = () => {
        if (spinner) return;
        setInfoP(ip => {return {...ip, hadErrors: false, inputs: []}});

        let data = {
            ID: props.account.BalanceID,
            Amount: amountRef.current.value
        };
        data.Amount = Number(data.Amount);
        if (isNaN(data.Amount)) return setInfoP(ip => {return {...ip, hadErrors: true, error: "Amount must be a number", inputs: ["amount"]}});

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/balances/addBalance`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (props.onChange) props.onChange();
                props.onClose();
            } else {
                setInfoP(ip => {return {...ip, hadErrors: true, inputs: [], error: "Error while updating the balance!"}});
            };
        }).catch(() => {
            setInfoP(ip => {return {...ip, hadErrors: true, inputs: [], error: "Server timed out"}});
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Add balance</div>
                <div className="genericModal__wrap__head__right" onClick={props.onClose} style={{backgroundImage: `url("/images/icon_close.svg")`}}></div>
            </div>

            <p>You are about to add balance to the following account:</p>
            <p>{props.account.AccountName}</p>
            <br />

            <div className={`genericModal__wrap__input ${infoP.inputs.includes("amount") ? "genericModal__wrap__input--error" : ""}`}>
                <p>Amount to add</p>
                <input ref={amountRef} type="number" placeholder="Amount" />
            </div>

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Cancel</div>
                <div className="genericModal__wrap__buttons__btn" onClick={() => !spinner && addAmount()}>
                    {spinner ? <Spinner color="white" style={{width: "16px", height: "16px"}} /> : "Save"}
                </div>
            </div>

            <p className="genericModal__wrap__infoP" style={{
                opacity: infoP.hadErrors ? 1 : 0
            }}>{infoP.error}</p>
        </div>
    </div>
};

const BalanceHistory = props => {
    const [data, setData] = React.useState();

    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "?");
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const wrapRef = React.useRef();

    const onClose = () => {
        if (wrapRef.current) {
            wrapRef.current.animate([
                {right: getComputedStyle(wrapRef.current).right},
                {right: "-100%"}
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };

        props.onClose();
    };

    React.useEffect(() => {
        if (!wrapRef.current) return;

        wrapRef.current.animate([
            {right: getComputedStyle(wrapRef.current).right},
            {right: 0}
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/balances/getTopupHistory`,
            data: {
                ID: props.balanceID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    }, []);

    return <div className="route__user__accountBalances__historyModal" onClick={onClose}>
        <div className="route__user__accountBalances__historyModal__wrap" ref={wrapRef} onClick={e => e?.stopPropagation()} style={{width: "600px"}}>

            <div className="route__user__accountBalances__historyModal__wrap__top">
                <div className="route__user__accountBalances__historyModal__wrap__top__left">Top-up history</div>
                <div className="route__user__accountBalances__historyModal__wrap__top__right"><img src="/images/icon_close.svg" onClick={onClose} /></div>
            </div>

            <div className="route__user__accountBalances__historyModal__wrap__content">
                {data ? <>
                    {data.status === "ok" ? <>
                        <FilteredCustomTable
                            accent="#6C5DD3"
                            theme={themeSelector}
                            headers={["Date", "Amount"]}
                            data={(()=>{
                                let out = [];
                                for (let item of data.data.sort((a,b) => a.Date > b.Date ? -1 : 1)) {
                                    out.push([
                                        {keyID: item.Date, type: "text", text: moment(item.Date).toDate().toLocaleDateString()},
                                        {keyID: item.Date, type: "text", text: `${Number(item.Amount).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} ${currencySignSelector}`}
                                    ]);
                                };

                                if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);
                                return out;
                            })()}
                        />
                    </> : <p>Error while fetching data!</p>}
                </> : <>
                    <p>Fetching history, this might take a moment...</p>
                    <br />
                    <br />
                    <Spinner color={themeSelector === "dark" ? "white" : "black"} />
                </>}
            </div>

        </div>
    </div>
};

const ProofHistory = props => {
    const [data, setData] = React.useState();
    const [dateFilters, setDateFilters] = React.useState();

    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "?");
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const curDefer = useDefer();

    const wrapRef = React.useRef();
    const timestampRef = React.useRef();

    const onClose = () => {
        if (wrapRef.current) {
            wrapRef.current.animate([
                {right: getComputedStyle(wrapRef.current).right},
                {right: "-100%"}
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };

        props.onClose();
    };

    const getData = (ts) => {
        if (ts !== timestampRef.current) return;

        let filters = [];
        if (dateFilters) {
            if (dateFilters?.start && dateFilters?.end) {
                filters.push({name: "ForDate", op: "pdgeq", value: dateFilters.start.toDate().getTime()});
                filters.push({name: "ForDate", op: "pdleq", value: dateFilters.end.toDate().getTime()});
            };
        };
        if (!filters) return setData({status: "ok", data: []});

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/balances/getAllSpentProofsByBalanceID`,
            data: {
                BalanceID: props.balanceID,
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (ts !== timestampRef.current) return;
            setData(res.data);
        }).catch(() => {
            if (ts !== timestampRef.current) return;
            setData(backendModule.genericError);
        });
    };

    React.useEffect(() => {
        if (!wrapRef.current) return;

        wrapRef.current.animate([
            {right: getComputedStyle(wrapRef.current).right},
            {right: 0}
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);

    React.useEffect(() => {
        curDefer(() => {
            let ts = Date.now();
            timestampRef.current = ts;
            getData(ts);
        }, 500);
    }, [dateFilters]);

    return <div className="route__user__accountBalances__historyModal" onClick={onClose}>
        <div className="route__user__accountBalances__historyModal__wrap" ref={wrapRef} onClick={e => e?.stopPropagation()} style={{width: "1000px"}}>

            <div className="route__user__accountBalances__historyModal__wrap__top">
                <div className="route__user__accountBalances__historyModal__wrap__top__left">Spent proof history</div>
                <div className="route__user__accountBalances__historyModal__wrap__top__right"><img src="/images/icon_close.svg" onClick={onClose} /></div>
            </div>

            <div className="route__user__accountBalances__historyModal__wrap__content">
                {data ? <>
                    {data.status === "ok" ? <>
                        <FilterByDate defaultValue="30days" onChange={e => setDateFilters(e)} />
                        <br />
                        <br />

                        <FilteredCustomTable
                            accent="#6C5DD3"
                            theme={themeSelector}
                            headers={["For month", "Uploaded", "Amount", "AdditionalAmount", ""]}
                            customColumns={["auto", "auto", "auto", "max-content"]}
                            data={(()=>{
                                let out = [];
                                for (let item of data.data) {
                                    out.push([
                                        {keyID: item.Date, type: "text", text: moment(item.Date).format("MM/YYYY")},
                                        {keyID: item.Date, type: "text", text: moment(item.Date).toDate().toLocaleDateString()},
                                        {keyID: item.Date, type: "text", text: `${Number(item.Amount).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} ${currencySignSelector}`},
                                        {keyID: item.Date, type: "text", text: `${Number(item.AmountAdditional ?? 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} ${currencySignSelector}`},
                                        {keyID: item.Date, type: "group", group: [
                                            {keyID: item.Date, type: "button", text: "View", onClick: e => animateBox(e, <PreviewImageModal image={item.Image} />), style: {marginRight: "10px"}},
                                            (item.ImageAdditional ? {keyID: item.Date, type: "button", text: "View additional", onClick: e => animateBox(e, <PreviewImageModal image={item.ImageAdditional} />)} : null)
                                        ].filter(t => t)}
                                    ]);
                                };

                                if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);
                                return out;
                            })()}
                        />
                    </> : <p>Error while fetching data!</p>}
                </> : <>
                    <p>Fetching history, this might take a moment...</p>
                    <br />
                    <br />
                    <Spinner color={themeSelector === "dark" ? "white" : "black"} />
                </>}
            </div>

        </div>
    </div>
};

export default UserAccountBalances;