export const addHeaderRefreshAction = (fn) => {
    if (typeof(fn) !== "function") return;

    return {
        type: "SITE_FUNCTIONS_ADD_HEADER_REFRESH",
        payload: fn
    };
};
export const removeHeaderRefreshAction = fn => {
    if (typeof(fn) !== "function") return;

    return {
        type: "SITE_FUNCTIONS_REMOVE_HEADER_REFRESH",
        payload: fn
    };
};

export const updateTrackingProfilesTimestamp = () => {
    return {
        type: "SITE_FUNCTIONS_UPDATE_TRACKING_TIMESTAMP"
    };
};

export const updateStickyHeader = newState => {
    return {
        type: "SITE_FUNCTIONS_UPDATE_STICKY_HEADER",
        payload: !!newState
    };
};

export const changeHeaderComponents = components => {
    if (!Array.isArray(components)) components = [];
    return {
        type: "SITE_FUNCTIONS_CHANGE_HEADER_COMPONENTS",
        payload: components
    };
};

export const changeSiteTheme = theme => {
    if (!["light", "dark"].includes(theme)) return;

    return {
        type: "SITE_FUNCTIONS_CHANGE_THEME",
        payload: theme
    };
};