import React from "react";
import "./index.scss";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import animateModule from "../../modules/animateModule";

import Spinner from "../../components/customComponents/Spinner";

const Register = () => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState({
        hadError: false,
        inputs: [],
        error: "-"
    });

    const usernameRef = React.useRef();
    const passwordRef = React.useRef();
    const emailRef = React.useRef();
    const confirmPasswordRef = React.useRef();
    const componentRegisterRef = React.useRef();

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            registerUser();
        }
    };

    const emailVertification = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    const validateEmail = (input) => {
        if (input.match(emailVertification)) {
            return true;
        } else {
            return false;
        }
    }

    const curNavigate = useNavigate();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const registerUser = () => {
        setInfoP(ip => {return {...ip, hadError: false, inputs: []}});

        let data = {
            email: emailRef.current.value,
            username: usernameRef.current.value,
            password: passwordRef.current.value,
            confirmPassword: confirmPasswordRef.current.value
        };

            //TODO handle password lengths < 8 > 64
        if (!data.email || !validateEmail(data.email)) {
            return setInfoP(ip => {return {...ip, hadError: true, inputs: ["email"], error: "Email can't be empty, or it must be entered properly"}});
        };
        if (!data.username) {
            return setInfoP(ip => {return {...ip, hadError: true, inputs: ["username"], error: "Username can't be empty."}});
        };
        if (!data.password) {
            return setInfoP(ip => {return {...ip, hadError: true, inputs: ["password"], error: "Password can't be empty."}});
        };
        if (data.password.length < 8 || data.password.length > 64) {
            return setInfoP(ip => {return {...ip, hadError: true, inputs: ["password"], error: "Password must be between 8 and 64 characters."}});
        };
        if (!data.confirmPassword) {
            return setInfoP(ip => {return {...ip, hadError: true, inputs: ["confirmPassword"], error: "Confirm Password field cannot be empty"}});
        };
        if(passwordRef.current.value !== confirmPasswordRef.current.value){
            return setInfoP(ip => {return {...ip, hadError: true, inputs: ["confirmPassword"], error: "Password don't match"}});
        }

        setSpinner(true);
        /*
            TODO

            REWRITE AXIOS TO MATCH THE REGISTER
        */
        // axios({
        //     method: "POST",
        //     url: `${backendModule.backendURL}/auth/login`, // TODO: change route to register 
        //     data,
        //     ...backendModule.axiosConfig
        // }).then(res => {
        //     if (res.data.status === "ok") {
        //         curDispatch(updateTimestamp());
        //         animateNavigate("/");
        //     } else {
        //         return setInfoP(ip => {return {...ip, hadError: true, inputs: ["username", "password"], error: "Invalid username or password!"}});
        //     };
        // }).catch(() => {
        //     return setInfoP(ip => {return {...ip, hadError: true, inputs: [], error: "Server timed out."}});
        // }).finally(() => {
        //     setSpinner(false);
        // });
    };

    React.useEffect(() => {
        componentRegisterRef.current.scrollIntoView({
            behavior:'instant'
        })
    
    }, []);

    return <div className="component__register" ref={componentRegisterRef}>
        <div className="component__register__left" style={{
            backgroundImage: 'url("./images/login_img.png")'
        }}></div>

        <div className="component__register__right">
            <div className="component__register__right__logo" style={{backgroundImage: 'url("./images/logo.svg")'}}></div>
            <p className="component__register__right__text">Welcome to Scale Track</p>
            <p className="component__register__right__heading">Sign up</p>

            <div className={`component__register__right__input ${infoP.inputs.includes("email") ? "component__register__right__input--error" : ""}`}>
                <span>Email</span>
                <input ref={emailRef} type="text" placeholder="Enter your email" />
            </div>
            <div className={`component__register__right__input ${infoP.inputs.includes("username") ? "component__register__right__input--error" : ""}`}>
                <span>User name</span>
                <input ref={usernameRef} type="text" placeholder="Enter your user name" />
            </div>
            <div className={`component__register__right__input ${infoP.inputs.includes("password") ? "component__register__right__input--error" : ""}`}>
                <span>Password</span>
                <input ref={passwordRef} type="password" placeholder="Enter your Password" />
            </div>
            <div className={`component__register__right__input ${infoP.inputs.includes("confirmPassword") ? "component__register__right__input--error" : ""}`}>
                <span>Confirm Password</span>
                <input ref={confirmPasswordRef} type="password" placeholder="Confirm your Password" onKeyDown={handleKeyDown} />
            </div>

            <div className="component__register__right__button"
                style={{
                    pointerEvents: spinner ? "none" : "all"
                }}
                onClick={registerUser}
            >
                {spinner ? <Spinner style={{width: "36px", height: "36px"}} color="white" /> : "Register"}
            </div>
            <p className="component__register__right__signin">Already have an Account ? <span onClick={() => animateNavigate("/login")}>Sign in</span></p>

            <p className="component__register__right__infoP" style={{
                opacity: infoP.hadError ? 1 : 0
            }}>{infoP.error}</p>
        </div>
    </div>
};

export default Register;