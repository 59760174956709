import React from "react";
import "./index.scss";
import moment from "moment";

import { useSelector } from "react-redux";
import axios from "axios";

import * as backendModule from "../../../../modules/backendModule";
import * as basicStylesModule from "../../../../modules/basicStylesModule";
import { animateBox } from "../../../../modules/componentAnimation";

import { FilteredCustomTable } from "../../../../components/customComponents/Table";
import Spinner from "../../../../components/customComponents/Spinner";
import ButtonWithDropdown from "../../../../components/customComponents/ButtonWithDropdown";

import YesNoModal from "../../../../components/modals/YesNoModal";

const AdminGlobalIntegrations_MC = () => {
    const [data, setData] = React.useState();
    const [selectedData, setSelectedData] = React.useState([]);

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const checkboxFunctionsRef = React.useRef();

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/globalIntegrations/mc/getAllSites`,
            orders: [
                {name: "createdAt", order: "desc"}
            ],
            ...backendModule.axiosConfig
        }).then(res => setData(res.data)).catch(() => setData(backendModule.genericError)).catch(() => {
            try {
                setSelectedData([]);
                checkboxFunctionsRef.current?.reset();
            } catch {};
        });
    };

    const editSite = () => {
        if (!data) return;
        if (data.status === "error") return;
        if (selectedData.length !== 1) return;
        let item = data.data.find(d => d.ID === selectedData[0]);
        if (!item) return;

        animateBox(<AdminGlobalIntegrations_MC_AddSite edit={item} onChange={getData} />);
    };

    const deleteSite = () => {
        animateBox(<YesNoModal
            heading="Are You sure?"
            text={[
                "You are about to remove ",
                <span style={{ color: "rgb(108, 93, 211)" }}>{selectedData.length} sites</span>,
                ". If you remove these services, they cant be used anymore. ",
                <br />,
                "This action is irreversible, are You sure?"
            ]}
            isRightButtonNormal={true}
            buttonRightCallback={async arg => {
                arg.disabledAll(true);
                arg.spinner(true);

                for (let item of selectedData) {
                    await axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/globalIntegrations/mc/removeSite`,
                        data: {
                            ID: item
                        },
                        ...backendModule.axiosConfig
                    }).then(() => null).catch(() => null);
                };

                if (data?.status === "ok") {
                    setData(d => {
                        return {
                            ...d,
                            data: d.data.filter(dt => !selectedData.includes(dt.ID))
                        };
                    });
                };
                arg.disabledAll(false);
                arg.spinner(false);
                arg.close();

                try {
                    setSelectedData([]);
                    checkboxFunctionsRef.current?.reset();
                } catch {};
            }}
        />);
    };

    React.useEffect(() => {
        getData();
    }, []);

    return <div className="route__admin__globalIntegrationsHJ">
        <div className="route__admin__globalIntegrationsHJ__buttons">
            <div className="route__admin__globalIntegrationsHJ__buttons__left">
                <div className={`route__admin__globalIntegrationsHJ__buttons__left__selected ${selectedData.length > 0 ? "route__admin__globalIntegrationsHJ__buttons__left__selected--active" : ""}`}>
                    {`${selectedData.length} site${selectedData.length > 1 ? "s" : ""} selected`}
                    <div
                        className="route__admin__globalIntegrationsHJ__buttons__left__selected__btn"
                        style={{ backgroundImage: `url("/images/icon_close.svg")` }}
                        onClick={() => checkboxFunctionsRef.current?.reset()}
                    ></div>
                </div>
            </div>
            <div className="route__admin__globalIntegrationsHJ__buttons__right">
                {selectedData.length === 0 && <div className="route__user__campaigns__head__right__btn" onClick={e => animateBox(<AdminGlobalIntegrations_MC_AddSite onChange={() => {
                    getData();
                }} />)}>
                    <img src="/images/icon_close.svg" style={{ transform: "rotate(45deg)" }} />
                    <p>Add site</p>
                </div>}
                {selectedData.length > 0 && <ButtonWithDropdown
                    value="More actions"
                    image="/images/icon_edit.svg"
                    data={[
                        (selectedData.length === 1 ? { name: "Edit", onClick: e => editSite(e) } : null),
                        { name: `Remove ${selectedData.length} site${selectedData.length > 1 ? "s" : ""}`, onClick: e => deleteSite(e) },
                    ].filter(t => t)}
                />}
            </div>
        </div>

        <FilteredCustomTable
            checkboxCB={(data?.status === "ok" && data?.data?.length > 0) ? e => setSelectedData(e) : undefined}
            checkboxFunctions={cf => checkboxFunctionsRef.current = cf()}
            theme={themeSelector}
            accent="#6C5DD3"
            headers={["Domain", "Site ID", "Created At"]}
            data={(()=>{
                if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                if (data.status === "error") return [[{keyID: "noData-error", type: "text", text: "Error while fetching data", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]];

                let out = [];
                for (let item of data.data) {
                    out.push([
                        {keyID: item.ID, type: "text", text: item.Domain},
                        {keyID: item.ID, type: "text", text: item.SiteID},
                        {keyID: item.ID, type: "text", text: moment(item.createdAt).toDate().toLocaleString()},
                    ]);
                };

                if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);
                return out;
            })()}
        />
    </div>
};

const AdminGlobalIntegrations_MC_AddSite = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState({
        hadError: false,
        inputs: [],
        text: ""
    });

    const domainRef = React.useRef();
    const siteidRef = React.useRef();

    const addSite = () => {
        if (spinner) return;
        setInfoP(ip => {return {...ip, hadError: false, inputs: []}});

        const data = {
            Domain: domainRef.current.value,
            SiteID: siteidRef.current.value
        };

        if (!data.Domain) return setInfoP(ip => {return {...ip, inputs: ["domain"], hadError: true, text: "Domain can't be empty"}});
        if (!data.SiteID) return setInfoP(ip => {return {...ip, inputs: ["siteid"], hadError: true, text: "Site ID can't be empty"}});

        if (data.Domain.split(".").length !== 2 && data.Domain !== "localhost") return setInfoP(ip => {return {...ip, inputs: ["domain"], hadError: true, text: "Domain is invalid, do not place subdomains here."}});

        if (props.edit) data["ID"] = props.edit["ID"];

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/globalIntegrations/mc/${props.edit ? "editSite" : "addSite"}`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (typeof(props.onChange) === "function") props.onChange();
                props.onClose();
            } else {
                setInfoP(ip => {return {...ip, inputs: [], hadError: true, text: "Error while saving the site, maybe domain already exists?"}});
            };
        }).catch(() => {
            setInfoP(ip => {return {...ip, inputs: [], hadError: true, text: "Server timed out!"}});
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">{props.edit ? "Edit" : "Add"} site</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            <div className={`genericModal__wrap__input ${infoP.inputs.includes("domain") ? "genericModal__wrap__input--error" : ""}`}>
                <p>Domain</p>
                <input defaultValue={props?.edit?.Domain ?? ""} ref={domainRef} type="text" placeholder="Domain (example.com)" />
            </div>

            <div className={`genericModal__wrap__input ${infoP.inputs.includes("siteid") ? "genericModal__wrap__input--error" : ""}`}>
                <p>Site ID</p>
                <input defaultValue={props?.edit?.SiteID ?? ""} ref={siteidRef} type="text" placeholder="Site ID (Site > Settings > Project ID)" />
            </div>

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Cancel</div>
                <div className="genericModal__wrap__buttons__btn" onClick={addSite}>
                    {spinner ? <Spinner style={{width: "17px", height: "17px"}} color="white" /> : "Save"}
                </div>
            </div>

            {infoP?.text && <p className="genericModal__wrap__infoP" style={{opacity: infoP.hadError ? 1 : 0}}>{infoP.text}</p>}
        </div>
    </div>
};

export default AdminGlobalIntegrations_MC;