import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";

import * as backendModule from "../../../modules/backendModule";
import * as basicStylesModule from "../../../modules/basicStylesModule";
import useOnScreen from "./../../../modules/hooks/useOnScreen";

import { FilteredCustomTable } from "../../../components/customComponents/Table";
import Spinner from "../../../components/customComponents/Spinner";

const UserCreatives_Requests = () => {
    const [kpiData, setKPIData] = React.useState();
    const [data, setData] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);

    const creativeRequestsStatusCodes = useSelector(state => state?.types?.creativeRequestsStatusCodes ?? {});
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const timestampRef = React.useRef();

    const curOnScreen = useOnScreen();

    const getData = ts => {
        if (timestampRef.current !== ts) return;

        setCanPaginate(false);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/requests/getRequests`,
            data: {
                limit: 20,
                offset: 0
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length >= 20) setCanPaginate(true);
            };
            setData(res.data);
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        });
    };

    const continueData = ts => {
        if (!data) return;
        if (data.status !== "ok") return;
        if (timestampRef.current !== ts) return;
        if (!canPaginate) return;

        setCanPaginate(false);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/requests/getRequests`,
            data: {
                limit: 20,
                offset: 0,
                filters: [
                    {name: "ID", op: "eq", value: data.data.map(d => d.ID)}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
            };
        }).catch(() => null);
    };

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        let curTimeout = setTimeout(() => {
            try {
                curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
            } catch {};

            let ts = Date.now();
            timestampRef.current = ts;
            continueData(ts);
        }, 500);

        return () => clearTimeout(curTimeout);
    }, [curOnScreen.isIntersecting, canPaginate]);

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/requests/getRequesterStats`,
            ...backendModule.axiosConfig
        }).then(res => setKPIData(res.data)).catch(() => setKPIData(backendModule.genericError));

        let ts = Date.now();
        timestampRef.current = ts;
        getData(ts);
    }, []);

    return <div className="route__user__creatives__requests">
        <div className="route__user__creatives__requests__kpi">
            <div className="route__user__creatives__requests__kpi__item">
                <p className="route__user__creatives__requests__kpi__item__top">Waiting</p>
                <p className="route__user__creatives__requests__kpi__item__bottom">{kpiData ? (kpiData.status === "ok" ? kpiData.data["Waiting"] : "-") : <Spinner style={{width: "34px", height: "34px"}} color={themeSelector === "dark" ? "white" : "black"} />}</p>
            </div>
            <div className="route__user__creatives__requests__kpi__item">
                <p className="route__user__creatives__requests__kpi__item__top">Completed</p>
                <p className="route__user__creatives__requests__kpi__item__bottom">{kpiData ? (kpiData.status === "ok" ? kpiData.data["Completed"] : "-") : <Spinner style={{width: "34px", height: "34px"}} color={themeSelector === "dark" ? "white" : "black"} />}</p>
            </div>
            <div className="route__user__creatives__requests__kpi__item">
                <p className="route__user__creatives__requests__kpi__item__top">Rejected</p>
                <p className="route__user__creatives__requests__kpi__item__bottom">{kpiData ? (kpiData.status === "ok" ? kpiData.data["Rejected"] : "-") : <Spinner style={{width: "34px", height: "34px"}} color={themeSelector === "dark" ? "white" : "black"} />}</p>
            </div>
        </div>

        <FilteredCustomTable
            theme={themeSelector}
            accent="#6C5DD3"
            headers={["Date requested", "Date completed", "Status", "", ""]}
            customColumns={(new Array(5)).fill("max-content")}
            style={{
                columnGap: "40px"
            }}
            data={(()=>{
                if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                if (data.status === "error") return [[{keyID: "noData-error", type: "custom", data: "There was an error while fetching data!", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]];

                let out = [];

                for (let item of data.data) {
                    out.push([
                        {keyID: item.ID, type: "text", text: moment(item.createdAt).toDate().toLocaleString()},
                        {keyID: item.ID, type: "text", text: item.Status >= 90 ? moment(item.completedAt).toDate().toLocaleString() : "-"},
                        {keyID: item.ID, type: "text", text: creativeRequestsStatusCodes[item.Status] ?? "-"},
                        (item.ExistingCreativeID ? {keyID: item.ID, type: "button", text: "View existing", onClick: () => window.open(`#/creatives?IDs=${item.ExistingCreativeID}`, "_blank"), style: {height: "100%", padding: "2px 10px"}} : null),
                        (item.FinishedCreativeIDs?.length ? {keyID: item.ID, type: "button", text: "View finished creative", onClick: () => window.open(`#/creatives?IDs=${(item.FinishedCreativeIDs ?? []).join(",")}`, "_blank"), style: {height: "100%", padding: "2px 10px"}} : null),

                        {keyID: item.ID, type: "groupNewline", group: [
                            {keyID: item.ID, type: "text", text: item.Description}
                        ]}
                    ].filter(f => f));
                };

                if (out.length === 0) out.push([{keyID: "noData-noData", type: "custom", data: "Nothing to show..."}]);
                return out;
            })()}
        />
        {canPaginate && <div style={{width: "100%", height: "1px"}} ref={curOnScreen.measureRef}></div>}
    </div>
};

export default UserCreatives_Requests;