import React from "react";
import "./index.scss";

import axios from "axios";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import * as backendModule from "../../../modules/backendModule";
import * as basicStylesModule from "../../../modules/basicStylesModule";
import * as siteFunctionsActions from "../../../actions/siteFunctionsActions";
import { animateBox } from "../../../modules/componentAnimation";
import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen"

import { FilteredCustomTable } from "../../../components/customComponents/Table";
import StyledButton from "../../../components/styledComponents/Button";
import StyledInput from "../../../components/styledComponents/Input";
import Spinner from "../../../components/customComponents/Spinner";
import Checkbox from "../../../components/customComponents/Checkbox";
import Dropdown from "../../../components/customComponents/Dropdown";
import ButtonWithDropdown from "../../../components/customComponents/ButtonWithDropdown";

import SelectUsersModal from "../../../components/modals/SelectUsersModal";
import YesNoModal from "../../../components/modals/YesNoModal";

const AdminSiteEvents = () => {
    const [data, setData] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [selectedItems, setSelectedItems] = React.useState([]);

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const timestampRef = React.useRef();
    const checkboxFunctionsRef = React.useRef();

    const curOnScreen = useOnScreen();
    const curDefer = useDefer();
    const curDispatch = useDispatch();

    const getData = (ts) => {
        if (timestampRef.current !== ts) return;

        setCanPaginate(false);
        checkboxFunctionsRef.current?.reset?.();
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/events/getAll`,
            data: {
                offset: 0,
                limit: 20
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length >= 20) setCanPaginate(true);
            };
            setData(res.data);
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        });
    };

    const continueData = (ts) => {
        if (timestampRef.current !== ts) return;
        if (!canPaginate) return;
        if (!data) return;
        if (data.status !== "ok") return;

        setCanPaginate(false);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/events/getAll`,
            data: {
                offset: 0,
                limit: 20,
                filters: [
                    {name: "ID", op: "notIn", value: data.data.map(d => d.ID)}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length >= 20) setCanPaginate(true);

                setData({
                    ...data,
                    data: [
                        ...data.data,
                        ...res.data.data
                    ]
                });
            };
        }).catch(() => null);
    };

    const removeEvents = (e, items) => {
        if (!e || !items) return;
        if (items.length === 0) return;

        animateBox(e, <YesNoModal
            heading="Are you sure?"
            text={[
                "You are about to remove ",
                <span style={{ color: "#6C5DD3" }}>{items.length} event{items.length > 1 ? "s" : ""}</span>,
                ", this action is irreversible!"
            ]}
            isRightButtonNormal={true}
            buttonRightCallback={async arg => {
                arg.disabledAll(true);
                arg.spinner(true);
                let hadError = false;

                for (let item of items) {
                    await axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/campaigns/events/remove`,
                        data: {
                            ID: item
                        },
                        ...backendModule.axiosConfig
                    }).then(res => {
                        if (res.data.status !== "ok") hadError = true;
                    }).catch(() => {
                        hadError = true;
                    });
                };

                arg.disabledAll(false);
                arg.spinner(false);
                arg.close();
                let ts = Date.now();
                timestampRef.current = ts;
                getData(ts);

                if (hadError) {
                    let tmp = document.querySelector(".route__admin__siteEvents__top__right");
                    if (!tmp) return;

                    animateBox({ currentTarget: tmp }, <YesNoModal
                        heading="Error"
                        text="There were errors while removing some events. Refresh the page and try again."
                        buttonLeftHidden={true}
                        isRightButtonNormal={true}
                        buttonRightText="Ok"
                    />);
                };
            }}
        />);
    };

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        let ts = Date.now();
        timestampRef.current = ts;
        curDefer(() => continueData(ts), 500);
    }, [canPaginate, curOnScreen.isIntersecting]);

    React.useEffect(() => {
        let firstTime = true;
        let handler = () => {
            let ts = Date.now();
            timestampRef.current = ts;
            curDefer(() => getData(ts), !firstTime ? 500 : 0);
            firstTime = false;
        };

        curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));
        handler();

        return () => curDispatch(siteFunctionsActions.removeHeaderRefreshAction(handler));
    }, []);

    return <div className="route__admin__siteEvents">
        <div className="route__admin__siteEvents__top">
            <div className="route__admin__siteEvents__top__left">
                <div className={`route__admin__siteEvents__top__left__selected ${selectedItems.length > 0 ? "route__admin__siteEvents__top__left__selected--active" : ""}`}>
                    {`${selectedItems.length} event${selectedItems.length > 1 ? "s" : ""} selected`}
                    <div
                        className="route__admin__siteEvents__top__left__selected__btn"
                        style={{ backgroundImage: `url("/images/icon_close.svg")` }}
                        onClick={() => checkboxFunctionsRef.current?.reset()}
                    ></div>
                </div>
            </div>
            <div className="route__admin__siteEvents__top__right">
                {selectedItems.length === 0 && <StyledButton onClick={e => animateBox(e, <AddSiteEvent onChange={() => {
                    let ts = Date.now();
                    timestampRef.current = ts;
                    getData(ts);
                }} />)}>Add event</StyledButton>}
                {selectedItems.length > 0 && <ButtonWithDropdown
                    image="/images/icon_edit.svg"
                    value="More actions"
                    data={[
                        { name: `Remove ${selectedItems.length} event${selectedItems.length > 1 ? "s" : ""}`, onClick: (e) => removeEvents(e, selectedItems) }
                    ]}
                />}
            </div>
        </div>

        <FilteredCustomTable
            checkboxCB={data?.data?.length > 0 ? e => setSelectedItems(e) : undefined}
            checkboxFunctions={f => checkboxFunctionsRef.current = f()}
            accent="#6C5DD3"
            theme={themeSelector}
            headers={["Event name", "Unique", "Date created", "Total fired events"]}
            data={(()=>{
                if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                if (data.status === "error") return [[{keyID: "noData-error", type: "custom", data: "Error while fetching events", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]];

                let out = [];
                for (let item of data.data) {
                    out.push([
                        {keyID: item.ID, type: "text", text: item.EventName},
                        {keyID: item.ID, type: "text", text: item.isUnique ? <span style={{color: themeSelector === "dark" ? basicStylesModule.successColor : basicStylesModule.successColorLight}}>Yes</span> : <span style={{color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}>No</span>},
                        {keyID: item.ID, type: "text", text: moment(item.createdAt).toDate().toLocaleString()},
                        {keyID: item.ID, type: "text", text: item.TotalFired},
                        (Object.keys(item.EventValues).length > 0 ? {keyID: item.ID, type: "groupNewline", group: [
                            {keyID: item.ID, type: "custom", data: <div className="route__admin__siteEvents__pills">
                                <strong>Event values</strong>
                                {Object.keys(item.EventValues).map(key => <span>{key}: {item.EventValues[key]}</span>)}
                            </div>}
                        ]} : null),
                        (Array.isArray(item.AllowedUsers) ? {keyID: item.ID, type: "groupNewline", group: [
                            {keyID: item.ID, type: "custom", data: <div className="route__admin__siteEvents__pills">
                                <strong>Allowed users</strong>
                                {item._AllowedUsers.map(usr => <span>{usr?.Username}</span>)}
                            </div>}
                        ]} : null)
                    ].filter(t => t))
                };

                if (out.length === 0) out.push([{keyID: "noData-noData", type: "custom", data: "Nothing to show..."}]);
                return out;
            })()}
        />
        {canPaginate && <div ref={curOnScreen.measureRef}></div>}
    </div>
};

const EventValuesList = props => {
    const [values, setValues] = React.useState((typeof(props.defaultValue) === "object" && !Array.isArray(props.defaultValue) && props.defaultValue) ? props.defaultValue : {});
    const [eventName, setEventName] = React.useState("");
    const [eventType, setEventType] = React.useState();

    const trackEventsValueTypesSelector = useSelector(state => state?.types?.trackEventValueTypes ?? []);
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const addEventValue = (e) => {
        if (!eventName || !eventType) return;
        let finalName = String(eventName);

        if (values[finalName]) {
            return animateBox(e, <YesNoModal
                heading="Already exists"
                text={[
                    "Event value with the name ",
                    eventName,
                    " already exists!"
                ]}
                buttonLeftHidden={true}
                buttonRightText={"Ok"}
                isRightButtonNormal={true}
            />);
        };
        setValues(v => {
            let tmp = {...v};
            tmp[finalName] = eventType;

            return tmp;
        });
        setEventName("");
    };

    return <div className="route__admin__siteEvents__eventValues genericModal">
        <div className="genericModal__wrap" style={{width: "800px"}}>
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Event values</div>
                <div className="genericModal__wrap__head__right" onClick={props.onClose} style={{backgroundImage: `url("/images/icon_close.svg")`}}></div>
            </div>

            <div className="route__admin__siteEvents__eventValues__add">
                <StyledInput value={eventName} onChange={e => setEventName(e?.target?.value)} placeholder="Value name" />
                <Dropdown
                    theme={themeSelector}
                    accent="#6C5DD3"
                    inlinePlaceholder="Value type"
                    data={trackEventsValueTypesSelector.map(t => {
                        return {name: t, value: t};
                    })}
                    onChange={e => eventType !== e?.value && setEventType(e?.value)}
                    selected={(()=>{
                        return trackEventsValueTypesSelector.indexOf(trackEventsValueTypesSelector.find(s => s === eventType));
                    })()}
                />
                <StyledButton onClick={addEventValue}>Add</StyledButton>
            </div>

            <div className="route__admin__siteEvents__eventValues__table">
                <FilteredCustomTable
                    theme={themeSelector}
                    accent="#6C5DD3"
                    headers={["Name", "Type", ""]}
                    data={(()=>{
                        let out = [];

                        for (let key of Object.keys(values)) {
                            out.push([
                                {keyID: key, type: "text", text: key},
                                {keyID: key, type: "text", text: values[key]},
                                {keyID: key, type: "button", text: "Remove", onClick: () => {
                                    setValues(v => {
                                        let tmp = {...v};
                                        delete tmp[key];
                                        return tmp;
                                    });
                                }}
                            ])
                        };

                        if (out.length === 0) out.push([{keyID: "noData-noData", type: "custom", data: "Nothing to show..."}]);
                        return out;
                    })()}
                />
            </div>

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Close</div>
                <div className="genericModal__wrap__buttons__btn" onClick={() => {
                    if (typeof(props.onChange) === "function") props.onChange(values);
                    props.onClose();
                }}>Save</div>
            </div>
        </div>
    </div>
};

const AddSiteEvent = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState({
        text: "",
        inputs: [],
        hadError: false
    });
    const [eventValues, setEventValues] = React.useState({});
    const [allowedUsers, setAllowedUsers] = React.useState(null);
    const [isUnique, setIsUnique] = React.useState(false);

    const eventNameRef = React.useRef();

    const addEvent = () => {
        if (spinner) return;

        setInfoP(ip => {return {...ip, hadError: false, inputs: []}});

        let data = {
            EventName: eventNameRef.current.value,
            AllowedUsers: allowedUsers,
            EventValues: eventValues,

            isUnique: !!isUnique
        };

        if (!data.EventName) return setInfoP(ip => {return {...ip, hadError: true, inputs: ["name"], text: "Event name can't be empty!"}});
        if (data.AllowedUsers && data.AllowedUsers.length === 0) return setInfoP(ip => {return {...ip, hadError: true, inputs: [], text: "At least 1 user must be added to the allow list!"}});
        if (!data.EventValues || typeof(data.EventValues) !== "object") return setInfoP(ip => {return {...ip, hadError: true, inputs: [], text: "Event values are invalid"}});
        if (!/^[A-Za-z0-9]*$/.test(data.EventName)) return setInfoP(ip => {return {...ip, hadError: true, inputs: ["name"], text: "Event name can't contain special characters or spaces, only letters and numbers!"}});

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/events/add`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (typeof(props.onChange) === "function") props.onChange();
                return props.onClose();
            };
            if (res.data.data === "ALREADY_EXISTS") {
                setInfoP(ip => {return {...ip, hadError: true, inputs: [], text: "An event with this name already exists"}});
            };
            setInfoP(ip => {return {...ip, hadError: true, inputs: [], text: "An error occured while adding an event"}});
        }).catch(() => {
            setInfoP(ip => {return {...ip, hadError: true, inputs: [], text: "Server timed out!"}});
        });
    };

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Add event</div>
                <div className="genericModal__wrap__head__right" onClick={props.onClose} style={{backgroundImage: `url("/images/icon_close.svg")`}}></div>
            </div>

            <div className={`genericModal__wrap__input ${infoP.inputs.includes("name") ? "genericModal__wrap__input--error" : ""}`}>
                <p>Event name</p>
                <input ref={eventNameRef} type="text" placeholder="Event name" />
            </div>

            <div className="genericModal__wrap__input" style={{display: "flex", alignItems: "center", gap: "10px", flexDirection: "row"}}>
                <Checkbox checked={isUnique} onChange={e => e !== isUnique && setIsUnique(!!e)} />
                <span>Event is unique (fires once per user)</span>
            </div>

            <div className="genericModal__wrap__input" style={{display: "flex", alignItems: "center", gap: "10px", flexDirection: "row"}}>
                <Checkbox checked={allowedUsers === null ? true : false} onChange={e => {
                    if (e && allowedUsers) {
                        setAllowedUsers(null);
                        return;
                    };
                    if (!e && !allowedUsers) {
                        setAllowedUsers([]);
                        return;
                    };
                }} />
                <span>Allow all users</span>
            </div>

            {allowedUsers !== null && <div className="genericModal__wrap__input" style={{display: "flex", alignItems: "center", gap: "10px", flexDirection: "row"}}>
                <span>Allowed users ({allowedUsers.length})</span>
                <StyledButton onClick={e => {
                    animateBox(e, <SelectUsersModal
                        defaultValue={allowedUsers}
                        onChange={e => setAllowedUsers(e)}
                    />);
                }} style={{height: "20px"}}>Edit</StyledButton>
            </div>}

            <div className="genericModal__wrap__input" style={{display: "flex", alignItems: "center", gap: "10px", flexDirection: "row"}}>
                <span>Event values ({Object.keys(eventValues).length})</span>
                <StyledButton style={{height: "20px"}} onClick={e => {
                    animateBox(e, <EventValuesList
                        defaultValue={eventValues}
                        onChange={e => setEventValues(e)}
                    />);
                }}>Edit</StyledButton>
            </div>

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Close</div>
                <div className="genericModal__wrap__buttons__btn" onClick={() => !spinner && addEvent()}>
                    {spinner ? <Spinner style={{width: "17px", height: "17px"}} color="white" /> : "Save"}
                </div>
            </div>

            <p className="genericModal__wrap__infoP" style={{opacity: infoP.hadError ? 1 : 0}}>{infoP.text}</p>
        </div>
    </div>
};

export default AdminSiteEvents;