import React from "react";

import useOnScreen from "../../modules/hooks/useOnScreen";

export default props => {
    const [isInViewport, setIsInViewport] = React.useState(false);
    const curOnScreen = useOnScreen();

    let finalProps = {...props};
    delete finalProps["src"];
    delete finalProps["onError"];

    React.useEffect(() => {
        if (isInViewport) return;

        if (curOnScreen.isIntersecting) setIsInViewport(true);
    }, [curOnScreen.isIntersecting, isInViewport]);

    return <img ref={curOnScreen.measureRef} src={isInViewport ? props.src : ""} onError={isInViewport ? props?.onError : undefined} {...finalProps} />
};