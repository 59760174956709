import React from "react";
import "./index.scss";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import animateModule from "../../modules/animateModule";

import { updateTimestamp } from "../../actions/timestampActions";

import Spinner from "../../components/customComponents/Spinner";

const Login = () => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState({
        hadError: false,
        inputs: [],
        error: "-"
    });

    const usernameRef = React.useRef();
    const passwordRef = React.useRef();

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            checkLogin();
        }
    };

    const curNavigate = useNavigate();
    const curDispatch = useDispatch();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const checkLogin = () => {
        setInfoP(ip => {return {...ip, hadError: false, inputs: []}});

        let data = {
            username: usernameRef.current.value,
            password: passwordRef.current.value
        };

        if (!data.username) {
            return setInfoP(ip => {return {...ip, hadError: true, inputs: ["username"], error: "Username can't be empty."}});
        };
        if (!data.password) {
            return setInfoP(ip => {return {...ip, hadError: true, inputs: ["password"], error: "Password can't be empty."}});
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/auth/login`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                curDispatch(updateTimestamp());
                animateNavigate("/");
            } else {
                return setInfoP(ip => {return {...ip, hadError: true, inputs: ["username", "password"], error: "Invalid username or password!"}});
            };
        }).catch(() => {
            return setInfoP(ip => {return {...ip, hadError: true, inputs: [], error: "Server timed out."}});
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        const body = document.querySelector('.component__login');
    
        body.scrollIntoView({
            behavior: 'instant'
        })
    
    }, []);

    return <div className="component__login">
        <div className="component__login__left" style={{
            backgroundImage: 'url("./images/login_img.png")'
        }}></div>

        <div className="component__login__right">
            <div className="component__login__right__logo" style={{backgroundImage: 'url("./images/logo.svg")'}}></div>
            <p className="component__login__right__text">Welcome to Scale Track</p>
            <p className="component__login__right__heading">Log in</p>

            <div className={`component__login__right__input ${infoP.inputs.includes("username") ? "component__login__right__input--error" : ""}`}>
                <span>Username</span>
                <input ref={usernameRef} type="text" placeholder="Username" />
            </div>
            <div className={`component__login__right__input ${infoP.inputs.includes("password") ? "component__login__right__input--error" : ""}`}>
                <span>Password</span>
                <input ref={passwordRef} type="password" placeholder="Password" onKeyDown={handleKeyDown} />
            </div>

            <div className="component__login__right__button"
                style={{
                    pointerEvents: spinner ? "none" : "all"
                }}
                onClick={checkLogin}
            >
                {spinner ? <Spinner style={{width: "48px", height: "48px"}} color="white" /> : "Log in"}
            </div>

            <p className="component__login__right__infoP" style={{
                opacity: infoP.hadError ? 1 : 0
            }}>{infoP.error}</p>
        </div>
    </div>
};

export default Login;