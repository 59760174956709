import React from "react";
import "./index.scss";

import axios from "axios";
import { useSelector } from "react-redux";

import * as backendModule from "../../../modules/backendModule";

import StyledButton from "../../../components/styledComponents/Button";
import StyledInput from "../../../components/styledComponents/Input";
import Spinner from "../../../components/customComponents/Spinner";

const AdminGlobalSharing = () => {
    const [data, setData] = React.useState();
    const [spinner, setSpinner] = React.useState(false);

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const passwordRef = React.useRef();

    const setNewPassword = () => {
        if (!passwordRef.current?.value) return;
        setSpinner(true);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/serverGlobalFunctions/liveShare/enable`,
            data: {
                Password: passwordRef.current.value
            },
            ...backendModule.axiosConfig
        }).catch(() => null).finally(() => {
            getData();
        });
    };

    const disableShare = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/serverGlobalFunctions/liveShare/disable`,
            ...backendModule.axiosConfig
        }).catch(() => null).finally(() => {
            getData();
        });
    };

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/serverGlobalFunctions/liveShare/status`,
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        getData();
    }, []);

    return <div className="route__admin__globalSharing">
        {(data && !spinner) ? <>
            {data.status === "ok" ? <>
                <div className="route__admin__globalSharing__notice">
                    <p>Live share is enabled!</p>
                    <u onClick={() => window.open(`${window.location.origin}/#/live-share`, "_blank")} style={{cursor: "pointer"}}>{window.location.origin}/#/live-share</u>
                </div>

                <br />
                <br />
                <StyledInput ref={passwordRef} type="password" placeholder="New password" style={{maxWidth: "400px", margin: "20px 0"}}></StyledInput>
                <StyledButton style={{marginLeft: "10px"}} onClick={setNewPassword}>Change password</StyledButton>

                <br />
                <br />
                <StyledButton onClick={disableShare}>Disable Live Share</StyledButton>
            </> : <>
                <p>Live share is not enabled</p>
                <p>Enter a new password to enable live share</p>
                <StyledInput ref={passwordRef} type="password" placeholder="New password" style={{maxWidth: "400px", margin: "20px 0"}}></StyledInput>
                <StyledButton style={{marginLeft: "10px"}} onClick={setNewPassword}>Enable</StyledButton>
            </>}
        </> : <Spinner color={themeSelector === "dark" ? "white" : "black"} />}
    </div>
};

export default AdminGlobalSharing;