import React from "react";
import "./index.scss";

import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import * as backendModule from "../../../modules/backendModule";
import { animateBox } from "../../../modules/componentAnimation";
import { countries } from "../../../modules/countryModule";
import { URLJoin, siteRiskColors } from "../../../modules/miscModule";
import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen";

import UserSitesCard from "./SitesCard";
import { TrackingScript } from "./SitesCard";
import { AddOffer } from "../Offers";

import FilterBySearch from "../../../components/filters/FilterBySearch";
import Dropdown from "../../../components/customComponents/Dropdown";
import Spinner from "../../../components/customComponents/Spinner";
import FilterByDate from "../../../components/filters/FilterByDate";
import AdvancedDropdown from "../../../components/customComponents/AdvancedDropdown";
import Checkbox from "../../../components/customComponents/Checkbox";
import RadioButton from "../../../components/customComponents/RadioButton";

import YesNoModal from "../../../components/modals/YesNoModal";
import PreviewImageModal from "../../../components/modals/PreviewImageModal";

import * as siteFunctionsActions from "../../../actions/siteFunctionsActions";

const UserSites = () => {
    const [offers, setOffers] = React.useState();
    const [data, setData] = React.useState();
    const [search, setSearch] = React.useState("");
    const [dateFilter, setDateFilter] = React.useState();
    const [countryFilter, setCountryFilter] = React.useState(null);
    const [offerFilter, setOfferFilter] = React.useState(null);
    const [siteTypeFilter, setSiteTypeFilter] = React.useState(null);
    const [siteTrafficSourceFilter, setSiteTrafficSourceFilter] = React.useState(null);
    const [spinner, setSpinner] = React.useState(false);
    const [paginationSpinner, setPaginationSpinner] = React.useState(false);
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [showStats, setShowStats] = React.useState(false);
    const [onlyForMyOffers, setOnlyForMyOffers] = React.useState(false);

    const siteTypesSelector = useSelector(state => state?.types?.siteTypes ?? {});
    const siteTrafficSourcesSelector = useSelector(state => state?.types?.siteTrafficSources ?? {});
    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "?");
    const userInfoSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});

    const curDispatch = useDispatch();
    const curDefer = useDefer();
    const curOnScreen = useOnScreen();

    const timestampRef = React.useRef(Date.now());

    const getAllOffers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {
                limit: null,
                offset: 0,
                extended: false
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setOffers(res.data);
        }).catch(() => {
            setOffers(backendModule.genericError);
        });
    };

    const removeSite = (e, item) => {
        if (!item) return;

        animateBox(e, <YesNoModal
            heading="Are you sure?"
            text={["You are about to remove ", <span style={{ color: "#6C5DD3" }}>{item.SiteName}</span>, ", this action is irreversible."]}
            isRightButtonNormal={true}
            buttonRightCallback={arg => {
                arg.errorMessage("");
                arg.disabledAll(true);
                arg.spinner(true);

                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/sites/removeSite`,
                    data: {
                        ID: item.ID
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "ok") {
                        arg.close();
                        let ts = Date.now();
                        timestampRef.current = ts;
                        getData(ts);
                    } else {
                        arg.errorMessage("Error while removing the site.");
                    };
                }).catch(() => {
                    arg.errorMessage("Server timed out.");
                }).finally(() => {
                    arg.disabledAll(false);
                    arg.spinner(false);
                });
            }}
        />);
    };

    const getData = (ts) => {
        if (canPaginate) setCanPaginate(false);
        let filters = [];
        let dateFilters = [];
        if (search) {
            filters.push({
                or: [
                    { name: "SiteName", op: "like", value: search },
                    { name: "SiteURL", op: "like", value: search },
                    { name: "Country", op: "like", value: search }
                ]
            });
        };
        if (dateFilter) {
            if (dateFilter?.start && dateFilter?.end) {
                dateFilters.push({ name: "createdAt", op: "dgeq", value: dateFilter.start.toDate().getTime() });
                dateFilters.push({ name: "createdAt", op: "dleq", value: dateFilter.end.toDate().getTime() });
            };
        };
        if (countryFilter) filters.push({ name: "Country", op: "eq", value: countryFilter });
        if (offerFilter) filters.push({ name: "OfferID", op: "eq", value: offerFilter });
        if (siteTypeFilter) filters.push({ name: "SiteType", op: "eq", value: siteTypeFilter });
        if (siteTrafficSourceFilter) filters.push({ name: "SiteTrafficSources", op: "like", value: [Number(siteTrafficSourceFilter)] });

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/sites/getAllSites`,
            data: {
                trackingData: showStats,
                extended: true,
                limit: 10,
                offset: 0,
                filters,
                dateFilters,
                onlyForMyOffers
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            setData(res.data);
            if (res.data.status === "ok") {
                if (res.data.data.length === 10) setCanPaginate(true);
            };
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        }).finally(() => {
            setSpinner(false);
        });
    };

    const continueData = (ts) => {
        if (!canPaginate) return;
        setCanPaginate(false);

        if (!data) return;
        if (!data.data) return;

        let filters = [];
        let dateFilters = [];
        if (search) {
            filters.push({
                or: [
                    { name: "SiteName", op: "like", value: search },
                    { name: "SiteURL", op: "like", value: search },
                    { name: "Country", op: "like", value: search }
                ]
            });
        };
        if (dateFilter) {
            if (dateFilter?.start && dateFilter?.end) {
                dateFilters.push({ name: "createdAt", op: "dgeq", value: dateFilter.start.toDate().getTime() });
                dateFilters.push({ name: "createdAt", op: "dleq", value: dateFilter.end.toDate().getTime() });
            };
        };
        if (countryFilter) filters.push({ name: "Country", op: "eq", value: countryFilter });
        if (offerFilter) filters.push({ name: "OfferID", op: "eq", value: offerFilter });
        if (siteTypeFilter) filters.push({ name: "SiteType", op: "eq", value: siteTypeFilter });
        if (siteTrafficSourceFilter) filters.push({ name: "SiteTrafficSources", op: "like", value: [Number(siteTrafficSourceFilter)] });

        setPaginationSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/sites/getAllSites`,
            data: {
                trackingData: showStats,
                extended: true,
                limit: 10,
                offset: data.data.length,
                filters,
                dateFilters,
                onlyForMyOffers
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 10) setCanPaginate(true);
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    }
                });
            };
        }).catch(() => null).finally(() => {
            setPaginationSpinner(false);
        });
    };

    const openSiteChecker = (ID, cb) => {
        let tmpAnchor = document.querySelector(".component__contentWrapper");
        if (!tmpAnchor) return;
        if (!ID) return;

        return animateBox({ currentTarget: tmpAnchor }, <SiteChecker anchor={tmpAnchor} ID={ID} onChange={cb} />);
    };

    React.useEffect(() => {
        let ts = Date.now();
        timestampRef.current = ts;
        curDefer(() => {
            getData(ts);
        }, 500);
    }, [search, dateFilter, countryFilter, offerFilter, siteTypeFilter, siteTrafficSourceFilter, showStats, onlyForMyOffers]);

    React.useEffect(() => {
        let handler = () => {
            let ts = Date.now();
            timestampRef.current = ts;
            getData(ts);
        };
        curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));

        return () => curDispatch(siteFunctionsActions.removeHeaderRefreshAction(handler));
    }, [search, dateFilter, countryFilter, offerFilter, siteTypeFilter, siteTrafficSourceFilter, showStats, onlyForMyOffers]);

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch { };

        setCanPaginate(false);
        let ts = Date.now();
        timestampRef.current = ts;
        curDefer(() => {
            continueData(ts);
        }, 500);

    }, [curOnScreen.isIntersecting, canPaginate]);

    React.useEffect(() => {
        curDispatch(siteFunctionsActions.updateStickyHeader(false));

        return () => curDispatch(siteFunctionsActions.updateStickyHeader(true));
    }, []);

    React.useEffect(() => {
        getAllOffers();
    }, []);

    return <div className="route__user__sites">
        <div className="route__user__sites__filters">
            <FilterBySearch onChange={setSearch} style={{ gridColumn: "1 / span 2" }} />
            <FilterByDate onChange={setDateFilter} style={{ gridColumn: "3 / span 2" }} />

            <AdvancedDropdown
                headline="Country"
                data={[{
                    key: "null",
                    name: "All",
                    value: null
                }, ...countries.map(c => {
                    return {
                        key: c.code,
                        name: c.name,
                        value: c.code,
                        image: `/images/countryFlags/${c.code.toLowerCase()}.png`
                    }
                })]}
                showSearch={true}
                selected={(() => {
                    if (!countryFilter) return 0;
                    return countries.indexOf(countries.find(c => c.code === countryFilter)) + 1;
                })()}
                onChange={e => setCountryFilter(e?.value)}
            />

            {<AdvancedDropdown
                headline="Offer"
                data={offers ? (offers.status === "error" ? [{ key: "null", name: "All offers", value: null }] : [{ key: "null", name: "All offers", value: null }, ...offers.data.map(offer => {
                    return {
                        key: offer.ID,
                        name: `${offer.OfferName} (${offer.OfferType ?? "-"}, ${offer.Country ?? "?"}) (${Number(offer.OfferPrice).toFixed(2)} ${currencySignSelector})`,
                        value: offer.ID
                    }
                })]) : null}
                showSearch={true}
                selected={(() => {
                    if (!offerFilter) return 0;
                    if (!offers) return 0;
                    if (offers.status !== "ok") return 0;
                    return offers.data.indexOf(offers.data.find(o => o.ID === offerFilter)) + 1;
                })()}
                onChange={e => setOfferFilter(e?.value)}
            />}

            <AdvancedDropdown
                headline="Site type"
                data={[{ key: "null", name: "All types", value: null }, ...Object.keys(siteTypesSelector).map(type => {
                    return {
                        key: type,
                        name: siteTypesSelector[type],
                        value: type
                    };
                })]}
                showSearch={true}
                selected={(() => {
                    if (siteTypeFilter === null) return 0;
                    let tmp = Object.keys(siteTypesSelector);
                    return tmp.indexOf(tmp.find(st => st === siteTypeFilter)) + 1;
                })()}
                onChange={e => setSiteTypeFilter(e?.value)}
            />

            <AdvancedDropdown
                headline="Traffic source"
                data={[{ key: "null", name: "All sources", value: null }, ...Object.keys(siteTrafficSourcesSelector).map(type => {
                    return {
                        key: type,
                        name: siteTrafficSourcesSelector[type],
                        value: type
                    };
                })]}
                showSearch={true}
                selected={(() => {
                    if (siteTrafficSourceFilter === null) return 0;
                    let tmp = Object.keys(siteTrafficSourcesSelector);
                    return tmp.indexOf(tmp.find(st => st === siteTrafficSourceFilter)) + 1;
                })()}
                onChange={e => setSiteTrafficSourceFilter(e?.value)}
            />
        </div>

        <div className="route__user__sites__buttons">
            <div className="route__user__sites__buttons__left">
                <p className="route__user__sites__buttons__left__item">
                    <RadioButton checked={showStats} onChange={e => setShowStats(!!e)} />
                    <span>Show site stats</span>
                </p>
            </div>
            {userInfoSelector?.Flags?.isAdmin && <div className="route__user__sites__buttons__left">
                <p className="route__user__sites__buttons__left__item">
                    <RadioButton checked={onlyForMyOffers} onChange={e => setOnlyForMyOffers(!!e)} />
                    <span>Show my offers only</span>
                </p>
            </div>}

            <div className="route__user__sites__buttons__right">
                <div className="route__user__sites__buttons__right__btn" onClick={e => animateBox(e, <AddSite onChange={(id) => {
                    openSiteChecker(id, () => {
                        let ts = Date.now();
                        timestampRef.current = ts;
                        getData(ts);
                    });
                }} />)}>Add site</div>
            </div>
        </div>

        {data ? <>
            {data.status === "ok" ? <>
                <div className="route__user__sites__cards">
                    <div className="route__user__sites__cards__spinner" style={{
                        pointerEvents: spinner ? "all" : "none",
                        opacity: spinner ? 1 : 0
                    }}>
                        <Spinner color="white" />
                    </div>

                    {data.data.map((item, itemIdx) => {
                        return <UserSitesCard
                            key={`user-site-card-${item.ID}`}
                            showStats={showStats}
                            data={item}
                            onEdit={(e) => {
                                animateBox(e, <AddSite edit={item} onChange={(id) => {
                                    openSiteChecker(id, () => {
                                        let ts = Date.now();
                                        timestampRef.current = ts;
                                        getData(ts);
                                    });
                                }} />)
                            }}
                            onRemove={e => removeSite(e, item)}
                        />
                    })}

                    {(paginationSpinner || canPaginate) && <div>
                        {paginationSpinner && <Spinner color="white" />}
                        {canPaginate && <div
                            style={{ opacity: 0, width: "1px", height: "1px" }}
                            ref={curOnScreen.measureRef}
                        ></div>}
                    </div>}
                </div>
            </> : <>
                <p>Error while loading sites.</p>
            </>}
        </> : <Spinner color="white" />}
    </div>
};

const AddSite = props => {
    const [ts, setTs] = React.useState(Date.now());
    const [offers, setOffers] = React.useState();
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState({
        error: "",
        hadErrors: false,
        inputs: []
    });
    const [websites, setWebsites] = React.useState();
    const [selectedOffer, setSelectedOffer] = React.useState(props.edit?.OfferID);
    const [selectedCountry, setSelectedCountry] = React.useState(props.edit?.Country);
    const [selectedType, setSelectedType] = React.useState(props.edit?.SiteType);
    const [selectedRisk, setSelectedRisk] = React.useState(props.edit?.SiteRisk);
    const [selectedTrafficSources, setSelectedTrafficSources] = React.useState(props.edit?.SiteTrafficSources ?? []);
    const [selectedIntegrationLander, setSelectedIntegrationlander] = React.useState(props.edit?.Integration_BoundLandingPageID ?? null);
    const [repeatingVisits, setRepeatingVisits] = React.useState(props.edit?.trackRepeatingRequests || false);

    const mainRef = React.useRef();
    const siteNameRef = React.useRef();
    const siteURLRef = React.useRef();
    const combinedPreLandingEntryRef = React.useRef();
    const combinedLandingEntryRef = React.useRef();

    const facebookPixelIDRef = React.useRef();
    const facebookPixelTokenRef = React.useRef();
    const mgidClientIDRef = React.useRef();
    const mgidEventNameRef = React.useRef();

    const siteTypesSelector = useSelector(state => state?.types?.siteTypes ?? {});
    const siteRisksSelector = useSelector(state => state?.types?.siteRisks ?? {});
    const siteTrafficSourcesSelector = useSelector(state => state?.types?.siteTrafficSources ?? {});
    const selectedTrackingProfileSelector = useSelector(state => state?.trackingProfiles?.selectedProfile ?? "-1");
    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "?");
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const onClose = (force = false) => {
        if (spinner && !force) return;

        return props.onClose();
    };

    const getAllOffers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {
                limit: null,
                offset: 0,
                extended: true,
                filters: [
                    {or: [
                        {name: "isActive", op: "eq", value: true},
                        (props.edit ? {name: "ID", op: "eq", value: props.edit?.OfferID} : {})
                    ]}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setOffers(res.data);
        }).catch(() => {
            setOffers(backendModule.genericError);
        });
    };

    const addSite = () => {
        if (spinner) return;
        setInfoP(i => { return { ...i, inputs: [], hadErrors: false } });

        let data = {
            OfferID: selectedOffer,
            SiteName: siteNameRef.current.value,
            SiteURL: siteURLRef.current.value,
            Country: selectedCountry,
            Type: selectedType,
            Risk: selectedRisk,
            TrafficSources: selectedTrafficSources,
            trackRepeatingRequests: !!repeatingVisits,
            IntegrationData: {}
        };

        if (!data.SiteName) return setInfoP(i => { return { ...i, hadErrors: true, inputs: ["name"], error: "Name can't be empty" } });
        if (!data.SiteURL) return setInfoP(i => { return { ...i, hadErrors: true, inputs: ["url"], error: "URL can't be empty" } });
        if (data.Country === null || data.Country === undefined) return setInfoP(i => { return { ...i, hadErrors: true, inputs: ["country"], error: "Country can't be empty" } });
        if (data.Type === null || data.Type === undefined) return setInfoP(i => { return { ...i, hadErrors: true, inputs: ["type"], error: "Type can't be empty" } });
        if (data.Risk === null || data.Risk === undefined) return setInfoP(i => { return { ...i, hadErrors: true, inputs: ["risk"], error: "Risk can't be empty" } });

        if (data.OfferID === null || data.OfferID === undefined) return setInfoP(i => { return { ...i, hadErrors: true, inputs: ["offer"], error: "Offer can't be empty" } });
        if (offers?.status === "ok") {
            let cOffer = offers.data.find(o => o.ID === data.OfferID);
            if (!cOffer || !cOffer?.isActive) return setInfoP(i => { return { ...i, hadErrors: true, inputs: ["offer"], error: "Offer is not active" } });
        };
        
        if (!data.SiteURL.startsWith("http://") && !data.SiteURL.startsWith("https://")) return setInfoP(i => { return { ...i, hadErrors: true, inputs: ["url"], error: "URL must begin with 'http://' or 'https://'" } });

        if (String(data.Type) === "2") {
            data.CombinedPreLandingEntry = combinedPreLandingEntryRef.current.value;
            data.CombinedLandingEntry = combinedLandingEntryRef.current.value;

            if (!data.CombinedPreLandingEntry) return setInfoP(i => { return { ...i, hadErrors: true, inputs: ["combined-prelanding"], error: "Pre-landing entry page missing" } });
            if (!data.CombinedLandingEntry) return setInfoP(i => { return { ...i, hadErrors: true, inputs: ["combined-landing"], error: "Landing entry page missing" } });
        };

        if (String(data.Type) === "0") {
            data["Integration_BoundLandingPageID"] = selectedIntegrationLander;

            if (selectedTrackingProfileSelector.startsWith("fb-")) {
                if (!data["Integration_BoundLandingPageID"]) return setInfoP(i => { return { ...i, hadErrors: true, inputs: ["integration-lander"], error: "Landing page is REQUIRED for integrations. (like facebook, google...etc)" } });
            };
        };

        if (selectedTrafficSources.find(ts => siteTrafficSourcesSelector[ts] === "Facebook") && (facebookPixelIDRef.current || facebookPixelTokenRef.current)) {
            data["IntegrationData"]["Facebook"] = {
                PixelID: facebookPixelIDRef.current.value,
                PixelToken: facebookPixelTokenRef.current.value
            };
        };
        if (selectedTrafficSources.find(ts => siteTrafficSourcesSelector[ts] === "Mgid") && mgidClientIDRef.current) {
            data["IntegrationData"]["Mgid"] = {
                ClientID: mgidClientIDRef.current.value,
                EventName: mgidEventNameRef.current.value ?? "Lead"
            };
        };

        if (props.edit) {
            data["ID"] = props.edit["ID"];
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/sites/${props.edit ? "editSite" : "addSite"}`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (props.onChange) props.onChange(res.data.data);
                onClose(true);
            } else {
                if (res.data.data === "ALREADY_EXISTS") {
                    setInfoP(i => { return { ...i, hadErrors: true, inputs: ["url"], error: `Error while ${props.edit ? "editting" : "adding"} a site. Site with that domain (URL) already exists!` } });
                } else {
                    setInfoP(i => { return { ...i, hadErrors: true, inputs: [], error: `Error while ${props.edit ? "editting" : "adding"} a site.` } });
                };
            };
        }).catch(() => {
            setInfoP(i => { return { ...i, hadErrors: true, inputs: [], error: "Server timed out." } });
        }).finally(() => {
            setSpinner(false);
        });
    };

    const getLandingSites = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/sites/getAllSites`,
            data: {
                offset: 0,
                limit: null,
                extended: false,
                filters: [
                    { name: "SiteType", op: "in", value: [1, 2] }
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setWebsites(res.data);
        }).catch(() => {
            setWebsites(backendModule.genericError);
        });
    };

    React.useEffect(() => {
        getAllOffers();
        getLandingSites();
    }, []);

    return <div className="route__user__sites__add">
        <div className="route__user__sites__add__wrap" ref={mainRef}>
            <div className={`route__user__sites__add__wrap__spinner ${!offers ? "route__user__sites__add__wrap__spinner--active" : ""}`}>
                <Spinner color={themeSelector === "dark" ? "white" : "black"} />
            </div>

            <div className="route__user__sites__add__wrap__head">
                <div className="route__user__sites__add__wrap__head__left">{props.edit ? "Edit" : "Add"} site</div>
                <div className="route__user__sites__add__wrap__head__right" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={onClose}></div>
            </div>

            {offers?.status === "error" ? <>
                <p>There was an error while fetching offers!</p>
            </> : <>

                <div className={`route__user__sites__add__wrap__input ${infoP.inputs.includes("name") ? "route__user__sites__add__wrap__input--error" : ""}`}>
                    <p>Site name</p>
                    <input ref={siteNameRef} type="text" placeholder="Site name" defaultValue={props.edit?.SiteName} />
                </div>

                <div className={`route__user__sites__add__wrap__input ${infoP.inputs.includes("url") ? "route__user__sites__add__wrap__input--error" : ""}`} onChange={() => setTs(Date.now())}>
                    <p>Site URL</p>
                    <input ref={siteURLRef} type="text" placeholder="Site URL (https://mysite.com)" defaultValue={props.edit?.SiteURL} />
                </div>

                <div className="route__user__sites__add__wrap__input route__user__sites__add__wrap__input--dropdown">
                    <p>Offer name</p>
                    <div className="route__user__sites__add__wrap__input__withButton">
                        <Dropdown
                            theme={themeSelector}
                            accent="#6C5DD3"
                            inlinePlaceholder="Offer name"
                            data={(offers?.data ?? []).map(o => {
                                return {
                                    search: `${o.OfferName} ${o.OfferType} ${o.Country}`,
                                    name: <span style={{color: o.isActive ? null : "rgb(238, 102, 102)"}}>{`${o.OfferName} (${o.OfferType ?? "-"}, ${o.Country ?? "?"}) (${Number(o.OfferPrice).toFixed(2)} ${currencySignSelector}) ${o.isActive ? "" : "(Inactive)"}`}</span>,
                                    value: o.ID
                                }
                            })}
                            onChange={e => setSelectedOffer(e?.value)}
                            selected={(() => {
                                if (selectedOffer === null || selectedOffer === undefined) return;
                                if (!offers) return;
                                if (offers.status !== "ok") return;

                                return offers.data.indexOf(offers.data.find(o => o.ID === selectedOffer));
                            })()}
                            style={{
                                borderColor: infoP.inputs.includes("offer") ? "#FF754C" : null
                            }}
                        />
                        <div className="route__user__sites__add__wrap__input__withButton__button" onClick={e => {
                            animateBox(e, <AddOffer onChange={() => {
                                setOffers();
                                setTimeout(() => getAllOffers(), 200);
                            }} />)
                        }}>
                            <img src="/images/icon_addRound.svg" />
                            <p>New offer</p>
                        </div>
                    </div>
                </div>

                <div className="route__user__sites__add__wrap__input route__user__sites__add__wrap__input--dropdown">
                    <p>Country</p>
                    <Dropdown
                        theme={themeSelector}
                        accent="#6C5DD3"
                        inlinePlaceholder="Country"
                        data={countries.map(c => {
                            return { name: c.name, value: c.code }
                        })}
                        onChange={e => setSelectedCountry(e?.value)}
                        selected={countries.indexOf(countries.find(c => c.code === selectedCountry))}
                        style={{
                            borderColor: infoP.inputs.includes("country") ? "#FF754C" : null
                        }}
                    />
                </div>

                <div className="route__user__sites__add__wrap__input route__user__sites__add__wrap__input--dropdown">
                    <p>Type</p>
                    <Dropdown
                        theme={themeSelector}
                        accent="#6C5DD3"
                        inlinePlaceholder="Type"
                        data={Object.keys(siteTypesSelector).map(st => {
                            return { name: siteTypesSelector[st], value: st }
                        })}
                        onChange={e => setSelectedType(e?.value)}
                        selected={(() => {
                            if (selectedType === null || selectedType === undefined) return;

                            return Object.keys(siteTypesSelector).indexOf(String(selectedType));
                        })()}
                        style={{
                            borderColor: infoP.inputs.includes("type") ? "#FF754C" : null
                        }}
                    />
                </div>

                {String(selectedType) === "2" && <>
                    <div className={`route__user__sites__add__wrap__input route__user__sites__add__wrap__input--inset ${infoP.inputs.includes("combined-prelanding") ? "route__user__sites__add__wrap__input--error" : ""}`} style={{ marginBottom: 0, paddingBottom: "20px" }}>
                        <p>Pre-landing entry file</p>
                        <input ref={combinedPreLandingEntryRef} type="text" placeholder="Pre-landing entry file (index.html)" defaultValue={props.edit?.CombinedPreLandingEntry} onChange={() => setTs(Date.now())} />
                    </div>

                    <div className={`route__user__sites__add__wrap__input route__user__sites__add__wrap__input--inset ${infoP.inputs.includes("combined-landing") ? "route__user__sites__add__wrap__input--error" : ""}`}>
                        <p>Landing entry file</p>
                        <input ref={combinedLandingEntryRef} type="text" placeholder="Landing entry file (offer.html)" defaultValue={props.edit?.CombinedLandingEntry} onChange={() => setTs(Date.now())} />
                    </div>
                </>}

                <div className="route__user__sites__add__wrap__input route__user__sites__add__wrap__input--dropdown">
                    <p>Risk</p>
                    <Dropdown
                        theme={themeSelector}
                        accent="#6C5DD3"
                        inlinePlaceholder="Risk"
                        data={Object.keys(siteRisksSelector).map(st => {
                            return {
                                search: siteRisksSelector[st], value: st, name: <div className="route__user__sites__add__wrap__input__risks">
                                    {siteRisksSelector[st]}
                                    <div className="route__user__sites__add__wrap__input__risks__items">
                                        {Object.keys(siteRisksSelector).map(risk => {
                                            return <div className="route__user__sites__add__wrap__input__risks__items__item" style={{ backgroundColor: risk <= st ? siteRiskColors(st) : siteRiskColors(-1) }}></div>
                                        })}
                                    </div>
                                </div>
                            }
                        })}
                        onChange={e => setSelectedRisk(e?.value)}
                        selected={(() => {
                            if (selectedRisk === null || selectedRisk === undefined) return;

                            return Object.keys(siteRisksSelector).indexOf(String(selectedRisk));
                        })()}
                        style={{
                            borderColor: infoP.inputs.includes("risk") ? "#FF754C" : null
                        }}
                    />
                </div>

                <div className="route__user__sites__add__wrap__input route__user__sites__add__wrap__input--dropdown">
                    <p>Track repeating visit spam (refreshes)</p>
                    <Dropdown
                        theme={themeSelector}
                        accent="#6C5DD3"
                        inlinePlaceholder="Track spam"
                        data={[
                            { name: "Yes - track every request / refresh", value: true },
                            { name: "No - track in intervals of 1h", value: false }
                        ]}
                        onChange={e => setRepeatingVisits(e?.value)}
                        selected={(() => {
                            if (repeatingVisits) return 0;
                            return 1;
                        })()}
                        style={{
                            borderColor: infoP.inputs.includes("trackspam") ? "#FF754C" : null
                        }}
                    />
                </div>

                <div className="route__user__sites__add__wrap__input">
                    <p>Traffic sources</p>
                    <div className="route__user__sites__add__wrap__input__sources">
                        {Object.keys(siteTrafficSourcesSelector).map(ts => {
                            ts = Number(ts);
                            return <div className="route__user__sites__add__wrap__input__sources__source">
                                <Checkbox checked={selectedTrafficSources.includes(ts)} onChange={e => {
                                    if (e && !selectedTrafficSources.includes(ts)) return setSelectedTrafficSources(t => [...t, ts]);
                                    if (!e && selectedTrafficSources.includes(ts)) return setSelectedTrafficSources(t => t.filter(tt => tt !== ts));
                                }} />
                                <span>{siteTrafficSourcesSelector[ts]}</span>
                            </div>
                        })}
                    </div>
                </div>

                {(String(selectedType) === "0" && (!websites || websites?.status === "ok")) && <div className="route__user__sites__add__wrap__input route__user__sites__add__wrap__input--dropdown">
                    <p>Landing page (for integration campaigns eg: facebook)</p>
                    {websites ? <Dropdown
                        theme={themeSelector}
                        accent="#6C5DD3"
                        inlinePlaceholder="Integration landing page"
                        data={[{ name: "None", value: null }, ...(websites?.status === "ok" ? websites.data : []).map(website => {
                            return {
                                name: <div className="route__user__sites__add__wrap__input__dropItem">
                                    <img
                                        src={website.SiteImage ?? "/images/image-missing.png"}
                                        onError={e => e.target.src = "/images/image-missing.png"}
                                        onClick={e => {
                                            e.stopPropagation();
                                            animateBox({ currentTarget: mainRef.current }, <PreviewImageModal image={website.SiteImage} />);
                                        }}
                                    />
                                    <p>{website.SiteName}</p>
                                    <div className="route__user__sites__add__wrap__input__dropItem__risks">
                                        {Object.keys(siteRisksSelector).map(risk => {
                                            return <div
                                                className="route__user__sites__add__wrap__input__dropItem__risks__risk"
                                                style={{ backgroundColor: risk <= website.SiteRisk ? siteRiskColors(website.SiteRisk) : siteRiskColors(-1) }}
                                            ></div>
                                        })}
                                    </div>
                                </div>,
                                search: `${website.SiteName} ${website.SiteURL}`,
                                value: website.ID
                            };
                        })]}
                        onChange={e => setSelectedIntegrationlander(e?.value)}
                        selected={(() => {
                            if (websites?.status !== "ok") return null;
                            if (!selectedIntegrationLander) return 0;

                            return websites.data.indexOf(websites.data.find(w => w.ID === selectedIntegrationLander)) + 1;
                        })()}
                        style={{
                            borderColor: infoP.inputs.includes("integration-lander") ? "#FF754C" : null
                        }}
                    /> : <Spinner style={{ width: "54px", height: "54px" }} color="white" />}
                </div>}

                {selectedTrafficSources.find(ts => siteTrafficSourcesSelector[ts] === "Facebook") && <>
                    <div className={`route__user__sites__add__wrap__input`} onChange={() => setTs(Date.now())}>
                        <p>Facebook Pixel ID (optional)</p>
                        <input readOnly={true} ref={facebookPixelIDRef} type="text" placeholder="Facebook pixel ID (optional)" defaultValue={props?.edit?.IntegrationData?.Facebook?.PixelID ?? ""} />
                    </div>

                    <div className={`route__user__sites__add__wrap__input`} onChange={() => setTs(Date.now())}>
                        <p>Facebook conversion token (optional)</p>
                        <input readOnly={true} ref={facebookPixelTokenRef} type="text" placeholder="Facebook conversion token" defaultValue={props?.edit?.IntegrationData?.Facebook?.PixelToken ?? ""} />
                    </div>
                </>}

                {selectedTrafficSources.find(ts => siteTrafficSourcesSelector[ts] === "Mgid") && <>
                    <div className={`route__user__sites__add__wrap__input`} onChange={() => setTs(Date.now())}>
                        <p>Mgid Client ID (optional)</p>
                        <input readOnly={true} ref={mgidClientIDRef} type="text" placeholder="Mgid Client ID (optional)" defaultValue={props?.edit?.IntegrationData?.Mgid?.ClientID ?? ""} />
                    </div>
                    <div className={`route__user__sites__add__wrap__input`} onChange={() => setTs(Date.now())}>
                        <p>Mgid Event Name (optional)</p>
                        <input readOnly={true} ref={mgidEventNameRef} type="text" placeholder="Mgid Event Name (optional)" defaultValue={props?.edit?.IntegrationData?.Mgid?.EventName ?? "Lead"} />
                    </div>
                </>}

                {(selectedType !== null && selectedType !== undefined) && <div className="route__user__sites__add__wrap__expectedLinks" key={ts}>
                    <p>Summary:</p>
                    <br />

                    {siteURLRef.current?.value.startsWith("http://") && <p style={{color: "orange"}}>
                        WARNING! URL is http, not https! Make sure to double check the URL, as this could lead to problems with tracking if the protocol is incorrect!
                        <br />
                        <br />
                    </p>}
                    {(["0", "1"].includes(String(selectedType))) && <>
                        <p>URL Should start with: <span style={{ color: "#69f978" }}>{siteURLRef.current?.value}</span></p>
                        {String(selectedType) === "0" && <>
                            <p>Site type: <span style={{ color: "#69f978" }}>Pre-Landing page</span></p>
                        </>}
                        {String(selectedType) === "1" && <>
                            <p>Site type: <span style={{ color: "#69f978" }}>Landing (offer) page</span></p>
                        </>}
                    </>}
                    {String(selectedType) === "2" && <>
                        <p>Site type: <span style={{ color: "#69f978" }}>Combined landing and pre-landing page (single domain)</span></p>
                        <p>Pre-Landing URL should start with: <span style={{ color: "#69f978" }}>{URLJoin(siteURLRef.current?.value, combinedPreLandingEntryRef.current?.value)}</span></p>
                        <p>Landing (offer) URL should start with: <span style={{ color: "#69f978" }}>{URLJoin(siteURLRef.current?.value, combinedLandingEntryRef.current?.value)}</span></p>
                        <p>Same tracking script should be placed on both websites!</p>
                    </>}
                    {(String(selectedType) === "0" && selectedIntegrationLander) && <>
                        <br />
                        <p>In case of integration (like facebook or google):</p>
                        <p>Landing (offer) page: {(() => {
                            if (!websites) return null;
                            if (websites.status !== "ok") return null;

                            return <span style={{ color: "#69f978" }}>{websites.data.find(w => w.ID === selectedIntegrationLander)?.SiteName ?? "-"}</span>
                        })()}</p>
                    </>}
                    <br />
                    <p>Site risk factor is: <span style={{ color: siteRiskColors(selectedRisk) }}>{siteRisksSelector[selectedRisk]}</span></p>
                    <p>Should the script track refreshes / bot / spam visits (Any site visits that are repeated more than once in 1 hour): {repeatingVisits ? <span style={{ color: "#69f978" }}>Yes</span> : <span style={{ color: "rgb(255, 136, 136)" }}>No</span>}</p>
                </div>}

                <div className="route__user__sites__add__wrap__buttons">
                    <div className="route__user__sites__add__wrap__buttons__btn route__user__sites__add__wrap__buttons__btn--secondary" onClick={onClose}>Cancel</div>
                    <div className="route__user__sites__add__wrap__buttons__btn" onClick={addSite}>
                        {spinner ? <Spinner style={{ width: "24px", height: "24px" }} color="#fff" /> : "Save"}
                    </div>
                </div>

                <p className="route__user__sites__add__wrap__infoP" style={{
                    opacity: infoP.hadErrors ? 1 : 0
                }}>{infoP.error}</p>

            </>}
        </div>
    </div>
};

const SiteChecker = props => {
    const [data, setData] = React.useState();
    const [infoP, setInfoP] = React.useState();
    const [completedStep, setCompletedStep] = React.useState(0);
    const [spinner, setSpinner] = React.useState(false);
    const [healthCheckSpinner, setHealthCheckSpinner] = React.useState(false);
    const [conversionData, setConversionData] = React.useState({});
    const [timestamp, setTimestamp] = React.useState(Date.now());
    const [lastHealthError, setLastHealthError] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    // 1
    const [trackingCheck, setTrackingCheck] = React.useState(undefined);

    const campaignIDRef = React.useRef();
    const finishedRef = React.useRef();

    const cleanUp = () => {
        if (campaignIDRef.current) {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/campaigns/removeTestCampaign`,
                data: {
                    ID: campaignIDRef.current,
                    ...(finishedRef.current ? {
                        SiteID: data?.data?.ID
                    } : {})
                },
                ...backendModule.axiosConfig
            }).then(() => null).catch(() => null);
        };
    };

    const onClose = () => {
        if (props.onChange) props.onChange();
        cleanUp();

        props.onClose();
    };

    const addTestCampaign = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/addTestCampaign`,
            data: {
                SiteID: props.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                campaignIDRef.current = res.data.data;
                setCompletedStep(1);
            } else {
                setInfoP("Error while generating a test campaign.");
            };
        }).catch(() => {
            setInfoP("Server timed out while generating campaign.");
        }).finally(() => {
            setSpinner(false);
        });
    };

    const getTrackingScript = (cb) => {
        let script = "";

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/sites/getTrackingScript`,
            data: {
                ID: props.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                script = res.data.data;
            } else {
                script = "There was an error while fetching the script...";
            };
        }).catch(() => {
            script = "Server timed out";
        }).finally(() => {
            setSpinner(false);

            animateBox({ currentTarget: props.anchor }, <TrackingScript data={script} />);
            if (cb) cb();
        });
    };

    const viewConversionInfo = (cb) => {
        let info = "";
        try {
            info = JSON.stringify(conversionData, undefined, 4);
        } catch { };
        animateBox({ currentTarget: props.anchor }, <TrackingScript highlight={true} heading="Conversion info" data={info} />);
    };

    const getSite = () => {
        if (!props.ID) return setData(backendModule.genericError);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/sites/getAllSites`,
            data: {
                limit: 1,
                filters: [
                    { name: "ID", op: "eq", value: props.ID }
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 1) {
                    return healthCheck(res.data.data[0]);
                };
            };
            return setData(backendModule.genericError);
        }).catch(() => {
            return setData(backendModule.genericError);
        });
    };

    // 0
    const healthCheck = (site) => {
        setHealthCheckSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/sites/healthCheck`,
            data: {
                ID: site.ID
            },
            ...backendModule.axiosConfig
        }).then(hc => {
            if (hc.data.status === "ok") {
                if (!data) setData({ status: "ok", data: site });
                setHealthCheckSpinner(false);
                addTestCampaign();
                setLastHealthError();
            } else {
                setLastHealthError(hc.data.data);
                if (!data) setData({ status: "ok", data: site });
            };
        }).catch(() => {
            setData(backendModule.genericError);
        }).finally(() => {
            setTimeout(() => setHealthCheckSpinner(false));
        });
    };

    // 1
    const openSite = (type) => {
        setTrackingCheck(null);

        const openURL = (url) => {
            try {
                let tmp = new URL(url);
                tmp.searchParams.set("sttest", "1");

                return tmp.toString();
            } catch {
                setInfoP("There was an error while openning the site");
            };
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getCampaignURLs`,
            data: {
                IntegrationID: "-1",
                IDs: [campaignIDRef.current]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (!res.data.data[campaignIDRef.current]) return setInfoP("Test campaign was not found, try again later!");

                if (data.data.SiteType === 1) {
                    if (!res.data.data[campaignIDRef.current]["landing"]) return setInfoP("Website URL was not found, try again later!");
                    return window.open(openURL(res.data.data[campaignIDRef.current]["landing"]), "_blank");
                } else if (data.data.SiteType === 0) {
                    if (!res.data.data[campaignIDRef.current]["pre-landing"]) return setInfoP("Website URL was not found, try again later!");
                    return window.open(openURL(res.data.data[campaignIDRef.current]["pre-landing"]), "_blank");
                } else {
                    if (!res.data.data[campaignIDRef.current][type]) return setInfoP("Website URL was not found, try again later!");
                    return window.open(openURL(res.data.data[campaignIDRef.current][type]), "_blank");
                }
            } else {
                setInfoP("There was an error while gettting links");
            };
        }).catch(() => {
            setInfoP("Server timed out while gettting links");
        });
    };

    const parseErrorMessage = err => {
        let tmp = err.split("_");
        if (tmp.length === 0) return "";

        tmp[0] = `${tmp[0].charAt(0).toUpperCase()}${tmp[0].substring(1, tmp[0].length).toLowerCase()}`;
        tmp = tmp.map((item, index) => {
            if (index === 0) return item;
            return item.toLowerCase();
        });

        return tmp.join(" ");
    };

    // 2
    React.useEffect(() => {
        if (!data) return;
        if (data.status === "error") return;

        const performTest1 = async (type) => {
            let test = await axios({
                method: "POST",
                url: `${backendModule.backendURL}/campaigns/checkTestCampaign`,
                data: {
                    CampaignID: campaignIDRef.current,
                    isPreLander: type === undefined ? ((data.data.SiteType === 0 || data.data.SiteType === 2) ? true : false) : !!type,
                    hasConverted: false
                },
                ...backendModule.axiosConfig
            }).then(res => res.data.status === "ok" ? true : false).catch(() => false);

            return test;
        };

        const performTest_prelander_lander = async (type) => {
            let test = await axios({
                method: "POST",
                url: `${backendModule.backendURL}/campaigns/checkTestCampaign`,
                data: {
                    CampaignID: campaignIDRef.current,
                    isPreLander: type === undefined ? ((data.data.SiteType === 0 || data.data.SiteType === 2) ? true : false) : !!type,
                    hasConverted: true
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") setConversionData(res.data.data);
                return res.data.status === "ok" ? true : false;
            }).catch(() => false);

            return test;
        };

        let curTimeout = null;
        const sleep = () => {
            clearTimeout(curTimeout);
            curTimeout = setTimeout(() => {
                setTimestamp(Date.now());
            }, 1000);
        };

        (async () => {
            if (data.data.SiteType === 0 || data.data.SiteType === 1) {
                if (trackingCheck === null) {
                    let test = await performTest1();
                    if (test) {
                        setTrackingCheck(1);
                        return;
                    };
                    sleep();
                } else if (trackingCheck === 1) {
                    if (completedStep === 1) setCompletedStep(2);
                    let test = await performTest_prelander_lander();
                    if (test) {
                        setTrackingCheck(2);
                        return;
                    };
                    sleep();
                } else if (trackingCheck === 2) {
                    if (completedStep === 2) {
                        setCompletedStep(3);
                        finishedRef.current = true;
                        cleanUp();
                        return;
                    };
                };
            } else {

                if (completedStep < 3) {
                    if (trackingCheck === null) {
                        let test = await performTest1(true);
                        if (test) {
                            setTrackingCheck(1);
                            return;
                        };
                        sleep();
                    } else if (trackingCheck === 1) {
                        if (completedStep === 1) setCompletedStep(2);
                        let test = await performTest_prelander_lander(true);
                        if (test) {
                            setTrackingCheck(2);
                            return;
                        };
                        sleep();
                    } else if (trackingCheck === 2) {
                        if (completedStep === 2) {
                            setCompletedStep(3);
                            setTrackingCheck(null);
                            return;
                        };
                    };
                } else {
                    if (trackingCheck === null) {
                        let test = await performTest1(false);
                        if (test) {
                            setTrackingCheck(1);
                            return;
                        };
                        sleep();
                    } else if (trackingCheck === 1) {
                        if (completedStep === 3) setCompletedStep(4);
                        let test = await performTest_prelander_lander(false);
                        if (test) {
                            setTrackingCheck(2);
                            return;
                        };
                        sleep();
                    } else if (trackingCheck === 2) {
                        if (completedStep === 4) {
                            setCompletedStep(5);
                            finishedRef.current = true;
                            cleanUp();
                            return;
                        };
                    };
                };
            };
        })()

    return () => clearTimeout(curTimeout);
    }, [trackingCheck, data, completedStep, timestamp])

    React.useEffect(() => {
        getSite();
    }, []);

    return <div className="route__user__sites__siteChecker genericModal">
        <div className="genericModal__wrap">
            <div className={`genericModal__wrap__spinner ${spinner ? "genericModal__wrap__spinner--active" : ""}`}>
                <Spinner color={themeSelector === "dark" ? "white" : "black"} />
            </div>

            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Check site</div>
                <div className="genericModal__wrap__head__right" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={onClose}></div>
            </div>

            {data ? <>
                {data.status === "ok" ? <>
                    <p className={`route__user__sites__siteChecker__step ${completedStep >= 1 ? "route__user__sites__siteChecker__step--completed" : ""}`}>
                        <span>1. Copy the script in to the <span style={{ color: "#5A49CE" }}>&lt;head&gt;</span> portion of the website.{data.data.SiteType === 2 ? " This script is for both the pre-lander and lander." : ""}</span>
                        {lastHealthError && <p style={{flexBasis: "100%", color: "#FF450D"}}>{parseErrorMessage(lastHealthError)}</p>}
                        <div className="route__user__sites__siteChecker__step__btn" onClick={(e) => {
                            getTrackingScript();
                        }}>Get script</div>
                        {healthCheckSpinner ? <Spinner color={themeSelector === "dark" ? "white" : "black"} style={{ width: "24px", height: "24px" }} /> : <div className="route__user__sites__siteChecker__step__btn" onClick={(e) => {
                            healthCheck(data.data);
                        }}>Verify</div>}
                    </p>

                    {completedStep >= 1 && (() => {
                        // pre-landing
                        if (data.data.SiteType === 0) return <>
                            <p className={`route__user__sites__siteChecker__step ${completedStep >= 2 ? "route__user__sites__siteChecker__step--completed" : ""}`}>
                                <span>2. Open the pre-lander</span>
                                {trackingCheck === undefined && <div className="route__user__sites__siteChecker__step__btn" onClick={openSite}>Open</div>}
                                {trackingCheck === null && <Spinner color={themeSelector === "dark" ? "white" : "black"} style={{ width: "24px", height: "24px" }} />}
                            </p>

                            {completedStep >= 2 && <p className={`route__user__sites__siteChecker__step ${completedStep >= 3 ? "route__user__sites__siteChecker__step--completed" : ""}`}>
                                <span>3. Redirect to landing page</span>
                                {trackingCheck === 1 && <Spinner color={themeSelector === "dark" ? "white" : "black"} style={{ width: "24px", height: "24px" }} />}
                            </p>}

                            {completedStep >= 3 && <>
                                <p style={{ color: "rgb(84, 238, 84)" }}>Testing completed, Pre-lander should be ready to use!</p>
                                <br />

                                <div className="genericModal__wrap__buttons">
                                    <div className="genericModal__wrap__buttons__btn" onClick={onClose}>Close</div>
                                </div>
                            </>}
                        </>

                        // landing
                        if (data.data.SiteType === 1) return <>
                            <p className={`route__user__sites__siteChecker__step ${completedStep >= 2 ? "route__user__sites__siteChecker__step--completed" : ""}`}>
                                <span>2. Open the landing page</span>
                                {trackingCheck === undefined && <div className="route__user__sites__siteChecker__step__btn" onClick={openSite}>Open</div>}
                                {trackingCheck === null && <Spinner color={themeSelector === "dark" ? "white" : "black"} style={{ width: "24px", height: "24px" }} />}
                            </p>

                            {completedStep >= 2 && <p className={`route__user__sites__siteChecker__step ${completedStep >= 3 ? "route__user__sites__siteChecker__step--completed" : ""}`}>
                                <span>3. Perform a conversion</span>
                                {trackingCheck === 1 && <Spinner color={themeSelector === "dark" ? "white" : "black"} style={{ width: "24px", height: "24px" }} />}
                                {completedStep === 3 && <div className="route__user__sites__siteChecker__step__btn route__user__sites__siteChecker__step__btn--noDisable" onClick={(e) => {
                                    viewConversionInfo();
                                }}>View details</div>}
                            </p>}

                            {completedStep >= 3 && <>
                                <p style={{ color: "rgb(84, 238, 84)" }}>Testing completed, Landing page should be ready to use!</p>
                                <br />

                                <div className="genericModal__wrap__buttons">
                                    <div className="genericModal__wrap__buttons__btn" onClick={onClose}>Close</div>
                                </div>
                            </>}
                        </>;

                        // combined
                        if (data.data.SiteType === 2) return <>
                            <p className={`route__user__sites__siteChecker__step ${completedStep >= 2 ? "route__user__sites__siteChecker__step--completed" : ""}`}>
                                <span>2. Open the pre-landing page</span>
                                {trackingCheck === undefined && <div className="route__user__sites__siteChecker__step__btn" onClick={() => openSite("pre-landing")}>Open</div>}
                                {(trackingCheck === null && completedStep <= 1) && <Spinner color={themeSelector === "dark" ? "white" : "black"} style={{ width: "24px", height: "24px" }} />}
                            </p>

                            {completedStep >= 2 && <p className={`route__user__sites__siteChecker__step ${completedStep >= 3 ? "route__user__sites__siteChecker__step--completed" : ""}`}>
                                <span>3. Redirect to landing page</span>
                                {(trackingCheck === 1 && completedStep <= 2) && <Spinner color={themeSelector === "dark" ? "white" : "black"} style={{ width: "24px", height: "24px" }} />}
                            </p>}

                            {completedStep >= 3 && <p className={`route__user__sites__siteChecker__step ${completedStep >= 4 ? "route__user__sites__siteChecker__step--completed" : ""}`}>
                                <span>4. Wait for the landing page to load</span>
                                {(trackingCheck === null && completedStep <= 3) && <Spinner color={themeSelector === "dark" ? "white" : "black"} style={{ width: "24px", height: "24px" }} />}
                            </p>}

                            {completedStep >= 4 && <p className={`route__user__sites__siteChecker__step ${completedStep >= 5 ? "route__user__sites__siteChecker__step--completed" : ""}`}>
                                <span>5. Perform a conversion</span>
                                {(trackingCheck === 1 && completedStep <= 4) && <Spinner color={themeSelector === "dark" ? "white" : "black"} style={{ width: "24px", height: "24px" }} />}
                                {completedStep === 5 && <div className="route__user__sites__siteChecker__step__btn route__user__sites__siteChecker__step__btn--noDisable" onClick={(e) => {
                                    viewConversionInfo();
                                }}>View details</div>}
                            </p>}

                            {completedStep >= 5 && <>
                                <p style={{ color: "rgb(84, 238, 84)" }}>Testing completed, Combined page should be ready to use!</p>
                                <br />

                                <div className="genericModal__wrap__buttons">
                                    <div className="genericModal__wrap__buttons__btn" onClick={onClose}>Close</div>
                                </div>
                            </>}
                        </>
                    })()}

                    {infoP && <p className="genericModal__wrap__infoP" style={{ opacity: 1 }}>{infoP}</p>}
                </> : <p className="genericModal__wrap__infoP" style={{ opacity: 1 }}>There was an error while fetching the site.</p>}
            </> : <Spinner color={themeSelector === "dark" ? "white" : "black"} style={{ width: "32px", height: "32px" }} />}
        </div>
    </div>
};

export default UserSites;