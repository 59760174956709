import React from "react";
import "./index.scss";

import axios from "axios";
import { useSelector } from "react-redux";
import * as backendModule from "../../../modules/backendModule";
import * as basicStylesModule from "../../../modules/basicStylesModule";

import Spinner from "../../customComponents/Spinner";
import Dropdown from "../../../components/customComponents/Dropdown";
import StyledButton from "../../styledComponents/Button";

const SelectUsersModal = props => {
    const [data, setData] = React.useState();
    const [dropdownSelected, setDropdownSelected] = React.useState();
    const [selected, setSelected] = React.useState(props.defaultValue === undefined ? [] : props.defaultValue);

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const dropdownResetRef = React.useRef();

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            data: {
                limit: null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    React.useEffect(() => {
        getData();
    }, []);

    return <div className="modals__selectUsersModal genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Select users</div>
                <div className="genericModal__wrap__head__right" onClick={props.onClose} style={{backgroundImage: `url("/images/icon_close.svg")`}}></div>
            </div>

            {data ? <>
                {data.status === "ok" ? <>
                    <div className="modals__selectUsersModal__dropdown">
                        <Dropdown
                            theme={themeSelector}
                            accent="#6C5DD3"
                            inlinePlaceholder="User"
                            data={data.data.filter(d => !selected.includes(d.ID)).map(usr => {
                                return {name: usr.Username, value: usr.ID}
                            })}
                            onChange={e => setDropdownSelected(e?.value)}
                            selected={(()=>{
                                if (!dropdownSelected) return null;
                                let tmp = data.data.filter(d => !selected.includes(d.ID));

                                return tmp.indexOf(tmp.find(t => t.ID === dropdownSelected));
                            })()}
                            onReset={r => dropdownResetRef.current = r}
                        />
                        <StyledButton style={{height: "100%"}} onClick={() => {
                            if (!dropdownSelected) return;

                            setSelected(s => [...s, dropdownSelected])
                            setDropdownSelected();
                            dropdownResetRef.current();
                        }}>Add</StyledButton>
                    </div>

                    <div className="modals__selectUsersModal__users">
                        {selected.map(s => {
                            let curUser = data.data.find(d => d.ID === s);

                            return <div className="modals__selectUsersModal__users__user">
                                <span>{curUser?.Username ?? "Unknown user"} ({curUser?.Email ?? "-"})</span>
                                <StyledButton isSecondary={true} style={{height: "30px"}} onClick={() => setSelected(cs => cs.filter(ss => ss !== s))}>Remove</StyledButton>
                            </div>
                        })}
                    </div>

                    <div className="genericModal__wrap__buttons" style={{marginTop: "20px"}}>
                        <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Close</div>
                        <div className="genericModal__wrap__buttons__btn" onClick={() => {
                            if (typeof(props.onChange) === "function") props.onChange(selected);
                            props.onClose();
                        }}>Save</div>
                    </div>
                </> : <p style={{color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}>Error while fetching users!</p>}
            </> : <Spinner style={{width: "32px", height: "32px"}} color={themeSelector === "dark" ? "white" : "black"} />}
        </div>
    </div>
};

export default SelectUsersModal;