import React from "react";
import "./index.scss";

import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";
import * as backendModule from "../../../modules/backendModule";
import { animateBox } from "../../../modules/componentAnimation";
import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen";

import { FilteredCustomTable } from "../../customComponents/Table";
import Spinner from "../../customComponents/Spinner";

const CreativeDetailsModal = props => {
    const [data, setData] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [globalSpinner, setGlobalSpinner] = React.useState(false);
    const [spinner, setSpinner] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const timestampRef = React.useRef();
    const curDefer = useDefer();
    const curOnScreen = useOnScreen();

    const wrapTableItem = item => <span style={{padding: "0 50px 0 0px"}}>{item}</span>;

    const getCampaignIDs = () => {
        if (!props?.item?.ID) return null;

        return props.item.ID;
    };

    const generateKey = item => {
        if (item?.ID) return item.ID;

        let final = "";
        if (!item) return String(item);
        for (let key of Object.keys(item)) {
            if (typeof (item[key]) === "object") {
                final += generateKey(item[key]);
                continue;
            };
            final += String(item[key]);
        };
        return final;
    };

    const getData = (ts) => {
        const filters = [];

        if (props.search) {
            filters.push({
                or: [
                    
                ]
            })
        };

        if (Array.isArray(props.filters)) filters.push(...props.filters);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getCreativeStatsByCampaignID`,
            data: {
                filters,
                CampaignID: getCampaignIDs()
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length >= 20) {
                    setCanPaginate(true);
                } else {
                    setCanPaginate(false);
                };
            };
            setData({...res.data, timestamp: Date.now()});
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData({...backendModule.genericError, timestmap: Date.now()});
        }).finally(() => {
            if (timestampRef.current !== ts) return;
            setGlobalSpinner(false);
        });
    };

    const continueData = (ts) => {
        setCanPaginate(false);
        if (!data) return;
        if (data.status !== "ok") return;
        if (data.data.lnegth === 0) return;

        const filters = [];

        if (props.search) {
            filters.push({
                or: [

                ]
            })
        };
        if (Array.isArray(props.filters)) filters.push(...props.filters);

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getCreativeStatsByCampaignID`,
            data: {
                CampaignID: getCampaignIDs(),
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (timestampRef.current !== ts) return;
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ],
                        timestamp: Date.now()
                    };
                });
                if (res.data.data.length >= 20) {
                    setCanPaginate(true);
                } else {
                    setCanPaginate(false);
                };
            };
        }).catch(() => null).finally(() => {
            if (timestampRef.current !== ts) return;
            setSpinner(false);
            setGlobalSpinner(false);
        });
    };

    const checkNaN = num => {
        if (isNaN(num)) return 0;
        return num;
    }

    React.useEffect(() => {
        if (data) setGlobalSpinner(true);

        let ts = Date.now();
        timestampRef.current = ts;
        curDefer(() => {
            getData(ts);
        }, 500);
    }, [props.search, props.filters]);

    React.useEffect(() => {
        if (curOnScreen.isIntersecting && canPaginate) {
            try {
                curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
            } catch { };

            let ts = Date.now();
            timestampRef.current = ts;
            curDefer(() => {
                continueData(ts);
            }, 500);
        };
    }, [canPaginate, curOnScreen.isIntersecting]);

    return <div className="modals__creativeDetailsModal__wrap__content" style={{
        gridTemplateColumns: !selectedItem ? "1fr auto" : "calc(100% - 800px) 800px",
        gridTemplateRows: "100%",
        padding: 0,
        maxHeight: "100%",
        minHeight: "100%",
        height: "100%"
    }} >
        <div className="modals__creativeDetailsModal__wrap__content__left">
            <div className="modals__creativeDetailsModal__wrap__content__left__tableWrap">
                <FilteredCustomTable
                    theme={themeSelector}
                    accent="#6C5DD3"
                    headers={["Creative", "Successful SMS Sent", "Visits", "Conversions", "CTR", "CR"]}
                    customColumns={["auto", "auto", "auto", "auto", "auto", "auto"]}
                    spinnerColor="#fff"
                    showSpinner={globalSpinner}
                    data={(() => {
                        let out = [];

                        if (!data) return [[{ keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black" }]];
                        if (data.status === "ok") {
                            for (let key of Object.keys(data.data).sort((a, b) => {
                                if (a === "other" && b !== "other") return 1; else return a > b ? 1 : -1;
                            })) {
                                
                                out.push([
                                    { keyID: `${generateKey(data.data[key])}-${data.timestamp}`, type: "text", text: wrapTableItem(key) },
                                    { keyID: `${generateKey(data.data[key])}-${data.timestamp}`, type: "text", text: wrapTableItem(data.data[key].Count) },
                                    { keyID: `${generateKey(data.data[key])}-${data.timestamp}`, type: "text", text: wrapTableItem(data.data[key].Visits) },
                                    { keyID: `${generateKey(data.data[key])}-${data.timestamp}`, type: "text", text: wrapTableItem(data.data[key].Conversions) },
                                    { keyID: `${generateKey(data.data[key])}-${data.timestamp}`, type: "text", text: wrapTableItem(`${Number(checkNaN(100 / +data.data[key].Count * +data.data[key].Visits)).toFixed(2)} %`) },
                                    { keyID: `${generateKey(data.data[key])}-${data.timestamp}`, type: "text", text: wrapTableItem(`${Number(checkNaN(100 / +data.data[key].Visits * +data.data[key].Conversions)).toFixed(2)} %`) }
                                ])
                            };
                        } else {
                            out.push([{ keyID: "noData-error", type: "text", text: "Error while fetching orders!", color: "#f96666" }]);
                        };

                        if (spinner) out.push([{ keyID: "pagination-spinner", type: "spinner", color: "white" }]);

                        if (out.length === 0) out.push([{ keyID: "noData-noData", type: "text", text: "Nothing to show for now." }]);

                        return out;
                    })()}
                />
            </div>

            {canPaginate && <div ref={curOnScreen.measureRef} className="modals__creativeDetailsModal__wrap__content__left__paginate"></div>}
        </div>
    </div>;
};

export default CreativeDetailsModal;