import React from "react";
import "./index.scss";

import {useNavigate} from "react-router-dom";
import animateModule from "../../modules/animateModule";

const LandingFooter = () => {

    let curNavigate = useNavigate();

    const animateNavigate = to => {
        animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));
    };

    const internalScroll = (item) => {
        let tmp = document.querySelector(item);
        if(!tmp) return;
        tmp.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start'});
    }

    return <div className="route__landingFooter">
        <div className="route__landingFooter__top">
            <div className="route__landingFooter__top__wrapper">
                <div className="route__landingFooter__top__wrapper__left" onClick={() => animateNavigate("/")}>
                    <img src="/images/landing/Logo.svg" ></img>
                </div>
                <div className="route__landingFooter__top__wrapper__right">
                    <a onClick={() => {internalScroll('#home')}}>Home</a>
                    <a onClick={() => {internalScroll('#features')}}>Features</a>
                    <a onClick={() => {internalScroll('#pricing')}}>Pricing</a>
                    <a onClick={() => animateNavigate("/register")}>NEW ACCOUNT</a>
                    <a onClick={() => animateNavigate("/login")}>LOG IN</a>
                </div>
            </div>
        </div>
        <div className="route__landingFooter__bot">
            <div className="route__landingFooter__bot__wrapper">
                <div className="route__landingFooter__bot__wrapper__left">
                    <p>Scale, LLC </p>
                    <p>Licence number: 4201920.01</p>
                    <p>Tax: 100616176200003</p>
                    <p>Address: info@scale-track.com</p>
                    <p>Address: Business Center, Sharjah Publishing City , Sharjah, UAE </p>
                    <p>© 2023, SCALETRACK</p>
                </div>
                <div className="route__landingFooter__bot__wrapper__right">
                    <div className="route__landingFooter__bot__wrapper__right__top">
                        <a href="#" target="_blank"><img src="/images/landing/telegram_icon.svg"></img></a>
                        <a href="#" target="_blank"><img src="/images/landing/facebook_icon.svg"></img></a>
                        <a href="#" target="_blank"><img src="/images/landing/instagram_icon.svg"></img></a>
                    </div>
                    <div className="route__landingFooter__bot__wrapper__right__bottom">
                        <p onClick={() => animateNavigate("/terms")}>Terms of Service</p>
                        <p onClick={() => animateNavigate("/privacy")}>Privacy policy</p>
                    </div>
                    <div className="route__landingFooter__bot__wrapper__right__bottom" style={{marginTop: "10px"}}>
                        <p onClick={() => animateNavigate("/data-deletion")}>Data deletion policy</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
};


export default LandingFooter;