import React from "react";
import "./index.scss";

const TermsOfService = () => {

    React.useEffect(() => {
        let tmp = document.querySelector(".component__contentWrapper");
        if (!tmp) return;

        tmp.scrollTo(0, 0);
    }, []);

    return <div className="route__termsOfService">
        <h1>PLEASE READ THIS AGREEMENT CAREFULLY; THIS IS A BINDING CONTRACT.</h1>

        <p>Welcome to Scale-Track. This Agreement provides terms and conditions for your use of the Ad Beacon platform. By clicking the Start Free Trial, Sign Up or Start Today button displayed online as part of the ordering process or signing an Order Confirmation that references this Agreement, you agree to the following terms and conditions (the “Agreement”) governing your use of Scale-Track Inc.’s (“Scale-Track,” “our,” “Platform”, “Scale-Track Software”, “Software” or “we”) online service, including but not limited to the use of software components on a hosted basis and associated support options (collectively, the “Service”). This Terms of Use (this “ToU”) furthermore, describes the terms and conditions applicable to your access and use of the Site and the Software data reporting related to software made available through the Site and Software. You warrant and represent that you are at least eighteen years of age or older or you will immediately stop use of this site.You acknowledge that you have read and understood and unconditionally agreed to be bound by the terms of this Agreement and that these terms will apply each time you access our Service. If you are entering into this Agreement on behalf of a company or other legal entity, you represent that you have authority to bind such entity to these terms and conditions.</p>
        <p>Scale-Track may amend this ToU at any time by posting the amended Terms of Use on the Site, and you agree that you will be bound by any changes to this ToU. For your convenience, the date of the last revision is included at the top of this page. Scale-Track may make changes to the Site and/or the Software at any time. You understand that Scale-Track may discontinue or restrict your use of the Site and/or Software for any reason or no reason with or without notice.</p>
        <p>YOUR USE OF THE SITE AND/OR OUR SAAS PLATFORM (REFERRED TO AS SOFTWARE), OR BY CLICKING “I ACCEPT” IF PRESENTED WITH THIS TOU IN A CLICK-THROUGH FORMAT, SIGNIFIES THAT YOU AGREE TO THIS TOU AND CONSTITUTES YOUR BINDING ACCEPTANCE OF THIS TOU, INCLUDING ANY MODIFICATIONS THAT SCALE-TRACK MAKES FROM TIME TO TIME.</p>

        <p className="route__termsOfService__underline">1. Privacy Policy.</p>
        <p>By clicking “I ACCEPT” or using the Site or the Software, you represent that you have read and consent to our Privacy Policy in addition to this ToU. Scale-Track may revise the Privacy Policy at any time, and the new versions will be available on the Site. If at any point you do not agree to any portion of the Privacy Policy, you must immediately stop using the Site and/or Software.</p>

        <p className="route__termsOfService__underline">2. Terms of Software.</p>
        <p>By clicking “I ACCEPT” and engaging Scale-Track to provide the Software, you represent that you have read and consent to the Terms of Software in addition to this ToU. Scale-Track may revise the Terms of Software at any time, and the new versions will be available on the Site and/or Software. If at any point you do not agree to any portion of the Terms of Software, you must immediately notify Scale-Track that you would like Scale-Track to stop providing you the Software Softwares. This Agreement should not be construed as a sales agreement. Instead, it provides you with limited licensing rights to use the Service in connection with your own use of our Platform, software and data. We provide you with increased functionality for certain tools when used together under this Agreement. By using the Scale-Track Platform andr our software, you agree to allow access and use your computer hardware, software and other equipment (“Equipment”) in accordance with this Agreement.</p>
        <p className="route__termsOfService__moveToRight">2.1. Account Information and Data. All of the content and information submitted to the Platform (including any copies thereof) is owned by you, or otherwise specific to your company or agency. By submitting Customer data to the Platform service (including any copies thereof), you grant Scale-Track a worldwide, royalty-free, non-exclusive right during the License Term to use, reproduce, create derivative works of, distribute, perform, transmit and publish your Customer Data for the sole purposes of (i) processing your Customer Data in connection with providing the Service to you and/or your Users and (ii) storing or hosting such Customer Data in a secure remote database or on our Site for access by your Users. All customer data is stored securely and can only be accessed by you and persons that you have given explicit authorization to access your account, and not shared with any other customer or third party. Scale-Track reserves the right to use your customer data in order to compile, analyze and disclose to third parties aggregated metrics, data and trends related to the use of its offerings as long as such metrics, data and trends do not contain any uniquely identifiable information. You are responsible for the accuracy, quality, integrity and legality of all your data. You shall obtain your data from legitimate sources, ensure that it is not misleading or inaccurate in any way and does not infringe upon the rights of any third party. Nothing contained in this Agreement will limit Scale-Track’s right to comply with governmental, court and law enforcement requests or requirements relating to your use of the Service, which may include disclosing your Customer data to the applicable authorities, we will make reasonable attempts to provide you notice in advance of our disclosure.</p>
    
        <p className="route__termsOfService__underline">3. Requirements.</p>
        <p>Before you can use the Software, you must agree to this ToU, the Terms of Use and the Privacy Policy, which you will be deemed to have done by utilizing the Software. These requirements may change as the Software evolves. You are responsible for any Internet connection fees or telecommunication fees that you incur when accessing the Software.</p>

        <p className="route__termsOfService__underline">4. ToU Updates.</p>
        <p>Scale-Track will revise this ToU as the Site and/or Software evolves. The next time you use the Software after such an update, you may be prompted to agree to or decline the revised ToU. You must agree to all revisions if you choose to continue using the Site and/or Software. By using the Site and/or Software, you agree to the then-current version of this ToU as posted on the Site. If at any point you do not agree to any portion of the then-current version of this ToU, you must immediately stop using the Site and/or Software.</p>

        <p className="route__termsOfService__underline">5. Termination of Software.</p>
        <p>Scale-Track reserves the right to deny Software to any person at Scale-Track’s sole and absolute discretion. The Software is offered with the understanding that Scale-Track may terminate any Account (defined below) registered to you and/or Scale-Track provision of the Software and/or Software Software at any time, for any reason or no reason, including without limitation for any violation of this ToU.</p>

        <p className="route__termsOfService__underline">6. Accounts and Security.</p>
        <p className="route__termsOfService__moveToRight">6.1. Eligibility. You represent that you are an adult and have the legal capacity to enter a contract in the jurisdiction where you reside.</p>
        <p className="route__termsOfService__moveToRight">6.2. Account. To access the Software, you must have an account (“Account“). You can create an Account by completing the registration process on the Site, through Scale-Track directly or as otherwise specified herein.</p>
        <p className="route__termsOfService__moveToRight">6.3. Software Data. We will provide certain data regarding the Software you receive from time to time (“Software Data“) through your Account. You agree to use the Software Data only in association with assessing the Software and the Software of your website and/or your client for no other purpose.</p>
        <p className="route__termsOfService__moveToRight">6.4. Account Security. Maintaining Account security is very important. You are entirely responsible for maintaining the confidentiality of the Account password. You agree to notify Scale-Track immediately if you believe that an account password may have been compromised.</p>
        <p className="route__termsOfService__moveToRight">6.5. Account Sharing or Transfers. You may not share or transfer any Account. You may not disclose your password to anyone else.</p>
        <p className="route__termsOfService__moveToRight">6.6. Cancellation by You. You have the right to cancel any monthly Account registered to you at the end of any month; yearly agreements may be canceled at the end of your 12 months period. You may cancel any Account registered to you by following the instructions on the Site or by contacting Scale-Track directly.</p>
        <p className="route__termsOfService__moveToRight">6.7. Effect of Account Termination or Cancellation. If you voluntarily terminate an Account or allow that Account to lapse by ceasing the Software, you may reactivate that Account by subscribing to additional Software at any time. Accounts terminated by Scale-Track for any type of abuse, including without limitation a violation of this ToU, may not be reactivated for any reason. If your Account is canceled, your access to the Software will automatically be terminated as well.</p>

        <p className="route__termsOfService__underline">7. Restrictions and Conditions of Use.</p>
        <p className="route__termsOfService__moveToRight">7.1. Use of Site and Software. Scale-Track permits you to view and use a single copy of the Site and/or the Software solely for the purpose of reviewing the information provided to you as part of the Software Softwares. You agree not to license, create derivative works from, transfer, sell or re-sell any information, content, or Software obtained from the Site and/or the Software, including the Software Data. Scale-Track reserves the right to add or remove information, content or Software from the Site at any time at its sole discretion.</p>
        <p className="route__termsOfService__moveToRight">7.2. No Violation of Laws. You agree that you will not, in connection with your use of the Site or the Software, violate any applicable law or regulation. Without limiting the foregoing, you agree that you will not make available through the Site and/or Software any material or information that infringes any copyright, trademark, patent, trade secret, or other rights of any party (including rights of privacy or publicity).</p>
        <p className="route__termsOfService__moveToRight">7.3. Misuse of Site and/or Software. You may not connect to or use the Site and/or Software in any way not expressly permitted by this ToU. Without limiting the foregoing, you agree that you will not: (a) institute, assist, or become involved in any type of attack, including without limitation denial of Software attacks, upon the Site and/or Software or otherwise attempt to disrupt the Site and/or Software or any other person’s use of the Site and/or Software; or (b) attempt to gain unauthorized access to the Site, Software, Accounts registered to other users, or the computer systems or networks connected to the Site and/or Software. Furthermore, you may not use the Site or Software to develop, generate, transmit or store information that: (i) is defamatory, harmful, abusive, obscene, or hateful; (ii) in any way obstructs or otherwise interferes with the normal performance of another person’s use of the Site and/or Software, (iii) performs any unsolicited commercial communication not permitted by applicable law; (iv) constitutes harassment or a violation of privacy or threatens other people or groups of people; (v) is harmful to children in any manner; (vi) violates any applicable law, regulation or ordinance; (vii) makes any false, misleading or deceptive statement or representation regarding Scale-Track and/or the Software; or (viii) constitutes phishing, pharming or impersonates any other person, or steals or assumes any person’s identity (whether a real identity or online nickname or alias).</p>
        <p className="route__termsOfService__moveToRight">7.4. No Data Mining or Harmful Code. You agree that you will not (a) obtain or attempt to obtain any information from the Software not directly provided to you through the Software, including without limitation information of other account holders, including other user’s Software Data; (b) intercept, examine or otherwise observe any proprietary communications protocol used by the Software, whether, through the use of a network analyzer, packet sniffer or other devices; or (c) use any type of bot, spider, virus, clock, timer, counter, worm, software lock, drop dead device, Trojan-horse routing, trap door, time bomb or any other codes, instructions or third-party software that is designed to provide a means of surreptitious or unauthorized access to, or distort, delete, damage or disassemble, the Site or the Software.</p>

        <p className="route__termsOfService__underline">8. Links.</p>
        <p className="route__termsOfService__moveToRight">8.1. Links from the Site. The Site may contain links to websites operated by other parties. Scale-Track provides these links to other websites as a convenience, and the use of these sites is at your own risk. The linked sites are not under the control of Scale-Track, and Scale-Track is not responsible for the content available on the other sites. Such links do not imply Scale-Track’s endorsement of information or material on any other site and Scale-Track disclaims all liability with regard to your access to and use of such linked websites.</p>
        <p className="route__termsOfService__moveToRight">8.2. Links to the Site. Unless otherwise set forth in a written agreement between you and Scale-Track, you must adhere to Scale-Track’s linking policy as follows: (i) the appearance, position and other aspects of the link may not be such as to damage or dilute the goodwill associated with Scale-Track’ and/or its licensors’ names and trademarks, (ii) the appearance, position and other attributes of the link may not create the false appearance that your organization or entity is sponsored by, affiliated with, or associated with Scale-Track, (iii) when selected by a user, the link must display the Site on full-screen and not within a “frame” on the linking Site, and (iv) Scale-Track reserves the right to revoke its consent to the link at any time and in its sole discretion.</p>

        <p className="route__termsOfService__underline">9. Intellectual Property.</p>
        <p className="route__termsOfService__moveToRight">9.1. Trademarks. Scale-Track and the Scale-Track logo are trademarks/Software marks of Scale-Track. Unauthorized use of any Scale-Track trademark, Software mark, or logo may be a violation of federal and state trademark laws.</p>
        <p className="route__termsOfService__moveToRight">9.2. Copyright. The Site and the Software and the content made available through the Site and the Software, including the Software Data, are protected by U.S. and international copyright laws. Except for your use of the content in connection with the Software to which we have the appropriate licensing rights or as authorized in this ToU, you may not use, modify, reproduce or distribute any of the content, or the design or layout of the Site, Software or Software Data, or individual sections of the content, design or layout of the Site without Scale-Track’ express prior written permission.</p>
        <p className="route__termsOfService__moveToRight">9.3. Intellectual Property Rights. Scale-Track, or its licensors, own all right, title and interest, including all related Intellectual Property Rights in and to the Service, the Scale-Track Platform and the Scale-Track Technology. This means that you will not acquire any rights to the Intellectual Property Rights of Scale-Track by using the Service or by agreeing to this Agreement. This Agreement is not a sale, and you do not acquire any ownership rights in the Service, the Scale-Track Technology or the Intellectual Property Rights are owned by Scale-Track, Inc., and/or any legal interest in our patents, trademarks, trade secrets or other intellectual property rights. The Scale-Track site may include trademarks, service marks or logos of third parties, all of which are the property of their respective owners. In addition, all content published on the Site belongs to Scale-Track and/or its licensors under applicable copyright law. No right, license or interest in any trademark is granted to you by Scale-Track.</p>

        <p className="route__termsOfService__underline">10. Proprietary Rights.</p>
        <p className="route__termsOfService__moveToRight">10.1. Except for the limited subscription rights granted herein, you acknowledge and agree that the Services may include certain proprietary trademarks, copyrightable materials, data, software and other intellectual property (collectively, “Intellectual Property”). Regarding Intellectual Property, and excepting from this License of Intellectual Property rights owned by the Company or its licensors for the Services (a) to use the Services in a manner inconsistent with the documentation; (b) to make any unauthorized copies of any materials or documentation provided by Company; (c) to remove any trademark, logo mark or access control information from the Site; or (d) otherwise interfere with use or distribution of these documents as they are provided by Company.</p>
        <p className="route__termsOfService__moveToRight">10.2. You agree to use the Company’s Trademarks only for the purpose for which they are registered, and not to cause confusion, mistake or deception. In addition, you must not use any marks which are confusingly similar to a Mark of Company, or otherwise infringe upon the rights of others. All intellectual property rights in the services and software provided by Company are owned by its respective vendors. All intellectual property rights in the Services and Software of third parties used in connection with or for accessing the Services are owned by their respective owners. The use of Company’s Marks in any manner other than explicitly permitted on these Terms of Use is strictly prohibited. The license granted to Customer by these Terms of Use is non-transferable, and may not be assigned or sublicensed by Customer without Company’s prior written consent. Company does not grant any express or implied rights to any trademarks that are not specifically mentioned on this Agreement, including but not limited to variations thereof, or combinations thereof.</p>
        <p className="route__termsOfService__moveToRight">10.3. During the term of this Agreement, you may submit suggestions and ideas to Company that relate to Company’s products or services. In doing so, you agree that nothing in this Agreement shall be construed as granting to you any rights in such suggestions or ideas. You further acknowledge that any ideas, suggestions, or improvements shall be owned by Company and that Company is free to include such ideas in future products without compensation to you.</p>

        <p className="route__termsOfService__underline">11. Fees and Payments</p>
        <p className="route__termsOfService__moveToRight">11.1. Fees. There is no charge during the 14-day free trial. After the free 14-day free trial Customers are charged based upon their advertising budget through the Platform for the previous months advertising budget optimized through the platform. Monthly contracts can be canceled at the end of each month, annual contracts are cancellable at the end of the period term. All Customers are subject to acceptance by Scale-Track at its discretion. All Customer information provided by or on behalf of Customer must be current, complete and accurate and Customer is responsible for keeping such information updated. Order information is subject to automatic processing by Scale-Track for the purposes of managing your account.</p>
        <p className="route__termsOfService__moveToRight">11.2. Fees and Payment. Scale-Track charges and collects in advance for use of the Platform Service. The renewal charge will be equal to the Subscription in effect during the prior term, unless Scale-Track has given you at least thirty (30) days prior written notice of a fee increase, which shall be effective upon renewal and thereafter. Our Services are billed on a subscription basis (“Subscription”). You will be billed in advance on a recurring and periodic basis (“Billing Cycle”). Billing cycles are set either on a monthly or annual (12 months) basis, depending on the type of subscription plan you select when purchasing a Subscription. Scale-Track reserves the right to change its fees or billing methods at any time by providing notice to you. The customer is responsible for all fees applicable to the services. All fees are due and payable as set forth on the invoice, unless otherwise agreed in writing. When applicable, the customer authorizes Company (i) to charge such card in accordance with billing frequency specified in the order, and (ii) take steps to determine whether a debit/credit card number provided is valid. Company reserves the right to terminate its agreement with Customer at any time, including but not limited to, in the event any payment information is found at any time to be inaccurate, incomplete and/or not current. Company shall not be responsible for any overdraft charges or other fees that may be incurred due to Company use of Customer’s card for payment hereunder. Company reserves the right to update the price for Services at any time after the initial term.</p>
        <p className="route__termsOfService__moveToRight">11.3. Notification of Dispute. The customer must notify Company of any fee dispute within 15 days of the invoice date and agree to work in good faith to promptly resolve any dispute and pay fees within 30 days following resolution of the dispute.</p>
        <p className="route__termsOfService__moveToRight">11.4. Late Payments. You acknowledge that Company may, without prior notice, immediately suspend or terminate the Services or any portion thereof and/or your access to the Service if you are in default of payment. Company also reserves the right to impose a charge to restore data from delinquent accounts that have been suspended or terminated.</p>
        <p className="route__termsOfService__moveToRight">11.5. Taxes and Withholding. Customer agrees to be responsible for all applicable taxes (including but not limited to withholding tax, sales tax, services tax, value-added tax (VAT), goods and services tax (GST), tariffs, Universal Services Fund (USF) fees (collectively, “Taxes”) imposed by any government entity or collecting agency based on the Services. Customer agrees that you will indemnify and hold Company harmless against any claim, liability, and/or penalties resulting from the gross negligence or willful misconduct.</p>
        <p className="route__termsOfService__moveToRight">11.6. Refunds, Chargebacks, Cancellations. We are confident that our products and services are of exceptional value and quality. However, if you are not satisfied with our software platform or service, please contact us by phone, email, or in writing. In the event of refunds, all refunds must be made in writing within thirty (30) days of the first, original charge . All charges made prior to and after shall be ineligible for a refund after a three (3) day period has passed. In the event of chargebacks, any chargeback (i.e., the dispute of a charge with a credit card service provider) will result in an automatic disqualification from any refund. Chargebacks will be reversed and declined. All refunds, chargebacks and cancellations are subject to the terms and conditions of this Policy. Please reach out to our office for more information if you have any concerns on billing.</p>
        <p className="route__termsOfService__moveToRight">11.7. Free Trials. In the event that you are being provided with a free trial under this Agreement, all provisions of this Agreement pertaining to warranties by Scale-Track, indemnification by Scale-Track, payment obligations and similar shall not apply. In addition, notifications provided through the Service indicating the remaining number of days in the free trial shall constitute notice of termination. Further, Scale-Track may terminate a free trial at any time and for any reason.</p>

        <p className="route__termsOfService__underline">12. Customer Account Responsibility.</p>
        <p className="route__termsOfService__moveToRight">12.1. Customer is responsible for customer’s network and computing resources used to connect to the Services, ensuring all users exit or log off from the Services at the end of each session. Customer also must maintain the confidentiality of Customer’s account, user id’s, and passwords used in conjunction with the Services. Company reserves the right to suspend or terminate its agreement with Customer if Customer misuses or otherwise shares login information among users. Scale-Track will not be held liable for any losses incurred by you as a result of a third party using your password or account, and you may be held liable for any such losses incurred by us and/or another party. Scale-Track reserves the right to review your account to confirm compliance with applicable Use Levels and to terminate or suspend your access for overuse and/or misuse.</p>
        <p className="route__termsOfService__moveToRight">12.2. You agree not to: (i) interfere or attempt to interfere with the proper working of the Services; or (ii) bypass, circumvent and/or abuse any access controls, usage limits, or other restrictions of the Website, Software or Services. You understand and agree that you will not provide false information about yourself, impersonate another individual or provide misleading or false user information in connection with your use of the Website or Services.</p>

        <p className="route__termsOfService__underline">13. Prohibited Activity.</p>
        <p className="route__termsOfService__moveToRight">13.1 This software and service is for professional business use only. The Company reserves the right to remove any Customer that violates our Terms, guidelines and any other agreements. You also represent and warrant that this content does not violate the intellectual property rights of third parties. By affirming, representing, and warranting that any Customer Sites do not relate to the following: pornography or sexually explicit content; the occult; hate speech; racism; the purchase and use of illegal narcotics; or illegal activities of any kind. Company reserves the right to make the final decision regarding what is appropriate.</p>
        <p className="route__termsOfService__moveToRight">13.2. You agree not to use automated methods to boost or otherwise affect your user activity. This includes using bots, click-farms, fake click-throughs and any other techniques that generate user activity on the Websites, the Service or Customer Sites with the intention of faking or otherwise artificially inflating user engagement.</p>
        <p className="route__termsOfService__moveToRight">13.3 Customer agrees not to (a) modify, distribute, prepare derivative works of, reverse engineer, reverse assemble, disassemble, decompile or attempt to decipher any code relating to the Services, Software and/or Company’s technology; (b) knowingly or negligently access or use the websites, services or software in a manner that abuses or disrupts the Company’s networks, security systems, user accounts. You understand that this includes, but is not limited to: malicious hacking (such as by performing Denial of Service attacks against the Websites), Denial of Service attacks (such as by performing Denial of Service attacks against the Company’s websites and/or services), and making unauthorized changes to any data related to one or more users of Company websites; (c) you agree not to offer, market, or resell our Services to any unauthorized third party without the authorized consent of Scale-Track; (d) You may not use the Services in violation of Company policies, applicable laws, ordinances or regulations. You agree to abide by all applicable domestic and international laws, statutes, ordinances and regulations regarding your use of our Services; (e) Customer’s use of the Websites or Services is limited to Customer’s internal business purposes, and Customer will not use any data obtained from Scale-Track in any manner that would result in a competitive benchmarking study that includes any portion of such data. Additionally, if you are a direct competitor, you may not use our Services for any purpose whatsoever. The Company shall have sole and exclusive discretion to determine the applicability of the restrictions set forth above and any violations thereof. The Company reserves the right to remove or otherwise deny access at its own discretion.</p>
        <p className="route__termsOfService__moveToRight">13.4. The Company reserves the right to refuse service, terminate accounts, and/or cancel orders in its sole discretion.</p>

        <p className="route__termsOfService__underline">14. Location.</p>
        <p>The Site and the Software are operated by Scale-Track in the United States. Those who choose to access the Site, and/or the Software from locations outside the United States do so on their own initiative and are responsible for compliance with applicable local laws.</p>

        <p className="route__termsOfService__underline">15. Children.</p>
        <p>The Site and the Software are not directed toward children under 13 years of age nor does Scale-Track knowingly collect information from children under 13 or allow them to create an Account or access Account features. If you are under 13, please do not submit any personally identifiable information to Scale-Track.</p>

        <p className="route__termsOfService__underline">16. DISCLAIMER OF WARRANTIES.</p>
        <p>THE SITE, SOFTWARE AND SOFTWARE DATA ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS FOR YOUR USE, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, AND THOSE ARISING FROM COURSE OF DEALING OR USAGE OF TRADE. Scale-Track MAKES NO WARRANTY AS TO THE ACCURACY, COMPLETENESS OR RELIABILITY OF ANY CONTENT AVAILABLE THROUGH THE SITE AND/OR SOFTWARE OR THE PERFORMANCE OF THE SITE AND/OR SOFTWARE. YOU ARE RESPONSIBLE FOR VERIFYING ANY INFORMATION BEFORE RELYING ON IT. USE OF THE SITE, SOFTWARE AND/OR SOFTWARE DATA IS AT YOUR SOLE RISK. Scale-Track DOES NOT WARRANT THAT YOU WILL BE ABLE TO ACCESS OR USE THE SITE AND/OR SOFTWARE AT THE TIMES OR LOCATIONS OF YOUR CHOOSING; THAT THE SITE OR THE SOFTWARE WILL BE UNINTERRUPTED OR ERROR-FREE; THAT DEFECTS WILL BE CORRECTED; THAT THE SITE OR SOFTWARE WILL BE SECURE; THAT SOFTWARE DATA YOU RECEIVE THROUGH THE SITE AND/OR SOFTWARE WILL BE ACCURATE, CORRECT, COMPLETE OR FREE OF DEFECTS; OR THAT THE SITE OR THE SOFTWARE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. ADDITIONALLY, Scale-Track SPECIFICALLY PROVIDES NO WARRANTY OR GUARANTEE OF ANY KIND THAT (I) YOU OR YOUR SITE WILL RECEIVE ANY TYPE OF GUARANTEED BENEFIT FROM THE USE OF THE SOFTWARE; OR (II) YOUR USE OF THE SOFTWARE WILL INCREASE OR IMPROVE THE TRAFFIC OR PLACEMENT OF YOUR SITE, THE TRAFFIC QUALITY TO YOUR SITE, OR REVENUES DERIVED THEREFROM. Because some states or jurisdictions do not allow the disclaimer of implied warranties, the foregoing disclaimer may not apply to you.</p>

        <p className="route__termsOfService__underline">17. LIMITATION OF LIABILITY;</p>
        <p>SOLE AND EXCLUSIVE REMEDY. TO THE MAXIMUM EXTENT PERMITTED BY LAW, Scale-Track, ITS AFFILIATES, LICENSORS AND BUSINESS PARTNERS (COLLECTIVELY, THE “RELATED PARTIES“) DISCLAIM ALL LIABILITY, WHETHER BASED IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, AND FURTHER DISCLAIMS ALL LOSSES, INCLUDING WITHOUT LIMITATION DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR SPECIAL DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH ACCESS TO OR USE OF THE SITE, THE SOFTWARE AND/OR SOFTWARE DATA, EVEN IF Scale-Track AND/OR RELATED PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. WITHOUT LIMITING THE FOREGOING, IN NO CASE SHALL THE LIABILITY OF Scale-Track OR ANY OF THE RELATED PARTIES EXCEED THE AMOUNT THAT YOU PAID TO US OR OUR DESIGNEES DURING THE SIX (6) MONTHS PRIOR TO THE TIME THE CAUSE OF ACTION GIVING RISE TO LIABILITY AROSE. (Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, the liability of Scale-Track and its affiliates shall be limited to the extent permitted by law.)</p>

        <p className="route__termsOfService__underline">18. Indemnification.</p>
        <p>You agree to indemnify, defend and hold Scale-Track and the Related Parties harmless from any and all claims, demands, damages or other losses, including reasonable attorneys’ fees, resulting from or arising out of your use of the Site and/or the Software or any breach by you of this ToU or any other policies that Scale-Track may issue for the Site and/or Software from time to time.</p>

        <p className="route__termsOfService__underline">19. Governing Law;</p>
        <p>Jurisdiction. This ToU is governed by, and will be construed under, the laws of the United States of America and the law of the State of California, without regard to conflict of law principles. The application of the United Nations Convention on Contracts for the International Sale of Goods is expressly excluded. Except as provided below (and claims proceeding in any small claims court), all disputes arising out of or related to your use of the Site and/or the Software shall be subject to the exclusive jurisdiction of the state and federal courts located within Los Angeles County, California and you agree to submit to the personal jurisdiction and venue of such courts.</p>

        <p className="route__termsOfService__underline">20. Binding Arbitration.</p>
        <p className="route__termsOfService__moveToRight">20.1. Arbitration Procedures. You and Scale-Track agree that, except as provided in Section 18.4 below, all disputes, controversies and claims related to this ToU (each a “Claim“), shall be finally and exclusively resolved by binding arbitration, which may be initiated by either party by sending a written notice requesting arbitration to the other party. Any election to arbitrate by one party shall be final and binding on the other. The arbitration will be conducted under the Streamlined Arbitration Rules and Procedures of JAMS that are in effect at the time the arbitration is initiated (the “JAMS Rules“) and under the terms set forth in this ToU. In the event of a conflict between the terms set forth in this Section 18 and the JAMS Rules, the terms in this Section 18 will control and prevail.</p>
        <p className="route__termsOfService__moveToRight">Except as otherwise set forth in this Terms of Use, you may seek any remedies available to you under federal, state or local laws in an arbitration action. As part of the arbitration, both you and we will have the opportunity for discovery of non-privileged information that is relevant to the Claim. The arbitrator will provide a written statement of the arbitrator’s decision regarding the Claim, the award given, and the arbitrator’s findings and conclusions on which the arbitrator’s decision is based. The determination of whether a Claim is subject to arbitration shall be governed by the Federal Arbitration Act and determined by a court rather than an arbitrator. Except as otherwise provided in this ToU, (i) you and Scale-Track may litigate in court to compel arbitration, stay proceedings pending arbitration, or confirm, modify, vacate or enter judgment on the award entered by the arbitrator; and (ii) the arbitrator’s decision shall be final, binding on all parties and enforceable in any court that has jurisdiction, provided that any award may be challenged if the arbitrator fails to follow applicable law. BY AGREEING TO THIS ARBITRATION PROVISION, YOU UNDERSTAND THAT YOU AND Scale-Track WAIVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. FURTHERMORE, REGARDING CLASS ACTION WAIVE, ANY LEGAL ACTION OR ARBITRATION ARISING IN CONNECTION WITH THE USE OF THE WEBSITE, THE SERVICES, OR THESE TERMS AND CONDITIONS MUST BE ON AN INDIVIDUAL BASIS. THIS MEANS NEITHER YOU NOR WE MAY JOIN OR CONSOLIDATE CLAIMS BY OR AGAINST OTHER CUSTOMERS, OR LITIGATE IN COURT OR ARBITRATE ANY CLAIMS AS A REPRESENTATIVE OR MEMBER OF A CLASS OR IN A PRIVATE ATTORNEY GENERAL CAPACITY</p>
        <p className="route__termsOfService__moveToRight">20.2. Location. The arbitration will take place in your hometown area if you so notify Scale-Track in your notice of arbitration or within ten (10) days following receipt of Scale-Track’s arbitration notice. In the absence of a notice to conduct the arbitration in your hometown area, the arbitration will be conducted in Los Angeles, California, unless the parties agree to video, phone and/or internet connection appearances.</p>
        <p className="route__termsOfService__moveToRight">20.3. Limitations. You and Scale-Track agree that any arbitration shall be limited to the Claim between Scale-Track and you individually. YOU AND Scale-Track AGREE THAT (A) THERE IS NO RIGHT OR AUTHORITY FOR ANY DISPUTE TO BE ARBITRATED ON A CLASS-ACTION BASIS OR TO UTILIZE CLASS-ACTION PROCEDURES; (B) THERE IS NO RIGHT OR AUTHORITY FOR ANY DISPUTE TO BE BROUGHT IN A PURPORTED REPRESENTATIVE CAPACITY OR AS A PRIVATE ATTORNEY GENERAL; AND (C) NO ARBITRATION SHALL BE JOINED WITH ANY OTHER ARBITRATION.</p>
        <p className="route__termsOfService__moveToRight">20.4. Exceptions to Arbitration. You and Scale-Track agree that the following Claims are not subject to the above provisions concerning negotiations and binding arbitration: (i) any Claim seeking to enforce or protect, or concerning the validity of, any of your or Scale-Track’s intellectual property rights; (ii) any claim related to, or arising from, allegations of theft, piracy, invasion of privacy or unauthorized use; and (iii) any claim for equitable relief. In addition to the foregoing, either party may assert an individual action in small claims court for Claims that are within the scope of such court’s jurisdiction in lieu of arbitration.</p>

        <p className="route__termsOfService__underline">21. General.</p>
        <p className="route__termsOfService__moveToRight">21.1. ToU Revisions. This ToU may only be revised in a writing signed by Scale-Track, or published by Scale-Track on the Site.</p>
        <p className="route__termsOfService__moveToRight">21.2. No Partnership. You agree that no joint venture, partnership, employment, or agency relationship exists between you and Scale-Track as a result of this ToU or your use of the Software.</p>
        <p className="route__termsOfService__moveToRight">21.3. Assignment. Scale-Track may assign this ToU, in whole or in part, to any person or entity at any time with or without your consent. You may not assign the ToU without Scale-Track’s prior written consent, and any unauthorized assignment by you shall be null and void.</p>
        <p className="route__termsOfService__moveToRight">21.4. Severability. If any part of this ToU is determined to be invalid or unenforceable, then that portion shall be severed, and the remainder of the ToU shall be given full force and effect.</p>
        <p className="route__termsOfService__moveToRight">21.5. Attorneys’ Fees. In the event any litigation or arbitration is brought by either party in connection with this ToU, the prevailing party shall be entitled to recover from the other party all the reasonable costs, attorneys’ fees and other expenses incurred by such prevailing party in the litigation.</p>
        <p className="route__termsOfService__moveToRight">21.6. No Waiver. Our failure to enforce any provision of this ToU shall in no way be construed to be a present or future waiver of such provision, nor in any way affect the right of any party to enforce each and every such provision thereafter. The express waiver by us of any provision, condition or requirement of this ToU shall not constitute a waiver of any future obligation to comply with such provision, condition or requirement.</p>
        <p className="route__termsOfService__moveToRight">21.7. Notices. All notices given by you or required under this ToU shall be in writing and addressed to: Scale-Track, Inc. 31280 Oak Crest Drive, Westlake Village, CA 91361 ATTN: CEO</p>
        <p className="route__termsOfService__moveToRight">21.8. Equitable Remedies. You hereby agree that Scale-Track would be irreparably damaged if the terms of this ToU were not specifically enforced, and therefore you agree that we shall be entitled, without bond, other security, or proof of damages, to appropriate equitable remedies with respect to breaches of this ToU, in addition to such other remedies as we may otherwise have available to us under applicable laws.</p>
        <p className="route__termsOfService__moveToRight">21.9. Entire Agreement. This ToU, including the documents expressly incorporated by reference, constitutes the entire agreement between you and us with respect to the Site and/or Software and supersedes all prior or contemporaneous communications, whether electronic, oral or written, between you and us with respect to the Site and/or Software.</p>
    </div>
};

export default TermsOfService;