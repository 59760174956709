import { combineReducers } from "redux";

import timestampReducer from "./timestampReducer";
import userDataReducer from "./userDataReducer";
import typesReducer from "./typesReducer";
import trackingProfilesReducer from "./trackingProfilesReducer";
import siteFunctionsReducer from "./siteFunctionsReducer";

export default combineReducers({
    timestamp: timestampReducer,
    userData: userDataReducer,
    trackingProfiles: trackingProfilesReducer,
    siteFunctions: siteFunctionsReducer,

    types: typesReducer
});