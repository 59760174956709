import React from "react";
import "./index.scss";

import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";

import * as basicStylesModule from "../../../modules/basicStylesModule";
import * as backendModule from "../../../modules/backendModule";
import * as miscModule from "../../../modules/miscModule";
import { animateBox } from "../../../modules/componentAnimation";
import { parseCSV } from "../../../modules/csvParserModule";
import useDefer from "../../../modules/hooks/useDefer";

import { FilteredCustomTable } from "../../../components/customComponents/Table";
import AdvancedDropdown from "../../../components/customComponents/AdvancedDropdown";
import Spinner from "../../../components/customComponents/Spinner";
import StyledInput from "../../../components/styledComponents/Input";
import StyledButton from "../../../components/styledComponents/Button";
import Dropdown from "../../../components/customComponents/Dropdown";
import FilterBySearch from "../../../components/filters/FilterBySearch";
import FilterByDate from "../../../components/filters/FilterByDate";

import YesNoModal from "../../../components/modals/YesNoModal";

const UserAccountManager = props => {
    const [data, setData] = React.useState();
    const [selectedAccount, setSelectedAccount] = React.useState();
    const [selectedDate, setSelectedDate] = React.useState();
    const [search, setSearch] = React.useState("");
    const [profileStats, setProfileStats] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "?");

    const curDefer = useDefer();

    const checkIfSingleDay = () => {
        if (!selectedDate) return false;
        let diff = selectedDate.start.diff(selectedDate.end, "day");
        
        if (diff === 0) return true;
        return false;
    };

    const getFacebookAccountStats = async () => {
        if (selectedAccount !== "facebook") return;
        if (!data) return;
        if (data.status !== "ok") return;

        let allAccounts = [];
        for (let item of data.data) {
            for (let profile of item.Profiles) {
                allAccounts.push(profile.IntegrationID);
            };
        };

        if (allAccounts.length === 0) return setProfileStats({Active: 0, Backups: 0, MissingBackups: 0, MissingBackupData: {}});
        allAccounts = allAccounts.map(acc => {
            let tmp = String(acc);
            if (tmp.startsWith("fb-")) tmp = tmp.substring(3, tmp.length);

            return tmp;
        });

        let profileStatsData = await axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/getProfileInfo`,
            data: {
                AccountIDs: allAccounts
            },
            ...backendModule.axiosConfig
        }).then(res => {
            return res.data;
        }).catch(() => {
            return backendModule.genericError;
        });
        if (profileStatsData?.status !== "ok") return setProfileStats({Active: 0, Backups: 0, MissingBackups: 0, MissingBackupData: {}});
        if (!profileStatsData?.data?.length) return setProfileStats({Active: 0, Backups: 0, MissingBackups: 0, MissingBackupData: {}});

        let activeByUser = {};

        for (let item of allAccounts) {
            for (let usr of data.data) {
                for (let p of usr.Profiles) {
                    if (String(p.IntegrationID).endsWith(item)) {
                        if (!activeByUser[usr.ID]) activeByUser[usr.ID] = [];

                        activeByUser[usr.ID].push(item);
                    };
                };
            };
        };

        for (let key of Object.keys(activeByUser)) activeByUser[key] = {
            Accounts: activeByUser[key],
            ActiveAccounts: [],
            BackupAccounts: [],
            MissingOffers: []
        };

        const allowedStatuses = ["Main", "Backup"];
        for (let key of Object.keys(activeByUser)) {
            for (let account of activeByUser[key]["Accounts"]) {
                for (let stats of profileStatsData.data) {
                    if (stats["AccountID"] !== account) continue;
                    if (!allowedStatuses.includes(stats.AccountStatus)) continue;

                    switch (stats.AccountStatus) {
                        case "Main":
                            activeByUser[key].ActiveAccounts.push({ID: account, Offers: Array.isArray(stats.Offers)  ? stats.Offers : []});
                            break;
                            case "Backup":
                            activeByUser[key].BackupAccounts.push({ID: account, Offers: Array.isArray(stats.Offers)  ? stats.Offers : []});
                            break;
                        default: break;
                    };
                };
            };
        };

        // final rundown
        for (let key of Object.keys(activeByUser)) {
            let missingOffers = [];
            for (let activeAcc of activeByUser[key].ActiveAccounts) {
                for (let activeOffer of (activeAcc.Offers ?? [])) {
                    let found = false;

                    for (let backupAcc of activeByUser[key].BackupAccounts) {
                        if (found) break;
                        for (let backupOffer of (backupAcc.Offers ?? [])) {
                            if (backupOffer === activeOffer) {
                                found = true;
                                break;
                            };
                        };
                    };

                    if (!found) missingOffers.push(activeOffer);
                };
            };

            activeByUser[key].MissingOffers = missingOffers;
        };

        let totalActive = 0;
        let totalBackup = 0;
        let missingBackup = 0;

        for (let key of Object.keys(activeByUser)) {
            totalActive += activeByUser[key].ActiveAccounts.length;
            totalBackup += activeByUser[key].BackupAccounts.length;
            missingBackup += activeByUser[key].MissingOffers.length;
        };
        return setProfileStats({Active: totalActive, Backups: totalBackup, MissingBackups: missingBackup, MissingBackupData: activeByUser});
    };

    const showMissingBackupData = () => {
        if (!data) return;
        if (data.status !== "ok") return;
        if (!profileStats?.MissingBackupData) return;
        let bd = profileStats?.MissingBackupData;

        for (let key of Object.keys(bd)) {
            if (!bd[key]?.ActiveAccounts?.length) delete bd[key];
        };

        const MissingBackup_fetchData = () => {
            const [offerData, setOfferData] = React.useState();

            React.useEffect(() => {
                let tmp = [];
                for (let key of Object.keys(bd)) {
                    tmp.push(...bd[key].MissingOffers);
                };
                if (tmp.length === 0) return setOfferData({status: "ok", data: []});

                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/offers/getAllOffers`,
                    data: {
                        limit: null,
                        offset: 0,
                        extended: false,
                        filters: [
                            {name: "ID", op: "in", value: tmp}
                        ]
                    },
                    ...backendModule.axiosConfig
                }).then(res => setOfferData(res.data)).catch(() => setOfferData(backendModule.genericError));
            }, []);

            if (!offerData) return <Spinner style={{width: "24px", height: "24px"}} color={themeSelector === "dark" ? "white" : "black"} />
            if (offerData?.status !== "ok") return <p>There was an error while fetching offers</p>

            return <>
                <p>The following users don't have backups for selected offers:</p>

                {Object.keys(bd).map(key => {
                    let curUser = data.data.find(usr => usr.ID === key);
                    if (!curUser) return null;

                    let tmp = [];

                    for (let mo of bd[key].MissingOffers) {
                        let curOffer = offerData.data.find(o => o.ID === mo);
                        if (!curOffer) continue;

                        tmp.push(`${curOffer.OfferName} (${curOffer.OfferType ?? "-"}, ${curOffer.Country ?? "-"})`);
                    };

                    return <>
                        <h4 style={{textAlign: "left", marginTop: "10px"}}>{curUser.Username}</h4>
                        {tmp.map(o => {
                            return <p style={{textAlign: "left", paddingLeft: "15px"}}>{o}</p>
                        })}
                    </>
                })}
            </>
        };

        animateBox(<YesNoModal
            heading="Missing backup offers"
            text={[<MissingBackup_fetchData />]}
            buttonLeftHidden={true}
            buttonRightText="Ok"
            isRightButtonNormal={true}
        />);
    };

    React.useEffect(() => {
        if (!selectedDate) return;
        if (!selectedAccount) return;
        checkIfSingleDay();

        setData();
        setProfileStats();
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/approvedSpendsPerAccount/getAccounts`,
            data: {
                Date: moment(selectedDate?.start).add(12, "hours").toDate().getTime(),
                DateEnd: moment(selectedDate?.end).add(-12, "hours").toDate().getTime(),
                trackFilters: [
                    { name: "createdAt", op: "pdgeq", value: moment(selectedDate?.start).startOf("day").toDate().getTime() },
                    { name: "createdAt", op: "pdleq", value: moment(selectedDate?.end).endOf("day").toDate().getTime() }
                ],
                Type: selectedAccount,
                utcOffset: (new Date()).getTimezoneOffset()
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    }, [selectedDate, selectedAccount]);

    React.useEffect(() => {
        if (data?.status === "ok" && selectedAccount === "facebook") getFacebookAccountStats();
    }, [data]);

    return <div className="route__user__accountManager">
        <div className="route__user__accountManager__split">
            <FilterBySearch onChange={e => setSearch(e)} />

            <AdvancedDropdown
                headline="Select integration"
                data={[
                    { key: "facebook", name: "Facebook", value: "facebook", image: miscModule.getTrackingProfileImage("facebook") },
                    { key: "mgid", name: "Mgid", value: "mgid", image: miscModule.getTrackingProfileImage("mgid") },
                    { key: "midas", name: "Midas", value: "midas", image: miscModule.getTrackingProfileImage("midas") },
                    { key: "adnow", name: "AdNow", value: "adnow", image: miscModule.getTrackingProfileImage("adnow") },
                ]}
                onChange={e => e?.value && setSelectedAccount(e?.value)}
            />

            {/* <StyledInput
                type="date"
                defaultValue={selectedDate.format("YYYY-MM-DD")}
                alternateStyle={true}
                onChange={e => {
                    let tmp = moment(e?.target?.value, "YYYY-MM-DD");
                    if (tmp.isValid()) curDefer(() => setSelectedDate(tmp), 1000);
                }}
            /> */}
            <FilterByDate
                disableAll={true}
                disable24h={true}
                defaultValue="yesterday"
                onChange={setSelectedDate}
            />

            {selectedAccount === "facebook" && <StyledButton onClick={() => {
                animateBox(<UserAccountManager_updateSpent_fb onChange={() => setSelectedDate(selectedDate)} />);
            }}>Spent change</StyledButton>}
        </div>

        {selectedAccount === "facebook" && <div className="route__user__accountManager__kpi">
            <div className="route__user__accountManager__kpi__item">
                <div className="route__user__accountManager__kpi__item__top">Active accounts</div>
                <div className="route__user__accountManager__kpi__item__bottom">{profileStats ? profileStats?.Active ?? "-" : <Spinner style={{width: "28px", height: "28px"}} theme={themeSelector} color={themeSelector === "dark" ? "white" : "black"} />}</div>
            </div>

            <div className="route__user__accountManager__kpi__item">
                <div className="route__user__accountManager__kpi__item__top">Backup accounts</div>
                <div className="route__user__accountManager__kpi__item__bottom">{profileStats ? profileStats?.Backups ?? "-" : <Spinner style={{width: "28px", height: "28px"}} color={themeSelector === "dark" ? "white" : "black"} />}</div>
            </div>

            <div className="route__user__accountManager__kpi__item">
                <div className="route__user__accountManager__kpi__item__top">Missing backup offers</div>
                <div className="route__user__accountManager__kpi__item__bottom" style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    {profileStats ? profileStats?.MissingBackups ?? "-" : <Spinner style={{width: "28px", height: "28px"}} color={themeSelector === "dark" ? "white" : "black"} />}
                    {profileStats ? (profileStats?.MissingBackups > 0 ? <StyledButton style={{height: "34px", marginLeft: "auto"}} onClick={showMissingBackupData}>View</StyledButton> : null) : null}
                </div>
            </div>
        </div>}

        {selectedAccount && <>
            {data ? <>
                <FilteredCustomTable
                    accent="#6C5DD3"
                    theme={themeSelector}
                    headers={[
                        "No.",
                        "Account",
                        "Spent ST",
                        (selectedAccount === "facebook" ? "Spent API" : null),
                        (checkIfSingleDay() ? "Approved" : null),

                        (selectedAccount === "facebook" ? "BM ID" : null),
                        (selectedAccount === "facebook" ? "AdsPower-P ID" : null),
                        (selectedAccount === "facebook" ? "Proxy ID" : null),
                        (selectedAccount === "facebook" ? "Account status" : null),
                        (selectedAccount === "facebook" ? "Datasets & Events" : null),
                        (selectedAccount === "facebook" ? "Offers" : null),

                        (selectedAccount === "facebook" ? "Integration active" : null),
                        (selectedAccount === "facebook" ? "Account active" : null),
                        (selectedAccount === "facebook" ? "Rejected ads" : null),
                        (selectedAccount === "facebook" ? "Missing ads" : null),
                        (checkIfSingleDay() ? " " : null),
                        (selectedAccount === "facebook" ? " " : null),
                        (selectedAccount === "facebook" ? " " : null)
                    ].filter(f => f)}
                    customColumns={[
                        "max-content",
                        "max-content",
                        "max-content",
                        "max-content",
                        (checkIfSingleDay() ? "max-content" : ""),
                        (selectedAccount === "facebook" ? "max-content" : null),
                        (selectedAccount === "facebook" ? "max-content" : null),
                        (selectedAccount === "facebook" ? "max-content" : null),
                        (selectedAccount === "facebook" ? "max-content" : null),
                        (selectedAccount === "facebook" ? "max-content" : null),
                        (selectedAccount === "facebook" ? "max-content" : null),
                        (selectedAccount === "facebook" ? "max-content" : null),
                        (selectedAccount === "facebook" ? "max-content" : null),
                        (selectedAccount === "facebook" ? "max-content" : null),
                        (selectedAccount === "facebook" ? "max-content" : null),
                        (selectedAccount === "facebook" ? "max-content" : null),
                        (selectedAccount === "facebook" ? "max-content" : null),
                        (selectedAccount === "facebook" ? "max-content" : null)
                    ].filter(f => f)}
                    style={{ columnGap: "40px" }}
                    data={(() => {
                        if (data.status !== "ok") return [[{ keyID: "noData-error", type: "custom", data: "There was an error while fetching data", style: { color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight } }]];

                        const yesColor = themeSelector === "dark" ? basicStylesModule.successColor : basicStylesModule.successColorLight;
                        const noColor = themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight;

                        let out = [];
                        let total = 0;
                        let totalAPI = 0;
                        let count = 0;
                        for (let usr of data.data) {
                            for (let profile of usr.Profiles) {
                                if (search) {
                                    let s = search.toLowerCase();
                                    if (
                                        !usr.Username.toLowerCase().includes(s) &&
                                        !profile.Name.toLowerCase().includes(s)
                                    ) continue;
                                };

                                count += 1;
                                total += profile.Spent;
                                if (profile.SpentAPI) totalAPI += profile.SpentAPI;
                                out.push([
                                    { keyID: `${usr.ID}-${profile.IntegrationID}`, type: "text" ,text: count },

                                    { keyID: `${usr.ID}-${profile.IntegrationID}`, type: "text", text: <p style={{display: "flex", flexDirection: "column"}}>
                                        <span>{profile.IntegrationID}</span>
                                        <span>{`${usr.Username} - ${profile.Name}`}</span>
                                    </p>, onClick: () => animateBox(<UserAccountManager_accountActivity_fb account={profile.IntegrationID} />) },

                                    { keyID: `${usr.ID}-${profile.IntegrationID}`, type: "text", text: `${Number(profile.Spent).toFixed(2)} ${currencySignSelector}` },

                                    (selectedAccount === "facebook" ? { keyID: `${usr.ID}-${profile.IntegrationID}`, type: "text", text: `${Number(profile.SpentAPI).toFixed(2)} ${currencySignSelector}` } : null),

                                    (checkIfSingleDay() ? { keyID: `${usr.ID}-${profile.IntegrationID}`, type: "text", text: profile.isApproved === null ? "-" : <span style={{ color: profile.isApproved ? yesColor : noColor }}>{profile.isApproved ? "Yes" : "No"} - {Number(profile.ApprovedSpent).toFixed(2)} {currencySignSelector}</span> } : null),

                                    (selectedAccount === "facebook" ? { keyID: `${usr.ID}-${profile.IntegrationID}`, type: "text", text: <UserAccountManager_profileInfoInline userID={usr.ID} integrationID={profile.IntegrationID} field={"BMID"} /> } : null),
                                    (selectedAccount === "facebook" ? { keyID: `${usr.ID}-${profile.IntegrationID}`, type: "text", text: <UserAccountManager_profileInfoInline integrationID={profile.IntegrationID} field={"AdsPowerPID"} /> } : null),
                                    (selectedAccount === "facebook" ? { keyID: `${usr.ID}-${profile.IntegrationID}`, type: "text", text: <UserAccountManager_profileInfoInline integrationID={profile.IntegrationID} field={"ProxyID"} /> } : null),
                                    (selectedAccount === "facebook" ? { keyID: `${usr.ID}-${profile.IntegrationID}`, type: "text", text: <UserAccountManager_profileInfoInline integrationID={profile.IntegrationID} field={"AccountStatus"} /> } : null),
                                    (selectedAccount === "facebook" ? { keyID: `${usr.ID}-${profile.IntegrationID}`, type: "text", text: <UserAccountManager_profileInfoInline integrationID={profile.IntegrationID} field={"DatasetsAndEvents"} /> } : null),
                                    (selectedAccount === "facebook" ? { keyID: `${usr.ID}-${profile.IntegrationID}`, type: "text", text: <UserAccountManager_profileInfoInline integrationID={profile.IntegrationID} field={"Offers"} suffix="offers" array={true} /> } : null),

                                    (selectedAccount === "facebook" ? { keyID: `${usr.ID}-${profile.IntegrationID}`, type: "text", text: <span style={{
                                        color: profile.integrationActive ? (themeSelector === "dark" ? basicStylesModule.successColor : basicStylesModule.successColorLight) : (themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight)
                                    }}>{profile.integrationActive ? "Yes" : "No"}</span> } : null),

                                    (selectedAccount === "facebook" ? { keyID: `${usr.ID}-${profile.IntegrationID}`, type: "text", text: <span style={{
                                        color: profile.AccountActive ? (themeSelector === "dark" ? basicStylesModule.successColor : basicStylesModule.successColorLight) : (themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight)
                                    }}>{profile.AccountActive ? "Yes" : "No"}</span> } : null),

                                    (selectedAccount === "facebook" ? {keyID: `${usr.ID}-${profile.IntegrationID}`, type: "text", text: <UserAccountManager_checkRejectedAds_inline integrationID={profile.IntegrationID} date={moment(selectedDate?.start).add(12, "hours").toDate().getTime()} dateEnd={moment(selectedDate?.end).add(-12, "hours").toDate().getTime()} />} : null),

                                    (selectedAccount === "facebook" ? {keyID: `${usr.ID}-${profile.IntegrationID}`, type: "text", text: <UserAccountManager_checkMissingAds_inline userID={usr.ID} integrationID={profile.IntegrationID} date={moment(selectedDate?.start).add(12, "hours").toDate().getTime()} dateEnd={moment(selectedDate?.end).add(-12, "hours").toDate().getTime()} />} : null),
                                    (checkIfSingleDay() ? {
                                        keyID: `${usr.ID}-${profile.IntegrationID}`, type: "custom", data: <div className="route__user__accountManager__btn route__user__accountManager__btn--purple" onClick={() => animateBox(<UserAccountManager_approveReject
                                            UserID={usr.ID}
                                            IntegrationID={profile.IntegrationID}
                                            Name={profile.Name}
                                            Spent={`${Number(profile.Spent).toFixed(2)} ${currencySignSelector}`}
                                            Date={moment(selectedDate?.start).add(12, "hours").toDate().getTime()}
                                            onChange={e => {
                                                setData(d => {
                                                    return {
                                                        ...d,
                                                        data: d.data.map(dd => {
                                                            let tmp = { ...dd };
                                                            if (tmp.ID === usr.ID) {
                                                                tmp.Profiles = tmp.Profiles.map(p => {
                                                                    let tmp2 = { ...p };
                                                                    if (p.IntegrationID === profile.IntegrationID) {
                                                                        for (let key of Object.keys(e)) tmp2[key] = e[key];
                                                                    }
                                                                    return tmp2;
                                                                });
                                                            };

                                                            return tmp;
                                                        })
                                                    };
                                                });
                                            }}
                                        />)}>Approve / Reject Spent</div>} : null),
                                    (selectedAccount === "facebook" ? {
                                        keyID: `${usr.ID}-${profile.IntegrationID}`,
                                        type: "custom",
                                        data: <div className="route__user__accountManager__btn route__user__accountManager__btn--gray" onClick={() => animateBox(<UserAccountManager_checkMissingAds userID={usr.ID} integrationID={profile.IntegrationID} date={moment(selectedDate?.start).add(12, "hours").toDate().getTime()} dateEnd={moment(selectedDate?.end).add(-12, "hours").toDate().getTime()} />)}>Missing ads</div>
                                    } : null),
                                    (selectedAccount === "facebook" ? {
                                        keyID: `${usr.ID}-${profile.IntegrationID}`,
                                        type: "custom",
                                        data: <div className="route__user__accountManager__btn route__user__accountManager__btn--red" onClick={() => animateBox(<UserAccountManager_checkRejectedAds integrationID={profile.IntegrationID} date={moment(selectedDate?.start).add(12, "hours").toDate().getTime()} dateEnd={moment(selectedDate?.end).add(-12, "hours").toDate().getTime()} />)}>Rejected ads</div>
                                    } : null)
                                ].filter(f => f))
                            };
                        };

                        if (out.length > 0) out = out.sort((a, b) => {
                            let a1 = Number(a[2].text.split(" ")[0]);
                            let b1 = Number(b[2].text.split(" ")[0]);
                            return (a1 > b1) ? -1 : 1;
                        });
                        out = out.map((item, itemIdx) => {
                            item[0].text = itemIdx + 1;
                            return item;
                        });
                        out.push([
                            { keyID: "total-1", type: "text", text: "", isFooter: true },
                            { keyID: "total-1", type: "text", text: "Total", isFooter: true },
                            { keyID: "total-1", type: "text", text: `${Number(total).toFixed(2)} ${currencySignSelector}`, isFooter: true },
                            (selectedAccount === "facebook" ? { keyID: "total-1", type: "text", text: `${Number(totalAPI).toFixed(2)} ${currencySignSelector}`, isFooter: true } : null),
                            { keyID: "total-1", type: "text", text: "", isFooter: true }
                        ].filter(f => f));
                        if (out.length === 0) out.push([{ keyID: "noData-noData", type: "custom", data: "Nothing to show..." }]);
                        return out;
                    })()}
                />
            </> : <Spinner style={{ width: "32px", height: "32px" }} color={themeSelector === "dark" ? "white" : "black"} />}
        </>}
    </div>
};

const UserAccountManager_accountActivity_fb = (props) => {
    const [data, setData] = React.useState();

    const wrapRef = React.useRef();
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const onClose = () => {
        if (wrapRef.current) {
            wrapRef.current.animate([
                { right: getComputedStyle(wrapRef.current).right },
                { right: "-100%" }
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };

        props.onClose();
    };

    React.useEffect(() => {
        if (!wrapRef.current) return;

        wrapRef.current.animate([
            { right: getComputedStyle(wrapRef.current).right },
            { right: 0 }
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);

    React.useEffect(() => {
        let acc = props.account;
        if (String(acc).startsWith("fb-")) {
            acc = acc.substring(3, acc.length);
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/getAccountUsageActivity`,
            data: {
                AccountID: acc
            },
            ...backendModule.axiosConfig
        }).then(res => setData(res.data)).catch(() => setData(backendModule.genericError));
    }, []);

    return <div className="route__user__accountManager__spentChange" onClick={() => onClose()}>
        <div className="route__user__accountManager__spentChange__wrap" ref={wrapRef} onClick={e => e?.stopPropagation()}>
            <div className="route__user__accountManager__spentChange__wrap__top">
                <div className="route__user__accountManager__spentChange__wrap__top__left">Account activity for {props.account}</div>

                <div className="route__user__accountManager__spentChange__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>

            <div className="route__user__accountManager__spentChange__wrap__content">
                <FilteredCustomTable
                    accent="#6C5DD3"
                    theme={themeSelector}
                    headers={["Media buyer", "Date added"]}
                    style={{
                        columnGap: "40px"
                    }}
                    customColumns={(new Array(2)).fill("max-content")}
                    data={(()=>{
                        if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                        if (data.status === "error") return [[{keyID: "noData-error", type: "text", text: "Error while fetching data", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]];

                        let out = [];
                        let c = 0;
                        for (let item of data.data) {
                            c += 1;
                            out.push([
                                {keyID: c, type: "text", text: item._Username},
                                {keyID: c, type: "text", text: moment(item.createdAt).toDate().toLocaleString()}
                            ]);
                        };

                        if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);
                        return out;
                    })()}
                />
            </div>
        </div>
    </div>
};

const UserAccountManager_approveReject = props => {
    const [isApproved, setIsApproved] = React.useState();
    const [actualSpent, setActualSpent] = React.useState("");
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState({
        text: "",
        inputs: [],
        hadError: false
    });

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const approveSpent = () => {
        if (spinner) return;

        setInfoP(ip => { return { ...ip, inputs: [], hadError: false } });

        const data = {
            UserID: props.UserID,
            IntegrationID: props.IntegrationID,
            Amount: actualSpent,
            Date: props.Date,
            isApproved: isApproved
        };

        if (!["true", "false"].includes(String(data.isApproved))) setInfoP(ip => { return { ...ip, hadError: true, inputs: ["approved"], text: "Please approve or reject the spent first" } });

        data.Amount = Number(data.Amount);
        if (!data.Amount || isNaN(data.Amount)) return setInfoP(ip => { return { ...ip, hadError: true, inputs: ["spent"], text: "Amount is invalid" } });
        if (data.Amount < 0) return setInfoP(ip => { return { ...ip, hadError: true, inputs: ["spent"], text: "Amount must be greater or equals to 0" } });

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/approvedSpendsPerAccount/setSpendStatus`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (typeof (props.onChange) === "function") props.onChange({
                    isApproved: data.isApproved,
                    ApprovedSpent: data.Amount
                });
                props.onClose();
            } else {
                setInfoP(ip => { return { ...ip, hadError: true, inputs: [], text: "There was an error while saving the spent approval!" } });
            };
        }).catch(() => {
            setInfoP(ip => { return { ...ip, hadError: true, inputs: [], text: "Server timed out!" } });
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        if (isApproved) {
            setActualSpent(String(props.Spent).split(" ")[0].replace(",", ""));
        };
    }, [isApproved]);

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Approve / Reject spent</div>
                <div className="genericModal__wrap__head__right" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={props.onClose}></div>
            </div>

            <p>Profile: {props.Name}</p>
            <p>Spent: {props.Spent}</p>
            <br />

            <div className={`genericModal__wrap__input genericModal__wrap__input--dropdown ${infoP.inputs.includes("approved") ? "genericModal__wrap__input--error" : ""}`}>
                <p>Is the current spent approved?</p>
                <Dropdown
                    theme={themeSelector}
                    accent="#6C5DD3"
                    data={[
                        { name: "Yes", value: true },
                        { name: "No", value: false }
                    ]}
                    onChange={e => setIsApproved(e?.value)}
                />
            </div>

            {isApproved === false && <div className={`genericModal__wrap__input ${infoP.inputs.includes("spent") ? "genericModal__wrap__input--error" : ""}`}>
                <p>How much spent should there actually be</p>
                <input value={actualSpent} onChange={e => setActualSpent(e?.target?.value)} type="text" placeholder="Actual spent" />
            </div>}

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Cancel</div>
                <div className="genericModal__wrap__buttons__btn" onClick={approveSpent}>
                    {spinner ? <Spinner style={{ width: "19px", height: "19px" }} color={themeSelector === "dark" ? "white" : "black"} /> : "Update"}
                </div>
            </div>

            {infoP.text && <p className="genericModal__wrap__infoP" style={{
                opacity: infoP.hadError ? 1 : 0
            }}>{infoP.text}</p>}
        </div>
    </div>
};

const UserAccountManager_updateSpent_fb = props => {
    const [csvData, setCSVData] = React.useState();
    const [csvErrors, setCSVErrors] = React.useState([]);
    const [selectedDate, setSelectedDate] = React.useState(moment().format("YYYY-MM-DD"));
    const [spinner, setSpinner] = React.useState(false);
    const [error, setError] = React.useState("");

    const wrapRef = React.useRef();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "?");

    const onClose = () => {
        if (wrapRef.current) {
            wrapRef.current.animate([
                { right: getComputedStyle(wrapRef.current).right },
                { right: "-100%" }
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };

        props.onClose();
    };

    const onFileSelected = async e => {
        if (!e?.target?.files?.length) return;

        setSpinner(true);
        let csvUnparsed = [];

        for (let file of e.target.files) {
            await new Promise(r => {
                let fr = new FileReader();
                fr.onload = fre => {
                    csvUnparsed.push(fre.target.result);
                    r();
                };
                fr.readAsText(file);
            });
        };

        let csvParsed = [];
        for (let item of csvUnparsed) {
            let tmp = await parseCSV(item).catch(() => []);
            if (tmp.length > 0) csvParsed.push(tmp);
        };
        csvUnparsed = undefined;

        let finalData = {};

        let allCampaigns = await axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaignsWithoutData`,
            data: {
                limit: null,
                offset: 0,
                allUsers: true
            },
            ...backendModule.axiosConfig
        }).then(res => res.data).catch(() => backendModule.axiosConfig);
        if (allCampaigns.status === "error" || allCampaigns.data.length === 0) {
            setSpinner(false);
            setError("There was an error while fetching campaigns, or there are none to be fetched!");
            return;
        };

        for (let csv of csvParsed) {
            let headers = csv.shift();

            let idx_Name = headers.indexOf("Ad Set Name");
            if (idx_Name === -1) idx_Name = headers.indexOf("AdSet name");

            let idx_Spent = headers.indexOf("Amount spent (EUR)");
            if (idx_Spent === -1) idx_Spent = headers.indexOf("Total Cost (EUR)");

            let idx_Impressions = headers.indexOf("Impressions");
            let idx_Outbound = headers.indexOf("Outbound clicks");
            let idx_Age = headers.indexOf("Age");
            let idx_Gender = headers.indexOf("Gender");

            if (idx_Name === -1 || idx_Spent === -1) continue;

            for (let data of csv) {
                let adName = data[idx_Name];
                let adSpent = Number(data[idx_Spent]);
                let adImpressions = data?.[idx_Impressions];
                let adOutbound = data?.[idx_Outbound];
                let adAge = data?.[idx_Age];
                let adGender = data?.[idx_Gender];
                
                
                if (!adName) continue;
                if (isNaN(adSpent)) continue;

                if (adImpressions) {
                    adImpressions = Number(adImpressions);
                    if (isNaN(adImpressions)) continue;
                } else {
                    adOutbound = null;
                };
                if (adOutbound) {
                    adOutbound = Number(adOutbound);
                    if (isNaN(adOutbound)) continue;
                } else {
                    adImpressions = null;
                };

                if (!adAge) adAge = null;
                if (!adGender) adGender = null;
                if (!["male", "female"].includes(adGender)) adGender = null;

                let rollingID = adName.split(" ").shift();
                if (!rollingID) continue;
                rollingID = Number(rollingID);
                if (isNaN(rollingID)) continue;

                let campaignID = null;
                let accountID = null;
                let userID = null;

                for (let c of allCampaigns.data) {
                    if (c.CampaignName.endsWith(` ${rollingID}`)) {
                        campaignID = c.ID;
                        userID = c.CreatedBy;
                        
                        if (String(c.IntegrationID).startsWith("fb-act_")) {
                            accountID = c.IntegrationID;
                        };
                    };
                };
                if (!campaignID || !accountID || !userID) continue;

                /*
                    group as follows:
                    accountID, campaignID, Gender, Age
                */
                // 1) parse generics
                if (!finalData[accountID]) finalData[accountID] = {};
                if (!finalData[accountID][campaignID]) finalData[accountID][campaignID] = {};
                if (!finalData[accountID][campaignID][null]) finalData[accountID][campaignID][null] = {};
                if (!finalData[accountID][campaignID][null][null]) finalData[accountID][campaignID][null][null] = {
                    Spent: 0,
                    Impressions: 0,
                    Outbound: 0,
                    UserID: userID
                };
                finalData[accountID][campaignID][null][null]["Spent"] += adSpent;
                if (adImpressions && adOutbound) {
                    finalData[accountID][campaignID][null][null]["Impressions"] += adImpressions;
                    finalData[accountID][campaignID][null][null]["Outbound"] += adOutbound;
                };

                if (adGender && adAge) {
                    if (!finalData[accountID][campaignID][adGender]) finalData[accountID][campaignID][adGender] = {};
                    if (!finalData[accountID][campaignID][adGender][adAge]) finalData[accountID][campaignID][adGender][adAge] = {
                        Spent: 0,
                        Impressions: 0,
                        Outbound: 0,
                        UserID: userID
                    };
                    finalData[accountID][campaignID][adGender][adAge]["Spent"] += adSpent;
                    if (adImpressions && adOutbound) {
                        finalData[accountID][campaignID][adGender][adAge]["Impressions"] += adImpressions;
                        finalData[accountID][campaignID][adGender][adAge]["Outbound"] += adOutbound;
                    };

                    if (!finalData[accountID][campaignID][adGender]) finalData[accountID][campaignID][adGender] = {};
                    if (!finalData[accountID][campaignID][adGender][null]) finalData[accountID][campaignID][adGender][null] = {
                        Spent: 0,
                        Impressions: 0,
                        Outbound: 0,
                        UserID: userID
                    };
                    finalData[accountID][campaignID][adGender][null]["Spent"] += adSpent;
                    if (adImpressions && adOutbound) {
                        finalData[accountID][campaignID][adGender][null]["Impressions"] += adImpressions;
                        finalData[accountID][campaignID][adGender][null]["Outbound"] += adOutbound;
                    };

                    if (!finalData[accountID][campaignID][null]) finalData[accountID][campaignID][null] = {};
                    if (!finalData[accountID][campaignID][null][adAge]) finalData[accountID][campaignID][null][adAge] = {
                        Spent: 0,
                        Impressions: 0,
                        Outbound: 0,
                        UserID: userID
                    };
                    finalData[accountID][campaignID][null][adAge]["Spent"] += adSpent;
                    if (adImpressions && adOutbound) {
                        finalData[accountID][campaignID][null][adAge]["Impressions"] += adImpressions;
                        finalData[accountID][campaignID][null][adAge]["Outbound"] += adOutbound;
                    };
                } else if (adGender) {
                    if (!finalData[accountID][campaignID][adGender]) finalData[accountID][campaignID][adGender] = {};
                    if (!finalData[accountID][campaignID][adGender][null]) finalData[accountID][campaignID][adGender][null] = {
                        Spent: 0,
                        Impressions: 0,
                        Outbound: 0,
                        UserID: userID
                    };
                    finalData[accountID][campaignID][adGender][null]["Spent"] += adSpent;
                    if (adImpressions && adOutbound) {
                        finalData[accountID][campaignID][adGender][null]["Impressions"] += adImpressions;
                        finalData[accountID][campaignID][adGender][null]["Outbound"] += adOutbound;
                    };
                } else if (adAge) {
                    if (!finalData[accountID][campaignID][null]) finalData[accountID][campaignID][null] = {};
                    if (!finalData[accountID][campaignID][null][adAge]) finalData[accountID][campaignID][null][adAge] = {
                        Spent: 0,
                        Impressions: 0,
                        Outbound: 0,
                        UserID: userID
                    };
                    finalData[accountID][campaignID][null][adAge]["Spent"] += adSpent;
                    if (adImpressions && adOutbound) {
                        finalData[accountID][campaignID][null][adAge]["Impressions"] += adImpressions;
                        finalData[accountID][campaignID][null][adAge]["outbound"] += adOutbound;
                    };
                };
            };
        };

        setCSVData(finalData);
        setSpinner(false);
    };

    const uploadSpentChange = async () => {
        let outData = [];
        setSpinner(true);
        for (let accountID of Object.keys(csvData)) {
            for (let campaignID of Object.keys(csvData[accountID])) {
                let tmp = [];
                let uid = null;

                for (let gender of Object.keys(csvData[accountID][campaignID])) {
                    for (let age of Object.keys(csvData[accountID][campaignID][gender])) {
                        let curData = csvData[accountID][campaignID][gender][age];
                        let AdditionalData = {};

                        if (curData.Impressions && curData.Outbound) {
                            AdditionalData["CTR"] = curData.Outbound / curData.Impressions * 100;
                        };
                        if (Object.keys(AdditionalData).length === 0) AdditionalData = null;
                        if (!uid) uid = curData.UserID;

                        tmp.push({
                            CampaignID: campaignID,
                            Column: "Spent_IN",
                            NewData: curData.Spent,
                            ForDate: moment(selectedDate, "YYYY.MM.DD").toDate().getTime(),
                            Gender: gender === "null" ? null : gender,
                            Age: age === "null" ? null : age,
                            AdditionalData
                        });
                    };
                };

                outData.push({
                    AccountID: accountID,
                    UserID: uid,
                    Data: tmp
                })
            };
        };

        let preparedData = {};
        for (let item of outData) {
            if (!preparedData[item.UserID]) preparedData[item.UserID] = {};
            if (!preparedData[item.UserID][item.AccountID]) preparedData[item.UserID][item.AccountID] = [];

            preparedData[item.UserID][item.AccountID].push(...item.Data);
        };

        let apiCalls = [];
        for (let uID of Object.keys(preparedData)) {
            for (let aID of Object.keys(preparedData[uID])) {
                apiCalls.push({
                    UserID: uID,
                    IntegrationID: aID,
                    Data: preparedData[uID][aID],

                    ImageBuffer: "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
                    ImageName: `autospent-${new Date().getTime()}-${Math.floor(Math.random() * 999999)}.gif`,

                    utcOffset: (new Date()).getTimezoneOffset()
                });
            };
        };

        let csvOutErrors = [];
        for (let item of apiCalls) {
            await axios({
                method: "POST",
                url: `${backendModule.backendURL}/dataUpdateRequest/addBulkRequest`,
                data: item,
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status !== "ok") {
                    csvOutErrors.push([item.IntegrationID, "Error while parsing the data change"]);
                };
            }).catch(() => {
                csvOutErrors.push([item.IntegrationID, "Server timed out!"]);
            });
        };
        setSpinner(false);
        if (csvOutErrors.length > 0) setCSVErrors(csvOutErrors);
        if (typeof(props.onChange) === "function") props.onChange();
        if (csvErrors.length === 0) {
            onClose();
        };
    };

    const calculateDataPoints = item => {
        let count = 0;

        for (let key of Object.keys(item)) {
            for (let key2 of Object.keys(item[key])) {
                count += Object.keys(item[key][key2]).length;
            };
        };

        return count;
    }

    React.useEffect(() => {
        if (!wrapRef.current) return;

        wrapRef.current.animate([
            { right: getComputedStyle(wrapRef.current).right },
            { right: 0 }
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);

    return <div className="route__user__accountManager__spentChange" onClick={() => onClose()}>
        <div className="route__user__accountManager__spentChange__wrap" ref={wrapRef} onClick={e => e?.stopPropagation()}>
            <div className="route__user__accountManager__spentChange__wrap__top">
                <div className="route__user__accountManager__spentChange__wrap__top__left">Spent change {csvData ? `for ${moment(selectedDate, "YYYY-MM-DD").toDate().toLocaleDateString()}` : ``}</div>

                {(csvData && Object.keys(csvData)?.length > 0 && !spinner && !error) && <StyledButton style={{marginLeft: "auto", marginRight: "20px", height: "30px"}} onClick={uploadSpentChange}>Upload spent change</StyledButton>}
                <div className="route__user__accountManager__spentChange__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>

            <div className="route__user__accountManager__spentChange__wrap__content">
                {((csvData || error) && !spinner) ? <>
                    {error ? <>
                        <p>{error}</p>

                        {csvErrors.length > 0 && <>
                            <p>The following errors occured:</p>
                            <FilteredCustomTable
                                theme="dark"
                                accent="#6C5DD3"
                                headers={["Account", "Error"]}
                                data={csvErrors.map(e => {
                                    return [
                                        {keyID: e[0], type: "text", text: e[0]},
                                        {keyID: e[0], type: "text", text: e[1]}
                                    ];
                                })}
                            />
                        </>}
                    </> : <>
                        <p>Please verify the following data before you proceed!</p>

                        <FilteredCustomTable
                            accent="#6C5DD3"
                            theme={themeSelector}
                            headers={["Account", "Found campaigns", "Total new spend", "Total data points"]}
                            data={(()=>{
                                let out = [];

                                for (let key of Object.keys(csvData)) {
                                    let ts = 0;
                                    for (let key2 of Object.keys(csvData[key])) {
                                        ts += csvData[key][key2][null][null]["Spent"];
                                    };
                                    out.push([
                                        {keyID: key, type: "text", text: key},
                                        {keyID: key, type: "text", text: Object.keys(csvData[key]).length},
                                        {keyID: key, type: "text", text: `${Number(ts).toFixed(2)} ${currencySignSelector}`},
                                        {keyID: key, type: "text", text: calculateDataPoints(csvData[key])}
                                    ]);
                                };

                                if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);
                                return out;
                            })()}
                        />
                    </>}
                </> : <>
                    {spinner ? <Spinner color="white" /> : <>
                        <p>Select the date for which the spent will be updated, and attach a CSV file with Account IDs, Ad Set names and spends</p>
                        <input type="file" accept="text/csv" multiple={true} style={{display: "none"}} onChange={onFileSelected} />
                        <StyledInput type="date" defaultValue={selectedDate} onChange={(e) => setSelectedDate(e.target.value)}></StyledInput>
                        <StyledButton isDisabled={moment(selectedDate, "YYYY-MM-DD").endOf("day").isAfter(moment().endOf("day"))} onClick={(e) => {
                            e.target.parentNode.querySelector("input[type=file]").click();
                        }}>Upload CSV</StyledButton>
                    </>}
                </>}
            </div>
        </div>
    </div>
};

const UserAccountManager_checkMissingAds = props => {
    const [data, setData] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/checkCampaignMissingAds`,
            data: {
                UserID: props.userID,
                IntegrationID: props.integrationID,
                Date: props.date,
                DateEnd: props.dateEnd
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    React.useEffect(() => {
        getData();
    }, []);

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Check missing ads</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            {data ? <>
                {data.status === "ok" ? <>
                    {data.data.length === 0 ? <p>All ads were found!</p> : <>
                        <p>Ad Account ID: {props.integrationID}</p>
                        <p>The following ads were found in facebook profile, but not in track campaigns:</p>
                        <p>Make sure that:</p>
                        <p>1) The ad has the right Scale-Track campaign URL</p>
                        <p>2) The ad has correct ST URL paramaeters {`(stadid={{ad.id}}&stutm=facebook)`}</p>
                        <p>3) The ad has not yet received any significant traffic (missing visits)</p>
                        <br />
                        {data.data.map(d => <p>Ad id: {d}</p>)}

                        <StyledButton style={{marginTop: "20px"}} onClick={() => animateBox(<UserAccountManager_checkMissingAds_fix date={props.date} dateEnd={props.dateEnd} userID={props.userID} integrationID={props.integrationID} onChange={() => getData()} />)}>Resolve</StyledButton>
                    </>}
                </> : <>
                    <p style={{color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}>There was an error while fetching data!</p>
                </>}
            </> : <>
                <p>Checking ads...</p>
                <Spinner style={{width: "24px", height: "24px"}} color={themeSelector === "dark" ? "white" : "black"} />
            </>}
        </div>
    </div>
};

const UserAccountManager_checkMissingAds_fix = (props) => {
    const [allMissingAds, setAllMissingAds] = React.useState();
    const [campaigns, setCampaigns] = React.useState();
    const [campaignBinds, setCampaignBinds] = React.useState({});
    const [spinner, setSpinner] = React.useState(false);

    const wrapRef = React.useRef();
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const onClose = () => {
        if (wrapRef.current) {
            wrapRef.current.animate([
                { right: getComputedStyle(wrapRef.current).right },
                { right: "-100%" }
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };

        props.onClose();
    };

    const getCampaigns = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaignsWithoutData`,
            data: {
                limit: null,
                offset: 0,
                allUsers: true,
                filters: [
                    {name: "IntegrationID", op: "eq", value: props.integrationID},
                    {name: "CreatedBy", op: "eq", value: props.userID}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setCampaigns(res.data);
        }).catch(() => {
            setCampaigns(backendModule.genericError);
        });
    };

    const getAds = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/checkCampaignMissingAds`,
            data: {
                UserID: props.userID,
                IntegrationID: props.integrationID,
                Date: props.date,
                DateEnd: props.dateEnd
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/integrations/facebook/getAdDetails`,
                    data: {
                        AdIDs: res.data.data
                    },
                    ...backendModule.axiosConfig
                }).then(final => {
                    setAllMissingAds(final.data);
                }).catch(() => {
                    setAllMissingAds(backendModule.genericError);
                });
            } else {
                setAllMissingAds(res.data);
            };
        }).catch(() => {
            setAllMissingAds(backendModule.genericError);
        });
    };

    const performUpdate = async () => {
        if (spinner) return;
        setSpinner(true);

        let finalBinds = {...campaignBinds};
        for (let key of Object.keys(finalBinds)) {
            if (!finalBinds[key]) delete finalBinds[key];
        };

        for (let key of Object.keys(campaignBinds)) {
            await axios({
                method: "POST",
                url: `${backendModule.backendURL}/campaigns/addCampaignIntegrationData`,
                data: {
                    CampaignID: campaignBinds[key],
                    Field: "fb_ads",
                    Value: key
                },
                ...backendModule.axiosConfig
            }).then(() => null).catch(() => null);
        };

        setSpinner(false);
        if (typeof(props.onChange) === "function") props.onChange();
        onClose();
    };

    React.useEffect(() => {
        if (!wrapRef.current) return;

        wrapRef.current.animate([
            { right: getComputedStyle(wrapRef.current).right },
            { right: 0 }
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);


    React.useEffect(() => {
        if (campaigns?.status === "ok" && allMissingAds?.status === "ok") {
            if (Object.keys(campaignBinds).length === 0) {
                let out = {};

                for (let ad of allMissingAds.data) {
                    let rid = ad.AdsetName.split(" ")[0];
                    rid = Number(rid);
                    if (isNaN(rid)) continue;
                    
                    let cid = null;
                    for (let c of campaigns.data) {
                        if (c.RollingID === rid) {
                            cid = c.ID;
                            break;
                        };
                    };
                    if (cid) {
                        out[ad.AdID] = cid;
                    };
                };

                setCampaignBinds(out);
            };
        };
    }, [campaigns, allMissingAds]);

    React.useEffect(() => {
        getCampaigns();
        getAds();
    }, []);

    return <div className="route__user__accountManager__spentChange" onClick={() => onClose()}>
        <div className="route__user__accountManager__spentChange__wrap" ref={wrapRef} onClick={e => e?.stopPropagation()} style={{width: `calc(100% - 86px)`}}>
            <div className="route__user__accountManager__spentChange__wrap__top">
                <div className="route__user__accountManager__spentChange__wrap__top__left">Fix missing ads</div>

                <StyledButton style={{
                    height: "30px",
                    marginLeft: "auto",
                    marginRight: "20px"
                }} onClick={() => performUpdate()}>Update</StyledButton>
                <div className="route__user__accountManager__spentChange__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>

            <div className="route__user__accountManager__spentChange__wrap__content">
                {(!allMissingAds || !campaigns || spinner) ? <Spinner style={{width: "32px", height: "32px"}} color={themeSelector === "dark" ? "white" : "black"} /> : <>
                    {(allMissingAds?.status !== "ok" || campaigns?.status !== "ok") ? <p>There was an error while fetching data!</p> : <>
                        <p>Please check all the ads and the campaigns that will be assigned to them</p>
                        <p>If this info is correct, click on "Update"</p>
                        <FilteredCustomTable
                            theme={themeSelector}
                            accent="#6C5DD3"
                            headers={["Ad ID", "Adset name", "Ad name", "Campaign"]}
                            customColumns={(new Array(4)).fill("max-content")}
                            style={{
                                columnGap: "40px"
                            }}
                            data={(()=>{
                                let out = [];

                                for (let item of allMissingAds.data) {
                                    out.push([
                                        {keyID: item.AdID, type: "text", text: item.AdID},
                                        {keyID: item.AdID, type: "text", text: item.AdsetName},
                                        {keyID: item.AdID, type: "text", text: item.AdName},
                                        {keyID: item.AdID, type: "text", text: <Dropdown
                                            theme={themeSelector}
                                            accent="#6C5DD3"
                                            style={{width: "300px"}}
                                            data={[
                                                {name: "No bind", value: null},
                                                ...campaigns.data.map(c => {
                                                    return {name: c.CampaignName, value: c.ID}
                                                })
                                            ]}
                                            showSearch={true}
                                            onChange={e => {
                                                if (e?.value === undefined) return;
                                                if (e?.value !== campaignBinds[item.AdID]) {
                                                    setCampaignBinds(cb => {
                                                        let tmp = {...cb};
                                                        tmp[item.AdID] = e?.value;
                                                        return tmp;
                                                    });
                                                };
                                            }}
                                            selected={(()=>{
                                                if (campaignBinds[item.AdID] === null) return null;
                                                if (!campaignBinds[item.AdID]) return 0;
                                                return campaigns.data.indexOf(campaigns.data.find(c => c.ID === campaignBinds[item.AdID])) + 1;
                                            })()}
                                        />},
                                    ]);
                                };

                                if (out.length === 0) return [[{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]];
                                return out;
                            })()}
                        />
                    </>}
                </>}
            </div>
        </div>
    </div>
};

const UserAccountManager_checkMissingAds_inline = props => {
    const [data, setData] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/checkCampaignMissingAds`,
            data: {
                UserID: props.userID,
                IntegrationID: props.integrationID,
                Date: props.date,
                DateEnd: props.dateEnd
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    React.useEffect(() => {
        getData();
    }, []);

    return data ? <>
        {data.status === "ok" ? <>
            {data.data.length}
        </> : <>
            -
        </>}
    </> : <>
        <Spinner style={{width: "15px", height: "15px"}} color={themeSelector === "dark" ? "white" : "black"} />
    </>
};

const UserAccountManager_checkRejectedAds = props => {
    const [data, setData] = React.useState();

    const wrapRef = React.useRef();
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/getAllProfileAds`,
            data: {
                UserID: props.userID,
                IntegrationID: props.integrationID,
                Date: props.date,
                DateEnd: props.dateEnd
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    const onClose = () => {
        if (wrapRef.current) {
            wrapRef.current.animate([
                { right: getComputedStyle(wrapRef.current).right },
                { right: "-100%" }
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };

        props.onClose();
    };

    const prepareAds = () => {
        let out = {
            allAds: [],
            approvedAds: [],
            rejectedAds: []
        };
        if (!data) return out;
        if (data.status !== "ok") return out;

        for (let item of data.data) {
            out.allAds.push(item);
            if ([
                "ACTIVE", "PAUSED", "ADSET_PAUSED", "CAMPAIGN_PAUSED"
            ].includes(item.AdEffectiveStatus)) {
                out.approvedAds.push(item);
            } else {
                out.rejectedAds.push(item);
            };
        };

        return out;
    };

    const openStory = item => {
        if (!item.StoryID) return null;

        window.open(`https://facebook.com/${item.StoryID}`, "_blank");
    };

    React.useEffect(() => {
        if (!wrapRef.current) return;

        wrapRef.current.animate([
            { right: getComputedStyle(wrapRef.current).right },
            { right: 0 }
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);

    React.useEffect(() => {
        getData();
    }, []);

    return <div className="route__user__accountManager__spentChange" onClick={() => onClose()}>
        <div className="route__user__accountManager__spentChange__wrap" ref={wrapRef} onClick={e => e?.stopPropagation()} style={{width: `calc(100% - 86px)`}}>
            <div className="route__user__accountManager__spentChange__wrap__top">
                <div className="route__user__accountManager__spentChange__wrap__top__left">Rejected ads</div>

                <div className="route__user__accountManager__spentChange__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>

            <div className="route__user__accountManager__spentChange__wrap__content">
                <FilteredCustomTable
                    accent="#6C5DD3"
                    theme={themeSelector}
                    style={{columnGap: "40px"}}
                    headers={["All ads", "Approved ads", "Rejected ads", "Rejected status"]}
                    customColumns={(new Array(4)).fill("max-content")}
                    data={(()=>{
                        if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                        if (data.status === "error") return [[{keyID: "noData-error", type: "text", text: "There was an error while fetching data"}]];
                        let out = [];
                        let tmpData = prepareAds();
                        for (let i=0; i<=tmpData.allAds.length-1; i++) {
                            out.push([
                                {keyID: String(i), type: "text", text: tmpData.allAds[i].AdID, onClick: () => openStory(tmpData.allAds[i])},
                                {keyID: String(i), type: "text", text: tmpData.approvedAds[i] ? tmpData.approvedAds[i].AdID : "", onClick: () => openStory(tmpData.approvedAds[i])},
                                {keyID: String(i), type: "text", text: tmpData.rejectedAds[i] ? tmpData.rejectedAds[i].AdID : "", onClick: () => openStory(tmpData.rejectedAds[i])},
                                {keyID: String(i), type: "text", text: tmpData.rejectedAds[i] ? tmpData.rejectedAds[i].AdEffectiveStatus : ""}
                            ]);
                        };

                        if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);
                        return out;
                    })()}
                />
            </div>
        </div>
    </div>
};

const UserAccountManager_checkRejectedAds_inline = props => {
    const [data, setData] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/getAllProfileAds`,
            data: {
                IntegrationID: props.integrationID,
                Date: props.date,
                DateEnd: props.dateEnd
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    const getRejectedPercentage = () => {
        if (!data) return <Spinner style={{width: "15px", height: "15px"}} color={themeSelector === "dark" ? "white" : "black"} />;
        if (data.status === "error") return "-";

        let total = 0;
        let rejected = 0;
        for (let item of data.data) {
            total += 1;
            if (![
                "ACTIVE", "PAUSED", "ADSET_PAUSED", "CAMPAIGN_PAUSED"
            ].includes(item.AdEffectiveStatus)) rejected += 1;
        };

        let finalPercent = 100 / total * rejected;
        if (isNaN(finalPercent)) finalPercent = 0;
        if (finalPercent === Number.NEGATIVE_INFINITY || finalPercent === Number.POSITIVE_INFINITY) finalPercent = 0;
        return `${finalPercent.toFixed(2)} %`
    };

    React.useEffect(() => {
        getData();
    }, []);

    return data ? <>
        {data.status === "ok" ? <>
            {getRejectedPercentage()}
        </> : <>
            -
        </>}
    </> : <>
        <Spinner style={{width: "15px", height: "15px"}} color={themeSelector === "dark" ? "white" : "black"} />
    </>
};

const UserAccountManager_profileInfoInline_updateText = props => {
    const [spinner, setSpinner] = React.useState(false);

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const valueRef = React.useRef();

    const updateData = () => {
        if (spinner) return;

        const data = {
            AccountID: props.integrationID,
            Field: props.field,
            Value: valueRef.current.value
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/updateProfileInfo`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (props.onChange) props.onChange();
                props.onClose();
            } else {
                animateBox(<YesNoModal
                    heading="Error"
                    text="There was an error while updating the profile"
                    buttonLeftHidden={true}
                    buttonRightText="Ok"
                    isRightButtonNormal={true}
                />);
            };
        }).catch(() => {
            animateBox(<YesNoModal
                heading="Error"
                text="Server timed out!"
                buttonLeftHidden={true}
                buttonRightText="Ok"
                isRightButtonNormal={true}
            />);
        }).finally(() => {
            setSpinner(false);
        });
    };


    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Update {props.field}</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            <div className="genericModal__wrap__input">
                <p>{props.field}</p>
                <input type="text" defaultValue={props.previous} ref={valueRef} />
            </div>

            <div className="genericModal__wrap__buttons">
            <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" style={{marginRight: "auto"}} onClick={props.openHistory}>View history</div>
                <div className="genericModal__wrap__buttons__btn" onClick={() => !spinner && updateData()}>
                    {spinner ? <Spinner style={{width: "20px", height: "20px"}} color={themeSelector === "dark" ? "white" : "black"} /> : "Save"}
                </div>
            </div>
        </div>
    </div>
};
const UserAccountManager_profileInfoInline_updateDropdown = props => {
    const [spinner, setSpinner] = React.useState(false);

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const valueRef = React.useRef(props.previous);

    const updateData = () => {
        if (spinner) return;

        const data = {
            AccountID: props.integrationID,
            Field: props.field,
            Value: valueRef.current
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/updateProfileInfo`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (props.onChange) props.onChange();
                props.onClose();
            } else {
                animateBox(<YesNoModal
                    heading="Error"
                    text="There was an error while updating the profile"
                    buttonLeftHidden={true}
                    buttonRightText="Ok"
                    isRightButtonNormal={true}
                />);
            };
        }).catch(() => {
            animateBox(<YesNoModal
                heading="Error"
                text="Server timed out!"
                buttonLeftHidden={true}
                buttonRightText="Ok"
                isRightButtonNormal={true}
            />);
        }).finally(() => {
            setSpinner(false);
        });
    };


    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Update {props.field}</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            <div className="genericModal__wrap__input">
                <p>{props.field}</p>
                <Dropdown
                    theme={themeSelector}
                    accent="#6C5DD3"
                    data={props.dropdown}
                    onChange={e => {
                        valueRef.current = e?.value;
                    }}
                    selected={(()=>{
                        if (!valueRef.current) return null;
                        return props.dropdown.indexOf(props.dropdown.find(d => d.value === valueRef.current));
                    })()}
                />
            </div>

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" style={{marginRight: "auto"}} onClick={props.openHistory}>View history</div>
                <div className="genericModal__wrap__buttons__btn" onClick={() => !spinner && updateData()}>
                    {spinner ? <Spinner style={{width: "20px", height: "20px"}} color={themeSelector === "dark" ? "white" : "black"} /> : "Save"}
                </div>
            </div>
        </div>
    </div>
};
const UserAccountManager_profileInfoInline_updateOffers = props => {
    const [offers, setOffers] = React.useState();
    const [spinner, setSpinner] = React.useState(false);
    const [value, setValue] = React.useState(Array.isArray(props.previous) ? props.previous : []);

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const resetRef = React.useRef();

    const updateData = () => {
        if (spinner) return;

        const data = {
            AccountID: props.integrationID,
            Field: props.field,
            Value: value
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/updateProfileInfo`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (props.onChange) props.onChange();
                props.onClose();
            } else {
                animateBox(<YesNoModal
                    heading="Error"
                    text="There was an error while updating the profile"
                    buttonLeftHidden={true}
                    buttonRightText="Ok"
                    isRightButtonNormal={true}
                />);
            };
        }).catch(() => {
            animateBox(<YesNoModal
                heading="Error"
                text="Server timed out!"
                buttonLeftHidden={true}
                buttonRightText="Ok"
                isRightButtonNormal={true}
            />);
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {
                limit: null,
                offset: 0,
                extended: false,
                filters: [
                    {name: "ResponsiblePerson", op: "jaeq", value: {ID: props.userID}}
                ],
                orders: [
                    {name: "OfferName", order: "asc"},
                    {name: "Country", order: "asc"},
                    {name: "OfferType", order: "asc"}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => setOffers(res.data)).catch(() => setOffers(backendModule.genericError));
    }, []);

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Update {props.field}</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            {offers ? <>
                {offers?.status === "ok" ? <>
                    <div className="genericModal__wrap__input">
                        <p>{props.field}</p>

                        {value.sort((a, b) => {
                            let a1 = offers.data.find(o => o.ID === a);
                            let b1 = offers.data.find(o => o.ID === b);

                            if (a1?.OfferName > b1?.OfferName) return 1;
                            if (a1?.Country > b1?.Country) return 1;
                            if (a1?.OfferType > b1?.OfferType) return 1;
                            return -1;
                        }).map(v => {
                            let curOffer = offers.data.find(o => o.ID === v);
                            return <div style={{display: "grid", gridTemplateColumns: "max-content 1fr", gap: "10px", alignItems: "center"}}>
                                <img src="/images/icon_close.svg" style={{cursor: "pointer"}} onClick={() => {
                                    setValue(vv => vv.filter(vvv => vvv !== v));
                                }} />
                                <span>{curOffer?.OfferName ?? "-"} ({curOffer?.OfferType ?? "-"}, {curOffer?.Country ?? "-"})</span>
                            </div>
                        })}

                        <Dropdown
                            theme={themeSelector}
                            accent="#6C5DD3"
                            data={offers.data.map(offer => {
                                return {name: `${offer.OfferName} (${offer.Country ?? "-"}, ${offer.OfferType ?? "-"})`, value: offer.ID}
                            })}
                            onChange={e => {
                                setValue(v => [...new Set([...v, e?.value])]);
                                try { resetRef.current() } catch {};
                            }}
                            onReset={e => resetRef.current = e}
                        />
                    </div>

                    <div className="genericModal__wrap__buttons">
                        <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" style={{marginRight: "auto"}} onClick={props.openHistory}>View history</div>
                        <div className="genericModal__wrap__buttons__btn" onClick={() => !spinner && updateData()}>
                            {spinner ? <Spinner style={{width: "20px", height: "20px"}} color={themeSelector === "dark" ? "white" : "black"} /> : "Save"}
                        </div>
                    </div>
                </> : <p>There was an error while fetching data</p>}
            </> : <Spinner style={{width: "32px", height: "32px"}} color={themeSelector === "dark" ? "white" : "black"} />}
        </div>
    </div>
};
const UserAccountManager_profileInfoInline_historyString = props => {
    const [users, setUsers] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const wrapRef = React.useRef();

    const getAllUsers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            data: {
                limit: null,
                offset: 0
            },
            ...backendModule.axiosConfig
        }).then(res => setUsers(res.data)).catch(() => setUsers(backendModule.genericError));
    };

    const onClose = () => {
        if (wrapRef.current) {
            wrapRef.current.animate([
                { right: getComputedStyle(wrapRef.current).right },
                { right: "-100%" }
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };

        props.onClose();
    };

    React.useEffect(() => {
        if (!wrapRef.current) return;

        wrapRef.current.animate([
            { right: getComputedStyle(wrapRef.current).right },
            { right: 0 }
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);

    React.useEffect(() => {
        getAllUsers();
    }, []);

    return <div className="route__user__offers__settings" onClick={() => onClose()}>
        <div className="route__user__offers__settings__wrap" ref={wrapRef} style={{width: `calc(100% - 86px)`}} onClick={e => e?.stopPropagation()}>

            <div className="route__user__offers__settings__wrap__top">
                <div className="route__user__offers__settings__wrap__top__left">History for {props.field}</div>
                <div className="route__user__offers__settings__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>

            <div className="route__user__offers__settings__wrap__content">
                {users ? <>
                    {users.status === "ok" ? <>
                        <FilteredCustomTable
                            theme={themeSelector}
                            accent="#6C5DD3"
                            headers={["No.", "Date", "User", "Value"]}
                            customColumns={(new Array(4)).fill("max-content")}
                            style={{columnGap: "40px"}}
                            data={(()=>{
                                let out = [];

                                let counter = 0;
                                for (let item of props.data.sort((a ,b) => {
                                    return a.Date < b.Date ? 1 : -1
                                })) {
                                    let curUser = users.data.find(u => u.ID === item.CreatedBy);
                                    curUser = curUser ? curUser.Username : "Unknown user";
                                    counter += 1;
                                    out.push([
                                        {keyID: counter, type: "text", text: counter},
                                        {keyID: counter, type: "text", text: moment(item.Date).toDate().toLocaleString()},
                                        {keyID: counter, type: "text", text: curUser},
                                        {keyID: counter, type: "text", text: item.Value}
                                    ])
                                };

                                if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);
                                return out;
                            })()}
                        />
                    </> : <>
                        <p style={{
                            color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight
                        }}>There was an error while fetching data!</p>
                    </>}
                </> : <Spinner style={{width: "32px", height: "32px"}} color={themeSelector === "dark" ? "white" : "black"} />}
            </div>
        </div>
    </div>
};
const UserAccountManager_profileInfoInline_historyOffers = props => {
    const [users, setUsers] = React.useState();
    const [offers, setOffers] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const wrapRef = React.useRef();

    const getAllUsers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            data: {
                limit: null,
                offset: 0
            },
            ...backendModule.axiosConfig
        }).then(res => setUsers(res.data)).catch(() => setUsers(backendModule.genericError));
    };

    const getAllOffers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {
                limit: null,
                offset: 0
            },
            ...backendModule.axiosConfig
        }).then(res => setOffers(res.data)).catch(() => setOffers(backendModule.genericError));
    };

    const onClose = () => {
        if (wrapRef.current) {
            wrapRef.current.animate([
                { right: getComputedStyle(wrapRef.current).right },
                { right: "-100%" }
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };

        props.onClose();
    };

    React.useEffect(() => {
        if (!wrapRef.current) return;

        wrapRef.current.animate([
            { right: getComputedStyle(wrapRef.current).right },
            { right: 0 }
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);

    React.useEffect(() => {
        getAllUsers();
        getAllOffers();
    }, []);

    return <div className="route__user__offers__settings" onClick={() => onClose()}>
        <div className="route__user__offers__settings__wrap" ref={wrapRef} style={{width: `calc(100% - 86px)`}} onClick={e => e?.stopPropagation()}>

            <div className="route__user__offers__settings__wrap__top">
                <div className="route__user__offers__settings__wrap__top__left">History for {props.field}</div>
                <div className="route__user__offers__settings__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>

            <div className="route__user__offers__settings__wrap__content">
                {(users && offers) ? <>
                    {(users.status === "ok" && offers.status === "ok") ? <>
                        <FilteredCustomTable
                            theme={themeSelector}
                            accent="#6C5DD3"
                            headers={["No.", "Date", "User", "Value"]}
                            customColumns={(new Array(4)).fill("max-content")}
                            style={{columnGap: "40px"}}
                            data={(()=>{
                                let out = [];

                                let counter = 0;
                                for (let item of props.data.sort((a ,b) => {
                                    return a.Date < b.Date ? 1 : -1
                                })) {
                                    let curUser = users.data.find(u => u.ID === item.CreatedBy);
                                    curUser = curUser ? curUser.Username : "Unknown user";
                                    counter += 1;
                                    out.push([
                                        {keyID: counter, type: "text", text: counter},
                                        {keyID: counter, type: "text", text: moment(item.Date).toDate().toLocaleString()},
                                        {keyID: counter, type: "text", text: curUser},
                                        {keyID: counter, type: "text", text: <div>
                                            {item.Value.map(v => {
                                                let curOffer = offers.data.find(o => o.ID === v);
                                                return <span>{curOffer?.OfferName ?? "-"} ({curOffer?.OfferType ?? "-"}, {curOffer?.Country ?? "-"})</span>
                                            })}
                                        </div>}
                                    ])
                                };

                                if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);
                                return out;
                            })()}
                        />
                    </> : <>
                        <p style={{
                            color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight
                        }}>There was an error while fetching data!</p>
                    </>}
                </> : <Spinner style={{width: "32px", height: "32px"}} color={themeSelector === "dark" ? "white" : "black"} />}
            </div>
        </div>
    </div>
};
const UserAccountManager_profileInfoInline = props => {
    const [data, setData] = React.useState();
    const [offers, setOffers] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/getProfileInfo`,
            data: {
                AccountIDs: [props.integrationID],
                Field: props.field,
                fetchOnlyOne: true
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    const getOffers = () => {
        if (!data) return;
        if (data?.status !== "ok") return;

        if (props.field === "Offers") {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/offers/getAllOffers`,
                data: {
                    limit: null,
                    offset: 0,
                    filters: [
                        {name: "ID", op: "in", value: data.data[props.field]}
                    ]
                },
                ...backendModule.axiosConfig
            }).then(res => {
                setOffers(res.data);
            }).catch(() => {
                setOffers(backendModule.genericError);
            });
        };
    };

    const openHistory = () => {
        if (data?.status !== "ok") return;

        switch (props.field) {
            case "Offers":
                animateBox(<UserAccountManager_profileInfoInline_historyOffers userID={props.userID} field={props.field} data={data?.data?.[`${props.field}_History`] ?? []} />)
                break;
            default:
                animateBox(<UserAccountManager_profileInfoInline_historyString userID={props.userID} field={props.field} data={data?.data?.[`${props.field}_History`] ?? []} />)
                break;
        };
    };

    const performUpdate = () => {
        switch (props.field) { 
            case "AccountStatus":
                animateBox(<UserAccountManager_profileInfoInline_updateDropdown userID={props.userID} openHistory={openHistory} integrationID={props.integrationID} field={props.field} previous={data?.status === "ok" ? (data?.data?.[props.field] ?? "") : ""} onChange={() => getData()} dropdown={[
                    {name: "Main", value: "Main"},
                    {name: "Backup", value: "Backup"}
                ]} />)
                break;
            case "DatasetsAndEvents":
                animateBox(<UserAccountManager_profileInfoInline_updateDropdown userID={props.userID} openHistory={openHistory} integrationID={props.integrationID} field={props.field} previous={data?.status === "ok" ? (data?.data?.[props.field] ?? "") : ""} onChange={() => getData()} dropdown={[
                    {name: "Yes", value: "Yes"},
                    {name: "No", value: "No"}
                ]} />)
                break;
            case "Offers":
                animateBox(<UserAccountManager_profileInfoInline_updateOffers userID={props.userID} openHistory={openHistory} integrationID={props.integrationID} field={props.field} previous={data?.status === "ok" ? (data?.data?.[props.field] ?? []) : []} onChange={() => getData()} />)
                break;
            default:
                animateBox(<UserAccountManager_profileInfoInline_updateText userID={props.userID} openHistory={openHistory} integrationID={props.integrationID} field={props.field} previous={data?.status === "ok" ? (data?.data?.[props.field] ?? "") : ""} onChange={() => getData()} />)
                break;
        };
    };

    React.useEffect(() => {
        if (!data) return;

        getOffers();
    }, [data]);

    React.useEffect(() => {
        getData();
    }, []);

    if (!data) return <Spinner style={{width: "18px", height: "18px"}} color={themeSelector === "dark" ? "white" : "black"} />

    if (data.status === "error") return <span style={{color: "gray"}} onClick={performUpdate}>No data</span>
    if (props.array) {
        if (props.field === "Offers" && data.data[props.field].length > 0) {
            if (offers) {
                if (offers.status === "error") {
                    return "Error";
                } else {
                    let names = offers.data.map(o => o.OfferName);
                    names = [...new Set(names)];
                    let finalName = names.join(", ");
                    if (finalName.length > 10) {
                        finalName = `${finalName.substring(0, 10)}...`;
                    };
                    return <span onClick={performUpdate}>{finalName}</span>;
                };
            } else {
                return <Spinner style={{width: "18px", height: "18px"}} color={themeSelector === "dark" ? "white" : "black"} />
            };
        } else {
            return <span style={{color: data.data[props.field] ? null : "gray"}} onClick={performUpdate}>{(data.data[props.field]?.length ?? 0)} {props.suffix ?? ""}</span>
        };
    };
    return <span style={{color: data.data[props.field] ? null : "gray"}} onClick={performUpdate}>{data.data[props.field] ?? "No data"}</span>
};

export default UserAccountManager;