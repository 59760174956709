import React from "react";
import "./index.scss";

import { useNavigate } from "react-router-dom";
import animateModule from "../../../modules/animateModule";

const AdminGlobalIntegrations = () => {
    const curNavigate = useNavigate();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".root__content"));

    return <div className="route__user__integrations">
        <div className="route__user__integrations__list">
            <div className="route__user__integrations__list__item">
                <img src="/images/campaign_orders/order_contact_id.svg" className="route__user__integrations__list__item__img" />
                <p className="route__user__integrations__list__item__head">JP</p>
                <p className="route__user__integrations__list__item__text">Active</p>

                <div className="route__user__integrations__list__item__btns">
                    <div className={`route__user__integrations__list__item__btns__btn route__user__integrations__list__item__btns__btn--connected`} onClick={e => {
                        animateNavigate("/admin-globalIntegrations/jp");
                    }}>
                        <img src="/images/integrations/integration_link.svg" />
                    </div>
                </div>
            </div>

            <div className="route__user__integrations__list__item">
                <img src="/images/campaign_orders/order_contact_phoneNumber.svg" className="route__user__integrations__list__item__img" />
                <p className="route__user__integrations__list__item__head">MW</p>
                <p className="route__user__integrations__list__item__text">Active</p>

                <div className="route__user__integrations__list__item__btns">
                    <div className={`route__user__integrations__list__item__btns__btn route__user__integrations__list__item__btns__btn--connected`} onClick={e => {
                        animateNavigate("/admin-globalIntegrations/mw");
                    }}>
                        <img src="/images/integrations/integration_link.svg" />
                    </div>
                </div>
            </div>

            <div className="route__user__integrations__list__item">
                <img src="/images/campaign_orders/order_contact_location.svg" className="route__user__integrations__list__item__img" />
                <p className="route__user__integrations__list__item__head">MC</p>
                <p className="route__user__integrations__list__item__text">Active</p>

                <div className="route__user__integrations__list__item__btns">
                    <div className={`route__user__integrations__list__item__btns__btn route__user__integrations__list__item__btns__btn--connected`} onClick={e => {
                        animateNavigate("/admin-globalIntegrations/mc");
                    }}>
                        <img src="/images/integrations/integration_link.svg" />
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default AdminGlobalIntegrations;