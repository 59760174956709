import React from "react";

import axios from "axios";
import { useSelector } from "react-redux";

import * as backendModule from "../../../modules/backendModule";
import * as basicStylesModule from "../../../modules/basicStylesModule";
import { animateBox } from "../../../modules/componentAnimation";

import Spinner from "../../customComponents/Spinner";

import PreviewImageModal from "../../modals/PreviewImageModal";
import PreviewVideoModal from "../../modals/PreviewVideoModal";

const AdCreativeModal = props => {
    const [data, setData] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    React.useEffect(() => {
        if (!props?.name) return setData(backendModule.genericError);
        let curName = String(props.name);
        if (curName.startsWith("f-")) {
            curName = curName.substring(2, curName.length);
        };
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/getCreativeFromAdName`,
            data: {
                Name: curName
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                switch (res.data.data.FileType) {
                    case "image":
                        animateBox(<PreviewImageModal image={res.data.data.File} />)
                        break;
                    case "video":
                        animateBox(<PreviewVideoModal video={res.data.data.File} />)
                        break;
                    default: break;
                };
                setData({status: "ok", data: null});
                props.onClose();
            } else {
                setData(backendModule.genericError);
            };
        }).catch(() => {
            setData(backendModule.genericError);
        });
    }, []);

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Ad data</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            {data ? <>
                {data.status === "error" && <p style={{color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}>There was an error while fetching data</p>}
            </> : <Spinner color={themeSelector === "dark" ? "white" : "black"} />}
        </div>
    </div>
};

export default AdCreativeModal;