import React from "react";
import "./index.scss";

import axios from "axios";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";

import * as siteFunctionsActions from "../../../actions/siteFunctionsActions";
import * as basicStylesModule from "../../../modules/basicStylesModule";
import * as backendModule from "../../../modules/backendModule";
import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen";
import { animateBox } from "../../../modules/componentAnimation";

import FilterByDate from "../../../components/filters/FilterByDate";
import FilterBySearch from "../../../components/filters/FilterBySearch";

import { FilteredCustomTable } from "../../../components/customComponents/Table";
import Spinner from "../../../components/customComponents/Spinner";

const GuestManagers_midas = () => {
    const [data, setData] = React.useState();
    const [filterDate, setFilterDate] = React.useState();
    const [filterSearch, setFilterSearch] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "?");
    const curDispatch = useDispatch();

    const curDefer = useDefer();
    const searchDefer = useDefer();
    const curOnScreen = useOnScreen();
    const timestampRef = React.useRef();

    const getData = ts => {
        if (!filterDate) return;
        setCanPaginate(false);

        let filters = [];
        let trackFilters = [];

        if (filterSearch) {
            let searchSplit = filterSearch.split(" ").map(s => String(s)).filter(s => s);
            let searchFilterArr = [];

            for (let s of searchSplit) searchFilterArr.push({or: [
                {name: "CampaignName", op: "like", value: s},
                {name: "ID", op: "eq", value: s}
            ]});
            filters.push({and: searchFilterArr});
        };
        if (filterDate) {
            if (filterDate?.start && filterDate?.end) {
                trackFilters.push({ name: "createdAt", op: "pdgeq", value: filterDate.start.toDate().getTime() });
                trackFilters.push({ name: "createdAt", op: "pdleq", value: filterDate.end.toDate().getTime() });
            };
        };

        setData();
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
            data: {
                limit: 20,
                offset: 0,
                allUsers: true,
                IntegrationID: "md",
                TableHeaders: [
                    "Visits",
                    "Conversions",
                    "CR",
                    "AR",
                    "CPA",
                    "CPAO",
                    "ROI"
                ],
                filters: [
                    {name: "CampaignActive", op: "eq", value: true},
                    ...filters
                ],
                trackFilters,
                orders: [
                    {
                        "name": "Visits",
                        "order": "desc"
                    }
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length >= 20) setCanPaginate(true);
            };
            curDefer(() => setData(res.data), 500);
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        });
    };

    const continueData = ts => {
        if (!data) return;
        if (data.status === "error") return;
        if (!canPaginate) return;
        if (!filterDate) return;

        setCanPaginate(false);

        let filters = [];
        let trackFilters = [];

        if (filterSearch) {
            let searchSplit = filterSearch.split(" ").map(s => String(s)).filter(s => s);
            let searchFilterArr = [];

            for (let s of searchSplit) searchFilterArr.push({or: [
                {name: "CampaignName", op: "like", value: s},
                {name: "ID", op: "eq", value: s}
            ]});
            filters.push({and: searchFilterArr});
        };
        if (filterDate) {
            if (filterDate?.start && filterDate?.end) {
                trackFilters.push({ name: "createdAt", op: "pdgeq", value: filterDate.start.toDate().getTime() });
                trackFilters.push({ name: "createdAt", op: "pdleq", value: filterDate.end.toDate().getTime() });
            };
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
            data: {
                limit: 20,
                offset: 0,
                allUsers: true,
                IntegrationID: "md",
                TableHeaders: [
                    "Visits",
                    "Conversions",
                    "CR",
                    "AR",
                    "CPA",
                    "CPAO",
                    "ROI"
                ],
                filters: [
                    {name: "CampaignActive", op: "eq", value: true},
                    ...filters
                ],
                trackFilters,
                orders: [
                    {
                        "name": "Visits",
                        "order": "desc"
                    }
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
            };
        }).catch(() => null);
    };

    const prepareTableData = (data, column) => {
        let tmpCol = column.replace(/ /g, "_");
        if (tmpCol.endsWith("_IN")) tmpCol = tmpCol.substring(0, tmpCol.length - 3);

        if (tmpCol?.endsWith?.(".lastDate") && tmpCol?.startsWith?.("@")) {
            if (data === 0) return "Never";
            if (moment(data).isValid()) return moment(data).toDate().toLocaleString();
        };
        switch (tmpCol) {
            case "Revenue":
            case "Spent":
            case "Profit":
            case "EPV":
            case "CPA":
            case "CPAO":
            case "CPR":
            case "CPR1000":
            case "CPV":
            case "ADP":
            case "CPC":
                let tmpRevenue = Number(data);
                if (isNaN(tmpRevenue)) return "-";
                return `${tmpRevenue.toFixed(3)} ${currencySignSelector}`;
            case "CR":
            case "AR":
            case "CUR":
            case "CRR":
            case "ROI":
            case "DR":
            case "BACR":
            case "CTR":
                let tmpCR = Number(data);
                if (isNaN(tmpCR)) return "-";
                return `${tmpCR.toFixed(3)} %`;
            case "ROAS":
                let tmpROAS = Number(data);
                if (isNaN(tmpROAS)) return "-";
                return `${tmpROAS.toFixed(3)}x`;
            default:
                let tmp = Number(data);
                if (isNaN(tmp)) return data;
                return tmp.toLocaleString();
        };
    };

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current); 
        } catch {};

        setCanPaginate(false);
        let ts = Date.now();
        timestampRef.current = ts;
        continueData(ts);
    }, [canPaginate, curOnScreen.isIntersecting]);

    React.useEffect(() => {
        let handler = () => {
            let ts = Date.now();
            timestampRef.current = ts;
            curDefer(() => getData(ts), 0);
        };

        handler();
        curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));
        return () => curDispatch(siteFunctionsActions.removeHeaderRefreshAction(handler));
    }, [filterDate, filterSearch]);

    return <div className="route__guestManagers__midas">
        <div className="route__guestManagers__midas__filters">
            <FilterBySearch onChange={e => searchDefer(() => setFilterSearch(e), 1000)} />
            <FilterByDate
                disableAll={true}
                disable24h={true}
                defaultValue={"today"}
                onChange={e => setFilterDate(e)}
            />
        </div>

        <FilteredCustomTable
            theme={themeSelector}
            accent="#6C5DD3"
            headers={["Campaign name", "Visits", "Conversions", "CR", "AR", "CPA", "CPAO", "ROI"]}
            customColumns={(new Array(8)).fill("max-content")}
            style={{columnGap: "40px"}}
            data={(()=>{
                if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                if (data?.status !== "ok") return [[{keyID: "noData-noData", type: "text", text: "Error while fetching campaigns", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]];


                let out = [];
                for (let item of data.data) {
                    out.push([
                        {keyID: item.ID, type: "text", text: <span
                            className="route__guestManagers__midas__campaign"
                            onClick={() => animateBox(<GuestManagers_midas_byPortal
                                item={item}
                                prepareTableData={prepareTableData}
                                filterDate={filterDate}
                            />)}
                        >{item.CampaignName}</span>},
                        {keyID: item.ID, type: "text", text: prepareTableData(item?.TableData?.Visits || 0, "Visits")},
                        {keyID: item.ID, type: "text", text: prepareTableData(item?.TableData?.Conversions || 0, "Conversions")},
                        {keyID: item.ID, type: "text", text: prepareTableData(item?.TableData?.CR || 0, "CR")},
                        {keyID: item.ID, type: "text", text: prepareTableData(item?.TableData?.AR || 0, "AR")},
                        {keyID: item.ID, type: "text", text: prepareTableData(item?.TableData?.CPA || 0, "CPA")},
                        {keyID: item.ID, type: "text", text: prepareTableData(item?.TableData?.CPAO || 0, "CPAO")},
                        {keyID: item.ID, type: "text", text: prepareTableData(item?.TableData?.ROI || 0, "ROI")}
                    ]);
                };

                if (canPaginate) out.push([{keyID: "data-paginate", type: "custom", data: <div ref={curOnScreen.measureRef}>
                    <Spinner style={{width: "32px", height: "32px"}} color={themeSelector === "dark" ? "white" : "black"} />
                </div>}]);
                if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);
                return out;
            })()}
        />
    </div>
};

const GuestManagers_midas_byPortal = props => {
    const [data, setData] = React.useState();
    const [filterDate, setFilterDate] = React.useState();
    const [filterSearch, setFilterSearch] = React.useState("");
    const [orders, setOrders] = React.useState();

    const mainRef = React.useRef();
    const curDefer = useDefer();
    const searchDefer = useDefer();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const onClose = (e) => {
        if (e) e?.stopPropagation();
        if (!mainRef.current) return props.onClose();

        mainRef.current.animate([
            { right: getComputedStyle(mainRef.current).right },
            { right: "-100%" }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease" });
        return props.onClose();
    };

    const getData = () => {
        if (!filterDate) return;

        const filters = [];
        if (filterDate?.start && filterDate?.end) {
            filters.push({name: "createdAt", op: "pdgeq", value: filterDate.start.toDate().getTime()});
            filters.push({name: "createdAt", op: "pdleq", value: filterDate.end.toDate().getTime()});
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getTrackingStats`,
            data: {
                allUsers: true,
                TableHeaders: [
                    "Visits",
                    "Conversions",
                    "CR",
                    "AR",
                    "CPA",
                    "CPAO",
                    "ROI",

                    "Date_TrafficSource"
                ],
                CampaignIDs: [props.item.ID],
                filters,
                IntegrationType: 4,
                trackGroupByDate: true,
                skipIntegrationDataPull: true
            },
            ...backendModule.axiosConfig
        }).then(res => setData(res.data)).catch(() => setData(backendModule.genericError));
    };

    const getPortals = () => {
        let allPortals = [];

        if (!data) return allPortals;
        if (data?.status !== "ok") return allPortals;

        for (let item of data?.data?.TableData?.Visits) {
            if (!allPortals.includes(item["Date_TrafficSource"])) allPortals.push(item["Date_TrafficSource"]);
        };

        return allPortals;
    };
    const getColumn = (col, portal) => {
        if (!data) return props.prepareTableData(0, col);
        if (data?.status !== "ok") return props.prepareTableData(0, col);

        for (let item of data.data.TableData[col]) {
            if (item.Date_TrafficSource === portal) return props.prepareTableData(item.Value, col);
        };

        return props.prepareTableData(0, col);
    };

    const orderData = (d, headers) => {
        if (!orders) return d;

        let headerIndex = headers.indexOf(orders.name);

        if (headerIndex === -1) return d;

        let asc = orders.order === "asc" ? 1 : -1;
        let desc = orders.order === "asc" ? -1 : 1;

        return [...d].sort((a, b) => {
            let a1 = a[headerIndex];
            let b1 = b[headerIndex];

            if (!a1 || !b1) return 0;

            a1 = a1?.text ?? "";
            b1 = b1?.text ?? "";

            if (headerIndex > 0 && !["Created At"].includes(headers[headerIndex])) {
                a1 = String(a1).split(" ")[0].replace(",", "");
                b1 = String(b1).split(" ")[0].replace(",", "");
            };

            if (Number(a1) && Number(b1)) {
                return Number(a1) > Number(b1) ? asc : desc;
            } else if (headers[headerIndex] === "Created at") {
                return moment(a1).isAfter(moment(b1)) ? asc : desc;
            } else {
                return a1 > b1 ? asc : desc;
            };
        });
    };

    React.useEffect(() => {
        if (!mainRef.current) return;

        mainRef.current.animate([
            { right: getComputedStyle(mainRef.current).right },
            { right: 0 }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease" });
    }, [mainRef.current]);

    React.useEffect(() => {
        curDefer(getData, 500);
    }, [filterDate]);

    return <div className="route__guestManagers__midas__portals" onClick={onClose}>
        <div className="route__guestManagers__midas__portals__wrap" ref={mainRef} onClick={e => e?.stopPropagation()}>
            <div className="route__guestManagers__midas__portals__wrap__header">
                <div className="route__guestManagers__midas__portals__wrap__header__left" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <span>Portals for {props.item.CampaignName}</span>
                </div>

                <div className="route__guestManagers__midas__portals__wrap__header__right" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={onClose}></div>
            </div>

            <div className="route__guestManagers__midas__portals__wrap__content">
                <div className="route__guestManagers__midas__filters">
                    <FilterBySearch onChange={e => setFilterSearch(e)} />
                    <FilterByDate
                        disableAll={true}
                        disable24h={true}
                        onChange={e => setFilterDate(e)}
                        defaultValue={props.filterDate || "today"}
                    />
                </div>

                <FilteredCustomTable
                    theme={themeSelector}
                    orderCB={e => setOrders(e)}
                    accent="#6C5DD3"
                    headers={["Portal", "Visits", "Conversions", "CR", "AR", "CPA", "CPAO", "ROI"]}
                    customColumns={(new Array(8)).fill("max-content")}
                    style={{columnGap: "40px"}}
                    data={(()=>{
                        if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                        if (data?.status !== "ok") return [[{keyID: "noData-noData", type: "text", text: "Error while fetching campaigns", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]];


                        let out = [];
                        let allPortals = getPortals();
                        for (let portal of allPortals) {
                            if (filterSearch) {
                                if (!String(portal).toLowerCase().includes(String(filterSearch).toLowerCase())) continue;
                            };
                            out.push([
                                {keyID: portal || `${String(portal)}-none`, type: "text", text: portal ?? "-"},
                                {keyID: portal || `${String(portal)}-none`, type: "text", text: getColumn("Visits", portal)},
                                {keyID: portal || `${String(portal)}-none`, type: "text", text: getColumn("Conversions", portal)},
                                {keyID: portal || `${String(portal)}-none`, type: "text", text: getColumn("CR", portal)},
                                {keyID: portal || `${String(portal)}-none`, type: "text", text: getColumn("AR", portal)},
                                {keyID: portal || `${String(portal)}-none`, type: "text", text: getColumn("CPA", portal)},
                                {keyID: portal || `${String(portal)}-none`, type: "text", text: getColumn("CPAO", portal)},
                                {keyID: portal || `${String(portal)}-none`, type: "text", text: getColumn("ROI", portal)},
                            ]);
                        };

                        if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);
                        if (orders) out = orderData(out, ["Portal", "Visits", "Conversions", "CR", "AR", "CPA", "CPAO", "ROI"]);
                        return out;
                    })()}
                />
            </div>
        </div>
    </div>
};

export default GuestManagers_midas;