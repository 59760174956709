export const init = (columns, trackEvents) => {
    if (!trackEvents) return columns;
    if (trackEvents?.status !== "ok") return columns;
    if (!Array.isArray(columns)) return columns;

    let out = [...columns];

    for (let event of trackEvents.data) {
        out.push(`@${event.EventName}.count`);
        out.push(`@${event.EventName}.lastDate`);

        for (let key of Object.keys(event.EventValues)) {
            if (event.EventValues[key].startsWith("number")) {
                out.push(`@${event.EventName}.${key}.min`);
                out.push(`@${event.EventName}.${key}.max`);
                out.push(`@${event.EventName}.${key}.avg`);
                out.push(`@${event.EventName}.${key}.sum`);
                out.push(`@${event.EventName}.${key}.count`);
                out.push(`@${event.EventName}.${key}.uniqueCount`);
            } else if (event.EventValues[key].startsWith("string")) {
                out.push(`@${event.EventName}.${key}.mostCommon`);
                out.push(`@${event.EventName}.${key}.leastCommon`);
                out.push(`@${event.EventName}.${key}.uniqueCount`);
            };
        };
    };

    return [...new Set([...out])];
};