import React from "react";
import "./index.scss";

const PreviewVideoModal = props => {
    return <div className="modal__previewVideo" onClick={e => e.stopPropagation()}>
        <div className="modal__previewVideo__wrap">
            <div className="modal__previewVideo__wrap__head">
                <div className="modal__previewVideo__wrap__head__left">Preview</div>
                <div className="modal__previewVideo__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            <div className="modal__previewVideo__wrap__img">
                <video src={props.video} controls />
            </div>
        </div>
    </div>
};

export default PreviewVideoModal;