import React from "react";
import "./index.scss";

import moment from "moment";
import axios from "axios";
import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen";
import * as backendMoudle from "../../../modules/backendModule";

import Spinner from "../../customComponents/Spinner";
import { FilteredCustomTable } from "../../customComponents/Table";

const GlobalChanges = (props) => {
    const [data, setData] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);

    const mainRef = React.useRef();
    const timestampRef = React.useRef();

    const curDefer = useDefer();
    const curOnScreen = useOnScreen();

    const getData = (ts) => {
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendMoudle.backendURL}/globalChangesHistory/getAllChanges`,
            data: {
                ItemID: props.ID,
                Table: props.Table,

                limit: 20,
                offset: 0
            },
            ...backendMoudle.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;

            if (res.data.status === "ok") {
                if (res.data.data.length === 20) setCanPaginate(true);
            };
            setData(res.data);
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendMoudle.genericError);
        });
    };

    const continueData = (ts) => {
        if (!data) return;
        if (data.status !== "ok") return;
        if (!canPaginate) return;

        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendMoudle.backendURL}/globalChangesHistory/getAllChanges`,
            data: {
                ItemID: props.ID,
                Table: props.Table,

                limit: 20,
                offset: data.data.length
            },
            ...backendMoudle.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;

            if (res.data.status === "ok") {
                if (res.data.data.length === 20) setCanPaginate(true);
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
            };
        }).catch(() => {
            if (timestampRef.current !== ts) return;
        });
    };

    const findObjectDiff = (finalObjectOld = {}, finalObjectNew = {}, prefix = "", oldObject, newObject) => {
        if (
            (typeof(oldObject) === "object" && !Array.isArray(oldObject)) &&
            (typeof(newObject) === "object" && !Array.isArray(newObject))
        ) {
            for (let key of Object.keys(oldObject)) {
                if (oldObject[key] === null && newObject[key] === null) continue;
                if (typeof(oldObject[key]) === "object" && typeof(newObject[key]) === "object") {
                    if (!Array.isArray(oldObject[key]) && !Array.isArray(newObject[key])) {
                        findObjectDiff(finalObjectOld, finalObjectNew, `${prefix}.${key}`, oldObject[key], newObject[key]);
                        continue;
                    };
                };
                let tmp1 = String(oldObject[key]);
                let tmp2 = String(newObject[key]);
                if (tmp1 !== tmp2) {
                    finalObjectOld[`${prefix}.${key}`] = tmp1;
                    finalObjectNew[`${prefix}.${key}`] = tmp2;
                };
            };
        };

        return [finalObjectOld, finalObjectNew];
    };

    const prepareChanges = (oldChanges, newChanges) => {
        let out = [];

        for (let item of Object.keys(oldChanges)) {
            if (Array.isArray(oldChanges[item]) || Array.isArray(newChanges[item])) {
                oldChanges[item] = String(oldChanges[item]);
                newChanges[item] = String(newChanges[item]);
            } else if (typeof(oldChanges[item]) === "object" && typeof(newChanges[item]) === "object") {
                let found = findObjectDiff({}, {}, item, oldChanges[item], newChanges[item]);

                out.push(...prepareChanges(found[0], found[1]));
                continue;
            };
            oldChanges[item] = String(oldChanges[item]);
            newChanges[item] = String(newChanges[item]);
            
            out.push(<div className="modals__globalChanges__wrap__content__change">
                <span className="modals__globalChanges__wrap__content__change__name">{item}</span>
                <span className="modals__globalChanges__wrap__content__change__left">{oldChanges[item]}</span>
                <span className="modals__globalChanges__wrap__content__change__middle">-&gt;</span>
                <span className="modals__globalChanges__wrap__content__change__right">{newChanges[item]}</span>
            </div>);
        };

        return out;
    };

    const onClose = (e) => {
        if (e) e?.stopPropagation();
        if (!mainRef.current) return props.onClose();

        mainRef.current.animate([
            { right: getComputedStyle(mainRef.current).right },
            { right: "-100%" }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease" });
        return props.onClose();
    };

    React.useEffect(() => {
        if (!mainRef.current) return;

        mainRef.current.animate([
            { right: getComputedStyle(mainRef.current).right },
            { right: 0 }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease" });
    }, [mainRef.current]);

    React.useEffect(() => {
        if (!canPaginate || !curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);

            let ts = Date.now();
            timestampRef.current = ts;
            curDefer(() => continueData(ts), 500);
        } catch {};
    }, [canPaginate, curOnScreen.isIntersecting]);

    React.useEffect(() => {
        let ts = Date.now();
        timestampRef.current = ts;
        getData(ts);
    }, []);

    return <div className="modals__globalChanges" onClick={onClose}>
        <div className="modals__globalChanges__wrap" onClick={e => e.stopPropagation()} ref={mainRef}>
            <div className="modals__globalChanges__wrap__header">
                <div className="modals__globalChanges__wrap__header__left">Changes history</div>
                <div className="modals__globalChanges__wrap__header__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={onClose}></div>
            </div>

            <div className="modals__globalChanges__wrap__content">
                <FilteredCustomTable
                    theme="dark"
                    accent="#6C5DD3"   
                    headers={["Date", "User", "Changes"]}
                    customColumns={["250px", "300px", "auto"]}
                    data={(()=>{
                        if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: "white"}]];
                        if (data.status === "error") return [[{keyID: "noData-error", type: "text", text: "There was an error while fetching data.", color:"#FF450D"}]];

                        let out = [];
                        for (let item of data.data) {
                            out.push([
                                {keyID: item.ID, type: "text", text: moment(item.createdAt).toDate().toLocaleString()},
                                {keyID: item.ID, type: "text", text: item._User.Username},
                                {keyID: item.ID, type: "text", text: prepareChanges(item.PreviousValues, item.NewValues)}
                            ]);
                        };

                        if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);
                        if (canPaginate) {
                            out.push([{keyID: "pagination-spinner", type: "spinner", color: "white"}]);
                        };

                        return out;
                    })()}
                />
                {canPaginate && <div ref={curOnScreen.measureRef} />}
            </div>
        </div>
    </div>
};

export default GlobalChanges;