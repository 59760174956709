import React from "react";

import axios from "axios";
import { useSelector } from "react-redux";
import { animateBox } from "../../../modules/componentAnimation";

import * as backendModule from "../../../modules/backendModule";

import Spinner from "../../../components/customComponents/Spinner";
import StyledButton from "../../../components/styledComponents/Button";

import FilterBySearch from "../../../components/filters/FilterBySearch";
import FilterByDate from "../../../components/filters/FilterByDate";

import PreviewImageModal from "../../../components/modals/PreviewImageModal";
import PreviewVideoModal from "../../../components/modals/PreviewVideoModal";

const UserCreatives_FB = () => {
    const [data, setData] = React.useState();
    const [trackData, setTrackData] = React.useState();
    const [spinner, setSpinner] = React.useState(false);
    const [search, setSearch] = React.useState("");
    const [dateFilter, setDateFilter] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const selectedTrackingProfileSelector = useSelector(state => state?.trackingProfiles?.selectedProfile ?? "-1");
    const allTrackingProfilesSelector = useSelector(state => state?.trackingProfiles?.profiles ?? []);
    const currencySelector = useSelector(state => state?.types?.currencySign ?? "?");

    const timestampRef = React.useRef();

    const findProfile = () => {
        if (!String(selectedTrackingProfileSelector).startsWith("fb-")) return null;

        for (let profile of allTrackingProfilesSelector) {
            if (profile.ID === selectedTrackingProfileSelector) return profile;
        };

        return null;
    };

    const getData = (ts) => {
        if (dateFilter === undefined) return;
        let curIntegration = findProfile();
        if (!curIntegration) return;
        if (!curIntegration?.AccountID) return;
        if (timestampRef.current !== ts) return;

        let finalDateFilters = [];
        if (dateFilter?.start && dateFilter?.end) {
            finalDateFilters.push({and: [
                {name: "createdAt", op: "pdgeq", value: dateFilter?.start?.toDate().getTime()},
                {name: "createdAt", op: "pdleq", value: dateFilter?.end?.toDate().getTime()}
            ]});
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/getCreativesCTR`,
            data: {
                IntegrationID: curIntegration.AccountID,
                filters: [
                    ...finalDateFilters
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 0) {
                    setData({ status: "ok", data: [] });
                    setSpinner(false);
                } else {
                    getAdCreatives(res.data.data, ts);
                    getTrackData(res.data.data.map(c => c.AdID), ts);
                };
            } else {
                setSpinner(false);
                setData(backendModule.genericError);
            };
        }).catch(() => {
            setSpinner(false);
            setData(backendModule.genericError);
        });
    };

    const getAdCreatives = (creatives, ts) => {
        let curIntegration = findProfile();
        if (!curIntegration) return;
        if (!curIntegration?.AccountID) return;
        if (timestampRef.current !== ts) return;

        const additionalCreatives = [];
        let filters = [];

        let searchSplit = search.split(" ");
        if (searchSplit.length > 0) {
            for (let item of searchSplit) {
                item = item.trim();
                if (!item) continue;
                if (!isNaN(Number(item))) {
                    additionalCreatives.push(item);
                } else {
                    filters.push({name: "MediaName", op: "like", value: item});
                };
            };
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/getCreativeBasicInfo`,
            data: {
                IntegrationID: curIntegration.AccountID,
                limit: null,
                CreativeIDs: [...creatives.map(c => {
                    return {Creative: c?.ID, AdID: c?.AdID}
                })],
                filters: filters.length > 0 ? [{and: filters}] : []
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                let tmp = {};
                for (let item of creatives) {
                    let foundCreative = res.data.data.find(c => c.ID === item.ID);
                    if (!foundCreative) continue;

                    tmp[foundCreative.ID] = { ...foundCreative, ...item };

                };
                setData({
                    status: "ok", data: Object.keys(tmp).map(key => {
                        return {
                            ID: key,
                            ...tmp[key]
                        };
                    })
                });
                setSpinner(false);
            } else {
                setData(backendModule.genericError);
                setSpinner(false);
            };
        }).catch(() => {
            setData(backendModule.genericError);
            setSpinner(false);
        });
    };

    const getTrackData = async (ads, ts) => {
        if (dateFilter === undefined) return;
        let curIntegration = findProfile();
        if (!curIntegration) return;
        if (!curIntegration?.AccountID) return;
        if (timestampRef.current !== ts) return;

        let finalDateFilters = [];
        if (dateFilter?.start && dateFilter?.end) {
            finalDateFilters.push({and: [
                {name: "createdAt", op: "pdgeq", value: dateFilter?.start?.toDate().getTime()},
                {name: "createdAt", op: "pdleq", value: dateFilter?.end?.toDate().getTime()}
            ]});
        };

        let out = [];
        let toProcess = [];
        for (let item of [...new Set(ads)]) {
            toProcess.push(new Promise(r => {
                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/campaigns/getTrackingStats`,
                    data: {
                        IntegrationType: curIntegration.IntegrationType,
                        filters: [
                            ...finalDateFilters,
                            {name: "IntegrationParams.stadid", op: "eq", value: item}
                        ],
                        skipIntegrationDataPull: false,
                        TableHeaders: [
                            "CPA",
                            "CR"
                        ]
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "ok") {
                        out.push({AdID: item, ...res.data.data});
                    };
                }).catch(() => null).finally(r);
            }));
        };

        await Promise.allSettled(toProcess);
        setTrackData(out);
    };

    const grabStatData = (ID, itemKey) => {
        if (!Array.isArray(trackData)) return null;
        for (let item of trackData) {
            if (item.AdID !== ID) continue;

            for (let key of Object.keys(item.Integrations)) {
                let td = item.Integrations[key]?.TableData;
                if (!td) break;

                if (td[itemKey] === null || td[itemKey] === undefined) return "-";

                switch (itemKey) {
                    case "CR":
                        return `${Number(td[itemKey]).toFixed(2)} %`;
                    case "CPA":
                        return `${Number(td[itemKey]).toFixed(2)} ${currencySelector}`;
                    default: return td[itemKey];
                };
            };
        };

        return "-";
    };

    React.useEffect(() => {
        let ts = Date.now();
        timestampRef.current = ts;
        getData(ts);
    }, [search, dateFilter]);

    return <div className="route__user__creatives__fb">
        <div className="route__user__creatives__fb__filters" style={{ gridTemplateColumns: "1fr 350px" }}>
            <FilterBySearch onChange={e => setSearch(e)} />
            <FilterByDate disableAll={true} onChange={e => setDateFilter(e)} defaultValue="today" />
        </div>
        <div className="route__user__creatives__fb__content">
            <div className="route__user__creatives__st__content__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }}>
                <Spinner color="white" align="center" />
            </div>
            {data ? <>
                {data.status === "ok" ? <>
                    {data.data.map(item => {
                        return <div className="route__user__creatives__fb__content__item" key={`creative-${item.ID}`}>
                            <div className="route__user__creatives__fb__content__item__name" title={item.Name}>[{`${item.Type.charAt(0).toUpperCase()}${item.Type.substring(1, item.Type.length)}`}] {item.Name}</div>
                            <div className="route__user__creatives__fb__content__item__date">
                                <span>CTR: {Number(item.CTR).toFixed(2)} %</span>
                                <span>CR: {trackData ? grabStatData(item.AdID, "CR") : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />}</span>
                                <span>CPA: {trackData ? grabStatData(item.AdID, "CPA") : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />}</span>
                            </div>
                            <div className="route__user__creatives__fb__content__item__date">Data points: {item.DataPoints ?? 0}</div>

                            <img src={(() => {
                                if (item.Type === "Image") return item.URL;
                                if (item.Type === "Video") return "/images/fileTypes/video.svg";
                                return "/images/fileTypes/file.svg";
                            })()} onClick={e => {
                                if (item.Type === "Image") return animateBox({ currentTarget: e.target.parentNode }, <PreviewImageModal image={item.URL} />);
                                if (item.Type === "Video") return animateBox({ currentTarget: e.target.parentNode }, <PreviewVideoModal video={item.URL} />)
                            }} />

                            <div className="route__user__creatives__fb__content__item__buttons">
                                {item.Type === "file" && <StyledButton isSecondary={false} style={{ padding: "0 10px" }} onClick={e => window.open(item.URL, "_blank")}>Download</StyledButton>}
                                {(item.Type === "Video" || item.Type === "Image") && <StyledButton isSecondary={false} style={{ padding: "0 10px" }} onClick={e => window.open((() => {
                                    let tmpURL = item.URL;
                                    tmpURL += tmpURL.includes("?") ? "&" : "?";
                                    tmpURL += "download=1";
                                    return tmpURL;
                                })(), "_blank", "download")}>Download</StyledButton>}
                            </div>
                        </div>
                    })}
                    {data.data.length === 0 && <p>Nothing to show...</p>}
                </> : <p className="route__user__creatives__fb__content__infoP">Error while fetching data!</p>}
            </> : <Spinner style={{ width: "32px", height: "32px" }} color="white" />}
        </div>
    </div>
};

export default UserCreatives_FB;