import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";

import * as basicStylesModule from "../../../modules/basicStylesModule";
import * as backendModule from "../../../modules/backendModule";
import useOnScreen from "../../../modules/hooks/useOnScreen";
import { animateBox } from "../../../modules/componentAnimation";

import { FilteredCustomTable } from "../../../components/customComponents/Table";

const AdminReportedURLs = () => {
    const [data, setData] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const curOnScreen = useOnScreen();

    const timestampRef = React.useRef();

    const getData = ts => {
        if (ts !== timestampRef.current) return;
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/reportedExternalURLs/getAllReportedURLs`,
            data: {
                limit: 20,
                offset: 0
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;

            setData(res.data);
            if (res.data.status === "ok") {
                if (res.data.data.length >= 20) setCanPaginate(true);
            };
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    const continueData = ts => {
        if (!data) return;
        if (data.status !== "ok") return;
        if (ts !== timestampRef.current) return;
        if (!canPaginate) return;

        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/reportedExternalURLs/getAllReportedURLs`,
            data: {
                limit: 20,
                offset: 0,
                filters: [
                    {name: "ID", op: "notIn", value: data.data.map(d => d.ID)}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;

            if (res.data.status === "ok") {
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
                if (res.data.data.length >= 20) setCanPaginate(true);
            };
        }).catch(() => null);
    };

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        setCanPaginate(false);
        let ts = Date.now();
        timestampRef.current = ts;
        continueData(ts);
    }, [canPaginate, curOnScreen.isIntersecting]);

    React.useEffect(() => {
        let ts = Date.now();
        timestampRef.current = ts;
        getData(ts);
    }, []);

    return <div className="route__admin__reportedURLs">
        <FilteredCustomTable
            theme={themeSelector}
            accent="#6C5DD3"
            headers={["User", "Integration", "URL", "Error", "Date", ""]}
            customColumns={["1fr", "1fr", "1fr", "1fr", "1fr", "max-content"]}
            style={{columnGap: "30px"}}
            data={(()=>{
                if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "light" ? "black" : "white"}]];
                if (data.status === "error") return [[{keyID: "noData-error", type: "custom", data: "Error while fetching data", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]];

                let out = [];
                for (let item of data.data) {
                    out.push([
                        {keyID: item.ID, type: "text", text: item?._User?.Username ?? "?"},
                        {keyID: item.ID, type: "text", text: `${item.Integration}-${item.AdID}`},
                        {keyID: item.ID, type: "text", text: item.MatchedURL},
                        {keyID: item.ID, type: "text", text: item.MatchedReason},
                        {keyID: item.ID, type: "text", text: moment(item.createdAt).toDate().toLocaleDateString()},
                        {keyID: item.ID, type: "button", text: "View", onClick: () => animateBox(<AdminReportedURLs_moreInfo item={item} />)}
                    ]);
                };

                if (out.length === 0) out.push([{keyID: "noData-noData", type: "custom", data: "Nothing to show..."}]);
                return out;
            })()}
        />
        {canPaginate && <div style={{width: "1px", height: "1px", opacity: 0}} ref={curOnScreen.measureRef}></div>}
    </div>
};

const AdminReportedURLs_moreInfo = props => {

    const prepareAdditionalData = ad => {
        let out = [];
        for (let key of Object.keys(ad)) {
            out.push(<div className="route__admin__reportedURLs__url">
                <span>{key}</span>
                <span>{String(ad[key])}</span>
            </div>);
        };

        return out;
    };

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Reported URL</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            <div className="route__admin__reportedURLs__url">
                <span>Report ID</span>
                <span>{props.item.ID}</span>
            </div>
            <div className="route__admin__reportedURLs__url">
                <span>Integration</span>
                <span>{props.item.Integration} {props.item.AdID}</span>
            </div>
            <div className="route__admin__reportedURLs__url">
                <span>Full URL</span>
                <span>{props.item.FullURL}</span>
            </div>
            <div className="route__admin__reportedURLs__url">
                <span>Matched URL (that caused this report)</span>
                <span>{props.item.MatchedURL}</span>
            </div>
            <div className="route__admin__reportedURLs__url">
                <span>Error reason</span>
                <span>{props.item.MatchedReason}</span>
            </div>
            <div className="route__admin__reportedURLs__url">
                <span>Created by User</span>
                <span>{props.item?._User?.Username ?? "?"} ({props.item.CreatedBy})</span>
            </div>
            <div className="route__admin__reportedURLs__url">
                <span>Date</span>
                <span>{moment(props.item.createdAt).toDate().toLocaleString()}</span>
            </div>

            {Object.keys(props.item.AdditionalData ?? {}).length > 0 && <>
                <br />
                <h4>Additional data</h4>
                <hr />
                <br />
                {prepareAdditionalData(props.item.AdditionalData)}
            </>}

        </div>
    </div>
};

export default AdminReportedURLs;