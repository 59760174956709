import React from "react";
import "./index.scss";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import { genericStatusColorPills } from "../../../../modules/miscModule";
import * as siteFunctionsActions from "../../../../actions/siteFunctionsActions";
import * as backendModule from "../../../../modules/backendModule";
import { animateBox } from "../../../../modules/componentAnimation";
import useDefer from "../../../../modules/hooks/useDefer";
import useOnScreen from "../../../../modules/hooks/useOnScreen";

import YesNoModal from "../../../../components/modals/YesNoModal";

import { FilteredCustomTable } from "../../../../components/customComponents/Table";
import ButtonWithDropdown from "../../../../components/customComponents/ButtonWithDropdown";
import Dropdown from "../../../../components/customComponents/Dropdown";
import Spinner from "../../../../components/customComponents/Spinner";

const AdminGlobalIntegrations_MW = () => {
    const [activeTab, setActiveTab] = React.useState(0);

    const contentRef = React.useRef();

    const setTab = (t) => {
        if (activeTab === t) return;

        contentRef.current.animate([
            {opacity: getComputedStyle(contentRef.current).opacity},
            {opacity: 0}
        ], {
            duration: 150,
            iterations: 1,
            fill: "both",
            easing: "ease"
        }).onfinish = () => {
            setActiveTab(t);

            contentRef.current.animate([
                {opacity: getComputedStyle(contentRef.current).opacity},
                {opacity: 1}
            ], {
                duration: 150,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };
    };

    return <div className="route__admin__globalIntegrationsMW">
        <div className="route__admin__globalIntegrationsMW__tabs">
            <div className={`route__admin__globalIntegrationsMW__tabs__tab ${activeTab === 0 ? "route__admin__globalIntegrationsMW__tabs__tab--active" : ""}`} onClick={() => setTab(0)}>Profile / Usage</div>
        </div>

        <div className="route__admin__globalIntegrationsMW__content" ref={contentRef}>
            {(()=>{
                switch (activeTab) {
                    case 0: return <>
                        <AdminGlobalIntegrations_MW__Profile />
                        <AdminGlobalIntegrations_MW__Messages />
                    </>
                    default: return null;
                };
            })()}
        </div>
    </div>
};

const AdminGlobalIntegrations_MW__Profile = () => {
    const [data, setData] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const curDispatch = useDispatch();

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/globalIntegrations/mw/getStats`,
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    React.useEffect(() => {
        getData();

        const handler = () => getData();

        curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));

        return () => curDispatch(siteFunctionsActions.removeHeaderRefreshAction(handler));
    }, []);

    return <div className="route__admin__globalIntegrationsMW__profile">

        <div className="route__admin__globalIntegrationsMW__profile__kpi">
            <div className="route__admin__globalIntegrationsMW__profile__kpi__item">
                <div className="route__admin__globalIntegrationsMW__profile__kpi__item__top">
                    <div className="route__admin__globalIntegrationsMW__profile__kpi__item__top__left">
                        <p>Balance</p>
                    </div>
                </div>
                <div className="route__admin__globalIntegrationsMW__profile__kpi__item__bottom">
                    <div className="route__admin__globalIntegrationsMW__profile__kpi__item__bottom__left">
                        {data ? <>
                            {data.status === "ok" ? `-` : "?"}
                        </> : <Spinner style={{width: "42px", height: "42px"}} color={themeSelector === "dark" ? "white" : "black"} />}
                    </div>
                </div>
            </div>

            <div className="route__admin__globalIntegrationsMW__profile__kpi__item">
                <div className="route__admin__globalIntegrationsMW__profile__kpi__item__top">
                    <div className="route__admin__globalIntegrationsMW__profile__kpi__item__top__left">
                        <p>Total messages</p>
                    </div>
                </div>
                <div className="route__admin__globalIntegrationsMW__profile__kpi__item__bottom">
                    <div className="route__admin__globalIntegrationsMW__profile__kpi__item__bottom__left">
                        {data ? <>
                            {data.status === "ok" ? data.data.TotalMessages : "?"}
                        </> : <Spinner style={{width: "42px", height: "42px"}} color={themeSelector === "dark" ? "white" : "black"} />}
                    </div>
                </div>
            </div>

            <div className="route__admin__globalIntegrationsMW__profile__kpi__item">
                <div className="route__admin__globalIntegrationsMW__profile__kpi__item__top">
                    <div className="route__admin__globalIntegrationsMW__profile__kpi__item__top__left">
                        <p>Pending messages</p>
                    </div>
                </div>
                <div className="route__admin__globalIntegrationsMW__profile__kpi__item__bottom">
                    <div className="route__admin__globalIntegrationsMW__profile__kpi__item__bottom__left">
                        {data ? <>
                            {data.status === "ok" ? data.data.TotalMessages - data.data.CompletedMessages - data.data.FailedMessages : "?"}
                        </> : <Spinner style={{width: "42px", height: "42px"}} color={themeSelector === "dark" ? "white" : "black"} />}
                    </div>
                </div>
            </div>

            <div className="route__admin__globalIntegrationsMW__profile__kpi__item">
                <div className="route__admin__globalIntegrationsMW__profile__kpi__item__top">
                    <div className="route__admin__globalIntegrationsMW__profile__kpi__item__top__left">
                        <p>Completed messages</p>
                    </div>
                </div>
                <div className="route__admin__globalIntegrationsMW__profile__kpi__item__bottom">
                    <div className="route__admin__globalIntegrationsMW__profile__kpi__item__bottom__left">
                        {data ? <>
                            {data.status === "ok" ? data.data.CompletedMessages : "?"}
                        </> : <Spinner style={{width: "42px", height: "42px"}} color={themeSelector === "dark" ? "white" : "black"} />}
                    </div>
                </div>
            </div>

            <div className="route__admin__globalIntegrationsMW__profile__kpi__item">
                <div className="route__admin__globalIntegrationsMW__profile__kpi__item__top">
                    <div className="route__admin__globalIntegrationsMW__profile__kpi__item__top__left">
                        <p>Failed messages</p>
                    </div>
                </div>
                <div className="route__admin__globalIntegrationsMW__profile__kpi__item__bottom">
                    <div className="route__admin__globalIntegrationsMW__profile__kpi__item__bottom__left">
                        {data ? <>
                            {data.status === "ok" ? data.data.FailedMessages : "?"}
                        </> : <Spinner style={{width: "42px", height: "42px"}} color={themeSelector === "dark" ? "white" : "black"} />}
                    </div>
                </div>
            </div>

        </div>

    </div>
};

const AdminGlobalIntegrations_MW__Messages = props => {
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [spinner, setSpinner] = React.useState(false);
    const [canPaginate, setCanPaginate] = React.useState(false);

    const curOnScreen = useOnScreen();
    const curDefer = useDefer();
    const curDispatch = useDispatch();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const curMWStatusCodesSelector = useSelector(state => state?.types?.globalIntegrations?.mw?.statusCodes ?? {});

    const curTimestampRef = React.useRef(Date.now());

    const getData = (ts) => {
        if (curTimestampRef.current !== ts) return;

        setCanPaginate(false);
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/globalIntegrations/mw/getAllMessages`,
            data: {
                limit: 20,
                offset: 0,
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (curTimestampRef.current !== ts) return;
            setData(res.data);

            if (res.data.status === "ok") {
                if (res.data.data.length === 20) setCanPaginate(true);
            };
        }).catch(() => {
            if (curTimestampRef.current !== ts) return;
            setData(backendModule.genericError);
        }).finally(() => {
            setSpinner(false);
        });
    };

    const continueData = ts => {
        if (!data) return;
        if (data.status !== "ok") return;
        if (!canPaginate) return;

        setSpinner(true);
        setCanPaginate(false);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}globalIntegrations/mw/getAllMessages`,
            data: {
                limit: 20,
                offset: data.data.length,
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (ts !== curTimestampRef.current) return;
            if (res.data.status !== "ok") return;

            setData(d => {
                return {
                    ...d,
                    data: [
                        ...d.data,
                        ...res.data.data
                    ]
                };
            });
            if (res.data.data.length === 20) setCanPaginate(true);
        }).catch(() => null).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        let ts = Date.now();
        curTimestampRef.current = ts;
        curDefer(() => continueData(ts), 500);
    }, [curOnScreen.isIntersecting, canPaginate]);

    React.useEffect(() => {
        let ts = Date.now();
        curTimestampRef.current = ts;
        curDefer(() => getData(ts), 100);

        const handler = () => {
            let ts = Date.now();
            curTimestampRef.current = ts;
            curDefer(() => getData(ts), 0);
        };

        curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));

        return () => curDispatch(siteFunctionsActions.removeHeaderRefreshAction(handler));
    }, [filters]);

    return <div className="route__admin__globalIntegrationsMW__orders">
        <FilteredCustomTable
            style={{width: "100%"}}
            spinnerColor={themeSelector === "dark" ? "white" : "black"}
            showSpinner={spinner}
            accent="#6C5DD3"
            theme={themeSelector}
            filters={[
                {name: "ID", friendlyName: "Message ID", type: "string"},
                {name: "From", friendlyName: "From", type: "string"},
                {name: "PhoneNumber", friendlyName: "PhoneNumber", type: "string"},
                {name: "MessageText", friendlyName: "Message text", type: "string"},
                {name: "Status", friendlyName: "Message status", type: "custom", varType: "number", data: Object.keys(curMWStatusCodesSelector).map(key => {
                    return {text: curMWStatusCodesSelector[key], value: key};
                })},
                {name: "createdAt", friendlyName: "Date created", type: "date"}
            ]}
            filterCB={f => setFilters(f)}
            headers={["Date", "From", "To", "Status"]}
            data={(()=>{
                if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: "white"}]];
                if (data.status === "error") return [[{keyID: "noData-error", type: "text", text: "Error while fetching data!"}]];

                let out = [];

                for (let item of data.data) {
                    out.push([
                        {keyID: item.ID, type: "text", text: (new Date(item.createdAt)).toLocaleString()},
                        {keyID: item.ID, type: "text", text: item.From},
                        {keyID: item.ID, type: "text", text: item.PhoneNumber},
                        {keyID: item.ID, type: "text", text: curMWStatusCodesSelector[item.Status]},
                        {keyID: item.ID, type: "groupNewline", group: [
                            {keyID: item.ID, type: "text", text: <p className="route__admin__globalIntegrationsMW__orders__pills">
                                <strong>Info:</strong>
                                <span>UserID: {item.CreatedByTeamUserID ?? item.CreatedBy}</span>
                            </p>}
                        ]},
                        {keyID: item.ID, type: "groupNewline", group: [
                            {keyID: item.ID, type: "button", text: "Show message", triggerDropdown: true, triggerData: () => item.MessageText}
                        ]}
                    ]);
                };

                if (out.length === 0) out.push([
                    {keyID: "noData-noData", type: "text", text: "Nothing to show..."}
                ]);

                if (spinner) out.push([
                    {keyID: "data-spinner", type: "spinner", color: "white"}
                ]);

                return out;
            })()}
        />
        {canPaginate && <div ref={curOnScreen.measureRef} style={{width: "1px", height: "1px", opacity: 0}}></div>}
    </div>
};

export default AdminGlobalIntegrations_MW;