const initialState = {
    profiles: [],
    teams: [],
    selectedProfile: undefined,
    selectedTeam: undefined
};

export default (state = initialState, action) => {
    switch(action.type) {
        case "TRACKING_PROFILES_UPDATE": return {
            ...state,
            profiles: action.payload
        };
        case "TRACKING_PROFILES_SELECT": return {
            ...state,
            selectedProfile: action.payload
        };

        case "TRACKING_TEAMS_UPDATE": return {
            ...state,
            teams: action.payload
        };
        case "TRACKING_TEAMS_SELECT": return {
            ...state,
            selectedTeam: action.payload
        };
        case "TRACKING_DATA_RESET": return {
            ...state,
            profiles: [],
            teams: [],
            selectedProfile: undefined,
            selectedTeam: undefined
        };
        default: return state;
    };
};