import React from "react";
import "./index.scss";

import propTypes from "prop-types";

import Spinner from "../../customComponents/Spinner";

const StyledButton = props => {
    let out = {};

    if (props.isSecondary) out["data-secondary"] = 1;
    if (props.isSpinner) out["data-spinner"] = 1;
    if (props.isDisabled) out["data-disabled"] = 1;

    let finalProps = {...props};
    delete finalProps["isSecondary"];
    delete finalProps["isSpinner"];
    delete finalProps["isDisabled"];

    return <button
            {...finalProps}
            className={`styledComponent__button ${finalProps.className ? ` ${finalProps.className}` : ""}`}
            {...out}
        >
        {props.isSpinner ? <Spinner color="white" style={{width: "18px", height: "18px"}} /> : props.children}
    </button>
};

export default StyledButton;

StyledButton.propTypes = {
    isSecondary: propTypes.bool,
    isSpinner: propTypes.bool,
    isDisabled: propTypes.bool
};